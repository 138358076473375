import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from 'react-bootstrap'

import { teamPropTypes } from '../propTypes'

import Team from './Team'

const TeamList = ({ teams, onChange, value }) => {
  const handleChange = (event) => {
    const newValue = parseInt(event.target.value)

    onChange(newValue)
  }

  return (
    <FormGroup>
      {teams.map(team => (
        <Team
          key={team.id}
          team={team}
          onChange={handleChange}
          selected={team.id === value}
        />
      ))}
    </FormGroup>
  )
}

TeamList.propTypes = {
  teams: PropTypes.arrayOf(teamPropTypes).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number
}

TeamList.defaultProps = {
  value: null
}

export default TeamList
