/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import ModalButton from './ModalButton'
import ClipboardWidget from './ClipboardWidget'

const i18noptions = {
  scope: 'components.direct_link_widget'
}

const DirectLinkModal = ({
  children,
  clipboardText,
  modalButtonText,
  copiedMessage,
  ...buttonProps
}) => (
  <ModalButton
    buttonText={modalButtonText}
    {...buttonProps}
  >
    <Modal.Header closeButton>
      <Modal.Title>{modalButtonText}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <ClipboardWidget
        text={clipboardText}
        copiedMessage={copiedMessage}
      />
      {children}
    </Modal.Body>
  </ModalButton>
)

DirectLinkModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  clipboardText: PropTypes.string.isRequired,
  copiedMessage: PropTypes.string,
  modalButtonText: PropTypes.string
}

DirectLinkModal.defaultProps = {
  children: [],
  copiedMessage: I18n.t('copied', i18noptions),
  modalButtonText: I18n.t('direct_link', i18noptions)
}


export default DirectLinkModal
