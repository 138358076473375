import { createSelector } from 'redux-orm'

import { orm } from '../../../../store'

const ormStateSelector = state => state.orm

const bundlesSelector = createSelector(
  orm,
  ormStateSelector,
  session => session.Bundle.all().toRefArray()
)

export default bundlesSelector
