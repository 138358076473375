import React from 'react'
import { bool, string, func, node, arrayOf, oneOfType } from 'prop-types'

import { Button } from 'react-bootstrap'

class ToggleButton extends React.PureComponent {
  constructor(props) {
    super(props)

    const { defaultChecked } = props

    this.state = {
      checked: defaultChecked || false
    }
  }

  handleToggle = () => {
    const { onToggle } = this.props
    const { checked } = this.state

    const newChecked = !checked

    if (onToggle !== null) {
      onToggle(newChecked)
    }

    this.setState({
      checked: newChecked
    })
  }

  render() {
    const { checkedContent, uncheckedContent, bsStyle, onToggle: _, ...otherProps } = this.props
    const { checked } = this.state

    return (
      <Button
        onClick={this.handleToggle}
        bsStyle={bsStyle}
        {...otherProps}
      >
        {checked ? checkedContent : uncheckedContent}
      </Button>
    )
  }
}

ToggleButton.propTypes = {
  checkedContent: oneOfType([node, arrayOf(node)]).isRequired,
  uncheckedContent: oneOfType([node, arrayOf(node)]).isRequired,
  onToggle: func,
  bsStyle: string,
  defaultChecked: bool
}

ToggleButton.defaultProps = {
  onToggle: null,
  bsStyle: 'white',
  defaultChecked: true
}

export default ToggleButton
