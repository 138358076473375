import React from 'react'
import SelectableBundle from './components/SelectableBundle'
import ShiftList from '../../../../../../../components/ShiftList'
import BundleDetails from './components/BundleDetails'

const BundlesList = ({ bundles }) => (
  <ShiftList>
    { bundles.map(bundle => (
      <SelectableBundle
        key={bundle.id}
        bundle={bundle}
      >
        <BundleDetails bundle={bundle} />
      </SelectableBundle>
      )) }
  </ShiftList>
  )

export default BundlesList
