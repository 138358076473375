import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const MaskedTimeInput = ({ value, ...otherProps }) => {
  const autoCorrectedTimePipe = createAutoCorrectedDatePipe('HH:MM')

  const formattedValue = value ? format(value, 'HH:mm') : ''

  return (
    <MaskedInput
      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
      placeholder="HH:MM"
      pipe={autoCorrectedTimePipe}
      value={formattedValue}
      className="form-control"
      keepCharPositions
      {...otherProps}
    />
  )
}

MaskedTimeInput.defaultProps = {
  value: null
}

MaskedTimeInput.propTypes = {
  value: PropTypes.instanceOf(Date)
}

export default MaskedTimeInput
