import React from 'react'

import Shift from '../../../components/Shift'
import PreferShiftButton from './components/PreferShiftButton'
import SelectShiftButton from './components/SelectShiftButton'

const SelectableShift = ({ children, shift }) => {
  const { selectionType } = shift
  const actionButton = selectionType === 'direct' ?
    <SelectShiftButton shift={shift} /> : <PreferShiftButton shift={shift} />

  const style = shift.grade ? { borderLeftWidth: '5px', borderLeftColor: shift.grade.color } : null

  return (
    <Shift actionButton={actionButton} style={style}>
      {children}
    </Shift>
  )
}

export default SelectableShift
