/* global moment */

import { ORM, Model, attr, many, fk } from 'redux-orm'
import { polyFk } from './PolymorphicForeignKey'

class Team extends Model {
  get momentCreatedAt() {
    return moment(this.ref.createdAt)
  }
}
Team.modelName = 'Team'
Team.fields = {
  id: attr(),
  name: attr(),
  group: fk('TeamGroup', 'teams')
}

class TeamGroup extends Model {}
TeamGroup.modelName = 'TeamGroup'
TeamGroup.fields = {
  id: attr(),
  name: attr()
}

class Schedule extends Model {}
Schedule.modelName = 'Schedule'
Schedule.fields = {
  id: attr(),
  name: attr(),
  startingAt: attr(),
  selectionType: attr(),
  team: fk('Team', 'schedules')
}

class Property extends Model {}
Property.modelName = 'Property'
Property.fields = {
  id: attr(),
  name: attr()
}

class MemberType extends Model {}
MemberType.modelName = 'MemberType'
MemberType.fields = {
  id: attr(),
  name: attr(),
  propertyIds: attr(),
  properties: many('Property', 'memberTypes')
}

class Shift extends Model {
  attendanceCount() {
    return this.attendances.count()
  }

  availableSpots() {
    return this.ref.attendanceLimit - this.attendanceCount()
  }
}

Shift.modelName = 'Shift'
Shift.fields = {
  id: attr(),
  name: attr(),
  startTime: attr(),
  endTime: attr(),
  selected: attr(),
  grade: fk('Grade', 'shifts'),
  bundle: fk('Bundle', 'shifts'),
  schedule: fk('Schedule', 'shifts'),
  team: fk('Team', 'shifts')
}

class Member extends Model {}
Member.modelName = 'Member'
Member.fields = {
  id: attr(),
  email: attr(),
  memberType: fk('MemberType', 'members')
}

class Grade extends Model {}
Grade.modelName = 'Grade'
Grade.fields = {
  id: attr(),
  name: attr(),
  color: attr(),
  schedule: fk('Schedule', 'grades')
}

class Bundle extends Model {}
Bundle.modelName = 'Bundle'
Bundle.fields = {
  id: attr(),
  name: attr(),
  color: attr(),
  schedule: fk('Schedule', 'bundles'),
  selectionType: attr(),
  selected: attr(),
  availableSpots: attr()
}

class Attendance extends Model {}
Attendance.modelName = 'Attendance'
Attendance.fields = {
  id: attr(),
  leader: attr(),
  member: fk('Member', 'attendances'),
  requirement: fk('Requirement', 'attendances'),
  shift: fk('Shift', 'attendances')
}

class Preference extends Model {}
Preference.modelName = 'Preference'
Preference.fields = {
  id: attr(),
  memberId: attr(),
  member: fk('Member', 'preferences'),
  shift: fk('Shift', 'preferences')
}

class Requirement extends Model {
  attendanceCount() {
    return this.attendances.count()
  }
}

Requirement.modelName = 'Requirement'
Requirement.fields = {
  id: attr(),
  requiredNumber: attr(),
  requiredValue: attr(),
  requirementable: polyFk(['Shift', 'Bundle'], 'requirements'),
  property: fk('Property', 'requirements')
}

class GuestList extends Model {}
GuestList.modelName = 'GuestList'
GuestList.fields = {
  id: attr(),
  name: attr(),
  group: fk('GuestListGroup', 'guestLists'),
  owner: polyFk(['Member', 'GuestListOwner'], 'guestLists')
}

class GuestListGroup extends Model {}
GuestListGroup.modelName = 'GuestListGroup'
GuestListGroup.fields = {
  id: attr(),
  name: attr()
}

class GuestListOwner extends Model {}
GuestListOwner.modelName = 'GuestListOwner'
GuestListOwner.fields = {
  id: attr(),
  email: attr(),
  name: attr()
}

const orm = new ORM()
orm.register(
  Shift,
  Member,
  Schedule,
  MemberType,
  Property,
  Grade,
  Bundle,
  Attendance,
  Requirement,
  Preference,
  GuestList,
  GuestListGroup,
  GuestListOwner,
  Team,
  TeamGroup
)

export default orm
