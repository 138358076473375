/* global I18n */

import { createSelector } from 'reselect'
import getShifts from './getShifts'
import {
  shiftHasAvailableSpots,
  memberHasEnoughAvailableHours,
  memberCanSelectShiftWithGrade,
  memberHasNoDoubledBookedShifts
} from '../../utilities/eligibilityChecks'

const getCurrentMember = state => state.currentMember

const getShiftsWithEligibility = createSelector(
  getShifts,
  getCurrentMember,
  (shifts, currentMember) => {
    const assignedShifts = shifts.filter(shift => shift.selectionType !== 'preferred' && shift.selected)
    const totalAssignedHours = assignedShifts
      .map(shift => shift.duration)
      .reduce((a, b) => a + b, 0)

    return shifts.map((shift) => {
      if (!shiftHasAvailableSpots(shift, currentMember)) {
        return ({
          ...shift,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.shift.no_available_spots')
        })
      } else if (shift.selectionType === 'direct' && !memberHasEnoughAvailableHours(shift.duration, currentMember, totalAssignedHours)) {
        return ({
          ...shift,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.shift.not_enough_hours_left')
        })
      } else if (shift.selectionType === 'direct' && shift.grade && !memberCanSelectShiftWithGrade(shift, assignedShifts)) {
        return ({
          ...shift,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.shift.not_enough_spots_with_grade_left')
        })
      } else if (!memberHasNoDoubledBookedShifts(assignedShifts, [shift])) {
        return ({
          ...shift,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.shift.no_double_booking')
        })
      }
      return ({
        ...shift,
        eligible: true,
        ineligibilityReason: null
      })
    })
  }
)

export default getShiftsWithEligibility
