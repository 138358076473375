import GuestListApi from '../apis/GuestListApi'
import guestListActions from './guestListActions'

const {
  requestGuestLists,
  receiveGuestLists
} = guestListActions

const api = new GuestListApi()

export const fetchGuestLists = params => (dispatch) => {
  dispatch(requestGuestLists())

  const { request, abort } = api.fetchAll(params)

  request.then(response => dispatch(receiveGuestLists(response.data)))

  return { request, abort }
}
