import { createSelector } from 'redux-orm'

import shiftRecordToObject from './shiftRecordToObject'
import { orm } from '../../../../store'

const ormStateSelector = state => state.orm
const bundleIdSelector = (state, id) => id

const shiftsInBundleSelector = createSelector(
  orm,
  ormStateSelector,
  bundleIdSelector,
  (session, bundleId) => session.Bundle.withId(bundleId).shifts.toModelArray().map(shift => (
    shiftRecordToObject(shift, { gradesList: true, bundlesList: true, membersList: true })
  ))
)

export default shiftsInBundleSelector
