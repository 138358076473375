/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { snakeCase } from 'lodash'

import {
  Modal,
  Button,
  ButtonGroup,
  Alert
} from 'react-bootstrap'

import LoadingIndicator from './LoadingIndicator'
import ButtonIcon from './ButtonIcon'

class ModalForm extends React.PureComponent {
  state = { closing: false }

  handleClose = () => {
    this.setState({
      closing: true
    })
  }

  handleExited = () => {
    const { onExited } = this.props

    onExited()

    this.setState({
      closing: false
    })
  }

  handleSubmit = (event) => {
    const { onSubmit } = this.props

    event.preventDefault()
    onSubmit(event)
  }

  renderHeaderButtons() {
    const { headerButtons, isLoading } = this.props

    return React.Children.map(headerButtons, button => (
      React.cloneElement(button, {
        disabled: isLoading
      })
    ))
  }

  render() {
    const {
      onSubmit: _,
      children,
      body,
      isLoading,
      newRecord,
      model,
      headerButtons,
      title,
      modelName,
      errors,
      ...otherProps
    } = this.props
    const { closing } = this.state

    const hasHeaderButtons = headerButtons && headerButtons.length > 0
    const translatedModelName = modelName || (model ? I18n.t(`activerecord.models.${snakeCase(model)}.one`) : null)

    return (
      <Modal
        show={!closing}
        onHide={this.handleClose}
        onExited={this.handleExited}
        {...otherProps}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton={!hasHeaderButtons}>
            {hasHeaderButtons && (
              <ButtonGroup className="pull-right">
                {this.renderHeaderButtons()}
                <ButtonIcon
                  key="close"
                  bsStyle="default"
                  onClick={this.handleClose}
                  icon="times"
                  disabled={isLoading}
                />
              </ButtonGroup>
            )}
            <Modal.Title componentClass="h3">
              {title || I18n.t(`helpers.actions.${newRecord ? 'new' : 'edit'}`, { model: translatedModelName })}
            </Modal.Title>
          </Modal.Header>
          {body || (
            <Modal.Body
              className={isLoading ? 'is-loading' : null}
            >
              {errors && (
                <Alert bsStyle="danger">
                  {errors.join(', ')}
                </Alert>
              )}
              {children}
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              className="pull-left"
              bsStyle="default"
              onClick={this.handleClose}
              disabled={isLoading}
            >
              {I18n.t('helpers.cancel')}
            </Button>
            <Button
              type="submit"
              bsStyle="primary"
              disabled={isLoading}
            >
              {isLoading && <LoadingIndicator nested /> && ' '}
              {I18n.t(`helpers.submit.${newRecord ? 'create' : 'update'}`, { model: translatedModelName })}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

ModalForm.propTypes = {
  onExited: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  body: PropTypes.node,
  isLoading: PropTypes.bool,
  newRecord: PropTypes.bool,
  model: PropTypes.string,
  modelName: PropTypes.string,
  title: PropTypes.string,
  headerButtons: PropTypes.arrayOf(PropTypes.element)
}

ModalForm.defaultProps = {
  onExited: null,
  onSubmit: null,
  children: '',
  body: null,
  isLoading: false,
  newRecord: true,
  model: null,
  title: null,
  modelName: null,
  headerButtons: []
}

export default ModalForm
