import Api, { RestPath } from '../Api'
import { getRequest } from '../Api/request'

class ShiftOverviewApi extends Api {
  constructor() {
    const path = new RestPath('shifts')
    super(path)
  }

  fetchAll(params) {
    return getRequest({
      path: this.path.collectionPath(),
      params: Object.assign({}, {
        include: 'schedule,team',
        fields: {
          schedules: 'id,name',
          teams: 'id,name'
        }
      }, params),
      abortable: true
    })
  }
}

export default ShiftOverviewApi
