import AssignedList from './AssignedList'
import SuggestedList from './SuggestedList'

import './styles.scss'

const Attendances = {}

Attendances.AssignedList = AssignedList
Attendances.SuggestedList = SuggestedList

export default Attendances
