/* global Reflux, _, $ */
import CustomFormActions from './custom_form_actions';

function getPageByNumber(pages, pageNumber) {
  return _.find(pages, function (page) {
    return page.number === pageNumber;
  });
}

export default Reflux.createStore({
  listenables: [CustomFormActions],
  custom_form: {
    static_page_descriptions: {},
    pages: []
  },
  deleted_pages: [],
  member_type_properties_store: null,

  setCustomForm: function (custom_form, member_type_properties_store) {
    this.custom_form = custom_form;
    //console.log("custom_form", this.custom_form);
    this.member_type_properties_store = member_type_properties_store;
    _.each(this.custom_form.pages, function (page) {
      page.fields = page.field_ids.map(function (field) { return parseInt(field); });
    });
    this.custom_form.pages = _.sortBy(this.custom_form.pages, function (page) { return page.number });
    this.trigger(this.custom_form);
  },

  onUpdateFieldPosition: function (page_number, field_id, position) {
    _.each(this.custom_form.pages, function (page) {
      page.fields = _.without(page.fields, field_id);
    });

    var page = getPageByNumber(this.custom_form.pages, page_number);
    page.fields.splice(position, 0, field_id);

    this.trigger(this.custom_form);
  },

  onRemoveField: function (page_number, field_id) {
    var page = getPageByNumber(this.custom_form.pages, page_number);
    page.fields = _.without(page.fields, field_id);
    this.trigger(this.custom_form);
  },

  onUpdatePageDescription: function (page_number, locale, description) {
    var page = getPageByNumber(this.custom_form.pages, page_number);
    page['description_' + locale] = description;
    this.trigger(this.custom_form);
  },

  onUpdateStaticPageDescription: function (key, locale, description) {
    if (!this.custom_form.static_page_descriptions[key]) {
      this.custom_form.static_page_descriptions[key] = {}
    }
    this.custom_form.static_page_descriptions[key][locale] = description;
    this.trigger(this.custom_form);
  },

  onAddPage: function () {
    var new_page_number = this.custom_form.pages.length + 1;
    this.custom_form.pages.push({ number: new_page_number, description: "", fields: [] });

    this.trigger(this.custom_form);
  },

  onRemovePage: function (page_number) {
    var removed_page;
    this.custom_form.pages = _.reject(this.custom_form.pages, function (page) {
      if (page.number === page_number) {
        removed_page = page;
      }
      return page.number === page_number;
    });
    removed_page._destroy = 1;
    this.deleted_pages.push(removed_page);
    this.updatePageNumbers();
    this.trigger(this.custom_form);
  },

  onSaveCustomForm: function (url) {
    if (this.member_type_properties_store.getRemainingRequiredMemberTypeProperties().length) {
      if (!confirm("You have left some required attributes out of the form. This form might not work as expected")) {
        return;
      }
    }

    this.custom_form.save_status = "saving";
    this.trigger(this.custom_form);

    $.ajax({
      method: "PUT",
      url: url,
      data: JSON.stringify({ custom_form: { static_page_descriptions: this.custom_form.static_page_descriptions, pages_attributes: this.custom_form.pages.concat(this.deleted_pages) } }),
      dataType: 'text',
      contentType: 'application/json',
      success: function (data) {
        this.custom_form.save_status = "success";
      }.bind(this),
      error: function (data) {
        this.custom_form.save_status = "error";
      }.bind(this),
      complete: function (data) {
        this.trigger(this.custom_form);
        _.delay(function () {
          this.custom_form.save_status = false;
          this.trigger(this.custom_form);
        }.bind(this), 2000);
      }.bind(this)
    });
  },

  updatePageNumbers: function () {
    _.each(this.custom_form.pages, function (page, i) {
      page.number = i + 1;
    });
  },

  getCurrentFieldIds: function () {
    var currentFieldIds = _.uniq(_.flatten(this.custom_form.pages.map(function (page) {
      return page.fields;
    })));

    return currentFieldIds;
  },

  getInitialState: function () {
    return this.custom_form;
  }
});
