import React from 'react'

import Effect from './Effect'

const Fade = props => (
  <Effect
    transitionStyles={() => ({
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 }
    })}
    transitionAttribute="opacity"
    {...props}
  />
)

export default Fade
