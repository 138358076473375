import React from 'react'
import PropTypes from 'prop-types'

import FontAwesome from 'react-fontawesome'
import { InputGroup } from 'react-bootstrap'

import { FieldGroup, FormControl } from './formExtensions'
import MaskedDateInput from './MaskedDateInput'
import MaskedTimeInput from './MaskedTimeInput'

class DateTimeInput extends React.PureComponent {
  render() {
    const {
      value,
      dateValue,
      timeValue,
      onDateChange,
      onTimeChange,
      withIcon,
      name,
      ...fieldGroupProps
    } = this.props

    return (
      <FieldGroup
        name={name}
        {...fieldGroupProps}
      >
        <InputGroup>
          {withIcon && (
            <InputGroup.Addon>
              <FontAwesome name="calendar" />
            </InputGroup.Addon>
          )}
          <FormControl.Container>
            <MaskedDateInput
              value={dateValue !== null ? dateValue : value}
              onFocus={this.handleFocus}
              onBlur={onDateChange}
              name={`${name}[date]`}
              className="form-control-nested"
            />
          </FormControl.Container>
          {withIcon && (
            <InputGroup.Addon>
              <FontAwesome name="clock-o" />
            </InputGroup.Addon>
          )}
          <MaskedTimeInput
            value={timeValue !== null ? timeValue : value}
            disabled={value === null && timeValue === null}
            name={`${name}[time]`}
            onBlur={onTimeChange}
          />
        </InputGroup>
      </FieldGroup>
    )
  }
}

DateTimeInput.defaultProps = {
  withIcon: true,
  value: null,
  dateValue: null,
  timeValue: null,
  onDateChange: null,
  onTimeChange: null,
  name: null
}

DateTimeInput.propTypes = {
  withIcon: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  dateValue: PropTypes.instanceOf(Date),
  timeValue: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  onTimeChange: PropTypes.func,
  name: PropTypes.string
}

export default DateTimeInput
