import React from 'react'

import BaseShiftMonthEvent from './ShiftMonthEvent'
import BaseShiftStandardEvent from './ShiftStandardEvent'
import BaseShiftEvent from './BaseShiftEvent'

import './styles.scss'

export const ShiftMonthEvent = props => (
  <BaseShiftEvent {...props} component={BaseShiftMonthEvent} />
)

export const ShiftStandardEvent = props => (
  <BaseShiftEvent {...props} component={BaseShiftStandardEvent} />
)
