/* global React, Reflux, I18n */

import Actions from './Actions';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [
    Reflux.ListenerMixin
  ],

  modalRef: null,

  getInitialState: function () {
    return {
      chipActivated: false
    };
  },

  componentDidMount: function () {
    $('#event-pos-modal').on('shown.bs.modal', () => {
      this.focusOnNfcInput();
    });

    $('#event-pos-modal').on('hidden.bs.modal', () => {
      Actions.reset();
    });

    this.listenTo(Actions.showEventPosModal, function (chipActivated) {
      this.setState({
        chipActivated: chipActivated,
      });

      $(this.modalRef).modal({
        backdrop: 'static',
        keyboard: false
      });
    });

    this.listenTo(Actions.dismissEventPosModal, function () {
      $(this.modalRef).modal("hide");
    });
  },

  focusOnNfcInput: function () {
    $('input#nfc').focus();
  },

  handleSubmitNfc: function (event) {
    event.preventDefault();
    const nfcValue = event.target.nfc.value;

    let valid = true
    if (nfcValue && nfcValue.length > 0) {
      valid = this.validateNfc(nfcValue)
    }

    if (valid) {
      Actions.activateChip(nfcValue);
    }
  },

  validateNfc: function (nfcValue) {
    // Disable this because -someone- forgot that they bought different chips this year where the ID includes letters
    // if (!/^\d+$/.test(nfcValue)) {
    //   alert(I18n.t('tickets_scanner.event_pos_modal.error_not_a_number'));
    //   return false;
    // }

    // The same someone also forgot that they bought chips with different lengths
    if (nfcValue.length < 10 || nfcValue.length > 16) {
      alert(I18n.t('tickets_scanner.event_pos_modal.error_nfc_length'));
      return false;
    }

    return true;
  },

  handleCancel: function () {
    Actions.dismissEventPosModal();
  },

  render: function () {
    return (
      <div
        ref={(ref) => { this.modalRef = ref; }}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        id="event-pos-modal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {I18n.t('tickets_scanner.event_pos_modal.title')}
              </h4>
            </div>
            <div className="modal-body">
              <form onSubmit={this.handleSubmitNfc}>
                <div className="form-group">
                  <label htmlFor="nfc">{I18n.t('tickets_scanner.event_pos_modal.label')}</label>
                  <input
                    name="nfc"
                    id="nfc"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-block btn-success">
                    {this.state.chipActivated ? I18n.t('tickets_scanner.event_pos_modal.replace_chip') : I18n.t('tickets_scanner.event_pos_modal.activate_chip')}
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="btn btn-block btn-primary" onClick={this.handleCancel}>
                {I18n.t('tickets_scanner.event_pos_modal.cancel')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
})
