import { connect } from 'react-redux'

import { setMembersFiltering, setMembersSorting, setIncludeExternalShifts } from '../../../actions/member-actions'

import Form from './Form'

const mapDispatchToProps = dispatch => ({
  onChangeFilter: text => dispatch(setMembersFiltering(text)),
  onChangeSorting: method => dispatch(setMembersSorting(method)),
  onChangeIncludeExternalShifts: includeExternalShifts => (
    dispatch(setIncludeExternalShifts(includeExternalShifts))
  )
})

export default connect(null, mapDispatchToProps)(Form)
