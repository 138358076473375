import { DndProvider } from 'react-dnd15'
import { HTML5Backend } from 'react-dnd-html5-backend15'
import React from 'react'
import PropTypes from 'prop-types'
import TicketDesigner from './components/TicketDesigner'
import './styles.css'

const TicketDesignerApp = ({
  templateSignedId,
  availableFields,
  elementsData
}) => {
  const handleSave = async (designData) => {
    console.log('Saving design:', designData)

    // Extract template ID using regex for more reliability
    const matches = window.location.pathname.match(/\/ticket_templates\/(\d+)/)
    const templateId = matches ? matches[1] : null
    if (!templateId) {
      console.error('Could not extract template ID from URL')
      return
    }

    try {
      const response = await fetch(`/ticket_templates/${templateId}`, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          ticket_template: designData
        })
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      console.log('Template saved successfully:', data)
    } catch (error) {
      console.error('Error saving template:', error)
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="ticket-designer-container">
        <TicketDesigner
          availableFields={availableFields}
          templateSignedId={templateSignedId}
          elementsData={elementsData}
          onSave={handleSave}
        />
      </div>
    </DndProvider>
  )
}

TicketDesignerApp.propTypes = {
  templateSignedId: PropTypes.string.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  elementsData: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default TicketDesignerApp
