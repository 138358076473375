import React from 'react'
import { element, bool, string } from 'prop-types'
import classNames from 'classnames'

import { Highlight } from '../../../../../../components/effects'

const HighlightableShiftWrapper = ({ loading, children, className }) => {
  const child = React.Children.only(children)
  const childWithProps = React.cloneElement(child, {
    className: classNames(className, child.props.className, {
      loading
    })
  })

  return (
    <Highlight active={!loading}>
      <div>
        {childWithProps}
      </div>
    </Highlight>
  )
}


HighlightableShiftWrapper.propTypes = {
  children: element.isRequired,
  loading: bool.isRequired,
  className: string
}

HighlightableShiftWrapper.defaultProps = {
  className: null
}

export default HighlightableShiftWrapper
