/* global I18n */

import React, { Component } from 'react'
import { Tab, Nav, NavItem, Panel } from 'react-bootstrap'

import ScheduleGroups from './ScheduleGroups'
import DateGroups from './DateGroups'

const i18noptions = {
  scope: 'components.grouped_shifts_for_registration'
}


class GroupedShiftsForRegistration extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: ['schedule', 'date'].includes(this.getHashState('tab')) ? this.getHashState('tab') : 'schedule',
      activeGroup: this.getHashState('group')
    }
  }

  getHashState = (key) => {
    const parsedHash = new URLSearchParams(
      window.location.hash.substring(1)
    )

    return parsedHash.get(key)
  }

  setHashState = (key, value) => {
    const parsedHash = new URLSearchParams(
      window.location.hash.substring(1)
    )

    if (value) {
      parsedHash.set(key, value)
    } else {
      parsedHash.delete(key)
    }

    history.pushState({ turbolinks: {} }, "", `#${parsedHash.toString()}`);
  }

  handleTabSelect = (selectedTab) => {
    this.setHashState('tab', selectedTab)
    this.setState({
      activeTab: selectedTab
    })
  }

  handleGroupSelect = (selectedGroup, expanded) => {
    if (expanded) {
      this.setHashState('group', selectedGroup)
      this.setState({
        activeGroup: selectedGroup
      })
    } else {
      this.setHashState('group', null)
      this.setState({
        activeGroup: null
      })
    }

  }

  render() {
    const { activeTab, activeGroup } = this.state

    return (
      <Tab.Container
        activeKey={activeTab}
        onSelect={this.handleTabSelect}
        id="grouped-shifts-for-registration"
        className="tabs-container"
      >
        <div>
          <Nav bsStyle="tabs">
            <NavItem eventKey="schedule">{I18n.t('schedule', i18noptions)}</NavItem>
            <NavItem eventKey="date">{I18n.t('date', i18noptions)}</NavItem>
          </Nav>
          <Tab.Content animation mountOnEnter>
            <Tab.Pane eventKey="schedule" title={I18n.t('schedule', i18noptions)}>
              <Panel.Body>
                <ScheduleGroups handleGroupSelect={this.handleGroupSelect} activeGroup={activeGroup} />
              </Panel.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="date" title={I18n.t('date', i18noptions)}>
              <Panel.Body>
                <DateGroups handleGroupSelect={this.handleGroupSelect} activeGroup={activeGroup} />
              </Panel.Body>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    )
  }
}

export default GroupedShiftsForRegistration
