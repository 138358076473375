import { createSelector } from 'redux-orm'
import { orm } from '../../../../store'

const ormStateSelector = state => state.orm

const propertiesSelector = createSelector(
  orm,
  ormStateSelector,
  session => session.Property.all().toRefArray()
)

export default propertiesSelector
