class RestPath {
  constructor(resource, nestedPath = null) {
    this.resource = resource
    this.nestedPath = nestedPath
  }

  collectionPath(parentId = null) {
    if (parentId === null) {
      return this.resource
    }

    return `${this.nestedPath.memberPath(parentId)}/${this.resource}`
  }

  memberPath(id) {
    return `${this.resource}/${id}`
  }
}

export default RestPath
