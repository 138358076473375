import React from 'react'
import { connect } from 'react-redux'
import { bool, number, func, arrayOf, object } from 'prop-types'
import { fetchTeams } from '../../actions/team-actions'
import getTeams from '../../selectors/teams/getTeams'

import TeamTable from './TeamTable'
class TeamTableWidget extends React.Component {
  handleFetchData = (params) => {
    const { onFetchTeams } = this.props

    return onFetchTeams({ ...params })
  }

  render() {
    const { teams, loading, pages } = this.props
    const tableProps = {
      teams,
      loading,
      pages,
      handleFetchData: this.handleFetchData
    }

    return (
      <TeamTable
        stateKey="ManagedTeamTable"
        {...tableProps}
      />
    )
  }
}

TeamTableWidget.propTypes = {
  teams: arrayOf(object).isRequired,
  pages: number,
  loading: bool.isRequired,
  onFetchTeams: func.isRequired
}

TeamTableWidget.defaultProps = {
  pages: null
}

const mapStateToProps = (state) => {
  const { teamsList: loading } = state.loadingStatus
  const { total: pages } = state.orm.Team.meta.page || {}

  return {
    teams: getTeams(state),
    pages,
    loading
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchTeams: params => dispatch(fetchTeams(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamTableWidget)
