/* global React, ReactDOM, $ */
import MemberTypePropertiesListItem from './member_type_properties_list_item';

import createReactClass from 'create-react-class';

export default createReactClass({
  componentDidMount: function () {

    $(ReactDOM.findDOMNode(this)).sortable({
      connectWith: '.custom_form_fields_list',
      revert: 0,
      helper: 'clone',
      start: function (e, ui) {
        $(ui.item).show();
      },
      stop: function (e, ui) {
        if (ui.item.parent().is(this)) $(this).sortable("cancel");
      }
    });
  },

  render: function () {
    var member_type_property_nodes = this.props.member_type_properties.map(function (member_type_property) {
      return (
        <MemberTypePropertiesListItem key={member_type_property.id} member_type_property={member_type_property} />
      );
    });

    return (
      <ul className="member_type_properties_list">
        {member_type_property_nodes}
      </ul>
    );
  }
})
