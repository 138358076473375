/* global Widgets */

import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

import ModalButton from '../components/ModalButton'

import generateId from '../utilities/generateId'

class HelpGuideWidget extends React.Component {
  constructor(props) {
    super(props)

    this.iframeId = generateId('iframe-video')
  }

  render() {
    const { showModal, videoId, text, title, track } = this.props

    const jsApiParam = track ? `&enablejsapi=1&origin=${window.location.origin}` : ''

    return (
      <ModalButton
        buttonText={<FontAwesome name="question-circle" size="lg" />}
        bsStyle="link"
        showModal={showModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {text && (
            <p>
              {text}
            </p>
          )}
          {videoId && (
            <p>
              <div className="embed-responsive embed-responsive-4by3">
                <iframe
                  data-track={track}
                  id={this.iframeId}
                  src={`https://www.youtube.com/embed/${videoId}?rel=0${jsApiParam}`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              </div>
            </p>
          )}
        </Modal.Body>
      </ModalButton>
    )
  }
}

HelpGuideWidget.defaultProps = {
  showModal: false,
  videoId: null,
  text: null,
  track: false
}

HelpGuideWidget.propTypes = {
  showModal: PropTypes.bool,
  videoId: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  track: PropTypes.bool
}

export default HelpGuideWidget
