/* global React, I18n */

import GroupedTicketItems from './GroupedTicketItems';

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    var teamsList = this.props.member.teams.map(function (team) {
      return team.name + " (" + team.group_name + ")";
    }).join(', ');

    var teams = "";
    if (this.props.member.teams.length > 0) {
      teams =
        <p>
          <b>{I18n.t('tickets_scanner.owner_info.teams')}: </b>{teamsList}
        </p>
    };

    var accepted_shifts;
    if (this.props.member.shifts_accepted) {
      accepted_shifts = <i className="fa fa-check text-navy"></i>
    } else {
      accepted_shifts = <i className="fa fa-times text-danger"></i>
    };


    var properties = this.props.member.properties.map(function (property) {
      if (property.type === 'MemberTypeProperties::CustomImage') {
        return (
          <div className="col-md-3" key={property.name}>
            <strong>{property.name}: </strong>
            <a href={property.url} target="_blank">{I18n.t('tickets_scanner.owner_info.view_image')}</a>
          </div>
        )
      } else {
        return (
          <div className="col-md-3" key={property.name}>
            <strong>{property.name}: </strong><span dangerouslySetInnerHTML={{ __html: property.value || '-' }}></span>
          </div>
        )
      }
    });

    var shifts = this.props.member.shifts.map((shift) => {
      return (
        <div className="col-md-12" style={{ display: "flex" }}>
          <div style={{ width: "11em", textTransform: "capitalize" }}>
            {moment.utc(shift.start_time).format('dddd ll')}
          </div>
          <a href={`/shifts/${shift.shift_id}/registration`} target="_blank" style={{ textDecoration: "none" }}>
            <div style={{ marginRight: "1em" }}>
              {moment.utc(shift.start_time).format('LT')} - {moment.utc(shift.end_time).format('LT')}
            </div>
          </a>
          <div>
            {shift.shown === false ? <i className="fa fa-times text-danger"></i> : shift.completed ? <i className="fa fa-check text-navy"></i> : null}
          </div>
          <div style={{ color: "#666" }}>
            {shift.meeting_point && `(${shift.meeting_point})`}
          </div>
        </div>
      )
    })

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src={this.props.member.profile_image || 'https://placehold.co/150x150?text=?'}
              className="profile-image img-circle" />
            <h2>{this.props.member.name}</h2>
            <p>{this.props.member.email}</p>
            <p>
              <b>{I18n.t('tickets_scanner.owner_info.accepted_shifts')}: </b>
              {accepted_shifts}
            </p>
            <p>
              <b>{I18n.t('tickets_scanner.owner_info.member_type')}: </b>
              {this.props.member.member_type_name}
            </p>
            <p>
              <b>{I18n.t('tickets_scanner.owner_info.total_shift_hours')}: </b>
              {this.props.member.published_shift_hours}
            </p>
            <p>
              <b>{I18n.t('tickets_scanner.owner_info.total_shift_count')}: </b>
              {this.props.member.published_shift_count}
            </p>
            {this.props.member.current_or_next_shift && (
              <p>
                <b>{I18n.t('tickets_scanner.owner_info.current_or_next_shift')}: </b>
                {this.props.member.current_or_next_shift.start_time} -
                {this.props.member.current_or_next_shift.end_time}
              </p>
            )}
            {teams}
          </div>
          <div className="col-xs-12 col-md-6">
            {this.props.groupedTicketItems.length > 0 ?
              <GroupedTicketItems groupedTicketItems={this.props.groupedTicketItems} /> :
              ''}
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          {properties}
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12"><b>{I18n.t('tickets_scanner.owner_info.shifts')}: </b></div>
          {shifts}
        </div>
      </div>

    );
  }
})
