/* global I18n */

import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import PubSub from 'pubsub-js'

import { getRequest, postRequest } from '../Api/request'

import TeamSelect from '../TeamSelect'
import LoadingIndicator from '../components/LoadingIndicator'

const i18noptions = {
  scope: 'components.force_team_select'
}

class ForceTeamSelect extends Component {
  state = {
    renderTeamSelect: false,
    showModal: true,
    teams: [],
    checkedTeamId: null
  }

  componentDidMount() {
    getRequest({
      path: 'teams/available_for_signup'
    }).then((response) => {
      this.setState({
        renderTeamSelect: true,
        teams: response.data
      })
    })
  }

  onFormSubmit = (event) => {
    event.preventDefault()
    const { checkedTeamId } = this.state

    postRequest({
      path: 'members/forced_team_selection',
      body: { member: { team_id: checkedTeamId } }
    }).then((response) => {
      const location = response.headers.location

      if (location) {
        return window.location.replace(location)
      }

      this.setState({
        showModal: false
      })
    }).catch((error) => {
      PubSub.publish('NOTIFICATION', { type: 'alert', message: (error.response.data.errors.base.join() || error.statusText) })
    })
  }

  handleSelectTeam = (teamId) => {
    this.setState({
      checkedTeamId: teamId
    })
  }

  renderTeamSelect = () => {
    const {
      renderTeamSelect,
      teams,
      checkedTeamId
    } = this.state

    if (!renderTeamSelect) {
      return (
        <LoadingIndicator />
      )
    }

    return (
      <TeamSelect
        teams={teams}
        value={checkedTeamId}
        onChange={this.handleSelectTeam}
      />
    )
  }

  render() {
    const { showModal } = this.state

    return (
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>
            {I18n.t('title', i18noptions)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.onFormSubmit}>
            {this.renderTeamSelect()}
          </form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ForceTeamSelect
