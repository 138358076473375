import { createSelector } from 'reselect'
import getShiftsWithEligibility from './getShiftsWithEligibility'

const getFilter = state => state.currentApp.filters.ineligibilityFilter

const getVisibleShifts = createSelector(
  getShiftsWithEligibility,
  getFilter,
  (shifts, filter) => {
    switch (filter) {
      case 'SHOW_ALL':
        return shifts
      case 'HIDE_INELIGIBLE':
        return shifts.filter(shift => shift.eligible)
      default:
        throw new Error(`Unknown filter: ${filter}`)
    }
  }
)

export default getVisibleShifts
