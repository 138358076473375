import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SelectableShiftStandardEvent, SelectableShiftMonthEvent } from './components/SelectableShiftEvent'
import ShiftCalendar from '../../../../../components/ShiftCalendar'
import SelectableShiftCalendarEventWrapper from './components/SelectableShiftCalendarEventWrapper'

import getUnselectedShiftsWithoutBundle from '../../../../selectors/shifts/getUnselectedShiftsWithoutBundle'

const CalendarView = ({ shifts }) => {
  const startingAt = shifts.map(shift => shift.startTime).sort()[0] || new Date()

  return (
    <ShiftCalendar
      defaultDate={startingAt}
      shifts={shifts}
      titleAccessor={shift => shift.name || shift.schedule.name}
      startAccessor={shift => shift.startTime}
      endAccessor={shift => shift.endTime}
      views={['month', 'week']}
      components={{
        event: SelectableShiftStandardEvent,
        eventWrapper: SelectableShiftCalendarEventWrapper,
        month: {
          event: SelectableShiftMonthEvent
        }
      }}
    />
  )
}

CalendarView.defaultProps = {
  shifts: []
}

CalendarView.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = state => ({
  shifts: getUnselectedShiftsWithoutBundle(state)
})

export default connect(
  mapStateToProps
)(CalendarView)
