/* global I18n */

import React from 'react'
import { arrayOf, func, number, objectOf, string } from 'prop-types'

import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap'

import memberTypePropTypes from '../../../../../propTypes/memberTypePropTypes'
import requirementPropTypes from '../../../../../propTypes/requirementPropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form.requirements'
}

const FILTERABLE_PROPERTY_TYPES = [
  'MemberTypeProperties::CustomCheckbox',
  'MemberTypeProperties::CustomSelect',
  'MemberTypeProperties::CustomInteger',
  'MemberTypeProperties::Birthdate'
]

class Field extends React.Component {
  get selectedMemberType() {
    const { requirement, getPropertyForId } = this.props
    const { propertyId } = requirement

    return getPropertyForId(propertyId)
  }

  defaultRequiredValueForPropertyId = (propertyId) => {
    const { getPropertyForId } = this.props
    const property = getPropertyForId(propertyId)
    const { type, data } = property

    switch (type) {
      case 'MemberTypeProperties::CustomCheckbox':
        return true
      case 'MemberTypeProperties::CustomSelect':
        return Object.keys(data.options)[0]
      case 'MemberTypeProperties::CustomInteger':
        return 0
      case 'MemberTypeProperties::Birthdate':
      default:
        return null
    }
  }

  handlePropertyChange = (event) => {
    const { onChange, index } = this.props
    const { value } = event.target

    onChange(index, {
      propertyId: value,
      requiredValue: this.defaultRequiredValueForPropertyId(value)
    })
  }

  handleInputChange = (event) => {
    const { onChange, index } = this.props
    const { value, name, type } = event.target
    let formattedValue = value

    if (type === 'number') {
      formattedValue = parseInt(value)
    }

    onChange(index, {
      [name]: formattedValue
    })
  }

  renderFilterablePropertyOptions = () => {
    const { memberTypes, getPropertyForId } = this.props

    return memberTypes.map(({ id, name, propertyIds }) => (
      <optgroup
        key={id}
        label={name}
      >
        {propertyIds.map((propertyId) => {
          const property = getPropertyForId(propertyId)
          if (property === undefined || !FILTERABLE_PROPERTY_TYPES.includes(property.type)) {
            return null
          }

          const { name: propertyName } = property

          return (
            <option
              key={propertyId}
              value={propertyId}
            >
              {propertyName}
            </option>
          )
        })}
      </optgroup>
    ))
  }

  renderFilter = () => {
    const { requirement } = this.props
    const { type, data } = this.selectedMemberType || {}
    const { requiredValue } = requirement

    switch (type) {
      case 'MemberTypeProperties::CustomCheckbox':
        return (<span> {I18n.t('selected', i18noptions)} </span>)
      case 'MemberTypeProperties::CustomInteger':
        return (
          <span>
            <span> {I18n.t('of_at_least', i18noptions)} </span>
            <FormControl
              value={requiredValue}
              name="requiredValue"
              bsSize="sm"
              style={{ width: '50px', display: 'inline-block' }}
              type="number"
              onChange={this.handleInputChange}
            />
          </span>
        )
      case 'MemberTypeProperties::Birthdate':
        return (
          <div>
            <span> {I18n.t('before', i18noptions)} </span>
            <FormControl
              value={requiredValue}
              name="requiredValue"
              bsSize="sm"
              style={{ width: 'auto', display: 'inline-block' }}
              type="date"
              onChange={this.handleInputChange}
            />
          </div>
        )
      case 'MemberTypeProperties::CustomSelect':
        return (
          <span>
            <span> {I18n.t('of', i18noptions)} </span>
            <FormControl
              value={requiredValue}
              name="requiredValue"
              componentClass="select"
              bsSize="sm"
              style={{ width: 'auto', display: 'inline-block' }}
              onChange={this.handleInputChange}
            >
              {Object.entries(data.options).map(([index, value]) => (
                <option
                  key={index}
                  value={index}
                >
                  {value[I18n.locale]}
                </option>
              ))}
            </FormControl>
          </span>
        )
      default:
        return null
    }
  }

  render() {
    const { requirement, validationErrors } = this.props
    const { requiredNumber, propertyId } = requirement
    const {
      requiredNumber: requiredNumberErrors,
      propertyId: propertyIdErrors
    } = validationErrors || {}

    return (
      <div>
        <FormGroup
          validationState={requiredNumberErrors ? 'error' : null}
        >
          <span> {I18n.t('must_have_at_least', i18noptions)} </span>
          <FormControl
            type="number"
            value={requiredNumber}
            name="requiredNumber"
            bsSize="sm"
            style={{ width: '60px', display: 'inline-block' }}
            onChange={this.handleInputChange}
          />
          <span> {I18n.t('members_with_the_property', i18noptions)} </span>
          {requiredNumberErrors && (
            <HelpBlock>{requiredNumberErrors.join(', ')}</HelpBlock>
          )}
        </FormGroup>
        <FormGroup
          validationState={propertyIdErrors ? 'error' : null}
        >
          <FormControl
            componentClass="select"
            bsSize="sm"
            name="propertyId"
            style={{ width: 'auto', display: 'inline-block' }}
            value={propertyId || ''}
            onChange={this.handlePropertyChange}
          >
            <option value={null}> {I18n.t('choose_a_member_type_property', i18noptions)} </option>
            {this.renderFilterablePropertyOptions()}
          </FormControl>
          {this.renderFilter()}
          {propertyIdErrors && (
            <HelpBlock>{propertyIdErrors.join(', ')}</HelpBlock>
          )}
        </FormGroup>
      </div>
    )
  }
}

Field.propTypes = {
  requirement: requirementPropTypes.isRequired,
  memberTypes: arrayOf(memberTypePropTypes).isRequired,
  getPropertyForId: func.isRequired,
  onChange: func.isRequired,
  index: number.isRequired,
  validationErrors: objectOf(
    arrayOf(string)
  )
}

Field.defaultProps = {
  validationErrors: null
}

export default Field
