import { orm } from '../../../store'
import BundleApi from '../apis/BundleApi'
import bundleActions from '../../../actions/bundleActions'
// import shiftActions from '../../../actions/shiftActions'
import {
  selectingRequirement,
  selectedRequirement
} from '../actions/ui-actions'
import {
  publishResponseErrorNotification
} from '../../../utilities/notifications'
import {
  getSpotsWithoutRequirement,
  getMatchingRequirements
} from '../utilities/requirementsChecks'

const {
  requestBundleUpdate,
  receiveBundleUpdate,
  receiveBundleError
} = bundleActions

export const selectingBundle = requestBundleUpdate
export const deselectingBundle = requestBundleUpdate
export const preferringBundle = requestBundleUpdate
export const unpreferringBundle = requestBundleUpdate
export const selectedBundle = receiveBundleUpdate
export const deselectedBundle = receiveBundleUpdate
export const preferredBundle = receiveBundleUpdate
export const unpreferredBundle = receiveBundleUpdate

const api = new BundleApi()

export const selectBundle = (bundleId, requirementId = null) => (dispatch) => {
  dispatch(selectedRequirement())
  dispatch(selectingBundle(bundleId))
  return api.select(bundleId, {
    body: { requirementId }
  }).then(response => dispatch(selectedBundle(response.data)))
    .catch((error) => {
      publishResponseErrorNotification(error.response.data)
      dispatch(receiveBundleError(bundleId))
    })
}

export const deselectBundle = bundleId => (dispatch) => {
  dispatch(deselectingBundle(bundleId))
  return api.deselect(bundleId)
    .then((response) => {
      dispatch(deselectedBundle(response.data))
    })
}

export const preferBundle = bundleId => (dispatch) => {
  dispatch(preferringBundle(bundleId))
  return api.prefer(bundleId)
    .then((response) => {
      dispatch(preferredBundle(response.data))
    }).catch((error) => {
      const data = error.response.data
      publishResponseErrorNotification(data)
      dispatch(receiveBundleError(bundleId))
    })
}

export const unpreferBundle = bundleId => (dispatch) => {
  dispatch(unpreferringBundle(bundleId))
  return api.unprefer(bundleId)
    .then((response) => {
      dispatch(unpreferredBundle(response.data))
    })
}

export const checkRequirementsAndSelectBundle = bundleId => (dispatch, getState) => {
  const state = getState()
  const bundle = getBundle(bundleId, state)

  if (bundle.requirements.length === 0) {
    dispatch(selectBundle(bundleId))
  } else {
    const currentMember = state.currentMember

    const matchingRequirements = getMatchingRequirements(bundle, currentMember)

    const spotsWithoutRequirement = getSpotsWithoutRequirement(bundle)

    if (spotsWithoutRequirement > 0 && matchingRequirements.length === 0) {
      dispatch(selectBundle(bundleId))
    } else if (selectBundle <= 0 && matchingRequirements.length === 1) {
      dispatch(selectBundle(bundleId, matchingRequirements[0].id))
    } else {
      const noRequirementOption = spotsWithoutRequirement > 0
      dispatch(selectingRequirement('bundle', bundleId, matchingRequirements, noRequirementOption))
    }
  }
}

const getBundle = (bundleId, state) => {
  const session = orm.session(state.orm)
  const { Bundle } = session
  const bundle = Bundle.withId(bundleId)
  return {
    ...bundle.ref,
    requirements: bundle.requirements.toRefArray()
  }
}
