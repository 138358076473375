import React, { useState } from 'react';
import DesignCanvas from './DesignCanvas';
import FieldsList from './FieldsList';
import ImageUploader from './ImageUploader';

// A4 dimensions at 300 DPI
const A4_WIDTH = 2480; // pixels
const A4_HEIGHT = 3508; // pixels

const SAVE_STATES = {
  IDLE: 'idle',
  SAVING: 'saving',
  SAVED: 'saved'
};

const TicketDesigner = ({ availableFields, elementsData, templateSignedId: existingTemplateSignedId, onSave }) => {
  const [templateSignedId, setTemplateSignedId] = useState(existingTemplateSignedId);
  const [placedFields, setPlacedFields] = useState(elementsData);
  const [uploadError, setUploadError] = useState(null);
  const [saveState, setSaveState] = useState(SAVE_STATES.IDLE);

  const handleImageUpload = (result) => {
    if (result.error) {
      setUploadError(result.error);
      // Clear error after 5 seconds
      setTimeout(() => setUploadError(null), 5000);
    } else {
      setTemplateSignedId(result.signedId);
      setUploadError(null);
    }
  };

  const handleFieldDrop = (item, x, y) => {
    const { id, elementType } = item

    const isPlaced = placedFields.some(field => field.id === id);

    // Set default size for QR codes if it's a new field
    const isQrCode = elementType === 'qr' || id.includes('qr');
    const defaultSize = isQrCode ? 100 : undefined;

    if (isPlaced) {
      setPlacedFields(
        placedFields.map(field =>
          field.id === id ? { ...field, x, y } : field
        )
      );
    } else {
      setPlacedFields([
        ...placedFields,
        { ...item, x, y, size: defaultSize }
      ])
    }
  };

  const handleFieldRemove = (itemId) => {
    setPlacedFields(placedFields.filter(field => field.id !== itemId));
  };

  const handleFieldSizeChange = (fieldId, newSize) => {
    setPlacedFields(
      placedFields.map(field =>
        field.id === fieldId ? { ...field, size: newSize } : field
      )
    );
  };

  const handleSave = async () => {
    if (!templateSignedId) return;

    setSaveState(SAVE_STATES.SAVING);

    const designData = {
      template: templateSignedId,
      elements_data: placedFields.map((field) => {
        const baseData = {
          id: field.id,
          name: field.name,
          x: field.x,
          y: field.y,
        };

        // Include size for QR code elements
        if (field.elementType === 'qr' || field.id.includes('qr')) {
          baseData.size = field.size || 100;
        }

        return baseData;
      })
    }

    await onSave(designData);

    setSaveState(SAVE_STATES.SAVED);
    setTimeout(() => setSaveState(SAVE_STATES.IDLE), 2000);
  };

  const getSaveButtonText = () => {
    switch (saveState) {
      case SAVE_STATES.SAVING:
        return 'Saving...';
      case SAVE_STATES.SAVED:
        return 'Saved!';
      default:
        return 'Save Design';
    }
  };

  return (
    <div className="ticket-designer-layout">
      {/* Left Sidebar */}
      <div className="sidebar">
        <div className="sidebar-content">
          {uploadError && (
            <div className="alert alert-danger" role="alert">
              {uploadError}
            </div>
          )}
          <p>Se vores guide til hvilken fil du kan uploade <a target="_blank" rel="noopener noreferrer" href="https://support.heap360.com/help/billetskabeloner">her</a></p>
          <ImageUploader
            onUpload={handleImageUpload}
            hasExistingImage={Boolean(templateSignedId)}
          />
          <button
            onClick={handleSave}
            disabled={!templateSignedId || saveState !== SAVE_STATES.IDLE}
            className="save-button"
          >
            {saveState === SAVE_STATES.SAVING && (
              <i className="fa fa-spinner fa-spin" style={{ marginRight: '0.5rem' }} />
            )}
            {getSaveButtonText()}
          </button>
        </div>

        <div className="sidebar-scroll">
          <FieldsList fields={availableFields} />
        </div>
      </div>

      {/* Main Content Area */}
      <div className="main-content">
        <DesignCanvas
          templateSignedId={templateSignedId}
          placedFields={placedFields}
          onFieldDrop={handleFieldDrop}
          onFieldRemove={handleFieldRemove}
          onFieldSizeChange={handleFieldSizeChange}
          width={A4_WIDTH}
          height={A4_HEIGHT}
        />
      </div>
    </div>
  );
};

export default TicketDesigner; 
