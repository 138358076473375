import { createSelector } from 'redux-orm'
import { orm } from '../../../../store'

const ormStateSelector = state => state.orm

const memberTypesSelector = createSelector(
  orm,
  ormStateSelector,
  session => session.MemberType.all().toModelArray().map(memberType => ({
    ...memberType.ref,
    propertyIds: memberType.properties.toRefArray().map(property => property.id)
  }))
)

export default memberTypesSelector
