import React from 'react'

import Bundle from './components/Bundle'
import PreferBundleButton from './components/PreferBundleButton'
import SelectBundleButton from './components/SelectBundleButton'
import BundleShiftDetails from './components/BundleShiftDetails'

const SelectableBundle = ({ children, bundle }) => {
  const { selectionType } = bundle
  const actionButton = selectionType === 'direct' ?
    <SelectBundleButton bundle={bundle} /> : <PreferBundleButton bundle={bundle} />

  const shiftDetails = bundle.shifts
    .sort((shiftA, shiftB) => shiftA.startTime - shiftB.startTime)
    .map(shift => <BundleShiftDetails key={shift.id} shift={shift} />)

  return (
    <div className="bundle-wrapper">
      <Bundle actionButton={actionButton}>
        {children}
      </Bundle>
      <div className="bundle-shift-list">
        {shiftDetails}
      </div>
    </div>
  )
}

export default SelectableBundle
