/* global React, Reflux */
import MemberTypePropertyStore from './member_type_property_store';
import MemberTypePropertiesList from './member_type_properties_list';
import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [Reflux.connect(MemberTypePropertyStore, 'member_type_properties')],

  componentDidMount: function () {
    //console.log(this.props.member_type_properties);
    MemberTypePropertyStore.setMemberTypeProperties(this.props.member_type_properties);
  },

  render: function () {
    return (
      <MemberTypePropertiesList member_type_properties={this.state.member_type_properties} />
    );
  }
})
