/* global I18n */
// replace `componentClass`prop to `as` prop when updating the version of react-bootstrap

import React from 'react'
import PropTypes from 'prop-types'

import { isNil } from 'lodash'
import { Modal, Tab, Nav, NavItem, Badge } from 'react-bootstrap'
import { Checkbox } from 'react-icheck'

import Attendances from './Attendances'
import ShiftCollectionSelect from './ShiftCollectionSelect'
import RegistrationRequirementsTab from './RegistrationRequirementsTab'
import ModalForm from '../../../../../components/ModalForm'
import { FieldGroup } from '../../../../../components/formExtensions'

import { shiftPropTypes, newShiftPropTypes } from '../../../../propTypes/shiftPropTypes'
import shiftGradePropTypes from '../../../../propTypes/shiftGradePropTypes'
import shiftBundlePropTypes from '../../../../propTypes/shiftBundlePropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form'
}

class BaseShiftModalForm extends React.Component {
  handleTranslateableInputChange = (newTranslationsAttributes) => {
    const { onChange } = this.props

    onChange({
      translationsAttributes: newTranslationsAttributes
    })
  }

  handleInputChange = (event) => {
    const { onChange } = this.props
    const target = event.target
    const { name, value } = target

    onChange({
      [name]: value
    })
  }

  handleCheckboxChange = (event) => {
    const { onChange } = this.props
    const target = event.target
    const { name, checked } = target

    onChange({
      [name]: checked
    })
  }

  handleChangeBundle = (selectedOption) => {
    const { onChange } = this.props

    this.handleCollectionChange('bundleId')(selectedOption)

    if (selectedOption) {
      const { bundles } = this.props
      const selectedBundle = bundles.find(bundle => bundle.id == selectedOption.value)
      const requirements = selectedBundle.requirements

      onChange({
        requirements
      })
    } else {
      const { defaultRequirements } = this.props

      onChange({
        requirements: defaultRequirements
      })
    }
  }

  handleCollectionChange = name => (selectedOption) => {
    const { onChange } = this.props

    let value
    if (selectedOption) {
      value = selectedOption.value
    } else {
      value = null
    }

    onChange({
      [name]: value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { shift, onSave } = this.props

    onSave(shift)
  }

  renderFieldGroup = (name, fieldGroupProps = {}) => {
    const { validationErrors, shift } = this.props
    const { id, [name]: value } = shift

    return (
      <FieldGroup
        id={id}
        model="shift"
        name={name}
        onChange={this.handleInputChange}
        value={(isNil(value) ? fieldGroupProps.defaultValue : value) || ''}
        errors={validationErrors !== null ? validationErrors[name] : null}
        {...fieldGroupProps}
      />
    )
  }

  renderTranslationField = (name, fieldGroupProps = {}) => {
    const { validationErrors, shift } = this.props
    const { id, translationsAttributes } = shift

    return (
      <FieldGroup.Translateable
        id={id}
        model="shift"
        name={name}
        onChange={this.handleTranslateableInputChange}
        translationsAttributes={translationsAttributes}
        errors={validationErrors !== null ? validationErrors.translationsAttributes : undefined}
        {...fieldGroupProps}
      />
    )
  }

  render() {
    const {
      isLoading,
      membersIsLoading,
      onClose,
      children,
      onChange,
      validationErrors,
      shift,
      grades,
      bundles,
      onSave: _,
      ...otherProps
    } = this.props
    const { attendances, preferences, requirements, hidden } = shift

    const WrappedCheckbox = props => (
      <div>
        <Checkbox
          {...props}
        />
      </div>
    )

    return (
      <ModalForm
        onExited={onClose}
        isLoading={isLoading}
        model="shift"
        className="modal-tabs"
        onSubmit={this.handleSubmit}
        {...otherProps}
        body={
          <Tab.Container defaultActiveKey="information">
            <div>
              <Nav bsStyle="tabs" id="shift-form-tabs" justified>
                <NavItem eventKey="information">
                  <span>{I18n.t('information', i18noptions)}</span>
                </NavItem>
                <NavItem eventKey="reqistration_requirements">
                  <span>{I18n.t('reqistration_requirements', i18noptions)} {requirements && (<Badge>{requirements.length}</Badge>)}</span>
                </NavItem>
                <NavItem eventKey="assigned-members" disabled={membersIsLoading}>
                  <span>{I18n.t('assigned_members', i18noptions)} {attendances && (<Badge>{attendances.length}</Badge>)}</span>
                </NavItem>
                <NavItem eventKey="suggested-members" disabled={membersIsLoading}>
                  <span>{I18n.t('suggested_members', i18noptions)} {preferences && (<Badge>{preferences.length}</Badge>)}</span>
                </NavItem>
              </Nav>
              <Modal.Body className={isLoading ? 'is-loading' : null}>
                <Tab.Content animation>
                  <Tab.Pane eventKey="information">
                    {this.renderTranslationField('name', { icon: 'tag' })}

                    {this.renderTranslationField(
                      'description',
                      { icon: 'pencil', componentClass: 'textarea' }
                    )}

                    {this.renderFieldGroup('gradeId', {
                      icon: 'certificate',
                      optionsIcon: 'square',
                      inputComponent: ShiftCollectionSelect,
                      onChange: this.handleCollectionChange('gradeId'),
                      collections: grades
                    })}

                    {this.renderFieldGroup('bundleId', {
                      icon: 'cube',
                      optionsIcon: 'cube',
                      inputComponent: ShiftCollectionSelect,
                      onChange: this.handleChangeBundle,
                      collections: bundles
                    })}

                    {this.renderFieldGroup('hidden', {
                      inputComponent: WrappedCheckbox,
                      checkboxClass: 'icheckbox_square-green icheck-item',
                      defaultChecked: hidden,
                      onChange: this.handleCheckboxChange,
                      value: null
                    })}

                    {this.renderFieldGroup('attendanceLimit', { icon: 'group' })}

                    {this.renderFieldGroup('meetingPoint', { icon: 'map-marker' })}

                    {children}
                  </Tab.Pane>
                  <Tab.Pane eventKey="reqistration_requirements">
                    <RegistrationRequirementsTab
                      requirements={requirements}
                      onChange={onChange}
                      onBundle={!!shift.bundleId}
                      validationErrors={
                        validationErrors && validationErrors.requirements
                          ? validationErrors.requirements[0]
                          : null
                      }
                    />
                  </Tab.Pane>
                  {!membersIsLoading &&
                    <Tab.Pane eventKey="assigned-members">
                      <Attendances.AssignedList
                        requirements={requirements}
                        attendances={attendances}
                        onChange={onChange}
                      />
                    </Tab.Pane>
                  }
                  {!membersIsLoading &&
                    <Tab.Pane eventKey="suggested-members">
                      <Attendances.SuggestedList
                        attendances={attendances}
                        preferences={preferences}
                        onChange={onChange}
                      />
                    </Tab.Pane>
                  }
                </Tab.Content>
              </Modal.Body>
            </div>
          </Tab.Container>
        }
      />
    )
  }
}

BaseShiftModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  shift: PropTypes.oneOfType([newShiftPropTypes, shiftPropTypes]).isRequired,
  grades: PropTypes.arrayOf(shiftGradePropTypes).isRequired,
  bundles: PropTypes.arrayOf(shiftBundlePropTypes).isRequired,
  isLoading: PropTypes.bool.isRequired,
  membersIsLoading: PropTypes.bool.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

BaseShiftModalForm.defaultProps = {
  validationErrors: null
}

export default BaseShiftModalForm
