import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createRootReducer from '../reducers/createRootReducer'
import assignStoreAppReducers from './assignStoreAppReducers'

const configureStore = () => {
  const store = createStore(
    createRootReducer(),
    compose(
      applyMiddleware(thunk)
    )
  )

  assignStoreAppReducers(store)

  return store
}

export default configureStore
