// Shifts
export const RECEIVE_SHIFT_ERROR = 'SCHEDULE_PLANNER_RECEIVE_SHIFT_ERROR'
export const COPY_SHIFT = 'SCHEDULE_PLANNER_COPY_SHIFT'
export const CHANGE_CURRENT_SHIFT = 'SCHEDULE_PLANNER_CHANGE_CURRENT_SHIFT'

// Shift grades
export const RECEIVE_SHIFT_GRADE_ERROR = 'SCHEDULE_PLANNER_RECEIVE_SHIFT_GRADE_ERROR'
export const CHANGE_CURRENT_SHIFT_GRADE = 'SCHEDULE_PLANNER_CHANGE_CURRENT_SHIFT_GRADE'

// Shift bundles
export const RECEIVE_SHIFT_BUNDLE_ERROR = 'SCHEDULE_PLANNER_RECEIVE_SHIFT_BUNDLE_ERROR'
export const CHANGE_CURRENT_SHIFT_BUNDLE = 'SCHEDULE_PLANNER_CHANGE_CURRENT_SHIFT_BUNDLE'

// attendances
export const RECEIVE_ATTENDANCE_ERROR = 'SCHEDULE_PLANNER_RECEIVE_ATTENDANCE_ERROR'

// Members
export const MEMBER_DRAG_STARTED = 'SCHEDULE_PLANNER_MEMBER_DRAG_STARTED'
export const MEMBER_DRAG_STOPPED = 'SCHEDULE_PLANNER_MEMBER_DRAG_STOPPED'
export const SELECT_MEMBER = 'SCHEDULE_PLANNER_SELECT_MEMBER'
export const SELECT_MEMBERS_RANGE = 'SCHEDULE_PLANNER_SELECT_MEMBERS_RANGE'
export const DESELECT_MEMBER = 'SCHEDULE_PLANNER_DESELECT_MEMBER'
export const DESELECT_MEMBERS_RANGE = 'SCHEDULE_PLANNER_DESELECT_MEMBERS_RANGE'
export const SET_MEMBERS_FILTERING = 'SCHEDULE_PLANNER_SET_MEMBERS_FILTERING'
export const SET_MEMBERS_SORTING = 'SCHEDULE_PLANNER_SET_MEMBERS_SORTING'
export const SET_INCLUDE_EXTERNAL_SHIFTS = 'SCHEDULE_PLANNER_SET_INCLUDE_EXTERNAL_SHIFTS'

// UI
export const OPEN_SHIFT_MODAL = 'SCHEDULE_PLANNER_OPEN_SHIFT_MODAL'
export const CLOSE_SHIFT_MODAL = 'SCHEDULE_PLANNER_CLOSE_SHIFT_MODAL'
export const OPEN_SHIFT_GRADE_MODAL = 'SCHEDULE_PLANNER_OPEN_SHIFT_GRADE_MODAL'
export const CLOSE_SHIFT_GRADE_MODAL = 'SCHEDULE_PLANNER_CLOSE_SHIFT_GRADE_MODAL'
export const OPEN_SHIFT_BUNDLE_MODAL = 'SCHEDULE_PLANNER_OPEN_SHIFT_BUNDLE_MODAL'
export const CLOSE_SHIFT_BUNDLE_MODAL = 'SCHEDULE_PLANNER_CLOSE_SHIFT_BUNDLE_MODAL'
