import store from './index'
import createRootReducer from '../reducers/createRootReducer'

export const replaceCurrentAppReducers = (appName) => {
  const newReducers = store.appReducers[appName]

  store.replaceReducer(createRootReducer(newReducers))
}


export const clearCurrentAppReducers = () => {
  store.replaceReducer(createRootReducer())
}
