import TeamApi from '../apis/TeamApi'
import teamActions from './teamActions'

const {
  requestTeams,
  receiveTeams
} = teamActions

const api = new TeamApi()

export const fetchTeams = params => (dispatch) => {
  dispatch(requestTeams())

  const { request, abort } = api.fetchAll(params)

  request.then(response => dispatch(receiveTeams(response.data)))

  return { request, abort }
}
