import {
  OPEN_SHIFT_BUNDLE_MODAL,
  CLOSE_SHIFT_BUNDLE_MODAL
} from '../../../constants/action-types'

import {
  RECEIVE_OBJECT,
  RECEIVE_OBJECT_DELETION
} from '../../../../../constants/action-types'

const isActiveReducer = (state = false, action) => {
  switch (action.type) {
    case OPEN_SHIFT_BUNDLE_MODAL:
      return true
    case CLOSE_SHIFT_BUNDLE_MODAL:
      return false
    case RECEIVE_OBJECT:
    case RECEIVE_OBJECT_DELETION:
      if (action.payload.type === 'bundles') {
        return false
      }
      return state
    default:
      return state
  }
}

export default isActiveReducer
