/* global I18n */

import React from 'react'
import { bool, arrayOf, func, objectOf, string } from 'prop-types'
import { cloneDeep } from 'lodash'

import Field from './Field'
import FieldCollection from '../../../../../../components/FieldCollection'

import memberTypePropTypes from '../../../../../propTypes/memberTypePropTypes'
import propertyPropTypes from '../../../../../propTypes/propertyPropTypes'
import requirementPropTypes from '../../../../../propTypes/requirementPropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form.requirements'
}

class Tab extends React.Component {
  getPropertyForId = (propertyId) => {
    const { propertiesById } = this.props

    return propertiesById[propertyId]
  }

  handleChangeRequirement = (index, newValues) => {
    const { onChange, requirements } = this.props
    const newRequirements = cloneDeep(requirements)

    newRequirements[index] = {
      ...newRequirements[index],
      ...newValues
    }

    onChange({
      requirements: newRequirements
    })
  }

  handleAddRequirement = (newRequirements, newRequirement) => {
    const { onChange } = this.props

    Object.assign(newRequirement, {
      requiredNumber: 0,
      requiredValue: '0'
    })

    onChange({
      requirements: newRequirements
    })
  }

  handleRemoveRequirement = (newRequirements) => {
    const { onChange } = this.props

    onChange({
      requirements: newRequirements
    })
  }

  render() {
    const { requirements, memberTypes, onBundle, validationErrors } = this.props

    return (
      <div>
        {/* eslint-disable jsx-a11y/label-has-for */}
        <label>{I18n.t('activerecord.attributes.shift.requirements')}</label>
        {/* eslint-enable jsx-a11y/label-has-for */}
        {onBundle && (
          <div>{I18n.t('requirements_on_bundle', i18noptions)}</div>
        )}
        <FieldCollection
          fieldData={requirements}
          addButtonText={I18n.t('add_requirement', i18noptions)}
          onAddField={this.handleAddRequirement}
          onRemoveField={this.handleRemoveRequirement}
          flagForDestruction
        >
          {(data, index) => (
            <Field
              requirement={data}
              memberTypes={memberTypes}
              getPropertyForId={this.getPropertyForId}
              index={index}
              onChange={this.handleChangeRequirement}
              validationErrors={validationErrors ? validationErrors[`${index}`] : null}
            />
          )}
        </FieldCollection>
      </div>
    )
  }
}

Tab.propTypes = {
  requirements: arrayOf(requirementPropTypes),
  memberTypes: arrayOf(memberTypePropTypes),
  propertiesById: objectOf(propertyPropTypes),
  onChange: func.isRequired,
  onBundle: bool,
  validationErrors: objectOf(
    objectOf(
      arrayOf(string)
    )
  )
}

Tab.defaultProps = {
  requirements: [],
  memberTypes: [],
  propertiesById: {},
  validationErrors: null,
  onBundle: false
}

export default Tab
