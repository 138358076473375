/* global I18n */

import PubSub from 'pubsub-js'

const i18noptions = {
  scope: 'components.roskilde_team_import'
}

const unsubscribeToTeamSynchronization = () => {
  const pusherChannel = document.body.dataset.currentMemberPusherChannel

  if (pusherChannel) {
    window.pusher.unsubscribe(pusherChannel)
  }
}

const subscribeToTeamSynchronization = () => {
  const globalVariables = document.body.dataset
  const pusherChannel = globalVariables.currentMemberPusherChannel

  if (window.pusher === undefined && globalVariables.enablePusher === 'true') {
    window.pusher = new window.Pusher(globalVariables.pusherKey)
  }

  if (window.pusher && pusherChannel) {
    window.pusher.subscribe(pusherChannel).bind('roskilde_update_team', ({ name }) => (
      PubSub.publish(
        'NOTIFICATION',
        { type: 'notice', message: I18n.t('finished_job_message', { name, ...i18noptions }) }
      )
    ))
  }
}

document.addEventListener('DOMContentLoaded', subscribeToTeamSynchronization)
document.addEventListener('page:load', subscribeToTeamSynchronization)
document.addEventListener('page:before-unload', unsubscribeToTeamSynchronization)
