import modelNameFromType from '../utilities/modelNameFromType'
import * as types from '../constants/action-types'

const createObjectActions = (type) => {
  const modelName = modelNameFromType(type)

  return {
    [`request${modelName}s`]: () => ({
      type: types.REQUEST_OBJECTS,
      payload: { type }
    }),
    [`receive${modelName}s`]: response => ({
      type: types.RECEIVE_OBJECTS,
      payload: { response, type }
    }),
    [`receive${modelName}`]: response => ({
      type: types.RECEIVE_OBJECT,
      payload: { response, type }
    }),
    [`request${modelName}Creation`]: () => ({
      type: types.REQUEST_OBJECT_CREATION,
      payload: { type }
    }),
    [`receive${modelName}Creation`]: response => ({
      type: types.RECEIVE_OBJECT,
      payload: { response, type }
    }),
    [`request${modelName}Update`]: id => ({
      type: types.REQUEST_OBJECT,
      payload: { id, type }
    }),
    [`receive${modelName}Update`]: response => ({
      type: types.RECEIVE_OBJECT,
      payload: { response, type }
    }),
    [`request${modelName}Deletion`]: id => ({
      type: types.REQUEST_OBJECT,
      payload: { id, type }
    }),
    [`receive${modelName}Deletion`]: id => ({
      type: types.RECEIVE_OBJECT_DELETION,
      payload: { id, type }
    }),
    [`receive${modelName}Error`]: id => ({
      type: types.RECEIVE_OBJECT_ERROR,
      payload: { id, type }
    })
  }
}

export default createObjectActions
