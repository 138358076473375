import React from 'react'
import { bool, func, arrayOf } from 'prop-types'

import LoadingIndicator from '../../../../components/LoadingIndicator'
import ShiftGradeLabel from '../../../../components/ShiftGradeLabel'

import shiftGradePropTypes from '../../../propTypes/shiftGradePropTypes'

const GradeList = ({ grades, onClick, isLoading }) => (
  <div className="pull-right">
    {isLoading ? (
      <LoadingIndicator size="2x" />
    ) : (grades.map((gradeAttributes) => {
      const { name, color } = gradeAttributes

      return (
        <ShiftGradeLabel
          color={color}
          name={name}
          className="pull-right label-button"
          key={name}
          role="button"
          tabIndex="0"
          onClick={() => onClick(gradeAttributes)}
        />
      )
    }))}
  </div>
)

GradeList.propTypes = {
  grades: arrayOf(shiftGradePropTypes).isRequired,
  onClick: func.isRequired,
  isLoading: bool.isRequired
}

export default GradeList
