/* global I18n */

import React from 'react'
import { connect } from 'react-redux'
import ReactSelect from 'react-select'

import getGrades from '../../../selectors/grades/getGrades'
import { setGradeFilter } from '../../../actions/filter-actions'

const i18noptions = {
  scope: 'shift_selector.grade_filter'
}

const getOptions = grades => (
  grades.map(grade => ({
    value: grade.id,
    label: grade.name
  }))
)

const GradeFilter = ({ value, options, label, onChange }) => (
  <ReactSelect
    value={value}
    onChange={onChange}
    options={options}
    placeholder="Alle klasser"
    multi
  />
)

const mapStateToProps = state => ({
  value: state.currentApp.filters.gradeFilter,
  options: getOptions(getGrades(state))
})

const mapDispatchToProps = dispatch => ({
  onChange: values => dispatch(setGradeFilter(values.map(value => value.value)))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeFilter)
