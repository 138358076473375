import React from 'react'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'

const ShiftActionButton = ({
  handleClick,
  buttonStyle,
  buttonText,
  active,
  inactiveReason,
  error,
  isLoading,
  selected
}) => {
  let buttonType

  if (error) {
    buttonText = <i className="fa fa-times" />
  } else if (isLoading) {
    buttonText = <i className="fa fa-spinner fa-spin" />
  } else if (selected) {
    buttonText = <i className="fa fa-times" />
  }

  if (error) {
    buttonType = 'danger'
  } else if (selected) {
    buttonType = 'warning'
  } else {
    buttonType = 'primary'
  }

  if (active) {
    return (
      <Button
        onClick={handleClick}
        bsStyle={buttonType}
        bsSize="sm"
      >

        {buttonText}
      </Button>
    )
  }
  const tooltip = (
    <Tooltip id="tooltip">
      {inactiveReason}
    </Tooltip>
    )

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <Button
        bsStyle={buttonType}
        bsSize="sm"
        className="disabled"
      >

        {buttonText}
      </Button>
    </OverlayTrigger>
  )
}

ShiftActionButton.defaultProps = {
  active: true,
  isLoading: false,
  selected: false
}

export default ShiftActionButton
