import { string, bool, shape, object, instanceOf } from 'prop-types'

const propertyPropTypes = shape({
  id: string.isRequired,
  name: string.isRequired,
  type: string.isRequired,
  data: object,
  required: bool.isRequired,
  deletedAt: instanceOf(Date)
})

export default propertyPropTypes
