import React from 'react'
import { arrayOf, func } from 'prop-types'
import { cloneDeep } from 'lodash'

import { ListGroup, Panel } from 'react-bootstrap'

import SuggestedItem from './SuggestedItem'

import preferencePropTypes from '../../../../../propTypes/preferencePropTypes'
import attendancePropTypes from '../../../../../propTypes/attendancePropTypes'

class SuggestedList extends React.Component {
  handleAddAttendance = (member) => {
    const { attendances, onChange } = this.props
    const attendanceCount = attendances.length
    const newAttendances = cloneDeep(attendances)

    newAttendances[attendanceCount] = {
      member,
      memberId: member.id,
      leader: false
    }

    onChange({
      attendances: newAttendances
    })
  }

  render() {
    const { preferences } = this.props

    return (
      <Panel>
        <ListGroup>
          {preferences.map((preference) => {
            const { member } = preference

            return (
              <SuggestedItem
                key={preference.id}
                member={member}
                onAdd={this.handleAddAttendance}
              />
            )
          })}
        </ListGroup>
      </Panel>
    )
  }
}

SuggestedList.propTypes = {
  preferences: arrayOf(preferencePropTypes),
  attendances: arrayOf(attendancePropTypes),
  onChange: func.isRequired
}

SuggestedList.defaultProps = {
  preferences: [],
  attendances: []
}

export default SuggestedList
