import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

const DataField = ({ value, icon, tooltip, ...props }) => {
  const content = (
    <span {...props}>
      <FontAwesome name={icon} />
      {' '}{value}
    </span>
  )

  if (tooltip !== null) {
    const tooltipElement = (
      <Tooltip id="data-tooltip">{tooltip}</Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={tooltipElement}>
        {content}
      </OverlayTrigger>
    )
  }

  return content
}

DataField.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  tooltip: PropTypes.node
}

DataField.defaultProps = {
  tooltip: null
}

export default DataField
