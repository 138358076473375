import { combineReducers } from 'redux'
import filters from './filtersReducer'
import ui from './uiReducer'

const rootReducer = combineReducers({
  filters,
  ui
})

export default rootReducer
