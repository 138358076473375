/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { differenceInCalendarDays } from 'date-fns'

import { Button } from 'react-bootstrap'

import LoadingIndicator from '../../../../components/LoadingIndicator'
import IBox from '../../../../components/IBox'

import SchedulePlannerShiftCalendar from './SchedulePlannerShiftCalendar'
import ShiftModalForm from './ShiftModalForm'
import GradeList from './GradeList'
import GradeModalForm from './GradeModalForm'
import BundleModalForm from './BundleModalForm'
import BundleList from './BundleList'

import { openShiftModal, updateShift, buildShiftWithMembers } from '../../actions/shift-actions'
import { openShiftGradeModal } from '../../actions/shift-grade-actions'
import { openShiftBundleModal } from '../../actions/shift-bundle-actions'

import shiftsSelector from '../../selectors/shifts/shiftsSelector'
import gradesSelector from '../../selectors/grades/gradesSelector'
import bundlesWithRequirementsSelector from '../../selectors/bundles/bundlesWithRequirementsSelector'
import memberTypesSelector from '../../selectors/memberTypes/memberTypesSelector'
import scheduleSelector from '../../selectors/schedules/scheduleSelector'

import shiftGradePropTypes from '../../../propTypes/shiftGradePropTypes'
import shiftBundlePropTypes from '../../../propTypes/shiftBundlePropTypes'
import memberTypePropTypes from '../../../propTypes/memberTypePropTypes'

import './styles.scss'

const i18noptions = {
  scope: 'components.schedule_planner.shifts'
}

const ShiftsView = ({
  shifts,
  grades,
  bundles,
  memberTypes,
  shiftsIsLoading,
  showShiftModal,
  showShiftGradeModal,
  showShiftBundleModal,
  onClickShift,
  onClickTimeSlot,
  onDropShift,
  onDropMember,
  onClickShiftGrade,
  onClickShiftBundle,
  startingAt,
  gradesIsLoading,
  bundlesIsLoading
}) => (
  <IBox
    title={I18n.t('title', i18noptions)}
    tools={(
      <div>
        {!gradesIsLoading && (
          <Button
            className="pull-right shift-ibox-button"
            bsStyle="white"
            bsSize="xs"
            onClick={() => onClickShiftGrade({})}
          >
            {I18n.t('helpers.actions.new', { model: I18n.t('activerecord.models.shift_grade.one') })}
          </Button>
        )}
        {!bundlesIsLoading && (
          <Button
            className="pull-right shift-ibox-button"
            bsStyle="white"
            bsSize="xs"
            onClick={() => onClickShiftBundle({})}
          >
            {I18n.t('helpers.actions.new', { model: I18n.t('activerecord.models.shift_bundle.one') })}
          </Button>
        )}
        <GradeList
          grades={grades}
          isLoading={gradesIsLoading}
          onClick={onClickShiftGrade}
        />
        <BundleList
          bundles={bundles}
          isLoading={bundlesIsLoading}
          onClick={onClickShiftBundle}
        />
      </div>
    )}
  >
    {shiftsIsLoading ? (
      <LoadingIndicator size="4x" />
    ) : (
      <SchedulePlannerShiftCalendar
        startingAt={startingAt}
        shifts={shifts}
        onClickShift={onClickShift}
        onClickSlot={onClickTimeSlot}
        onDropShift={onDropShift}
        onDropMember={onDropMember}
      />
    )}
    {showShiftModal && (
      <ShiftModalForm
        grades={grades}
        bundles={bundles}
      />
    )}
    {showShiftGradeModal && (
      <GradeModalForm
        memberTypes={memberTypes}
      />
    )}
    {showShiftBundleModal && (<BundleModalForm />)}
  </IBox>
)

ShiftsView.defaultProps = {
  shifts: [],
  grades: [],
  bundles: []
}

ShiftsView.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.object),
  grades: PropTypes.arrayOf(shiftGradePropTypes),
  bundles: PropTypes.arrayOf(shiftBundlePropTypes),
  memberTypes: PropTypes.arrayOf(memberTypePropTypes).isRequired,
  shiftsIsLoading: PropTypes.bool.isRequired,
  gradesIsLoading: PropTypes.bool.isRequired,
  bundlesIsLoading: PropTypes.bool.isRequired,
  onClickShift: PropTypes.func.isRequired,
  onClickTimeSlot: PropTypes.func.isRequired,
  onDropShift: PropTypes.func.isRequired,
  onDropMember: PropTypes.func.isRequired,
  showShiftGradeModal: PropTypes.bool.isRequired,
  showShiftBundleModal: PropTypes.bool.isRequired,
  showShiftModal: PropTypes.bool.isRequired,
  onClickShiftGrade: PropTypes.func.isRequired,
  onClickShiftBundle: PropTypes.func.isRequired,
  startingAt: PropTypes.instanceOf(Date).isRequired
}

const mapStateToProps = (state) => {
  const { loadingStatus, currentApp } = state
  const { ui } = currentApp
  const {
    shiftsList: shiftsIsLoading,
    gradesList: gradesIsLoading,
    bundlesList: bundlesIsLoading
  } = loadingStatus
  const { shiftModalForm, shiftGradeModalForm, shiftBundleModalForm } = ui
  const { isActive: showShiftModal } = shiftModalForm
  const { isActive: showShiftGradeModal } = shiftGradeModalForm
  const { isActive: showShiftBundleModal } = shiftBundleModalForm
  const memberTypes = memberTypesSelector(state)

  const shifts = shiftsSelector(state)
  const grades = gradesSelector(state)
  const bundles = bundlesWithRequirementsSelector(state)
  const { startingAt } = scheduleSelector(state)

  return {
    shiftsIsLoading,
    gradesIsLoading,
    bundlesIsLoading,
    showShiftModal,
    showShiftGradeModal,
    showShiftBundleModal,
    shifts,
    grades,
    bundles,
    startingAt,
    memberTypes
  }
}

const mapDispatchToProps = dispatch => ({
  onClickShiftGrade: attributes => dispatch(openShiftGradeModal(attributes)),
  onClickShiftBundle: attributes => dispatch(openShiftBundleModal(attributes)),
  onClickShift: shift => dispatch(openShiftModal('edit', shift)),
  onClickTimeSlot: ({ start, end, slots }) => {
    // Start and end is a date with local timezone, so converting it to UTC
    const startTime = start
    const endTime = end

    if (differenceInCalendarDays(end, start) >= 1) {
      const timeSlots = slots.map((slot) => {
        const time = slot

        return {
          startTime: time,
          endTime: time
        }
      })

      dispatch(openShiftModal('new', {
        times: timeSlots
      }))
    } else {
      dispatch(openShiftModal('new', {
        times: [{
          startTime,
          endTime
        }]
      }))
    }
  },
  onDropShift: ({ event: shift, startTime, endTime }) => {
    const { id } = shift

    dispatch(updateShift(id, {
      startTime,
      endTime
    }))
  },
  onDropMember: ({ startTime, endTime }) => (
    dispatch(buildShiftWithMembers({
      times: [{
        startTime,
        endTime
      }]
    }))
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftsView)
