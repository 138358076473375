import {
  CLOSE_SHIFT_BUNDLE_MODAL,
  RECEIVE_SHIFT_BUNDLE_ERROR
} from '../../../constants/action-types'

import {
  REQUEST_OBJECT,
  RECEIVE_OBJECT,
  RECEIVE_OBJECT_DELETION,
  REQUEST_OBJECT_CREATION
} from '../../../../../constants/action-types'

const validationErrorsReducer = (state = null, action) => {
  switch (action.type) {
    case CLOSE_SHIFT_BUNDLE_MODAL:
      return null
    case REQUEST_OBJECT:
    case REQUEST_OBJECT_CREATION:
    case RECEIVE_OBJECT:
    case RECEIVE_OBJECT_DELETION:
      if (action.payload.type === 'bundles') {
        return null
      }
      return state
    case RECEIVE_SHIFT_BUNDLE_ERROR:
      return action.payload.errors
    default:
      return state
  }
}

export default validationErrorsReducer
