import * as types from '../constants/action-types'
import MemberApi from '../apis/MemberApi'
import scheduleSelector from '../selectors/schedules/scheduleSelector'
import shiftSelector from '../selectors/shifts/shiftSelector'
import { createAttendances } from './attendance-actions'
import { changeCurrentShift } from './shift-actions'
import memberActions from '../../../actions/memberActions'

const {
  requestMembers,
  receiveMembers
} = memberActions

export const memberDragStarted = () => ({ type: types.MEMBER_DRAG_STARTED })
export const memberDragStopped = () => ({ type: types.MEMBER_DRAG_STOPPED })
export const setMembersFiltering = filtering => ({
  type: types.SET_MEMBERS_FILTERING,
  payload: filtering
})
export const setMembersSorting = sorting => ({
  type: types.SET_MEMBERS_SORTING,
  payload: sorting
})
export const setIncludeExternalShifts = includeExternalShifts => ({
  type: types.SET_INCLUDE_EXTERNAL_SHIFTS,
  payload: includeExternalShifts
})

export const selectMember = (id, multiSelect) => ({
  type: types.SELECT_MEMBER,
  payload: { id, multiSelect }
})
export const deselectMember = (id, multiSelect) => ({
  type: types.DESELECT_MEMBER,
  payload: { id, multiSelect }
})
export const selectMembersRange = (id, ids) => ({
  type: types.SELECT_MEMBERS_RANGE,
  payload: { id, ids }
})
export const deselectMembersRange = (id, ids) => ({
  type: types.DESELECT_MEMBERS_RANGE,
  payload: { id, ids }
})

export const addMembersToShift = shiftId => (dispatch, getState) => {
  const state = getState()
  const selectedMemberIds = state.currentApp.ui.membersView.selection.ids

  dispatch(createAttendances(shiftId, selectedMemberIds))
}

export const fetchMembers = () => (dispatch, getState) => {
  dispatch(requestMembers())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new MemberApi(scheduleId)

  return api.fetchAll()
    .then((response) => {
      dispatch(receiveMembers(response.data))

      const newState = getState()
      const shiftModalForm = newState.currentApp.ui.shiftModalForm

      if (shiftModalForm.isActive) {
        const { id: shiftId } = shiftModalForm.currentShift
        if (shiftId) {
          const { attendances, preferences } = shiftSelector(newState, shiftId)
          dispatch(changeCurrentShift({ attendances, preferences }))
        }
      }
    })
}
