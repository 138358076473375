/* global I18n */

import React, { Component } from 'react'
import { bool, number, string, arrayOf, shape } from 'prop-types'

import RadioButtonsWithSettings from '../components/RadioButtonsWithSettings'
import { FieldGroup } from '../components/formExtensions'

const i18noptions = {
  scope: 'components.schedule_shift_settings_widget'
}

class ScheduleShiftSettingsWidget extends Component {
  renderMinimumShiftHoursFields = () => {
    const { memberTypes } = this.props
    const moreThenOneMemberType = memberTypes.length > 1

    return (
      <div>
        <h4>{I18n.t('minimum_shift_hours_per_member_type_header', i18noptions)}</h4>
        <p>{I18n.t('minimum_shift_hours_per_member_type_description', i18noptions)}</p>
        {memberTypes.map(({ name, id, minimumShiftHours }) => (
          <FieldGroup
            model="Schedule"
            name={`schedule[minimum_shift_hours_per_member_type][${id}]`}
            label={moreThenOneMemberType ? name : false}
            defaultValue={minimumShiftHours}
          />
        ))}
      </div>
    )
  }

  renderMaximumShiftHoursFields = () => {
    const { memberTypes } = this.props
    const moreThenOneMemberType = memberTypes.length > 1

    return (
      <div>
        <h4>{I18n.t('maximum_shift_hours_per_member_type_header', i18noptions)}</h4>
        <p>{I18n.t('maximum_shift_hours_per_member_type_description', i18noptions)}</p>
        {memberTypes.map(({ name, id, maximumShiftHours }) => (
          <FieldGroup
            model="Schedule"
            name={`schedule[maximum_shift_hours_per_member_type][${id}]`}
            label={moreThenOneMemberType ? name : false}
            defaultValue={maximumShiftHours}
          />
        ))}
      </div>
    )
  }

  renderDirectSelectionSettings = () => (
    <div>
      {this.renderMinimumShiftHoursFields()}
      {this.renderMaximumShiftHoursFields()}
    </div>
  )

  renderPreferedSelectionSettings = () => (
    <div>
      {this.renderMinimumShiftHoursFields()}
    </div>
  )

  renderSelectionSettings = (value) => {
    switch (value) {
      case 'direct_shift_selection_enabled':
        return this.renderDirectSelectionSettings()
      case 'preferred_shift_selection_enabled':
        return this.renderPreferedSelectionSettings()
      case 'shift_selection_disabled':
      default:
        return null
    }
  }

  render() {
    const {
      shiftSelectionAllowed,
      shiftPreferencesAllowed,
      shiftSelectionSetting
    } = this.props

    const translatedLabel = (settings, key) => I18n.t(`activerecord.attributes.schedule/${settings}.${key}`)
    const buildShiftSelectionOption = key => (
      [translatedLabel('shift_selection_setting', key), key]
    )

    const shiftSelectionOptions = [
      buildShiftSelectionOption('shift_selection_disabled')
    ]

    if (shiftSelectionAllowed) {
      shiftSelectionOptions.push(
        buildShiftSelectionOption('direct_shift_selection_enabled')
      )
    }

    if (shiftPreferencesAllowed) {
      shiftSelectionOptions.push(
        buildShiftSelectionOption('preferred_shift_selection_enabled')
      )
    }

    if (shiftSelectionOptions.length <= 1) {
      return null
    } else {
      return (
        <RadioButtonsWithSettings
          model="Schedule"
          name="schedule[shift_selection_setting]"
          label={I18n.t('activerecord.attributes.schedule.shift_selection_setting')}
          options={shiftSelectionOptions}
          renderContent={this.renderSelectionSettings}
          defaultCheckedValue={shiftSelectionSetting}
        />
      )
    }
  }
}

ScheduleShiftSettingsWidget.propTypes = {
  shiftSelectionAllowed: bool.isRequired,
  shiftPreferencesAllowed: bool.isRequired,
  memberTypes: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    minimumShiftHours: number,
    maximumShiftHours: number
  }).isRequired).isRequired,
  shiftSelectionSetting: string.isRequired
}

export default ScheduleShiftSettingsWidget
