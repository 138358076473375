import { createSelector } from 'reselect'
import getSelectedSchedules from './getSelectedSchedules'

const getPreferredSchedules = createSelector(
  getSelectedSchedules,
  schedules => schedules
    .filter(schedule => schedule.selectionType === 'preferred')
)

export default getPreferredSchedules
