/* global React, Reflux */
import CustomFormStore from './custom_form_store';
import CustomFormActions from './custom_form_actions';
import MemberTypePropertyStore from './member_type_property_store';
import StaticPage from './static_page';
import CustomFormPage from './custom_form_page';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [Reflux.connect(CustomFormStore, 'custom_form')],

  componentDidMount: function () {
    CustomFormStore.setCustomForm(this.props.custom_form, MemberTypePropertyStore);
  },

  handleAddPageClick: function () {
    CustomFormActions.addPage();
  },

  render: function () {
    var customFormPageNodes = this.state.custom_form.pages.map(function (page) {
      return (
        <CustomFormPage key={page.number} page={page} />
      );
    });

    return (
      <div>
        {customFormPageNodes}
        <a onClick={this.handleAddPageClick} href="##">Add page</a>
        <hr />
        <StaticPage key="team_select" page_key="team_select" descriptions={this.state.custom_form.static_page_descriptions['team_select']} />
        <StaticPage key="confirm" page_key="confirm" descriptions={this.state.custom_form.static_page_descriptions['confirm']} />
      </div>
    );
  }
});
