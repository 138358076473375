import scheduleActions from '../../../actions/scheduleActions'
import { fetchShifts } from './shift-actions'
import { fetchShiftGrades } from './shift-grade-actions'
import { fetchShiftBundles } from './shift-bundle-actions'
import { fetchMembers } from './member-actions'
import { fetchMemberTypes } from './member-type-actions'

export const receiveSchedule = scheduleActions.receiveSchedule

export const fetchData = () => (dispatch) => {
  Promise.all([
    dispatch(fetchShiftGrades()),
    dispatch(fetchShiftBundles()),
    dispatch(fetchMemberTypes())
  ]).then(() => (
    dispatch(fetchShifts())
  )).then(() => (
    dispatch(fetchMembers())
  ))
}
