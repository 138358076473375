/* global React, I18n */

import GroupedTicketItems from './GroupedTicketItems';

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    return (
      <div className="row">
        <div className="col-md-6">
          <h2>{this.props.guest.name}</h2>
          <p>{this.props.guest.email}</p>
          <p>
            <b>{I18n.t('tickets_scanner.owner_info.comment')}: </b>{this.props.guest.comment}
          </p>
          <p>
            <b>{I18n.t('tickets_scanner.owner_info.guest_list_group')}: </b>{this.props.guest.guest_list_group}
          </p>
          <p>
            <b>{I18n.t('tickets_scanner.owner_info.guest_list')}: </b>{this.props.guest.guest_list}
          </p>
        </div>
        <div className="col-md-6">
          {this.props.groupedTicketItems.length > 0 ?
            <GroupedTicketItems groupedTicketItems={this.props.groupedTicketItems} /> :
            ''}
        </div>
      </div>
    );
  }
})
