/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import fetch from 'isomorphic-fetch'

import csrfToken from '../utilities/csrfToken'

const i18noptions = {
  scope: 'components.shifts_export_button'
}

class ShiftsExportButton extends Component {
  state = {
    showModal: false,
    exportFileUrl: ''
  }

  componentWillUnmount() {
    const pusherChannel = document.body.dataset.currentMemberPusherChannel

    window.pusher.unsubscribe(pusherChannel)
  }

  triggerExportShifts = () => {
    const { exportUrlPath, pusherEvent } = this.props

    const pusherChannel = document.body.dataset.currentMemberPusherChannel

    if (window.pusher && pusherChannel) {
      window.pusher.subscribe(pusherChannel).bind(pusherEvent, ({ file_public_url }) => (
        this.setState({ exportFileUrl: file_public_url })
      ))
    }

    fetch(
      exportUrlPath, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken()
      }
    }
    )
  }

  handleShowModal = () => {
    this.setState({ showModal: true })
    this.triggerExportShifts()
  }

  checkForExportFileUrl = () => (
    this.state.exportFileUrl.length > 0
  )

  handleCloseModal = () => {
    if (this.checkForExportFileUrl()) {
      this.setState({
        showModal: false,
        exportFileUrl: ''
      })
    }
  }

  renderSpinner = () => (
    <div style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
    </div>
  )

  renderExportFileUrl = () => {
    if (this.checkForExportFileUrl()) {
      return (
        <a href={this.state.exportFileUrl}>{I18n.t('download_link_text', i18noptions)}</a>
      )
    }

    return this.renderSpinner()
  }

  render() {
    const { showModal } = this.state

    return (
      <div>
        <Button
          onClick={this.handleShowModal}
          bsStyle="white"
          bsSize="sm"
        >
          {I18n.t('button_text', i18noptions)}
        </Button>

        <Modal
          onHide={this.handleCloseModal}
          show={showModal}
        >
          <Modal.Header closeButton={this.checkForExportFileUrl()}>
            <Modal.Title>{I18n.t('warning_message', i18noptions)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.renderExportFileUrl()}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

ShiftsExportButton.propTypes = {
  exportUrlPath: PropTypes.string.isRequired,
  pusherEvent: PropTypes.string.isRequired
}

export default ShiftsExportButton
