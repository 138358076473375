/* global I18n */

import { createSelector } from 'reselect'
import getShifts from '../shifts/getShifts'
import getBundles from './getBundles'

import {
  allShiftsHaveAvailableSpots,
  memberHasEnoughAvailableHours,
  memberCanSelectShiftsWithGrade,
  memberHasNoDoubledBookedShifts
} from '../../utilities/eligibilityChecks'

const getCurrentMember = state => state.currentMember

const getBundlesWithEligibility = createSelector(
  getBundles,
  getShifts,
  getCurrentMember,
  (bundles, shifts, currentMember) => {
    const assignedShifts = shifts.filter(shift => shift.selectionType !== 'preferred' && shift.selected)
    const totalAssignedHours = assignedShifts
      .map(shift => shift.duration)
      .reduce((a, b) => a + b, 0)

    return bundles.map((bundle) => {
      if (!allShiftsHaveAvailableSpots(bundle.shifts, currentMember)) {
        return ({
          ...bundle,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.bundle.no_available_spots')
        })
      } else if (bundle.selectionType === 'direct' && !memberHasEnoughAvailableHours(bundle.duration, currentMember, totalAssignedHours)) {
        return ({
          ...bundle,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.bundle.not_enough_hours_left')
        })
      } else if (bundle.selectionType === 'direct' && !memberCanSelectShiftsWithGrade(bundle.shifts, assignedShifts)) {
        return ({
          ...bundle,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.bundle.not_enough_spots_with_grade_left')
        })
      } else if (!memberHasNoDoubledBookedShifts(assignedShifts, bundle.shifts)) {
        return ({
          ...bundle,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.bundle.no_double_booking')
        })
      }
      return ({
        ...bundle,
        eligible: true,
        ineligibilityReason: null
      })
    })
  }
)

export default getBundlesWithEligibility
