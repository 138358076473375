/* global I18n */

import React from 'react'
import { bool, node, arrayOf, oneOfType } from 'prop-types'
import classNames from 'classnames'

import memberPropTypes from '../../../../propTypes/memberPropTypes'

import LoadingIndicator from '../../../../../components/LoadingIndicator'
import DataField from '../../../../../components/DataField'
import TooltipIcon from '../../../../../components/TooltipIcon'

const i18noptions = {
  scope: 'components.schedule_planner.members'
}

const Template = ({
  selected,
  dragged,
  dragging,
  loading,
  member,
  children,
  ...otherProps
}) => {
  const {
    name,
    displayedShiftCount,
    displayedShiftDuration,
    doubleBookedShiftIds,
    shiftsAccepted
  } = member

  return (
    <div
      className={
        classNames(
          'member-card',
          {
            selected,
            dragged,
            dragging
          }
        )
      }
      {...otherProps}
    >
      <div className="member-name">
        {name}
        {doubleBookedShiftIds.length > 0 && (
          <TooltipIcon
            icon="exclamation-triangle"
            className="text-danger member-icon"
            tooltip={I18n.t('double_booked', i18noptions)}
          />
        )}
        {shiftsAccepted && (
          <TooltipIcon
            icon="check"
            className="text-info member-icon"
            tooltip={I18n.t('accepted_all_shifts', i18noptions)}
          />
        )}
        {children}
      </div>
      <div className="clearfix">
        <DataField
          className="member-stat"
          icon="hashtag"
          tooltip={I18n.t('shift_count', i18noptions)}
          value={loading ? <LoadingIndicator nested /> : displayedShiftCount}
        />
        <DataField
          className="member-stat"
          icon="clock-o"
          tooltip={I18n.t('shifts_duration', i18noptions)}
          value={loading ? <LoadingIndicator nested /> : displayedShiftDuration}
        />
      </div>
    </div>
  )
}

Template.propTypes = {
  selected: bool,
  dragged: bool,
  dragging: bool,
  loading: bool,
  member: memberPropTypes.isRequired,
  children: oneOfType([arrayOf(node), node])
}

Template.defaultProps = {
  children: null,
  selected: false,
  dragged: false,
  dragging: false,
  loading: false
}

export default Template
