import { utils, Button } from 'react-bootstrap'
import { setDefaultOptions } from 'date-fns'
import { da } from 'date-fns/locale'
import { I18n } from "i18n-js";
import * as Routes from './routes'
import translations from "./translations.json";
window.I18n = new I18n(translations);
window.I18n.enableFallback = true;
window.I18n.fallbacks = {
  da_heartland: ['da'],
  da_duf: ['da'],
  da_bloddonerne: ['da'],
  en_heartland: ['en'],
  en_duf: ['en'],
  en_bloddonerne: ['en'],
  da: ['en'],
  en: ['da']
}

// Wrap DOM-dependent code in DOMContentLoaded event listener
document.addEventListener('DOMContentLoaded', () => {
  var globalVariables = document.body.dataset;
  window.I18n.defaultLocale = globalVariables.defaultLocale;
  window.I18n.locale = globalVariables.locale;
});

setDefaultOptions({ locale: da })

// Add white as valid bsStyle prop for buttons
utils.bootstrapUtils.addStyle(Button, 'white')

// Create global routes object
window.Routes = Routes
