/* global I18n */
import React from 'react'
import { connect } from 'react-redux'
import { checkRequirementsAndSelectShift, deselectShift } from '../../../actions/shift-actions'
import { deselectBundle } from '../../../actions/bundle-actions'
import ShiftActionButton from '../../../components/ShiftActionButton'

const i18noptions = {
  scope: 'shift_selector.shift'
}

class SelectShiftButton extends React.Component {
  handleClick = () => {
    const { shift, selectShift, deselectShift, deselectBundle } = this.props
    const { id, selected, error, bundle } = shift
    const { id: bundleId } = bundle || {}

    if (error) {
    } else if (selected && bundleId) {
      deselectBundle(bundleId)
    } else if (selected) {
      deselectShift(id)
    } else {
      selectShift(id)
    }
  }

  render() {
    const { shift, isLoading } = this.props
    const { id, selected, eligible, ineligibilityReason, error, selectable } = shift

    let buttonText
    if (selected) {
      buttonText = I18n.t('actions.remove_shift', i18noptions)
    } else {
      buttonText = I18n.t('actions.select_shift', i18noptions)
    }

    return (
      <ShiftActionButton
        handleClick={this.handleClick}
        buttonText={buttonText}
        active={eligible === undefined ? selectable : eligible && selectable}
        inactiveReason={selectable ? ineligibilityReason : I18n.t('shift_selector.shift.selection_disabled')}
        error={error}
        isLoading={isLoading}
        selected={selected}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const {
    shifts: loadingShiftIds,
    bundles: loadingBundleIds
  } = state.loadingStatus
  const { id: shiftId, bundle } = props.shift

  const shiftIsLoading = loadingShiftIds.includes(shiftId)
  const bundleIsLoading = bundle && loadingBundleIds.includes(bundle.id)
  const isLoading = shiftIsLoading || bundleIsLoading

  return {
    isLoading
  }
}

const mapDispatchToProps = dispatch => ({
  selectShift: id => dispatch(checkRequirementsAndSelectShift(id)),
  deselectShift: id => dispatch(deselectShift(id)),
  deselectBundle: id => dispatch(deselectBundle(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectShiftButton)
