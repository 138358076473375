/* global React, Reflux, I18n */

import OwnersStore from './OwnersStore';
import Actions from './Actions';
import OwnersList from './OwnersList';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [
    Reflux.connect(OwnersStore, 'owners'),
    Reflux.ListenerMixin
  ],

  modalRef: null,

  componentDidMount: function () {
    this.listenTo(Actions.showExtendedModal, function () {
      $(this.modalRef).modal({
        backdrop: 'static',
        keyboard: false
      });
    });

    this.listenTo(Actions.dismissExtendedModal, function () {
      $(this.modalRef).modal("hide");
    });
  },

  handleCancel: function () {
    Actions.dismissExtendedModal();
    Actions.reset();
  },

  render: function () {
    var content;

    if (this.state.owners.loaded) {
      if (this.state.owners.list.length > 0) {
        content = <OwnersList owners={this.state.owners.list} />
      } else {
        content =
          <div className="alert alert-warning text-center">
            <h3>{I18n.t('tickets_scanner.owners_list.not_found')}</h3>
          </div>
      }
    } else {
      content =
        <div className="text-center">
          <i className='fa fa-spinner fa-4x fa-spin'></i>
        </div>
    }

    return (
      <div
        ref={(ref) => { this.modalRef = ref; }}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="extended-owners-list-modal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{I18n.t('tickets_scanner.owners_list.modal_title')}</h4>
            </div>
            <div className="modal-body">
              {content}
            </div>
            <div className="modal-footer">
              <div className="btn btn-block btn-primary" onClick={this.handleCancel}>
                {I18n.t('tickets_scanner.owners_list.cancel')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
})
