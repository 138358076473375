import { createSelector } from 'reselect'

import membersSelector from './membersSelector'

const selectionSelector = state => state.currentApp.ui.membersView.selection.ids

const draggedMembersSelector = createSelector(
  membersSelector, selectionSelector,
  (members, selection) => members.filter(member => selection.includes(member.id))
)

export default draggedMembersSelector
