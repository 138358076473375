import React from 'react'
import PropTypes from 'prop-types'

import { InputGroup } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

import FormControl from './formControlExtensions'

const FieldInput = ({
  name,
  icon,
  addon,
  children,
  inputComponent,
  ...props
}) => {
  let input
  if (inputComponent) {
    input = React.createElement(inputComponent, {
      name,
      ...props
    }, children)
  } else if (children) {
    input = React.cloneElement(children, {
      name,
      ...props,
      ...children.props
    })
  } else {
    input = <FormControl name={name} {...props} />
  }

  if (icon) {
    addon = (
      <FontAwesome
        name={icon}
      />
    )
  }

  if (addon) {
    return (
      <InputGroup>
        <InputGroup.Addon>
          {addon}
        </InputGroup.Addon>
        {input}
      </InputGroup>
    )
  } else {
    return input
  }
}

FieldInput.defaultProps = {
  name: null,
  icon: null,
  addon: null,
  children: null,
  inputComponent: null
}

FieldInput.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  addon: PropTypes.node,
  children: PropTypes.node,
  inputComponent: PropTypes.any // eslint-disable-line react/forbid-prop-types
}

export default FieldInput
