import * as types from '../constants/action-types'
import { clearCurrentAppReducers, replaceCurrentAppReducers } from '../store/replaceCurrentAppReducers'

export const receiveCurrentMember = currentMember => ({
  type: types.RECEIVE_CURRENT_MEMBER,
  payload: currentMember
})

export const receiveCurrentEventOrOrganization = currentEventOrOrganization => ({
  type: types.RECEIVE_CURRENT_EVENT_OR_ORGANIZATION,
  payload: currentEventOrOrganization
})

export const clearCurrentApp = () => ({
  type: types.CLEAR_CURRENT_APP
})

export const clearCurrentAppAndReducers = () => (dispatch) => {
  dispatch(clearCurrentApp())
  clearCurrentAppReducers()
}

export const replaceCurrentAppAndReducers = appName => (dispatch) => {
  replaceCurrentAppReducers(appName)
  dispatch(clearCurrentApp())
}
