import React from 'react'
import { format, parseISO } from 'date-fns'

import SelectableShift from '../../../../SelectableShift'
import ShiftList from '../../../../../../components/ShiftList'
import ShiftDetailsMedium from '../../ShiftDetailsMedium'

const GroupedShiftsList = ({ groupedShifts }) => {
  const shiftListHeader = key => format(parseISO(key), 'EEEE [d.] do MMMM yyyy')

  const renderShiftGroup = group => group.map(shift => (
    <SelectableShift
      key={shift.id}
      shift={shift}
    >
      <ShiftDetailsMedium shift={shift} />
    </SelectableShift>
  ))

  return (
    <div>
      {Object.entries(groupedShifts).map(([key, group]) => (
        <ShiftList
          key={key}
          header={shiftListHeader(key)}
        >
          {renderShiftGroup(group)}
        </ShiftList>
      ))}
    </div>
  )
}

export default GroupedShiftsList
