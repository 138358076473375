import React from 'react'
import { connect } from 'react-redux'
import { bool, number, func, arrayOf, object } from 'prop-types'

import { fetchShifts } from '../actions/shift-actions'
import getShifts from '../selectors/shifts/getShifts'
import { humanizeAttribute, humanizeModel } from '../utilities/i18n'

import { ServerSideDataTable } from '../components/DataTable'

const ShiftOverviewTable = ({ shifts, pages, loading, onFetchShifts }) => (
  <ServerSideDataTable
    stateKey="ShiftOverviewTable"
    onFetchData={onFetchShifts}
    data={shifts}
    loading={loading}
    pages={pages}
    sortable={false}
    columns={[
      {
        Header: humanizeModel('team'),
        sortable: true,
        accessor: 'team.name'
      },
      {
        Header: humanizeModel('schedule'),
        sortable: true,
        accessor: 'schedule.name'
      },
      {
        Header: humanizeAttribute('shift', 'name'),
        sortable: true,
        accessor: 'name'
      },
      {
        Header: humanizeAttribute('shift', 'startTime'),
        sortable: true,
        accessor: 'startTime'
      },
      {
        Header: humanizeAttribute('shift', 'endTime'),
        sortable: true,
        accessor: 'endTime'
      },
      {
        Header: humanizeAttribute('shift', 'duration'),
        accessor: 'duration'
      },
      {
        Header: humanizeAttribute('shift', 'attendanceCount'),
        accessor: 'attendanceCount'
      },
      {
        Header: humanizeAttribute('shift', 'attendanceLimit'),
        sortable: true,
        accessor: 'attendanceLimit'
      }
    ]}
  />
)

ShiftOverviewTable.propTypes = {
  shifts: arrayOf(object).isRequired,
  pages: number,
  loading: bool.isRequired,
  onFetchShifts: func.isRequired
}

ShiftOverviewTable.defaultProps = {
  pages: null
}

const mapStateToProps = (state) => {
  const { shiftsList: loading } = state.loadingStatus
  const { total: pages } = state.orm.Shift.meta.page || {}

  return {
    shifts: getShifts(state),
    pages,
    loading
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchShifts: params => dispatch(fetchShifts(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftOverviewTable)
