 import { singularize } from '../../utilities/inflectors'
import modelNameFromType from '../../utilities/modelNameFromType'

import {
  RECEIVE_OBJECT,
  RECEIVE_OBJECTS,
  RECEIVE_OBJECT_DELETION
} from '../../constants/action-types'

const modelFromType = (session, type) => {
  const modelName = modelNameFromType(type)
  const { [modelName]: model } = session

  return model
}

const getRelationshipValues = (model, relationships) => (
  Object.entries(relationships).reduce((result, [associationName, { data }]) => {
    if (data instanceof Array) {
      result.hasMany[associationName] = data.map(({ id }) => id)
    } else if (data) {
      result.belongsTo[associationName] = data.id

      if (model.fields[associationName].getClass().displayName === 'PolymorphicForeignKey') {
        result.belongsTo[`${associationName}Type`] = modelNameFromType(data.type)
      }
    } else {
      result.belongsTo[associationName] = null
    }

    return result
  }, { belongsTo: {}, hasMany: {} })
)

const getAssociationField = (model, associationModelName) => {
  const fieldAndName = Object.entries(model.fields).find(([_name, field]) => {
    if (field.toModelName === undefined) {
      return false
    }

    if (field.toModelName instanceof Array && field.toModelName.includes(associationModelName)) {
      return true
    }

    if (field.toModelName === associationModelName) {
      return true
    }
  })

  const field = fieldAndName[1]
  field.name = fieldAndName[0]

  return field
}

const createHasManyRelationships = (session, record, relationships, parentId, queue) => (
  Object.entries(relationships).forEach(([associationName, ids]) => {
    const modelName = record.getClass().modelName
    const association = record[associationName]
    const removeQuery = association && association.exclude(obj => ids.includes(obj.id))

    try {
      if (record[associationName].add && record[associationName].remove) {
        ids.forEach(id => (
          record[associationName].add(id)
        ))

        const idsToRemove = removeQuery.toRefArray().map(object => object.id)

        idsToRemove.forEach(id => (
          record[associationName].remove(id)
        ))
      } else {
        const model = modelFromType(session, associationName)

        const associationField = getAssociationField(model, modelName)

        ids.forEach((id) => {
          if (associationField.getClass().displayName === 'PolymorphicForeignKey') {
            model.withId(id).update({
              [associationField.name]: parentId,
              [`${associationField.name}Type`]: modelName
            })
          } else {
            model.withId(id).update({
              [associationField.name]: parentId
            })
          }
        })

        removeQuery.delete()
      }
    } catch (error) {
      if (queue) {
        queue.push({ record, relationships: { [associationName]: ids }, parentId })
      } else {
        console.warn(error)
      }
    }
  })
)

const deleteObject = (session, id, type) => {
  const model = modelFromType(session, type)
  model.withId(id).delete()
}

const upsertObject = (session, object, queue) => {
  const { type, id, attributes, relationships } = object
  const model = modelFromType(session, type)
  const relationshipValues = getRelationshipValues(model, relationships || {})

  const record = model.upsert({
    id,
    ...attributes,
    ...relationshipValues.belongsTo
  })

  createHasManyRelationships(session, record, relationshipValues.hasMany, id, queue)
}

const upsertObjects = (session, objects, queue) => objects.forEach(object => upsertObject(session, object, queue))

const upsertDocument = (session, document, queue) => {
  if (document === undefined) {
    return
  }

  if (document instanceof Array) {
    upsertObjects(session, document, queue)
  } else {
    upsertObject(session, document, queue)
  }
}

const createQueuedRelationships = (session, queue) => (
  queue.forEach(({ record, relationships, parentId }) => (
    createHasManyRelationships(session, record, relationships, parentId)
  ))
)

const clearModelForType = (session, type) => {
  const model = modelFromType(session, type)
  model.all().delete()
}

const updateSession = (session, action) => {
  const queue = []

  switch (action.type) {
    case RECEIVE_OBJECT: {
      const { data, included } = action.payload.response

      upsertDocument(session, included, queue)
      upsertDocument(session, data, queue)
      createQueuedRelationships(session, queue)
      break
    }
    case RECEIVE_OBJECTS: {
      const { response, type } = action.payload
      const { data, included } = response

      clearModelForType(session, type)
      upsertDocument(session, included, queue)
      upsertDocument(session, data, queue)
      createQueuedRelationships(session, queue)
      break
    }
    case RECEIVE_OBJECT_DELETION: {
      const { id, type } = action.payload

      deleteObject(session, id, type)
      break
    }
    default:
  }
}

export default updateSession
