/* global I18n */

import React from 'react'
import ReactTable from 'react-table'
import { camelCase } from 'lodash'

import 'react-table/react-table.css'
import './styles.scss'

const DataTable = ({ ...tableProps }) => {
  const translatedTexts = Object.entries(I18n.t('components.data_table')).reduce((result, [key, text]) => {
    result[`${camelCase(key)}Text`] = text

    return result
  }, {})

  return (
    <ReactTable
      pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
      defaultPageSize={15}
      className="-striped"
      {...translatedTexts}
      {...tableProps}
    />
  )
}

export default DataTable
