import { createSelector } from 'reselect'
import getBundlesWithEligibility from './getBundlesWithEligibility'

const getFilter = state => state.currentApp.filters.ineligibilityFilter

const getVisibleBundles = createSelector(
  getBundlesWithEligibility,
  getFilter,
  (bundles, filter) => {
    switch (filter) {
      case 'SHOW_ALL':
        return bundles
      case 'HIDE_INELIGIBLE':
        return bundles.filter(bundle => bundle.eligible)
      default:
        throw new Error(`Unknown filter: ${filter}`)
    }
  }
)

export default getVisibleBundles
