/* global Reflux */

export default Reflux.createActions({
  'addPage': {},
  'removePage': {},
  'updateFieldPosition': {},
  'updatePageDescription': { sync: true },
  'updateStaticPageDescription': { sync: true },
  'removeField': {},
  'saveCustomForm': {}
});
