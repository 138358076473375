/* global I18n, moment, Routes */

import React from 'react'
import PropTypes from 'prop-types'

import { countBy } from 'lodash'

import AttendanceStatus from './AttendanceStatus'

const i18noptions = {
  scope: 'components.grouped_shifts_for_registration'
}

const registrationTag = (id, startTime, endTime, schedule) => {
  let anchorText = ''
  if (!schedule) {
    anchorText += `${moment(startTime).utc().strftime(I18n.t('date.formats.default'))} `
  }

  anchorText += `${moment(startTime).utc().format('HH:mm')} - \
    ${moment(endTime).utc().format('HH:mm')}`

  return (
    <a href={Routes.registration_shift_path(id)}>
      {anchorText}
    </a>
  )
}

const renderAttendanceStatuses = (attendances) => {
  const attendanceStatuses = countBy(attendances, (attendance) => {
    if (attendance.shown === null) {
      return 'notRegistred'
    }

    if (attendance.shown === false) {
      return 'notShown'
    }

    if (attendance.shown) {
      if (attendance.completed === null) {
        return 'shown'
      }

      if (attendance.completed === false) {
        return 'notCompleted'
      }

      if (attendance.completed) {
        return 'completed'
      }
    }
  })

  return Object.entries(attendanceStatuses).map(([name, count]) => (
    <AttendanceStatus name={name} count={count} key={name} />
  ))
}

const renderScheduleName = (schedule) => {
  if (schedule) {
    return (
      <div className="shift-cell">{schedule.name}</div>
    )
  }
  return null
}

const ShiftRow = ({
  id,
  startTime,
  endTime,
  name,
  attendances,
  schedule,
  showSchedule
}) => (
  <div className="shift-row">
    <div className="shift-cell" data-label={I18n.t('date_time', i18noptions)}>
      {registrationTag(id, startTime, endTime, schedule)}
    </div>
    {showSchedule && (
      <div className="shift-cell" data-label={I18n.t('schedule', i18noptions)}>
        {schedule ? schedule.name : ''}
      </div>
    )}
    <div className="shift-cell" data-label={I18n.t('shift', i18noptions)}>
      {name}
    </div>
    <div className="shift-cell">
      {renderAttendanceStatuses(attendances)}
    </div>
  </div >
)

ShiftRow.defaultProps = {
  name: null,
  schedule: null,
  attendances: []
}

ShiftRow.propTypes = {
  id: PropTypes.number.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  name: PropTypes.string,
  attendances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      shown: PropTypes.bool,
      completed: PropTypes.bool
    })
  ),
  schedule: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
}

export default ShiftRow
