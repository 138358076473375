import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

const FormControlContainer = ({ className, ...divProps }) => (
  <div
    className={classNames(
      'form-control',
      'form-control-container',
      className
    )}
    {...divProps}
  />
)

FormControlContainer.propTypes = {
  className: string
}

FormControlContainer.defaultProps = {
  className: null
}

export default FormControlContainer
