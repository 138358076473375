import Api, { RestPath } from '../../../Api'
import { getRequest } from '../../../Api/request'

class MemberTypeApi extends Api {
  constructor(scheduleId) {
    const parentPath = new RestPath('schedules')
    const path = new RestPath('member_types', parentPath)
    super(path)

    this.scheduleId = scheduleId
  }

  fetchAll() {
    return getRequest({
      path: this.path.collectionPath(this.scheduleId),
      params: {
        include: 'properties'
      }
    })
  }
}

export default MemberTypeApi
