import React from 'react'
import { number, string, bool, node, func, arrayOf, objectOf, oneOfType } from 'prop-types'
import { isObject } from 'lodash'

import { Transition } from 'react-transition-group'

export const defaultProps = {
  duration: 400,
  children: null,
  transitionStyles: () => ({}),
  defaultStyles: () => ({}),
  transitionAttribute: null,
  easing: {
    enter: 'ease-in',
    exit: 'ease-out'
  }
}

const Effect = ({
  duration,
  active,
  children,
  transitionStyles,
  defaultStyles,
  transitionAttribute,
  easing,
  ...otherProps
}) => {
  const renderChild = (state) => {
    const stateKey = {
      entering: 'enter',
      entered: 'enter',
      exiting: 'exit',
      exited: 'exit'
    }[state]

    let currentEasing
    if (isObject(easing)) {
      currentEasing = easing[stateKey]
    } else {
      currentEasing = easing
    }

    let currentDuration
    if (isObject(duration)) {
      currentDuration = duration[stateKey]
    } else {
      currentDuration = duration
    }

    let transition
    if (currentEasing && transitionAttribute) {
      transition = { transition: `${transitionAttribute} ${currentDuration}ms ${currentEasing}` }
    }

    return React.cloneElement(children, {
      style: {
        ...transition,
        ...defaultStyles(currentDuration),
        ...transitionStyles(currentDuration)[state]
      }
    })
  }

  return (
    <Transition
      timeout={duration}
      in={active}
      {...otherProps}
    >
      {state => renderChild(state)}
    </Transition>
  )
}

Effect.propTypes = {
  active: bool.isRequired,
  duration: oneOfType([number, objectOf(number)]),
  children: oneOfType([node, arrayOf(node)]),
  transitionStyles: func,
  transitionAttribute: string,
  defaultStyles: func,
  easing: oneOfType([string, objectOf(string)])
}

Effect.defaultProps = defaultProps

export default Effect
