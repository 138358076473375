/* global React, _ */
import CustomFormActions from './custom_form_actions';
import DescriptionField from './description_field';

import createReactClass from 'create-react-class';

export default createReactClass({

  handleDescriptionChange: function (locale, description) {
    CustomFormActions.updateStaticPageDescription(this.props.page_key, locale, description);
  },

  render: function () {
    var page = {}
    const available_locales = JSON.parse(document.body.dataset.availableLocales)
    var descriptions = this.props.descriptions || []
    _.each(available_locales, function (locale, i) {
      page['description_' + locale] = descriptions[locale]
    })

    return (
      <div className="ibox">
        <div className="ibox-title">
          <h5>{this.props.page_key} page</h5>
        </div>
        <div className="ibox-content">
          <DescriptionField key={this.props.page_key} page_key={this.props.page_key} handleDescriptionChange={this.handleDescriptionChange} page={page} />
        </div>
      </div>
    );
  }
});
