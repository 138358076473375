/* global React, I18n */

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    return (
      <div className="ibox float-e-margins">
        <div className="ibox-content">
          <div className="alert alert-danger">
            <h3>
              <i className="fa fa-warning fa-2x">
                <span style={{ marginLeft: '10px' }}>
                  {I18n.t('tickets_scanner.errors.ticket_not_found')}
                </span>
              </i>
            </h3>
          </div>
        </div>
      </div>
    );
  }
})
