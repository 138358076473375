import React from 'react';
import { useDrag } from 'react-dnd15';

const DraggableField = ({ field }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'FIELD',
    item: { type: 'FIELD', ...field },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const isQrCode = field.type === 'qrcode';

  return (
    <div
      ref={drag}
      className={`draggable-field ${isDragging ? 'dragging' : ''} ${isQrCode ? 'qrcode-field' : ''}`}
    >
      {isQrCode ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            width: '24px',
            height: '24px',
            border: '1px dashed #666',
            marginRight: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10px'
          }}>
            QR
          </div>
          {field.name}
        </div>
      ) : (
        field.name
      )}
    </div>
  )
}

const FieldsList = ({ fields }) => {
  return (
    <div className="fields-container">
      <h2 className="fields-title">Available Fields</h2>
      <div className="fields-grid">
        {fields.map(field => (
          <DraggableField key={field.id} field={field} />
        ))}
      </div>
    </div>
  )
}

export default FieldsList 
