let currentId = 0

const generateId = (prefix) => {
  currentId += 1

  if (prefix) {
    return `${prefix}-${currentId}`
  }
  return currentId
}
export default generateId

export const resetIdCounter = () => (currentId = 0)
