import { createSelector } from 'redux-orm'

import { orm } from '../../../../store'
import memberRecordToObject from './memberRecordToObject'
import scheduleSelector from '../schedules/scheduleSelector'

const ormStateSelector = state => state.orm
const includeExternalShiftsSelector = state => (
  state.currentApp.ui.membersView.includeExternalShifts
)

const membersSelector = createSelector(
  orm,
  ormStateSelector,
  includeExternalShiftsSelector,
  scheduleSelector,
  (session, includeExternalShifts, schedule) => session.Member.all().toModelArray().map(member => (
    memberRecordToObject(member, { schedule, includeExternalShifts })
  ))
)

export default membersSelector
