import PropTypes from 'prop-types'

const { string, shape, oneOfType } = PropTypes

export const persistedShiftBundlePropTypes = shape({
  id: string.isRequired,
  name: string.isRequired
})

export const newShiftBundlePropTypes = shape({
  name: string
})

const shiftBundlePropTypes = oneOfType([
  persistedShiftBundlePropTypes,
  newShiftBundlePropTypes
])

export default shiftBundlePropTypes
