import React from 'react'
import { bool } from 'prop-types'
import FontAwesome from 'react-fontawesome'

const LoadingIndicator = ({ nested, ...iconProps }) => {
  const icon = (
    <FontAwesome
      name="spinner"
      pulse
      {...iconProps}
    />
  )

  if (nested) {
    return icon
  }

  return (
    <div
      style={{
        textAlign: 'center'
      }}
    >
      {icon}
    </div>
  )
}

LoadingIndicator.propTypes = {
  nested: bool
}

LoadingIndicator.defaultProps = {
  nested: false
}

export default LoadingIndicator
