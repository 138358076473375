import { createSelector } from 'redux-orm'
import { orm } from '../../../../store'
import shiftRecordToObject from './shiftRecordToObject'

const ormStateSelector = state => state.orm
const loadingStatusSelector = state => state.loadingStatus
const shiftIdSelector = (state, shiftId) => shiftId

const shiftSelector = createSelector(
  orm,
  ormStateSelector,
  shiftIdSelector,
  loadingStatusSelector,
  (session, shiftId, { gradesList, bundlesList, membersList }) => (
    shiftRecordToObject(session.Shift.withId(shiftId), { gradesList, bundlesList, membersList })
  )
)

export default shiftSelector
