import React from 'react'
import { bool, number, func, object, arrayOf } from 'prop-types'

const FILTER_REQUEST_DELAY_IN_MILLISECONDS = 500

const serverSide = (WrappedDataTable) => {
  const wrapper = class extends React.Component {
    state = {
      filtering: false,
      fetchDataTimeout: null
    }

    abortRequest = null

    fetchDataParams = (tableState) => {
      const { page, pageSize, sorted, filtered } = tableState

      const params = {
        page: {
          number: page + 1,
          size: pageSize
        }
      }

      if (sorted && sorted.length > 0) {
        params.sort = sorted.map(({ id: attribute, desc }) => (
          `${desc ? '-' : ''}${attribute}`
        )).join(',')
      }

      if (filtered && filtered.length > 0) {
        params.filter = filtered.reduce((result, { id: attribute, value }) => {
          result[attribute] = value
          return result
        }, {})
      }

      return params
    }

    fetchData = (tableState, newFiltering) => {
      const { onFetchData } = this.props
      const params = this.fetchDataParams(tableState)

      this.abortFetchData()
      const { request, abort } = onFetchData(params)
      this.abortRequest = abort
      request.then(() => {
        this.abortRequest = null
      })

      this.setState({
        filtering: newFiltering,
        fetchDataTimeout: null
      })
    }

    abortFetchData = () => {
      if (this.abortRequest) {
        this.abortRequest('test')
        this.abortRequest = null
      }
    }

    stopDelayedFetchData = () => {
      const { fetchDataTimeout } = this.state

      if (fetchDataTimeout) {
        clearTimeout(fetchDataTimeout)
      }
    }

    handleFetchData = (tableState) => {
      const { filtering } = this.state
      const newFiltering = tableState.filtered.length > 0

      this.stopDelayedFetchData()

      if (newFiltering && !filtering) {
        this.setState({
          fetchDataTimeout: setTimeout(() => (
            this.fetchData(tableState, newFiltering)
          ), FILTER_REQUEST_DELAY_IN_MILLISECONDS)
        })
      } else {
        this.fetchData(tableState, newFiltering)
      }
    }

    componentWillUnmount() {
      this.abortFetchData()
    }

    render() {
      const { pages, loading, data, onFetchData: _, ...tableProps } = this.props

      return (
        <WrappedDataTable
          manual
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.handleFetchData}
          {...tableProps}
        />
      )
    }
  }

  wrapper.propTypes = {
    onFetchData: func.isRequired,
    data: arrayOf(object),
    pages: number,
    loading: bool
  }

  wrapper.defaultProps = {
    data: [],
    pages: null,
    loading: true
  }

  return wrapper
}

export default serverSide
