/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'
import { difference } from 'lodash'

import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import FilterForm from './FilterForm'
import MemberCard from './MemberCard'
import DoubleBookingWarningModal from './DoubleBookingWarningModal'
import LoadingIndicator from '../../../../components/LoadingIndicator'
import IBox from '../../../../components/IBox'
import PopoverIcon from '../../../../components/PopoverIcon'

import sortedMembersSelector from '../../selectors/members/sortedMembersSelector'
import {
  memberDragStarted,
  memberDragStopped,
  selectMember,
  selectMembersRange,
  deselectMember,
  deselectMembersRange
} from '../../actions/member-actions'
import {
  undoCreateAttendance
} from '../../actions/attendance-actions'

import './styles.scss'

const i18noptions = {
  scope: 'components.schedule_planner.members'
}

const defaultState = {
  newDoubleBookedMembers: [],
  newDoubledBoookedShiftId: null
}

class MembersList extends React.Component {
  state = defaultState

  componentWillReceiveProps(nextProps) {
    const { newDoubleBookedMembers, newDoubledBoookedShiftId } = this.state
    const { members } = this.props
    const { members: nextMembers } = nextProps

    let nextNewDoubledBoookedShiftId = newDoubledBoookedShiftId
    let nextNewDoubleBookedMembers = [...newDoubleBookedMembers]
    members.forEach((member) => {
      const nextMember = nextMembers.find(currentNextMember => currentNextMember.id === member.id)

      if (nextMember) {
        const { doubleBookedShiftIds } = member
        const { doubleBookedShiftIds: nextDoubleBookedShiftIds } = nextMember

        if (nextDoubleBookedShiftIds.length > doubleBookedShiftIds.length) {
          if (nextNewDoubledBoookedShiftId === newDoubledBoookedShiftId) {
            nextNewDoubledBoookedShiftId = difference(nextDoubleBookedShiftIds, doubleBookedShiftIds)[0]
          }
          nextNewDoubleBookedMembers.push(nextMember)
        } else if (nextDoubleBookedShiftIds.length < doubleBookedShiftIds.length) {
          nextNewDoubleBookedMembers = nextNewDoubleBookedMembers
            .filter(doubleBookedMember => doubleBookedMember !== nextMember.id)
        }
      }
    }, [])

    this.setState({
      newDoubleBookedMembers: nextNewDoubleBookedMembers,
      newDoubledBoookedShiftId: nextNewDoubledBoookedShiftId
    })
  }

  resetState = () => {
    this.setState(defaultState)
  }

  handleAcceptDoubleBooking = () => {
    this.resetState()
  }

  handleUndoDoubleBooking = () => {
    const { onUndoDoubleBooking } = this.props
    const { newDoubleBookedMembers, newDoubledBoookedShiftId } = this.state

    const memberIds = newDoubleBookedMembers.map(member => member.id)

    onUndoDoubleBooking(newDoubledBoookedShiftId, memberIds)
    this.resetState()
  }

  handleClickOutside = () => {
    const { onDeselectMembersRange, selectedMemberIds } = this.props

    onDeselectMembersRange(null, selectedMemberIds)
  }

  renderMemberCardList = () => {
    const {
      members,
      loadingMembers,
      onDragMemberBegin,
      onDragMemberEnd,
      onSelectMember,
      onSelectMembersRange,
      onDeselectMember,
      onDeselectMembersRange,
      selectedMemberIds,
      lastSelectedMemberId,
      isDragging
    } = this.props

    const cellMeasurerCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 76
    })

    const renderMemberCard = ({ index, key, parent, style }) => {
      const orderedMemberIds = members.map(member => member.id)
      const member = members[index]
      const { id } = member
      const memberIsLoading = loadingMembers.includes(id)
      const memberIsSelected = selectedMemberIds.includes(id)

      return (
        <CellMeasurer
          key={key}
          cache={cellMeasurerCache}
          parent={parent}
          rowIndex={index}
          columnIndex={0}
        >
          <div style={style}>
            <MemberCard
              isLoading={memberIsLoading}
              isSelected={memberIsSelected}
              isDragging={isDragging && memberIsSelected}
              member={member}
              onBeginDrag={onDragMemberBegin}
              onEndDrag={onDragMemberEnd}
              onSelect={onSelectMember}
              onSelectRange={onSelectMembersRange}
              onDeselectRange={onDeselectMembersRange}
              onDeselect={onDeselectMember}
              orderedIds={orderedMemberIds}
              selectedIds={selectedMemberIds}
              lastSelectedId={lastSelectedMemberId}
              style={style}
            />
          </div>
        </CellMeasurer>
      )
    }

    const selectionHelpText = { __html: I18n.t('selection_help_html', i18noptions) }

    return (
      <div>
        <span>
          {I18n.t('selection_help_icon', i18noptions)}
          {' '}
          <PopoverIcon
            icon="question-circle"
            content={
              <ul
                dangerouslySetInnerHTML={selectionHelpText} // eslint-disable-line react/no-danger
              />
            }
          />
        </span>
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              width={width}
              height={400}
              className="member-card-list"
              role="list"
              rowRenderer={renderMemberCard}
              rowCount={members.length}
              deferredMeasurementCache={cellMeasurerCache}
              rowHeight={cellMeasurerCache.rowHeight}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  renderContent = () => {
    const { isLoading } = this.props

    if (isLoading) {
      return (<LoadingIndicator size="4x" />)
    }

    return (
      <div id="member-view">
        <FilterForm />
        {this.renderMemberCardList()}
      </div>
    )
  }
  render() {
    const { newDoubleBookedMembers } = this.state

    return (
      <IBox
        title={I18n.t('title', i18noptions)}
      >
        <DoubleBookingWarningModal
          onAccept={this.handleAcceptDoubleBooking}
          onUndo={this.handleUndoDoubleBooking}
          members={newDoubleBookedMembers}
        />
        {this.renderContent()}
      </IBox>
    )
  }
}

MembersList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  loadingMembers: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedMemberIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  lastSelectedMemberId: PropTypes.number,
  onDragMemberBegin: PropTypes.func.isRequired,
  onDragMemberEnd: PropTypes.func.isRequired,
  onSelectMember: PropTypes.func.isRequired,
  onDeselectMember: PropTypes.func.isRequired,
  onSelectMembersRange: PropTypes.func.isRequired,
  onDeselectMembersRange: PropTypes.func.isRequired,
  onUndoDoubleBooking: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const members = sortedMembersSelector(state)
  const { membersList: isLoading, members: loadingMembers } = state.loadingStatus
  const { selection, isDragging } = state.currentApp.ui.membersView
  const { ids: selectedMemberIds, lastSelectedId: lastSelectedMemberId } = selection

  return {
    members,
    isLoading,
    loadingMembers,
    selectedMemberIds,
    lastSelectedMemberId,
    isDragging
  }
}

const mapDispatchToProps = dispatch => ({
  onDragMemberBegin: () => dispatch(memberDragStarted()),
  onDragMemberEnd: () => dispatch(memberDragStopped()),
  onSelectMember: (id, multiSelect) => dispatch(selectMember(id, multiSelect)),
  onDeselectMember: (id, multiSelect) => dispatch(deselectMember(id, multiSelect)),
  onSelectMembersRange: (id, ids) => dispatch(selectMembersRange(id, ids)),
  onDeselectMembersRange: (id, ids) => dispatch(deselectMembersRange(id, ids)),
  onUndoDoubleBooking: (shiftId, memberIds) => dispatch(undoCreateAttendance(shiftId, memberIds))
})

export { MemberCardTemplate } from './MemberCard'
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  onClickOutside(MembersList)
)
