/* global I18n */

import React from 'react'
import { string } from 'prop-types'

import FontAwesome from 'react-fontawesome'

const i18noptions = {
  scope: 'components.member_info_button.paid_status'
}

const PaidStatus = ({ paidStatus }) => {
  let colorClass
  let labelText

  switch (paidStatus) {
    case 'paid':
      colorClass = 'text-navy'
      labelText = I18n.t('paid', i18noptions)
      break
    case 'partially_paid':
      colorClass = 'text-warning'
      labelText = I18n.t('partial_paid', i18noptions)
      break
    case 'not_paid':
      colorClass = 'text-dange'
      labelText = I18n.t('not_paid', i18noptions)
      break
    case 'disabled':
    default:
      return null
  }

  return (
    <p>
      <FontAwesome name="money" className={colorClass} />
      {' '}{labelText}
    </p>
  )
}

PaidStatus.propTypes = {
  paidStatus: string.isRequired
}

export default PaidStatus
