import React from 'react'
import { arrayOf } from 'prop-types'

import Calendar from '../../components/Calendar'

import { shiftPropTypes } from '../propTypes/shiftPropTypes'

const ShiftCalendar = ({ shifts, ...calendarProps }) => (
  <Calendar
    events={shifts}
    popup
    {...calendarProps}
  />
)

ShiftCalendar.defaultProps = {
  shifts: []
}

ShiftCalendar.propTypes = {
  shifts: arrayOf(shiftPropTypes)
}

export default ShiftCalendar
