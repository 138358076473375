import { createSelector } from 'reselect'
import getVisibleShifts from './getVisibleShifts'

const getTextFilter = state => state.currentApp.filters.textFilter
const getScheduleFilter = state => state.currentApp.filters.scheduleFilter
const getGradeFilter = state => state.currentApp.filters.gradeFilter

const getDatumForShift = (shift) => {
  let datum = ''
  if (shift.name) { datum += shift.name }
  if (shift.schedule.location) { datum += shift.schedule.location }
  if (shift.schedule.name) { datum += shift.schedule.name }
  return datum
}

const applyTextFilter = (shifts, textFilter) => {
  const regex = new RegExp(textFilter, 'i')

  return shifts.filter((shift) => {
    const datum = getDatumForShift(shift)
    return datum.search(regex) > -1
  })
}

const applyGradeFilter = (shifts, gradeFilter) => (
  shifts.filter(shift => shift.grade && gradeFilter.includes(shift.grade.id))
)

const applyScheduleFilter = (shifts, scheduleFilter) => (
  shifts.filter(shift => scheduleFilter.includes(shift.schedule.id))
)

const getFilteredShifts = createSelector(
  getVisibleShifts,
  getScheduleFilter,
  getGradeFilter,
  getTextFilter,
  (shifts, scheduleFilter, gradeFilter, textFilter) => {
    if (scheduleFilter.length > 0) {
      shifts = applyScheduleFilter(shifts, scheduleFilter)
    }

    if (gradeFilter.length > 0) {
      shifts = applyGradeFilter(shifts, gradeFilter)
    }

    if (textFilter !== '') {
      shifts = applyTextFilter(shifts, textFilter)
    }

    return shifts
  }
)

export default getFilteredShifts
