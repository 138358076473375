import { getDate, parseISO, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import memberRecordToObject from '../members/memberRecordToObject'

const shiftRecordToObject = (shift, { gradesList, bundlesList, membersList }) => {
  const shiftRef = shift.ref

  const startTime = utcToZonedTime(parseISO(shift.startTime), 'UTC')
  const endTime = utcToZonedTime(parseISO(shift.endTime), 'UTC')
  const schedule = shift.schedule

  let timeTitle
  if (getDate(startTime) !== getDate(endTime)) {
    timeTitle = `${format(startTime, 'dd MMM HH:mm')} - ${format(endTime, 'dd MMM HH:mm')}`
  } else {
    timeTitle = `${format(startTime, 'dd MMM HH:mm')}-${format(endTime, 'HH:mm')}`
  }

  let requirements
  if (bundlesList) {
    requirements = []
  } else if (shift.bundle) {
    requirements = shift.bundle.requirements.toModelArray()
  } else {
    requirements = shift.requirements.toModelArray()
  }

  return {
    ...shiftRef,
    start: startTime,
    end: endTime,
    startTime,
    endTime,
    timeTitle,
    attendanceCount: shift.attendanceCount(),
    availableSpots: shift.availableSpots(),
    gradeId: !gradesList && shift.grade && shift.grade.ref.id,
    bundleId: !bundlesList && shift.bundle && shift.bundle.ref.id,
    grade: !gradesList && shift.grade && shift.grade.ref,
    bundle: !bundlesList && shift.bundle && shift.bundle.ref,
    requirements: requirements
      .map(requirement => ({
        ...requirement.ref,
        propertyId: requirement.property.id,
        attendanceCount: requirement.attendanceCount()
      })),
    attendances: membersList ? [] : shift.attendances.toModelArray().map(attendance => ({
      ...attendance.ref,
      member: attendance.member && memberRecordToObject(attendance.member, { schedule })
    })).sort((a, b) => (a.member.name > b.member.name ? 1 : -1)),
    preferences: membersList ? [] : shift.preferences.toModelArray().map(preference => ({
      ...preference.ref,
      member: preference.member && memberRecordToObject(preference.member, { schedule })
    }))
  }
}

export default shiftRecordToObject
