/* global I18n */

import React from 'react'
import { func, arrayOf } from 'prop-types'
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button
} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

import { shiftPropTypes } from '../../../propTypes/shiftPropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.shared.modal_form'
}

class ModalFormShiftsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalHours: 0
    };
  }

  componentDidMount() {
    this.calculateTotalHours();
  }

  componentDidUpdate(prevProps) {
    if (this.props.shifts !== prevProps.shifts) {
      this.calculateTotalHours();
    }
  }

  calculateTotalHours() {
    let hours = 0;
    this.props.shifts.forEach(shift => {
      const start = new Date(shift.startTime);
      const end = new Date(shift.endTime);
      hours += (end - start) / (1000 * 60 * 60);
    });
    this.setState({ totalHours: hours });
  }

  render() {
    const { shifts, onRemoveShift } = this.props;
    const { totalHours } = this.state;

    return (
      <div>
        <ListGroup>
          {shifts.map(({ id, name, timeTitle }) => (
            <ListGroupItem>
              <Row>
                <Col md={9}>
                  <b>{name ? `${name}: ${timeTitle}` : timeTitle}</b>
                </Col>
                <Col md={3}>
                  <Button
                    key="remove"
                    bsStyle="danger"
                    bsSize="xs"
                    onClick={() => onRemoveShift(id)}
                    block
                  >
                    <FontAwesome name="times" />
                    {' '}{I18n.t('remove', i18noptions)}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </ListGroup>
        <div>{I18n.t('total_hours', i18noptions)}: {totalHours}</div>
      </div>
    );
  }
}

ModalFormShiftsList.propTypes = {
  onRemoveShift: func.isRequired,
  shifts: arrayOf(shiftPropTypes)
}

ModalFormShiftsList.defaultProps = {
  shifts: []
}

export default ModalFormShiftsList
