/* global Reflux */

export default Reflux.createActions([
  "updateQuery", // Triggers when query string updates by any source
  "forceSearch", // Triggers when user hits enter on input with entered query
  "showExtendedModal", // Triggers when query met requrements to show extended results modal
  "dismissExtendedModal", // Triggers when user hits enter on input with entered query
  "checkIn", // Check-in current displayed member
  "removeCheckIn", // Check-out current displayed member
  "resetOwnersList", // Triggers when members list (autocomplete) must be updated
  "reset", // Return scanner state to the initial value
  "showEventPosModal", // Triggers when user hits enter on input with entered query
  "dismissEventPosModal", // Triggers when user hits enter on input with entered query
  "activateChip", // Triggers when chip is scanned
]);
