/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, FormControl, HelpBlock } from 'react-bootstrap'

import memberTypePropTypes from '../../propTypes/memberTypePropTypes'
import teamMemberTypePropTypes from '../../propTypes/teamMemberTypePropTypes'

const i18noptions = {
  scope: 'components.team_member_types_select'
}

const TeamMemberType = ({
  index,
  formInputNamePrefix,
  teamMemberType,
  updateTeamMemberTypeLimit,
  onSelectMemberTypeId,
  memberTypes
}) => (
  /* eslint-disable no-underscore-dangle */
  <div style={{ display: 'inline-block' }}>
    <Row>
      <Col md={6}>
        <FormControl
          name={`${formInputNamePrefix}[${index}][member_type_id]`}
          componentClass="select"
          value={teamMemberType.memberTypeId}
          onChange={e => onSelectMemberTypeId(index, parseInt(e.target.value))}
        >
          {memberTypes.map(memberType => (
            <option key={memberType.id} value={memberType.id}>
              {memberType.name}
            </option>
          ))}
        </FormControl>
      </Col>

      <Col md={6}>
        <FormControl
          name={`${formInputNamePrefix}[${index}][limit]`}
          type="number"
          value={typeof teamMemberType.limit === 'number' && isFinite(teamMemberType.limit) ?
            teamMemberType.limit : ''
          }
          onChange={e => updateTeamMemberTypeLimit(index, parseInt(e.target.value))}
        />
        <HelpBlock>{I18n.t('member_type_limit_help_text', i18noptions)}</HelpBlock>
      </Col>

    </Row>
  </div>
  /* eslint-enable no-underscore-dangle */
)

TeamMemberType.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  formInputNamePrefix: PropTypes.string.isRequired,
  teamMemberType: teamMemberTypePropTypes.isRequired,
  updateTeamMemberTypeLimit: PropTypes.func.isRequired,
  onSelectMemberTypeId: PropTypes.func.isRequired,
  memberTypes: PropTypes.arrayOf(memberTypePropTypes).isRequired
}

export default TeamMemberType
