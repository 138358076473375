import React from 'react'
import { func, arrayOf, shape } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { unpreferShift } from '../../../../actions/shift-actions'
import getPreferredShifts from '../../../../selectors/shifts/getPreferredShifts'
import getPreferredSchedules from '../../../../selectors/schedules/getPreferredSchedules'
import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'
import schedulePropTypes from '../../../../../propTypes/schedulePropTypes'
import ScheduleAndGradeGroupedShiftsList from '../ScheduleAndGradeGroupedShiftsList'

const PreferredShiftsList = ({ shifts, schedules, actions }) => (
  <ScheduleAndGradeGroupedShiftsList
    shifts={shifts}
    schedules={schedules}
    actions={actions}
  />
)

PreferredShiftsList.propTypes = {
  shifts: arrayOf(shiftPropTypes).isRequired,
  schedules: arrayOf(schedulePropTypes).isRequired,
  actions: shape({
    unpreferShift: func.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  shifts: getPreferredShifts(state),
  schedules: getPreferredSchedules(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    unpreferShift
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferredShiftsList)
