import { createSelector } from 'redux-orm'
import { parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { orm } from '../../../../store'

const dbStateSelector = state => state.orm

const getShifts = createSelector(
  orm,
  dbStateSelector,
  session => session.Shift.all().orderBy('startTime').toModelArray().map(shift => ({
    ...shift.ref,
    startTime: utcToZonedTime(parseISO(shift.ref.startTime), 'UTC'),
    endTime: utcToZonedTime(parseISO(shift.ref.endTime), 'UTC'),
    schedule: shift.schedule.ref,
    bundle: shift.bundle && shift.bundle.ref,
    grade: shift.grade && shift.grade.ref,
    requirements: shift.requirements.toRefArray()
  }))
)

export default getShifts
