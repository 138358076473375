import { createSelector } from 'redux-orm'
import { orm } from '../../store'

const dbStateSelector = state => state.orm

const getMembers = createSelector(
  orm,
  dbStateSelector,
  session => session.Member.all().toModelArray()
)

export default getMembers
