import React from 'react';
import { useDrop } from 'react-dnd15';
import PlacedField from './PlacedField';

const DesignCanvas = ({
  templateSignedId,
  placedFields,
  onFieldDrop,
  onFieldRemove,
  onFieldSizeChange,
}) => {
  const [, drop] = useDrop({
    accept: 'FIELD',
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset();
      const initialOffset = monitor.getInitialClientOffset();
      const initialSourceClientOffset = monitor.getInitialSourceClientOffset();

      // Calculate how far the mouse is from the top-left of the dragged element
      const mouseOffset = {
        x: initialOffset.x - initialSourceClientOffset.x,
        y: initialOffset.y - initialSourceClientOffset.y
      };

      const canvasRect = document.getElementById('design-canvas').getBoundingClientRect();

      // Subtract the mouse offset to get the position of the element's top-left corner
      let xPercent = ((offset.x - mouseOffset.x - canvasRect.left) / canvasRect.width) * 100;
      let yPercent = ((offset.y - mouseOffset.y - canvasRect.top) / canvasRect.height) * 100;

      if (xPercent < 0) {
        xPercent = 0;
      }

      if (yPercent < 0) {
        yPercent = 0;
      }

      if (xPercent > 100) {
        xPercent = 100;
      }

      if (yPercent > 100) {
        yPercent = 100;
      }

      onFieldDrop(item, xPercent, yPercent);
    }
  });

  const backgroundImage = templateSignedId ? `url(/blobs/proxy/${templateSignedId}/template_bg)` : null

  return (
    <div className="canvas-container">
      <div className="canvas-aspect-ratio-box">
        <div
          id="design-canvas"
          ref={drop}
          className="canvas-area"
          style={{
            backgroundImage: backgroundImage ? backgroundImage : 'none',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          {placedFields.map(field => (
            <PlacedField
              key={field.id}
              field={field}
              onRemove={onFieldRemove}
              onSizeChange={onFieldSizeChange}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DesignCanvas
