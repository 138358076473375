import React from 'react'
import PropTypes from 'prop-types'

import { ControlLabel } from 'react-bootstrap'

import { humanizeAttribute } from '../../utilities/i18n'

const FieldLabel = ({ model, name, label }) => {
  const labelNameKey = name && name.slice(-2) === 'Id' ? name.slice(0, -2) : name
  const labelTekst = label || ((model && name) ? humanizeAttribute(model, labelNameKey) : null)

  return (labelTekst && <ControlLabel>{labelTekst}</ControlLabel>)
}

FieldLabel.defaultProps = {
  model: null,
  name: null,
  label: null
}

FieldLabel.propTypes = {
  model: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string
}

export default FieldLabel
