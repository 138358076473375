import React from 'react'
import { bool } from 'prop-types'
import FontAwesome from 'react-fontawesome'

const BooleanIcon = ({ value }) => {
  if (value) {
    return (
      <FontAwesome name="check" className="text-navy" />
    )
  }

  return (
    <FontAwesome name="times" className="text-danger" />
  )
}

BooleanIcon.propTypes = {
  value: bool.isRequired
}

export default BooleanIcon
