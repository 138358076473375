/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fetch from 'isomorphic-fetch'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PubSub from 'pubsub-js'

import csrfToken from '../utilities/csrfToken'


const i18noptions = {
  scope: 'components.roskilde_team_import'
}

class RoskildeSyncButton extends Component {
  state = {
    syncStarted: false
  }

  queueTeamImportJob = () => {
    const { urlPath, teamName } = this.props

    fetch(
      urlPath, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken()
      }
    }
    ).then((response) => {
      if (response.ok) {
        this.setState({
          syncStarted: true
        })
        PubSub.publish(
          'NOTIFICATION',
          { type: 'notice', message: I18n.t('queued_job_message', { name: teamName, ...i18noptions }) }
        )
      } else {
        PubSub.publish(
          'NOTIFICATION',
          { type: 'alert', message: I18n.t('job_failed_message', i18noptions) }
        )
      }
    })
  }

  buttonDescriptionTooltip = () => {
    const { lastImportAt, lastImportStatus } = this.props

    if (lastImportAt || lastImportStatus) {
      return (
        <Tooltip id="tooltip">
          <strong>Last import:</strong> {lastImportAt}<br />
          <strong>Status:</strong> {lastImportStatus}
        </Tooltip>
      )
    }
  }

  button = () => {
    const { syncStarted } = this.state
    const { inProgress } = this.props
    const label = inProgress ? I18n.t('sync_in_progress', i18noptions) : I18n.t('button_text', i18noptions)

    return (
      <Button
        bsStyle="white"
        disabled={syncStarted || inProgress}
        onClick={this.queueTeamImportJob}
      >
        {label}
      </Button>
    )
  }

  render() {
    if (this.buttonDescriptionTooltip()) {
      return (
        <OverlayTrigger placement="bottom" overlay={this.buttonDescriptionTooltip()}>
          {this.button()}
        </OverlayTrigger>
      )
    }

    return this.button()
  }
}

RoskildeSyncButton.defaultProps = {
  lastImportAt: 'Not run',
  lastImportStatus: 'Success'
}

RoskildeSyncButton.propTypes = {
  teamName: PropTypes.string.isRequired,
  inProgress: PropTypes.bool.isRequired,
  lastImportAt: PropTypes.string,
  lastImportStatus: PropTypes.string,
  urlPath: PropTypes.string.isRequired
}

export default RoskildeSyncButton
