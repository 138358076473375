import React from 'react'
import { number, string, oneOfType } from 'prop-types'

const LOCAL_STORAGE_KEY = 'persistableDataTableState'

const persistable = (WrappedDataTable) => {
  const wrapper = class extends React.Component {
    constructor(props) {
      super(props)

      this.state = this.getLocalStoredState() || {}
    }

    getGeneralLocalStoredState = () => {
      const localStoredState = localStorage.getItem(LOCAL_STORAGE_KEY)

      if (localStoredState) {
        try {
          return JSON.parse(localStoredState)
        } catch (error) {
          localStorage.removeItem(LOCAL_STORAGE_KEY)
          return {}
        }
      }

      return {}
    }

    setLocalStoredState = () => {
      const { stateKey } = this.props
      const generalLocalStoredState = this.getGeneralLocalStoredState()
      generalLocalStoredState[stateKey] = this.state

      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(generalLocalStoredState)
      )
    }

    getLocalStoredState = () => {
      const { stateKey } = this.props
      const generalLocalStoredState = this.getGeneralLocalStoredState()
      return generalLocalStoredState[stateKey]
    }

    handleChange = (changes) => {
      this.setState(changes, this.setLocalStoredState)
    }

    render() {
      const { ...tableProps } = this.props
      const onChangeProps = {
        onPageChange: pageIndex => this.handleChange({ page: pageIndex }),
        onPageSizeChange: (pageSize, pageIndex) => this.handleChange({ page: pageIndex, pageSize }),
        onSortedChange: sorted => this.handleChange({ page: 0, sorted }),
        onFilteredChange: filtered => this.handleChange({ page: 0, filtered }),
        onResizedChange: resized => this.handleChange({ resized }),
        onExpandedChange: (expanded, index) => this.handleChange({ expanded, index })
      }

      return (
        <WrappedDataTable
          {...onChangeProps}
          {...this.state}
          {...tableProps}
        />
      )
    }
  }

  wrapper.propTypes = {
    stateKey: oneOfType([string, number]).isRequired
  }

  return wrapper
}

export default persistable
