import React from 'react'
import { string, bool, number, objectOf, oneOfType } from 'prop-types'

import Effect, { defaultProps } from './Effect'

class FlashEffect extends React.Component {
  state = {
    animateIn: false,
    animateOut: false
  }

  render() {
    const { active, duration, transitionAttribute, flash, ...otherProps } = this.props
    const { animateIn, animateOut } = this.state

    const animate = animateIn || animateOut
    const halfDuration = duration / 2

    return (
      <Effect
        active={active}
        duration={halfDuration}
        exit={false}
        onEnter={() => this.setState({ animateIn: true })}
        onEntered={() => this.setState({ animateIn: false, animateOut: true })}
      >
        <Effect
          active={animateIn}
          duration={halfDuration}
          onExited={() => this.setState({ animateOut: false })}
          transitionAttribute={(animate ? transitionAttribute : null)}
          transitionStyles={() => ({
            entering: flash,
            entered: flash
          })}
          debug
          {...otherProps}
        />
      </Effect>
    )
  }
}

FlashEffect.propTypes = {
  active: bool.isRequired,
  duration: number,
  transitionAttribute: string,
  flash: objectOf(oneOfType([string, number]))
}

FlashEffect.defaultProps = {
  duration: defaultProps.duration,
  transitionAttribute: defaultProps.transitionAttribute,
  flash: null
}

export default FlashEffect
