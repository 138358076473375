import { func, string, bool, node, arrayOf, oneOfType } from 'prop-types'
import React from 'react'
import { DropTarget } from 'react-dnd'
import classNames from 'classnames'
import { differenceInMinutes, getHours, getMinutes, set, addMinutes } from 'date-fns'

import BigCalendar from 'react-big-calendar'

const DropableBackgroundWrapper = ({
  connectDropTarget,
  children,
  type,
  isOver,
  baseWrapperComponent
}) => {
  const BackgroundWrapper = baseWrapperComponent || BigCalendar.components[type]
  const resultingChildren = React.cloneElement(children, {
    className: classNames(children.props.className, {
      'rbc-addons-dnd-over': isOver
    })
  })

  return (
    <BackgroundWrapper>
      {connectDropTarget(resultingChildren)}
    </BackgroundWrapper>
  )
}

DropableBackgroundWrapper.propTypes = {
  connectDropTarget: func.isRequired,
  type: string.isRequired,
  isOver: bool.isRequired,
  children: oneOfType([node, arrayOf(node)]),
  baseWrapperComponent: func
}

DropableBackgroundWrapper.defaultProps = {
  children: null,
  baseWrapperComponent: null
}

const createWrapper = (itemTypes, type, onEventDrop, baseWrapperComponent) => {
  const collectTarget = (connect, monitor) => ({
    baseWrapperComponent,
    type,
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  })

  const dropTarget = {
    drop: (props, monitor) => {
      const { value } = props
      const itemType = monitor.getItemType()
      const { event } = monitor.getItem()

      if (event) {
        const { startTime, endTime } = event

        const durationInMinutes = differenceInMinutes(endTime, startTime)
        const newStartTime = type === 'dateCellWrapper'
          ? set(value, { hours: getHours(startTime), minutes: getMinutes(startTime) })
          : value
        const newEndTime = addMinutes(newStartTime, durationInMinutes)

        onEventDrop({
          itemType,
          event,
          startTime: newStartTime,
          endTime: newEndTime
        })
      } else {
        onEventDrop({
          itemType,
          startTime: value,
          endTime: value
        })
      }
    }
  }

  return DropTarget(itemTypes, dropTarget, collectTarget)(DropableBackgroundWrapper)
}

export default createWrapper
