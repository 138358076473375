import {
  COPY_SHIFT,
  CLOSE_SHIFT_MODAL
} from '../../../constants/action-types'

import {
  RECEIVE_OBJECT
} from '../../../../../constants/action-types'

const isCopyingReducer = (state = false, action) => {
  switch (action.type) {
    case COPY_SHIFT:
      return true
    case CLOSE_SHIFT_MODAL:
      return false
    case RECEIVE_OBJECT:
      if (action.payload.type === 'shifts') {
        return false
      }
      return state
    default:
      return state
  }
}

export default isCopyingReducer
