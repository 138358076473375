/* global I18n, Routes */

import React from 'react'

import FontAwesome from 'react-fontawesome'

import memberPropTypes from '../../propTypes/memberPropTypes'

import Status from './Status'
import PaidStatus from './PaidStatus'
import MemberTypePropertyValue from '../MemberTypePropertyValue'

const i18noptions = {
  scope: 'components.member_info_button'
}

const PopoverContent = ({
  member: {
    id,
    email,
    sex,
    birthdate,
    phone,
    passive,
    shiftCount,
    shiftHours,
    paidStatus
  }
}) => (
  <div>
    <Status status={status} />
    <PaidStatus paidStatus={paidStatus} />

    {passive && (
      <p>
        <FontAwesome name="user-secret" />
        {I18n.t('activerecord.attributes.user.passive')}
      </p>
    )}

    {(sex || birthdate) && (
      <p>
        {sex && (
          <MemberTypePropertyValue.Sex value={sex} />
        )}
        {birthdate && !sex && (
          <FontAwesome name="person" />
        )}
        {' '}
        {birthdate && (
          <MemberTypePropertyValue.Birthdate value={birthdate} />
        )}
      </p>
    )}

    <p>
      <FontAwesome name="envelope-o" />
      {' '}<a href={`mailto:${email}`}>{email}</a>
    </p>

    {phone && (
      <p>
        <FontAwesome name="phone" />
        {' '}<a href={`tel:${phone.replace(/\s+/, '')}`}>{phone}</a>
      </p>
    )}

    <p>
      <FontAwesome name="calendar" />
      {(
        ` ${I18n.t('shifts_count', { ...i18noptions, count: shiftCount })
        } (${I18n.t('shifts_hours', { ...i18noptions, count: shiftHours })
        })`
      )}
    </p>

    <div>
      <a
        href={Routes.new_message_path({ recipients: `member_${id}` })}
        className="btn btn-primary btn-block"
      >
        {I18n.t('send_message', i18noptions)}
      </a>
    </div>
  </div>
)

PopoverContent.propTypes = {
  member: memberPropTypes.isRequired
}

export default PopoverContent
