import * as types from '../constants/action-types'
import ShiftGradeApi from '../apis/ShiftGradeApi'
import scheduleSelector from '../selectors/schedules/scheduleSelector'
import gradeActions from '../../../actions/gradeActions'
import { updateShift } from './shift-actions'

const {
  requestGrades,
  receiveGrades,
  requestGradeCreation,
  receiveGradeCreation,
  requestGradeUpdate,
  receiveGradeUpdate,
  requestGradeDeletion,
  receiveGradeDeletion
} = gradeActions

export const receiveShiftGradeError = errors => ({
  type: types.RECEIVE_SHIFT_GRADE_ERROR,
  payload: errors
})

export const openShiftGradeModal = shiftGrade => ({
  type: types.OPEN_SHIFT_GRADE_MODAL,
  payload: shiftGrade
})
export const closeShiftGradeModal = () => ({
  type: types.CLOSE_SHIFT_GRADE_MODAL
})
export const changeCurrentShiftGrade = changes => ({
  type: types.CHANGE_CURRENT_SHIFT_GRADE,
  payload: changes
})

export const fetchShiftGrades = () => (dispatch, getState) => {
  dispatch(requestGrades())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new ShiftGradeApi(scheduleId)

  return api.fetchAll()
    .then(response => dispatch(receiveGrades(response.data)))
}

export const createShiftGrade = shiftGrade => (dispatch, getState) => {
  dispatch(requestGradeCreation())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new ShiftGradeApi(scheduleId)

  return api.create({ body: { shiftGrade } })
    .then(response => dispatch(receiveGradeCreation(response.data)))
    .catch(error => dispatch(receiveShiftGradeError({ ...error.response.data })))
}

export const updateShiftGrade = (id, shiftGrade) => (dispatch) => {
  dispatch(requestGradeUpdate(id))

  const api = new ShiftGradeApi()

  return api.update(id, { body: { shiftGrade } })
    .then(response => dispatch(receiveGradeUpdate(response.data)))
    .catch(error => dispatch(receiveShiftGradeError({ id, ...error.response.data })))
}

export const createOrUpdateShiftGrade = shiftGrade => (dispatch) => {
  const { id } = shiftGrade
  if (id) {
    dispatch(updateShiftGrade(id, shiftGrade))
  } else {
    dispatch(createShiftGrade(shiftGrade))
  }
}

export const deleteShiftGrade = id => (dispatch) => {
  dispatch(requestGradeDeletion())

  const api = new ShiftGradeApi()

  return api.destroy(id)
    .then(() => dispatch(receiveGradeDeletion(id)))
}

export const removeShift = shiftId => dispatch => (
  dispatch(updateShift(shiftId, { gradeId: null }))
)
