import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

const TooltipIcon = ({ icon, tooltip, ...iconProps }) => {
  const tooltipElement = (<Tooltip>{tooltip}</Tooltip>)

  return (
    <OverlayTrigger placement="top" overlay={tooltipElement} trigger={['hover', 'focus', 'tap']}>
      <FontAwesome name={icon} {...iconProps} />
    </OverlayTrigger>
  )
}

TooltipIcon.propTypes = {
  icon: PropTypes.string,
  tooltip: PropTypes.node.isRequired
}

TooltipIcon.defaultProps = {
  icon: 'question-circle'
}

export default TooltipIcon
