/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { isBefore, isAfter } from 'date-fns'

import BaseShiftModalForm from './BaseShiftModalForm'
import DateTimeInput from '../../../../../components/DateTimeInput'
import ButtonIcon from '../../../../../components/ButtonIcon'

import { shiftPropTypes } from '../../../../propTypes/shiftPropTypes'

import parseTimeFromValue from './parseTimeFromValue'
import parseDateFromValue from './parseDateFromValue'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form'
}
class EditShiftModalForm extends React.Component {
  handleDateChange = (event) => {
    const { onChange, shift } = this.props
    const { value, name: fullName } = event.target
    const name = fullName.replace('[date]', '')
    const oldTime = shift[name]
    const newTime = parseDateFromValue(oldTime, value)

    onChange({
      [name]: newTime
    })
  }

  handleTimeChange = (event) => {
    const { onChange, shift } = this.props
    const { value, name: fullName } = event.target
    const name = fullName.replace('[time]', '')
    const oldTime = shift[name]
    const newTime = parseTimeFromValue(oldTime, value)

    if ((name === 'startTime' && isBefore(shift.endTime, newTime))
      || (name === 'endTime' && isAfter(shift.startTime, newTime))
    ) {
      onChange({
        startTime: newTime,
        endTime: newTime
      })
    } else {
      onChange({
        [name]: newTime
      })
    }
  }

  handleDelete = () => {
    const { onDelete, shift } = this.props

    if (confirm(I18n.t('confirm_delete', i18noptions))) {
      onDelete(shift.id)
    }
  }

  handleCopy = () => {
    const { onCopy, shift } = this.props

    onCopy(shift)
  }

  handleSave = (shift) => {
    const { onUpdate } = this.props

    onUpdate(shift.id, shift)
  }

  renderDateTimeInputs = (name) => {
    const { onChange, shift, validationErrors } = this.props
    const { id, [name]: value } = shift

    return (
      <DateTimeInput
        id={`shift-${id}-${name}`}
        model="shift"
        name={name}
        value={value}
        onDateChange={event => this.handleDateChange(event)}
        onTimeChange={event => this.handleTimeChange(event)}
        errors={validationErrors !== null ? validationErrors[name] : null}
        withIcon
      />
    )
  }

  render() {
    const { onDelete, onCopy, onUpdate, ...otherProps } = this.props
    const modelName = I18n.t('activerecord.models.shift.one')

    return (
      <BaseShiftModalForm
        {...otherProps}
        modelName={modelName}
        onSave={this.handleSave}
        newRecord={false}
        headerButtons={[
          <ButtonIcon
            key="clone"
            bsStyle="success"
            onClick={this.handleCopy}
            icon="clone"
            tooltip={I18n.t('copy_shift', i18noptions)}
          />,
          <ButtonIcon
            key="delete"
            bsStyle="danger"
            onClick={this.handleDelete}
            icon="trash"
            tooltip={I18n.t('helpers.actions.destroy', { model: modelName })}
          />
        ]}
      >
        <fieldset>
          {this.renderDateTimeInputs('startTime')}
          {this.renderDateTimeInputs('endTime')}
        </fieldset>
      </BaseShiftModalForm>
    )
  }
}

EditShiftModalForm.propTypes = {
  shift: shiftPropTypes.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}

EditShiftModalForm.defaultProps = {
  validationErrors: null
}

export default EditShiftModalForm
