import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

const PopoverIcon = ({ icon, content, ...iconProps }) => {
  const tooltipElement = (<Popover>{content}</Popover>)

  return (
    <OverlayTrigger placement="top" overlay={tooltipElement}>
      <FontAwesome name={icon} {...iconProps} />
    </OverlayTrigger>
  )
}

PopoverIcon.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.node.isRequired
}

PopoverIcon.defaultProps = {
  icon: 'info-circle'
}

export default PopoverIcon
