import { shape, string, number } from 'prop-types'

import memberPropTypes from './memberPropTypes'

const preferencePropTypes = shape({
  id: string.isRequired,
  memberId: number.isRequired,
  member: memberPropTypes
})

export default preferencePropTypes
