/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'


const i18noptions = {
  scope: 'shift_selector.requirement_selector'
}

const RequirementSelector = ({
  selectionType,
  shiftOrBundleId,
  requirements,
  noRequirementOption,
  onRequirementSelect,
  onCancel
}) => {
  const fields = requirements.map(requirement => (
    <li
      className="list-group-item"
      key={requirement.id}
    >
      <div
        role="presentation"
        onClick={() => onRequirementSelect(selectionType, shiftOrBundleId, requirement.id)}
      >
        {I18n.t(`${requirement.propertyType}.option`, { ...i18noptions, defaultValue: '{{name}} {{value}}', name: requirement.name, value: requirement.requiredPrettyValue })}
      </div>
    </li>
  ))

  return ((noRequirementOption || requirements.length > 0) ? (
    <ul className="list-group list-group-hover">
      {noRequirementOption ?
        (
          <li className="list-group-item" key="no-requirement">
            <div
              role="presentation"
              onClick={() => onRequirementSelect(selectionType, shiftOrBundleId)}
            >
              {I18n.t('no_requirement', i18noptions)}
            </div>
          </li>
        ) : null}

      {fields}
    </ul>
  ) : (
    <div>{I18n.t('no_available_requirements', i18noptions)}</div>
  ))
}

RequirementSelector.defaultProps = {
  noRequirementOption: false,
  requirements: []
}

RequirementSelector.propTypes = {
  type: PropTypes.string.isRequired,
  shiftOrBundleId: PropTypes.number.isRequired,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      requiredPrettyValue: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  noRequirementOption: PropTypes.bool,
  onRequirementSelect: PropTypes.func.isRequired
}

export default RequirementSelector
