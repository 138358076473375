/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { intersection } from 'lodash'

import LoadingIndicator from '../../../../../../components/LoadingIndicator'

import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.shift'
}

const BaseShiftEvent = ({
  event: shift,
  isLoading,
  membersIsLoading,
  isDraggingMember,
  selectedMemberIds,
  component: Component,
  ...eventProps
}) => {
  if (isLoading) {
    return (<LoadingIndicator />)
  }

  const { attendanceCount, attendanceLimit, attendances } = shift
  const availableSpots = attendanceLimit - attendanceCount

  let attendanceMessage = `${attendanceCount}/${attendanceLimit}`

  if (isDraggingMember) {
    const attendingMemberIds = attendances.map(attendance => attendance.member.id)
    const selectedAttendingMembers = intersection(selectedMemberIds, attendingMemberIds)

    if (selectedAttendingMembers.length > 0) {
      const conflictingMemberNames = attendances
        .filter(attendance => selectedAttendingMembers.includes(attendance.memberId))
        .map(attendance => attendance.member.name)

      attendanceMessage = I18n.t('already_attending', {
        scope: i18noptions.scope,
        members: conflictingMemberNames.join(', ')
      })
    } else if (availableSpots <= 0) {
      attendanceMessage = I18n.t('no_spots_left', i18noptions)
    }
  } else if (membersIsLoading) {
    attendanceMessage = (<span><LoadingIndicator nested />/{attendanceLimit}</span>)
  }

  return (
    <Component
      {...eventProps}
      attendanceMessage={attendanceMessage}
      event={shift}
    />
  )
}

BaseShiftEvent.defaultProps = {
}

BaseShiftEvent.propTypes = {
  event: shiftPropTypes.isRequired,
  isLoading: PropTypes.bool.isRequired,
  membersIsLoading: PropTypes.bool.isRequired,
  isDraggingMember: PropTypes.bool.isRequired,
  selectedMemberIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  component: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.event
  const { loadingStatus, currentApp } = state
  const { ui } = currentApp
  const { membersView } = ui
  const { isDragging: isDraggingMember, selection } = membersView
  const { ids: selectedMemberIds } = selection
  const { shifts: loadingShifts, membersList: membersIsLoading } = loadingStatus
  const isLoading = loadingShifts.includes(id)

  return {
    isLoading,
    membersIsLoading,
    isDraggingMember,
    selectedMemberIds
  }
}

export default connect(mapStateToProps)(BaseShiftEvent)
