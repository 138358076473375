import PropTypes from 'prop-types'

const { string, shape, oneOfType } = PropTypes

export const persistedShiftGradePropTypes = shape({
  id: string.isRequired,
  name: string.isRequired,
  color: string.isRequired
})

export const newShiftGradePropTypes = shape({
  name: string,
  color: string
})

const shiftGradePropTypes = oneOfType([
  persistedShiftGradePropTypes,
  newShiftGradePropTypes
])

export default shiftGradePropTypes
