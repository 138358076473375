/* global Reflux */

import Actions from './Actions';
import TicketStore from './TicketStore';
export default Reflux.createStore({
  listenables: [Actions],

  onActivateChip: function (nfc) {
    console.log("onActivateChip");
    var url = this.activateChipUrl.replace(/:id/, TicketStore.ticket.id);

    $.ajax({
      url: url,
      method: 'POST',
      data: {
        nfc: nfc
      },
      success: function () {
        Actions.dismissEventPosModal();
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(url, status, err.toString());
      }.bind(this)
    });
  },
});
