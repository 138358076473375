import React from 'react'
import { number } from 'prop-types'

import Effect from './Effect'

const Slide = ({ height, ...props }) => {
  const defaultStyle = { top: -height, zIndex: -1 }
  return (
    <Effect
      transitionStyles={() => ({
        entering: defaultStyle,
        entered: { top: 0 },
        exiting: defaultStyle,
        exited: defaultStyle
      })}
      transitionAttribute="top"
      {...props}
    />
  )
}

Slide.propTypes = {
  height: number.isRequired
}

export default Slide
