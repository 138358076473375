import { createSelector } from 'redux-orm'
import { parseISO } from 'date-fns'

import { orm } from '../../../../store'

const ormStateSelector = state => state.orm

const scheduleSelector = createSelector(
  orm,
  ormStateSelector,
  (session) => {
    const schedule = session.Schedule.all().toRefArray()[0]

    return {
      ...schedule,
      startingAt: parseISO(schedule.startingAt)
    }
  }
)

export default scheduleSelector
