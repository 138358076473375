/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'

import ModalButton from '../components/ModalButton'

import '../stylesheets/delete-link-prompt-widget.scss'

const i18noptions = {
  scope: 'helpers.prompt.delete'
}

class DeleteLinkPromptWidget extends Component {
  state = {
    disabled: true,
    value: ''
  }

  handleChange = (e) => {
    const { verify } = this.props

    this.setState({
      value: e.target.value,
      disabled: e.target.value !== (verify || I18n.t('verify', i18noptions))
    })
  }

  disableLink = () => {
    this.setState({ disabled: true })
  }

  renderFormGroup = () => {
    const { value, disabled } = this.state
    const { linkText, path, modal, message } = this.props

    return (
      <div>
        <FormGroup>
          <ControlLabel>
            {message || I18n.t('message', i18noptions)}
          </ControlLabel>
          <FormControl
            type="text"
            value={value}
            onChange={this.handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Button
            bsStyle="danger"
            disabled={disabled}
            href={path}
            data-method="delete"
            onClick={this.disableLink}
          >
            {modal ? I18n.t('shared.delete') : linkText}
          </Button>
        </FormGroup>
      </div>
    )
  }

  renderModal = () => {
    const { linkText, bsStyle, menuItem, ...buttonProps } = this.props
    let className

    if (menuItem) {
      className = 'delete-link-menu-item'
    } else if (bsStyle === 'link') {
      className = 'delete-link-modal-button'
    }

    return (
      <ModalButton
        buttonText={linkText}
        bsStyle={bsStyle}
        {...buttonProps}
        className={className}
        href="#"
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('helpers.confirm_delete')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.renderFormGroup()}
        </Modal.Body>
      </ModalButton>
    )
  }

  render() {
    const { modal } = this.props

    if (modal) {
      return this.renderModal()
    }

    return this.renderFormGroup()
  }
}

DeleteLinkPromptWidget.defaultProps = {
  modal: true,
  message: null,
  verify: null,
  bsStyle: 'link',
  menuItem: false
}

DeleteLinkPromptWidget.propTypes = {
  modal: PropTypes.bool,
  message: PropTypes.string,
  verify: PropTypes.string,
  bsStyle: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  menuItem: PropTypes.bool
}

export default DeleteLinkPromptWidget
