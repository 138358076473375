/* global I18n */

import React from 'react'
import { format } from 'date-fns'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import ShiftRequirementDetails from './components/ShiftRequirementDetails'
import TooltipIcon from '../../../../../../components/TooltipIcon'

const i18noptions = {
  scope: 'shift_selector.shift.mtp_requirements'
}

const ShiftDetailsMedium = ({ shift }) => {
  const {
    name,
    description,
    startTime,
    endTime,
    schedule,
    availableSpots,
    duration,
    requirements,
    grade,
    meetingPoint
  } = shift

  const shiftTime = `${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`
  const shiftInfo = name ? `${schedule.name} - ${name}` : schedule.name
  const shiftLocation = meetingPoint ? meetingPoint : schedule.location

  const availableSpotsContent = (
    <strong>{I18n.t('available_spots', i18noptions)}: {availableSpots}</strong>
  )

  const contentWithOverlay = requirements.length > 0 ? (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="left"
      overlay={(
        <Popover
          id="popover-positioned-left"
          bsClass="shift-selector-popover popover"
          title={I18n.t('requirements_header', i18noptions)}
        >
          <ShiftRequirementDetails requirements={requirements} />
        </Popover>
      )}
    >
      <span>
        {availableSpotsContent}
        &nbsp;
        <i className="fa fa-info-circle" />
      </span>
    </OverlayTrigger>
  ) : (
    <span>{availableSpotsContent}</span>
  )

  const gradeInfo = () => (
    <p>
      <span>
        <strong>Type:</strong>
        &nbsp;
        {grade && grade.name}
      </span>
    </p>
  )

  return (
    <div className="shift-details shift-details-medium">
      <div className="shift-time">
        <p><strong>{shiftTime}</strong></p>
        <p>{I18n.t('shift_duration', { scope: 'shift_selector', count: duration })}</p>
      </div>
      <div className="shift-info">
        <p>
          {shiftInfo}
        </p>
        <p>
          {description}
        </p>
        <p><i>{I18n.t('schedule.shifts.form.meeting_point')} {shiftLocation}</i></p>
      </div>
      <div className="shift-availability">
        <p>
          {contentWithOverlay}
        </p>
        {grade && gradeInfo()}
      </div>
    </div>
  )
}

export default ShiftDetailsMedium
