import {
  OPEN_SHIFT_MODAL,
  CLOSE_SHIFT_MODAL,
  CHANGE_CURRENT_SHIFT
} from '../../../constants/action-types'

const currentShiftReducer = (state = null, action) => {
  switch (action.type) {
    case OPEN_SHIFT_MODAL: {
      return action.payload.attributes
    }
    case CHANGE_CURRENT_SHIFT:
      return {
        ...state,
        ...action.payload
      }
    case CLOSE_SHIFT_MODAL:
      return null
    default:
      return state
  }
}

export default currentShiftReducer
