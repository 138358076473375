import { createSelector } from 'redux-orm'

import { orm } from '../../../../store'

const ormStateSelector = state => state.orm

const gradesSelector = createSelector(
  orm,
  ormStateSelector,
  session => session.Grade.all().toRefArray()
)

export default gradesSelector
