/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

const i18noptions = {
  scope: 'components.shift_report_form'
}

class FilterSelect extends Component {
  state = {
    selectedId: this.props.attributes.id,
    selectedType: this.props.attributes.type,
    filterValue: this.props.attributes.value
  }

  getDefaultFilterValueForMemberTypeProperty = (memberTypeProperty) => {
    switch (memberTypeProperty.type) {
      case 'MemberTypeProperties::CustomCheckbox':
        return 't'
      case 'MemberTypeProperties::CustomSelect':
        return Object.keys(memberTypeProperty.data.options)[0]
      case 'MemberTypeProperties::CustomIntegerRange':
        return memberTypeProperty.data.min
      default:
        return null
    }
  }

  getSelectedMemberTypeProperty = (selectedId) => {
    const { memberTypeProperties } = this.props

    return memberTypeProperties.find(memberTypeProperty => (
      memberTypeProperty.id === selectedId
    ))
  }

  getDefaultFilterValueForTeamGroup = () => {
    const { teamGroups } = this.props
    return teamGroups[0].id
  }

  propegateOnChange = (attributes) => {
    this.setState(attributes)

    attributes = { ...this.state, ...attributes }

    this.props.onChange(this.props.attributes.key, attributes)
  }

  parseValue = (value) => {
    const [type, id] = value.split(':')
    return { type, id: parseInt(id) }
  }

  buildValue = (type, id) => {
    if (type === 'team_group') {
      return `${type}:`
    }
    return `${type}:${id}`
  }

  handleSelect = (event) => {
    const { type, id } = this.parseValue(event.target.value)

    if (type) {
      let filterValue
      if (type === 'member_type_property') {
        const selectedMemberTypeProperty = this.getSelectedMemberTypeProperty(id)
        filterValue = this.getDefaultFilterValueForMemberTypeProperty(selectedMemberTypeProperty)
      }
      if (type === 'team_group') {
        filterValue = this.getDefaultFilterValueForTeamGroup()
      }

      this.propegateOnChange({
        selectedId: id,
        selectedType: type,
        filterValue
      })

    } else {
      this.propegateOnChange({
        selectedId: null,
        selectedType: null,
        filterValue: null
      })
    }
  }

  handleChangeFilterValue = (event) => {
    this.propegateOnChange({
      filterValue: event.target.value || null
    })
  }

  handleOnRemove = (e) => {
    e.preventDefault()
    this.props.onRemove(this.props.attributes.key)
  }

  renderFilterableMemberTypePropertyOptions = () => {
    const { memberTypeProperties } = this.props
    return (
      memberTypeProperties.map(memberTypeProperty => (
        <option
          key={memberTypeProperty.id}
          value={this.buildValue('member_type_property', memberTypeProperty.id)}
        >
          {memberTypeProperty.name}
        </option>
      ))
    )
  }

  renderFilter = () => {
    if (!this.state.selectedType) {
      return null
    }

    if (this.state.selectedType === 'team_group') {
      return (
        <select
          name={`report[team_groups][]`}
          value={this.state.filterValue}
          className="form-control input-sm"
          style={{ width: 'auto', display: 'inline-block' }}
          onChange={this.handleChangeFilterValue}
        >
          {
            this.props.teamGroups.map(teamGroup => (
              <option key={teamGroup.id} value={teamGroup.id}>{teamGroup.name}</option>
            ))
          }
        </select>
      )
    }

    if (!this.state.selectedId) {
      return null
    }

    // Else return mtp filter select
    const selectedMemberTypeProperty = this.getSelectedMemberTypeProperty(this.state.selectedId)
    let type
    if (selectedMemberTypeProperty != null) {
      type = selectedMemberTypeProperty.type
    }

    switch (type) {
      case 'MemberTypeProperties::CustomCheckbox':
        return (
          <select
            name={`report[member_type_properties][${this.state.selectedId}][]`}
            value={this.state.filterValue}
            className="form-control input-sm"
            style={{ width: 'auto', display: 'inline-block' }}
            onChange={this.handleChangeFilterValue}
          >
            <option value="t">{I18n.t('shared.yes')}</option>
            <option value="f">{I18n.t('shared.no')}</option>
          </select>
        )
      case 'MemberTypeProperties::CustomSelect':
        return (
          <select
            name={`report[member_type_properties][${this.state.selectedId}][]`}
            value={this.state.filterValue}
            className="form-control input-sm"
            style={{ width: 'auto', display: 'inline-block' }}
            onChange={this.handleChangeFilterValue}
          >
            {
              Object.entries(selectedMemberTypeProperty.data.options).map((option) => {
                const index = option[0]
                const value = option[1]
                return <option key={index} value={index}>{value[I18n.locale]}</option>
              })
            }
          </select>
        )
      case 'MemberTypeProperties::CustomIntegerRange':
        return (
          <input
            type="number"
            name={`report[member_type_properties][${this.state.selectedId}]`}
            value={this.state.filterValue}
            className="form-control input-sm"
            style={{ width: 'auto', display: 'inline-block' }}
            onChange={this.handleChangeFilterValue}
            min={selectedMemberTypeProperty.data.min}
            max={selectedMemberTypeProperty.data.max}
          />
        )
      default:
        return (<span />)
    }
  }

  render() {
    return (
      <div>
        <p>
          <button className="btn btn-warning pull-right" type="button" onClick={this.handleOnRemove}>{I18n.t('helpers.remove')}</button>
        </p>
        <p>
          <select
            className="form-control input-sm"
            style={{ width: 'auto', maxWidth: '100%', display: 'inline-block' }}
            value={this.buildValue(this.state.selectedType, this.state.selectedId) || ''}
            onChange={this.handleSelect}
          >
            <option value="">{I18n.t('choose_filter', i18noptions)}</option>
            <optgroup label={I18n.t('other', i18noptions)}>
              <option value="team_group:">{I18n.t('choose_team_group', i18noptions)}</option>
            </optgroup>
            <optgroup label={I18n.t('member_type_properties', i18noptions)}>
              {this.renderFilterableMemberTypePropertyOptions()}
            </optgroup>
          </select>
          {this.renderFilter()}
        </p>
      </div>
    )
  }
}

FilterSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  attributes: PropTypes.shape({
    key: PropTypes.node.isRequired,
    type: PropTypes.string,
    id: PropTypes.number,
    value: PropTypes.node
  }).isRequired,
  teamGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  memberTypeProperties: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      deletedAt: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool
    }).isRequired
  ).isRequired
}

export default FilterSelect
