import { combineReducers } from 'redux'
import isDragging from './isDraggingReducer'
import selection from './selectionReducer'
import filters from './filtersReducer'
import sorting from './sortingReducer'
import includeExternalShifts from './includeExternalShiftsReducer'

const membersViewReducer = combineReducers({
  isDragging,
  selection,
  filters,
  sorting,
  includeExternalShifts
})

export default membersViewReducer
