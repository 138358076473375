import {
  SET_INELIGIBILITY_FILTER,
  SET_TEXT_FILTER,
  SET_SCHEDULE_FILTER,
  SET_GRADE_FILTER
} from '../constants/action-types'

const defaultState = {
  ineligibilityFilter: 'HIDE_INELIGIBLE',
  textFilter: '',
  scheduleFilter: [],
  gradeFilter: []
}

const filtersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_INELIGIBILITY_FILTER:
      return {
        ...state,
        ineligibilityFilter: action.filter
      }
    case SET_TEXT_FILTER:
      return {
        ...state,
        textFilter: action.filter
      }
    case SET_SCHEDULE_FILTER:
      return {
        ...state,
        scheduleFilter: action.filter
      }
    case SET_GRADE_FILTER:
      return {
        ...state,
        gradeFilter: action.filter
      }
    default:
      return state
  }
}

export default filtersReducer
