import React from 'react'
import PropTypes from 'prop-types'

import ShiftCalendar from '../../../../components/ShiftCalendar'
import { ShiftStandardEvent, ShiftMonthEvent } from './ShiftEvent'
import ShiftEventWrapper from './ShiftEventWrapper'
import ClickableBackgroundWrapper from './ClickableBackgroundWrapper'

import { SHIFT_EVENT, MEMBER_CARD } from '../../../constants/item-types'
import { shiftPropTypes } from '../../../../propTypes/shiftPropTypes'

import './styles.scss'

const SchedulePlannerShiftCalendar = ({ startingAt, shifts, onClickShift, onClickSlot, onDropShift, onDropMember }) => {
  const onDropShiftOrMember = ({ itemType, ...args }) => {
    switch (itemType) {
      case MEMBER_CARD:
        onDropMember(args)
        break
      case SHIFT_EVENT:
      default:
        onDropShift(args)
    }
  }

  return (
    <ShiftCalendar
      defaultDate={startingAt}
      shifts={shifts}
      dragAndDropType={[MEMBER_CARD, SHIFT_EVENT]}
      scrollToTime={new Date(0, 0, 0, 6)}
      titleAccessor="name"
      onSelectEvent={shift => onClickShift(shift)}
      onSelectSlot={slotInfo => onClickSlot(slotInfo)}
      onEventDrop={onDropShiftOrMember}
      components={{
        event: ShiftStandardEvent,
        eventWrapper: ShiftEventWrapper,
        dateCellWrapper: ClickableBackgroundWrapper,
        dayWrapper: ClickableBackgroundWrapper,
        month: {
          event: ShiftMonthEvent
        }
      }}
    />
  )
}

SchedulePlannerShiftCalendar.propTypes = {
  shifts: PropTypes.arrayOf(shiftPropTypes),
  onClickShift: PropTypes.func.isRequired,
  onClickSlot: PropTypes.func.isRequired,
  onDropShift: PropTypes.func.isRequired,
  onDropMember: PropTypes.func.isRequired,
  startingAt: PropTypes.instanceOf(Date).isRequired
}

SchedulePlannerShiftCalendar.defaultProps = {
  shifts: []
}

export default SchedulePlannerShiftCalendar
