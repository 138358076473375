/* global I18n */

import React from 'react'
import { bool, func, string, arrayOf } from 'prop-types'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { MEMBER_CARD } from '../../../constants/item-types'
import memberPropTypes from '../../../../propTypes/memberPropTypes'
import { publishNotification } from '../../../../../utilities/notifications'

import Template from './Template'
import MemberInfoButton from '../../../../../components/MemberInfoButton'
import { Highlight } from '../../../../../components/effects'

const i18noptions = {
  scope: 'components.schedule_planner.members'
}

class MemberCard extends React.Component {
  componentDidMount() {
    const { connectDragPreview } = this.props

    connectDragPreview(getEmptyImage())
  }

  handleClick = (event) => {
    const {
      member,
      isSelected,
      onSelect,
      onDeselect,
      onSelectRange,
      onDeselectRange,
      orderedIds,
      selectedIds,
      lastSelectedId
    } = this.props
    const { id } = member
    const { shiftKey, metaKey, ctrlKey } = event
    const multiSelect = metaKey || ctrlKey

    if (shiftKey) {
      const start = lastSelectedId !== null ? orderedIds.indexOf(lastSelectedId) : 0
      const end = orderedIds.indexOf(id)

      let ids
      if (start < end) {
        ids = orderedIds.slice(start, end + 1)
      } else {
        ids = orderedIds.slice(end, start + 1)
      }

      if (ids.every(currentId => selectedIds.indexOf(currentId) >= 0)) {
        onDeselectRange(id, ids)
      } else {
        onSelectRange(id, ids)
      }
    } else if (isSelected) {
      onDeselect(id, multiSelect)
    } else {
      onSelect(id, multiSelect)
    }
  }

  renderCard = () => {
    const { member, isDragging, isLoading, isSelected } = this.props

    return (
      <div>
        <Template
          role="listbox"
          tabIndex={0}
          onClick={this.handleClick}
          member={member}
          dragged={isDragging}
          selected={isSelected}
          loading={isLoading}
        >
          <MemberInfoButton member={member} />
        </Template>
      </div>
    )
  }

  render() {
    const { connectDragSource, isLoading } = this.props

    return (
      <Highlight active={!isLoading}>
        {connectDragSource(this.renderCard(), {
          dropEffect: 'copy',
          effectAllowed: 'any'
        })}
      </Highlight>
    )
  }
}

MemberCard.propTypes = {
  member: memberPropTypes.isRequired,
  isDragging: bool.isRequired,
  isLoading: bool.isRequired,
  isSelected: bool.isRequired,
  connectDragSource: func.isRequired,
  connectDragPreview: func.isRequired,
  onBeginDrag: func.isRequired,
  onEndDrag: func.isRequired,
  onSelect: func.isRequired,
  onSelectRange: func.isRequired,
  onDeselect: func.isRequired,
  onDeselectRange: func.isRequired,
  orderedIds: arrayOf(string).isRequired,
  selectedIds: arrayOf(string).isRequired,
  lastSelectedId: string
}

MemberCard.defaultProps = {
  lastSelectedId: null
}

const memberCardSource = {
  beginDrag: (props) => {
    const { onBeginDrag, member, onSelect, isSelected } = props
    const { id } = member

    if (!isSelected) {
      onSelect(id, false)
    }

    onBeginDrag()

    return {
      memberId: id
    }
  },
  endDrag: ({ onEndDrag }) => onEndDrag()
}

const collect = connect => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview()
})

export const MemberCardTemplate = Template
export default DragSource(MEMBER_CARD, memberCardSource, collect)(MemberCard)
