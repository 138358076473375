import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

import filteredMembersSelector from './filteredMembersSelector'
import { sortingMethodTypes, sortingOrderTypes } from '../../constants/sorting-method-types'

const {
  SORTING_BY_NAME,
  SORTING_BY_SHIFT_COUNT,
  SORTING_BY_SHIFT_HOURS,
  SORTING_BY_MISSING_HOURS
} = sortingMethodTypes
const { ASCENDING_ORDER, DESCENDING_ORDER } = sortingOrderTypes

const sortingSelector = state => state.currentApp.ui.membersView.sorting

const sortedMembersSelector = createSelector(
  filteredMembersSelector, sortingSelector,
  (members, { method, order }) => {
    let sortingFunction
    switch (method) {
      case SORTING_BY_SHIFT_COUNT:
        sortingFunction = ['displayedShiftCount', 'displayedShiftHours', 'name']
        break
      case SORTING_BY_SHIFT_HOURS:
        sortingFunction = ['displayedShiftHours', 'displayedShiftCount', 'name']
        break
      case SORTING_BY_MISSING_HOURS:
        sortingFunction = [(member => member.shiftHours - member.maxShiftHours), 'shiftHours', 'displayedShiftCount', 'name']
        break
      case SORTING_BY_NAME:
      default:
        sortingFunction = ['name', 'displayedShiftHours', 'displayedShiftCount']
    }

    const result = sortBy(members, sortingFunction)

    switch (order) {
      case DESCENDING_ORDER:
        return result.reverse()
      case ASCENDING_ORDER:
      default:
        return result
    }
  }
)

export default sortedMembersSelector
