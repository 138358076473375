import Api, { RestPath } from '../../../Api'
import { getRequest, patchRequest, postRequest } from '../../../Api/request'

class ShiftBundleApi extends Api {
  constructor(scheduleId) {
    const parentPath = new RestPath('schedules')
    const path = new RestPath('shift_bundles', parentPath)
    super(path)

    this.scheduleId = scheduleId
  }

  fetchAll() {
    return getRequest({
      path: this.path.collectionPath(this.scheduleId),
      params: {
        include: 'requirements'
      }
    })
  }

  create({ body }) {
    return postRequest({
      path: this.path.collectionPath(this.scheduleId),
      params: {
        include: 'requirements'
      },
      body
    })
  }

  update(id, { body }) {
    return patchRequest({
      path: this.path.memberPath(id),
      params: {
        include: 'requirements'
      },
      body
    })
  }
}

export default ShiftBundleApi
