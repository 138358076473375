/* global I18n */

import React from 'react'
import { format } from 'date-fns'

const BundleShiftDetails = ({ shift }) => {
  const {
    name,
    startTime,
    endTime,
    duration,
    grade
  } = shift

  const shiftTime = `${format(startTime, 'do MMM')}  ${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`
  const style = grade ? { borderLeftStyle: 'solid', borderLeftWidth: '5px', borderLeftColor: grade.color } : null

  return (
    <div className="bundle-shift-details" style={style}>
      <div className="bundle-shift-time">
        <strong>{shiftTime}</strong>
      </div>
      <div className="bundle-shift-duration">
        {I18n.t('shift_duration', { scope: 'shift_selector', count: duration })}
      </div>
      <div className="bundle-shift-name">
        {name}
      </div>
    </div>
  )
}

export default BundleShiftDetails
