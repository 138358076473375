/* global Routes */

import { snakeCase } from 'lodash'

const polymorphicPath = ({ model, action, id }) => {
  let pathName = ''
  switch (action) {
    case 'new':
    case 'edit':
      pathName = `${action}_`
      break
    case 'delete':
    case 'show':
    default:
      break
  }
  pathName += snakeCase(model)
  pathName += '_path'

  if (id) {
    return Routes[pathName](id)
  }

  return Routes[pathName]()
}

export default polymorphicPath
