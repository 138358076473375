/* global I18n */

import React from 'react'
import { arrayOf, object, func } from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

const i18noptions = {
  scope: 'components.schedule_planner.members.double_booking'
}

const DoubleBookingWarningModal = ({ members, onAccept, onUndo }) => (
  <Modal onHide={onAccept} show={members.length > 0}>
    <Modal.Header closeButton>
      <Modal.Title>{I18n.t('modal_title', i18noptions)}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>{I18n.t('warning', i18noptions)}</p>
      <ul>
        {members.map(member => (
          <li>{member.name}</li>
        ))}
      </ul>
    </Modal.Body>

    <Modal.Footer>
      <Button bsStyle="primary" onClick={onUndo}>{I18n.t('undo', i18noptions)}</Button>
      <Button bsStyle="danger" onClick={onAccept}>{I18n.t('continue', i18noptions)}</Button>
    </Modal.Footer>
  </Modal>
)

DoubleBookingWarningModal.propTypes = {
  members: arrayOf(object).isRequired,
  onAccept: func.isRequired,
  onUndo: func.isRequired
}

export default DoubleBookingWarningModal
