import { number, string, shape } from 'prop-types'

const requirementPropTypes = shape({
  id: string,
  // name: string.isRequired,
  // attendanceCount: number.isRequired,
  propertyId: string.isRequired,
  requiredNumber: number.isRequired,
  // requiredPrettyValue: string,
  requiredValue: string
})

export default requirementPropTypes
