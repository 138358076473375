import {
  forwardManyToOneDescriptor
} from 'redux-orm/lib/descriptors'

function forwardPolymorphicManyToOneDescriptor(fieldName, declaredToModelNames) {
  const functions = declaredToModelNames.reduce((result, declaredToModelName) => {
    result[declaredToModelName] = forwardManyToOneDescriptor(fieldName, declaredToModelName)
    return result
  }, {})

  return {
    get() {
      const declaredToModelName = this._fields[`${fieldName}Type`] // eslint-disable-line no-underscore-dangle
      if (declaredToModelName) {
        const boundGet = functions[declaredToModelName].get.bind(this)

        return boundGet()
      }
      return undefined
    },
    set(value) {
      const declaredToModelName = this._fields[`${fieldName}Type`] // eslint-disable-line no-underscore-dangle
      const boundSet = functions[declaredToModelName].set.bind(this)

      return boundSet(value)
    }
  }
}

export default forwardPolymorphicManyToOneDescriptor
