import { createSelector } from 'redux-orm'

import { orm } from '../../../../store'
import membersSelector from './membersSelector'
import gradesSelector from '../grades/gradesSelector'

const ormStateSelector = state => state.orm
const filtersSelector = state => state.currentApp.ui.membersView.filters

const filteredMembersSelector = createSelector(
  orm,
  ormStateSelector,
  membersSelector,
  gradesSelector,
  filtersSelector,
  (session, members, grades, { text, hideFulfilled, showAcceptedShiftsOnly }) => {
    let filteredMembers = members

    if (text !== null) {
      const regex = new RegExp(text, 'i')

      filteredMembers = filteredMembers.filter(member => (
        regex.test(member.name)
      ))
    }

    if (hideFulfilled) {
      filteredMembers = filteredMembers.filter(({
        id: memberId,
        minShiftHours,
        maxShiftHours,
        shiftHours,
        memberType
      }) => {
        if (minShiftHours && minShiftHours > shiftHours) return true
        if (maxShiftHours && maxShiftHours < shiftHours) return true

        const shifts = session.Attendance
          .filter({ member: memberId })
          .toModelArray()
          .map(attendance => attendance.shift.ref)

        return grades.some(({
          id: gradeId,
          minimumCountPerMemberType,
          maximumCountPerMemberType
        }) => {
          const shiftCount = shifts
            .filter(shift => shift.grade === gradeId)
            .length

          if (
            minimumCountPerMemberType[memberType] &&
              minimumCountPerMemberType[memberType] > shiftCount
          ) return true

          if (
            maximumCountPerMemberType[memberType] &&
              maximumCountPerMemberType[memberType] < shiftCount
          ) return true

          return false
        })
      })
    }

    if (showAcceptedShiftsOnly) {
      filteredMembers = filteredMembers.filter(member => member.shiftsAccepted)
    }

    return filteredMembers
  }
)

export default filteredMembersSelector
