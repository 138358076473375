/* global I18n */

import React from 'react'
import { func } from 'prop-types'

import FontAwesome from 'react-fontawesome'
import { Button } from 'react-bootstrap'

import BaseItem from './BaseItem'

import memberPropTypes from '../../../../../propTypes/memberPropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form.members'
}

class SuggestedItem extends React.Component {
  handleAddAttendance = () => {
    const { onAdd, member } = this.props

    onAdd(member)
  }

  render() {
    const { member } = this.props

    return (
      <BaseItem
        member={member}
        actionButtons={[
          <Button
            key="add"
            bsStyle="primary"
            bsSize="xs"
            onClick={this.handleAddAttendance}
            block
          >
            <FontAwesome name="plus" />
            {' '}{I18n.t('assign', i18noptions)}
          </Button>
        ]}
      />
    )
  }
}

SuggestedItem.propTypes = {
  member: memberPropTypes.isRequired,
  onAdd: func.isRequired
}

export default SuggestedItem
