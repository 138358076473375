import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTagsInput from 'react-tagsinput'

import '../stylesheets/tags-input.scss'

class TagsInput extends Component {
  state = {
    inputValue: ''
  }

  handleChangeInputValue = inputValue => this.setState({
    inputValue: inputValue.trim()
  })

  renderTag = (tagProps) => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = tagProps

    return (
      <div {...other}>
        {getTagDisplayValue(tag)}
        {!disabled &&
          <button
            type="button"
            className={classNameRemove}
            aria-label="Close"
            onClick={() => onRemove(key)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
    )
  }

  renderInput = (inputProps) => {
    const { ...other } = inputProps

    return (
      <input
        type="text"
        {...other}
      />
    )
  }

  renderLayout = (tagComponents, inputComponent) => (
    <div className="tags-input--container">
      {tagComponents}
      {inputComponent}
    </div>
  )

  render() {
    const { inputValue } = this.state
    const { placeholder, ...other } = this.props

    const pasteSplit = data => (
      data.split(/[,\s;]+/).map(d => d.trim())
    )

    return (
      <ReactTagsInput
        {...other}
        renderTag={this.renderTag}
        renderInput={this.renderInput}
        renderLayout={this.renderLayout}
        onChangeInput={this.handleChangeInputValue}
        inputValue={inputValue}
        pasteSplit={pasteSplit}
        addKeys={[9, 13, 32, 188] /* tab, enter, space, comma */}
        className="tags-input form-control"
        focusedClassName="tags-input form-control-focus"
        tagProps={{
          className: 'label label-primary tags-input--tag',
          classNameRemove: 'close'
        }}
        inputProps={{
          className: 'tags-input--input',
          placeholder
        }}
        addOnBlur
        addOnPaste
      />
    )
  }
}

TagsInput.defaultProps = {
  placeholder: null
}

TagsInput.propTypes = {
  placeholder: PropTypes.string
}

export default TagsInput
