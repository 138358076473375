/* global I18n */

import React from 'react'
import { connect } from 'react-redux'
import { checkRequirementsAndSelectBundle } from '../../../../../../../../../actions/bundle-actions'
import ShiftActionButton from '../../../../../../../../ShiftActionButton'

const i18noptions = {
  scope: 'shift_selector.bundle'
}

class SelectBundleButton extends React.Component {
  handleClick = () => {
    const { bundle, selectBundle } = this.props
    const { id, error } = bundle

    if (!error) {
      selectBundle(id)
    }
  }

  render() {
    const { bundle, isLoading } = this.props
    const { error, eligible, ineligibilityReason, selectable } = bundle

    return (
      <ShiftActionButton
        handleClick={this.handleClick}
        buttonText={I18n.t('actions.select_bundle', i18noptions)}
        active={eligible === undefined ? selectable : eligible && selectable}
        inactiveReason={selectable ? ineligibilityReason : I18n.t('shift_selector.bundle.selection_disabled')}
        error={error}
        isLoading={isLoading}
      />
    )
  }
}

const mapStateToProps = (state, props) => ({
  isLoading: state.loadingStatus.bundles.includes(props.bundle.id)
})

const mapDispatchToProps = dispatch => ({
  selectBundle: id => dispatch(checkRequirementsAndSelectBundle(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectBundleButton)
