/* global React */

import TicketItems from './TicketItems';

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    var groupedTicketItems = this.props.groupedTicketItems.map(function (group) {
      return (
        <div className="col-md-6" key={group.type}>
          <h4>{group.type}</h4>
          <TicketItems ticketItems={group.items} />
        </div>
      )
    });

    return (
      <div>
        {groupedTicketItems}
      </div>
    );
  }
})
