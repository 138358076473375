import React from 'react'
import { arrayOf, func } from 'prop-types'
import { cloneDeep } from 'lodash'

import { ListGroup } from 'react-bootstrap'

import AssignedItem from './AssignedItem'

import groupById from '../../../../utilities/groupById'
import attendancePropTypes from '../../../../../propTypes/attendancePropTypes'
import requirementPropTypes from '../../../../../propTypes/requirementPropTypes'

class AssignedList extends React.Component {
  get requirementsById() {
    const { requirements } = this.props

    if (requirements) {
      return groupById(requirements.filter(requirement => requirement.id !== undefined))
    }

    return {}
  }

  handleChangeAttendance = (index, newValues) => {
    const { onChange, attendances } = this.props
    const newAttendances = cloneDeep(attendances)

    newAttendances[index] = {
      ...newAttendances[index],
      ...newValues
    }

    onChange({
      attendances: newAttendances
    })
  }

  handleRemoveAttendance = (index) => {
    const { onChange, attendances } = this.props

    const newAttendances = cloneDeep(attendances)
    const removedAttendance = newAttendances[index]
    removedAttendance._destroy = true // eslint-disable-line no-underscore-dangle

    onChange({
      attendances: newAttendances
    })
  }

  render() {
    const { attendances } = this.props
    const requirementsById = this.requirementsById

    return (
      <ListGroup className="shift-member-list">
        {attendances.map((attendance, index) => {
          const { _destroy, member, requirementId } = attendance

          if (_destroy) {
            return null
          }

          const requirement = requirementsById[requirementId]

          return (
            <AssignedItem
              key={member.id}
              attendance={attendance}
              member={member}
              requirement={requirement}
              onChange={this.handleChangeAttendance}
              onRemove={this.handleRemoveAttendance}
              index={index}
            />
          )
        })}
      </ListGroup>
    )
  }
}

AssignedList.propTypes = {
  attendances: arrayOf(attendancePropTypes),
  requirements: arrayOf(requirementPropTypes),
  onChange: func.isRequired
}

AssignedList.defaultProps = {
  attendances: [],
  requirements: []
}

export default AssignedList
