/* global Reflux */

import Actions from './Actions';

export default Reflux.createStore({
  listenables: [Actions],

  getInitialState: function () {
    this.ticket = null;
    return this.ticket;
  },

  isBarcodeString: function (query) {
    return /\d{8}/.test(query);
  },

  onUpdateQuery: function (query) {
    if (this.isBarcodeString(query)) { this.find(query); }
  },

  onForceSearch: function (query) {
    if (this.isBarcodeString(query)) { this.find(query); }
  },

  onCheckIn: function (opts) {
    var url = this.checkInUrl.replace(/:id/, this.ticket.id);

    $.ajax({
      url: url,
      method: 'POST',
      success: function () {
        if (opts.hasEventPosProducts) {
          Actions.showEventPosModal(opts.eventPosChipActivated);
        } else {
          Actions.reset();
        }
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(url, status, err.toString());
      }.bind(this)
    });
  },

  onRemoveCheckIn: function () {
    var url = this.removeCheckInUrl.replace(/:id/, this.ticket.id);

    var reason = window.prompt('Why are you removing this check-in?', '');

    if (reason !== null || reason !== "") {
      url += "?reason=" + encodeURI(reason);
    }

    $.ajax({
      url: url,
      method: 'POST',
      success: function () {
        Actions.reset();
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(url, status, err.toString());
      }.bind(this)
    });
  },

  onReset: function () {
    this.ticket = null;
    this.trigger(this.ticket);
  },

  find: function (barcode) {
    $.ajax({
      url: this.findUrl,
      dataType: 'json',
      data: {
        barcode: barcode
      },
      cache: false,
      success: function (ticket) {
        this.ticket = ticket;
        this.trigger(ticket);
      }.bind(this),
      error: function (xhr, status, err) {
        if (xhr.status == 404) {
          this.ticket = { notFound: true };
          this.trigger(this.ticket);
        }
      }.bind(this)
    });
  }

});
