import React from 'react'
import { format } from 'date-fns'
import FontAwesome from 'react-fontawesome'

import ShiftCalendarEvent from '../../../../../../../components/ShiftCalendarEvent'

import { shiftPropTypes } from '../../../../../../../propTypes/shiftPropTypes'

const MonthEvent = ({
  event: shift,
  ...eventProps
}) => {
  const {
    startTime,
    endTime,
    bundle,
    attendanceCount,
    attendanceLimit,
    requirements
  } = shift

  return (
    <ShiftCalendarEvent
      event={shift}
      className="shift-month-event-content"
      infoMessage={`${format(startTime, 'HH:mm')}-${format(endTime, 'HH:mm')}`}
      {...eventProps}
      title={null}
    >
      <div className="shift-event-ekstra-info">
        {bundle && (
          <FontAwesome
            name="cube"
            className="shift-event-icon"
            style={{
              color: bundle.color
            }}
          />
        )}
        {requirements.length > 0 && (
          <FontAwesome
            name="lock"
            className="shift-event-icon"
          />
        )}
        <span>{`${attendanceCount}/${attendanceLimit}`}</span>
      </div>
    </ShiftCalendarEvent>
  )
}

MonthEvent.defaultProps = {
}

MonthEvent.propTypes = {
  event: shiftPropTypes.isRequired
}

export default MonthEvent
