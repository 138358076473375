/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'

import { snakeCase } from 'lodash'

const i18noptions = {
  scope: 'components.grouped_shifts_for_registration'
}

const statusLabel = {
  notRegistred: 'plain',
  notShown: 'danger',
  shown: 'primary',
  notCompleted: 'danger',
  completed: 'primary'
}

const AttendanceStatus = ({ name, count }) => {
  if (count === 0) {
    return null
  }

  return (
    <span
      style={{ marginLeft: '3px' }}
      className={`label label-${statusLabel[name]}`}
    >
      {`${I18n.t(snakeCase(name), i18noptions)} ${count}`}
    </span>
  )
}

AttendanceStatus.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
}

export default AttendanceStatus
