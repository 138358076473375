/* global I18n */

import React from 'react'
import { arrayOf, element, node } from 'prop-types'

import { ListGroupItem, Row, Col } from 'react-bootstrap'

import MemberInfoButton from '../../../../../../components/MemberInfoButton'
import DataField from '../../../../../../components/DataField'
import TooltipIcon from '../../../../../../components/TooltipIcon'

import memberPropTypes from '../../../../../propTypes/memberPropTypes'

const memberi18noptions = {
  scope: 'components.schedule_planner.members'
}

const BaseItem = ({ member, children, actionButtons }) => {
  const {
    id,
    name,
    displayedShiftCount,
    displayedShiftDuration,
    doubleBookedShiftIds
  } = member

  return (
    <ListGroupItem key={id}>
      <Row>
        <Col md={9}>
          <Row>
            <Col md={12}>
              <b>{name}</b>
              {doubleBookedShiftIds.length > 0 && (
                <TooltipIcon
                  icon="exclamation-triangle"
                  className="text-danger member-icon"
                  tooltip={I18n.t('double_booked', memberi18noptions)}
                />
              )}
              <MemberInfoButton member={member} />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <DataField
                icon="hashtag"
                tooltip={I18n.t('shift_count', memberi18noptions)}
                value={displayedShiftCount}
              />
            </Col>
            <Col md={4}>
              <DataField
                icon="clock-o"
                tooltip={I18n.t('shifts_duration', memberi18noptions)}
                value={displayedShiftDuration}
              />
            </Col>
          </Row>
          {children}
        </Col>
        <Col md={3}>
          {actionButtons}
        </Col>
      </Row>
    </ListGroupItem>
  )
}

BaseItem.propTypes = {
  member: memberPropTypes.isRequired,
  actionButtons: arrayOf(element).isRequired,
  children: node
}

BaseItem.defaultProps = {
  children: null
}

export default BaseItem
