import React from 'react'
import { element, bool, string } from 'prop-types'
import classNames from 'classnames'

import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'

class BaseShiftWrapper extends React.Component {
  renderChild = () => {
    const { children, filled, className, shift } = this.props
    const { grade } = shift

    const child = React.Children.only(children)
    const childProps = {
      className: classNames(
        className,
        child.props.className,
        'shift-event', {
          filled
        }
      )
    }

    if (grade) {
      childProps.style = {
        ...child.props.style,
        borderLeft: `${grade.color} solid 5px`
      }
    }

    return React.cloneElement(child, childProps)
  }

  render() {
    return (
      <div className="shift-event-wrapper">
        {this.renderChild()}
      </div>
    )
  }
}

BaseShiftWrapper.propTypes = {
  children: element.isRequired,
  className: string,
  filled: bool,
  shift: shiftPropTypes.isRequired
}

BaseShiftWrapper.defaultProps = {
  className: null,
  filled: false
}


export default BaseShiftWrapper
