/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import memberTypePropTypes from '../../propTypes/memberTypePropTypes'
import teamMemberTypePropTypes from '../../propTypes/teamMemberTypePropTypes'

import TeamMemberType from './TeamMemberType'
import FieldCollection from '../../components/FieldCollection'

const i18noptions = {
  scope: 'components.team_member_types_select'
}

class TeamMemberTypesSelect extends Component {
  state = {
    teamMemberTypes: this.props.teamMemberTypes
  }

  onSelectMemberTypeId = (teamMemberTypeIndex, newMemberTypeId) => {
    const { teamMemberTypes } = this.state

    this.setState({
      teamMemberTypes: teamMemberTypes.map((teamMemberType, index) => {
        // eslint-disable-next-line no-underscore-dangle
        if (teamMemberTypeIndex === index) {
          return {
            ...teamMemberType,
            memberTypeId: newMemberTypeId
          }
        }

        return teamMemberType
      })
    })
  }

  onAddTeamMemberType = (newTeamMemberTypes, newTeamMeberType) => {
    const { allMemberTypes } = this.props

    const availableMemberType = allMemberTypes.find(memberType => (
      !this.selectedMemberTypeIds().includes(memberType.id)
    ))

    Object.assign(newTeamMeberType, {
      memberTypeId: availableMemberType.id,
      limit: null
    })

    this.setState({
      teamMemberTypes: newTeamMemberTypes
    })
  }

  onRemoveTeamMemberType = (newTeamMemberTypes) => {
    this.setState({
      teamMemberTypes: newTeamMemberTypes
    })
  }

  selectedMemberTypeIds = () => (
    this.state.teamMemberTypes.reduce((memberTypeIds, teamMemberType) => {
      // eslint-disable-next-line no-underscore-dangle
      if (teamMemberType._destroy) {
        return memberTypeIds
      }

      return memberTypeIds.concat(teamMemberType.memberTypeId)
    }, [])
  )

  updateTeamMemberTypeLimit = (teamMemberTypeIndex, limit) => {
    const { teamMemberTypes } = this.state

    this.setState({
      teamMemberTypes: teamMemberTypes.map((teamMemberType, index) => {
        // eslint-disable-next-line no-underscore-dangle
        if (teamMemberTypeIndex === index) {
          return {
            ...teamMemberType,
            limit
          }
        }

        return teamMemberType
      })
    })
  }

  render() {
    const { teamMemberTypes } = this.state
    const { allMemberTypes, formInputNamePrefix } = this.props

    return (
      <FieldCollection
        addButtonText={I18n.t('add_member_type', i18noptions)}
        addButtonClasses="pull-left"
        fieldData={teamMemberTypes}
        onAddField={this.onAddTeamMemberType}
        onRemoveField={this.onRemoveTeamMemberType}
        maximumCount={allMemberTypes.length}
        formInputNamePrefix={formInputNamePrefix}
        flagForDestruction
      >
        {(teamMemberType, index) => (
          <TeamMemberType
            key={index}
            index={index}
            formInputNamePrefix={formInputNamePrefix}
            teamMemberType={teamMemberType}
            updateTeamMemberTypeLimit={this.updateTeamMemberTypeLimit}
            onSelectMemberTypeId={this.onSelectMemberTypeId}
            memberTypes={allMemberTypes.filter(memberType => (
              !this.selectedMemberTypeIds().includes(memberType.id) ||
              memberType.id === teamMemberType.memberTypeId
            ))}
          />
        )}
      </FieldCollection>
    )
  }
}

TeamMemberTypesSelect.propTypes = {
  formInputNamePrefix: PropTypes.string.isRequired,
  teamMemberTypes: PropTypes.arrayOf(teamMemberTypePropTypes).isRequired,
  allMemberTypes: PropTypes.arrayOf(memberTypePropTypes).isRequired
}

export default TeamMemberTypesSelect
