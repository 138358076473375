import React from 'react'

import GroupsCollection from './GroupsCollection'

const DateGroups = (props) => {
  const scheduleProps = dateString => ({
    showSchedule: true,
    name: dateString,
    fetchShiftsPath: `registration/attendances/dates/${dateString}/shifts`,
    key: dateString,
    ...props
  })

  return (
    <GroupsCollection
      groupProps={scheduleProps}
      fetchGroupsPath="registration/attendances/dates"
    />
  )
}

export default DateGroups
