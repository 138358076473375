/* global React, ReactDOM, $ */
import CustomFormActions from './custom_form_actions';
import CustomFormField from './custom_form_field';
import DescriptionField from './description_field';

import createReactClass from 'create-react-class';

export default createReactClass({

  componentDidMount: function () {
    $(ReactDOM.findDOMNode(this)).find('.custom_form_fields_list').sortable({
      connectWith: ['.custom_form_fields_list', '.member_type_properties_list'],
      stop: function (e, ui) {
        var newPosition = ui.item.index();
        var oldPosition = parseInt(ui.item.attr('data-position'));
        $(ui.item.parent()).sortable("cancel");
        if (newPosition != oldPosition) {
          this.handleSortStop(e, ui, newPosition);
        }
      }.bind(this),
      receive: function (e, ui) {
        var position = ui.item.index();
        $(ui.sender).sortable("cancel");
        this.handleSortStop(e, ui, position);
      }.bind(this)
    });
  },

  handleSortStop: function (e, ui, position) {
    var fieldId = parseInt(ui.item.attr('data-member-type-property-id'));
    var pageNumber = this.props.page.number;
    CustomFormActions.updateFieldPosition(pageNumber, fieldId, position);
  },

  handleDescriptionChange: function (locale, description) {
    var pageNumber = this.props.page.number;
    CustomFormActions.updatePageDescription(pageNumber, locale, description);
  },

  handleRemovePageClick: function () {
    CustomFormActions.removePage(this.props.page.number);
  },

  render: function () {
    var position = -1;
    var pageNumber = this.props.page.number;
    var customFormFieldNodes = this.props.page.fields.map(function (field_id) {
      position += 1;
      return (
        <CustomFormField key={field_id} position={position} field_id={field_id} pageNumber={pageNumber} />
      );
    });

    return (
      <div className="ibox">
        <div className="ibox-title">
          <h5>Page {this.props.page.number}</h5>
          <div className="ibox-tools">
            <a className="btn btn-xs btn-danger" onClick={this.handleRemovePageClick} href="##">Remove page</a>
          </div>
        </div>
        <div className="ibox-content">
          <DescriptionField key={'page' + this.props.page.number} page_key={'page' + this.props.page.number} handleDescriptionChange={this.handleDescriptionChange} page={this.props.page} />
        </div>
        <div className="ibox-content">
          <ul className="custom_form_fields_list" data-page-number={this.props.page.number}>
            {customFormFieldNodes}
          </ul>
        </div>
      </div>
    );
  }
});
