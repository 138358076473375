import ShiftApi from '../apis/ShiftApi'

import shiftActions from '../../../actions/shiftActions'
import { publishResponseErrorNotification } from '../../../utilities/notifications'
import shiftSelector from '../selectors/shifts/shiftSelector'

const { requestShiftUpdate, receiveShiftUpdate, receiveShiftError } = shiftActions

export const createAttendances = (shiftId, memberIds) => (dispatch) => {
  dispatch(requestShiftUpdate(shiftId))

  const attendances = memberIds.map(memberId => ({
    shiftId,
    memberId
  }))

  const api = new ShiftApi()
  const body = {
    shift: {
      id: shiftId,
      attendances
    }
  }

  api.update(shiftId, { body })
    .then((response) => {
      dispatch(receiveShiftUpdate(response.data))
    }).catch((error) => {
      const data = error.response.data
      publishResponseErrorNotification(data)
      dispatch(receiveShiftError(shiftId))
    })
}

export const undoCreateAttendance = (shiftId, memberIds) => (dispatch, getState) => {
  dispatch(requestShiftUpdate(shiftId))

  const shift = shiftSelector(getState(), shiftId)
  const attendances = shift.attendances.filter(attendance => (
    memberIds.includes(attendance.member.id)
  ))

  const attendancesAttributes = attendances.map(attendance => ({
    id: attendance.id,
    _destroy: true
  }))

  const api = new ShiftApi()
  const body = {
    shift: {
      id: shiftId,
      attendances: attendancesAttributes
    }
  }

  api.update(shiftId, { body })
    .then((response) => {
      dispatch(receiveShiftUpdate(response.data))
    })
}
