import { createSelector } from 'redux-orm'

import shiftRecordToObject from './shiftRecordToObject'
import { orm } from '../../../../store'

const ormStateSelector = state => state.orm
const gradeIdSelector = (state, id) => id

const shiftsInGradeSelector = createSelector(
  orm,
  ormStateSelector,
  gradeIdSelector,
  (session, gradeId) => session.Grade.withId(gradeId).shifts.toModelArray().map(shift => (
    shiftRecordToObject(shift, { gradesList: true, bundlesList: true, membersList: true })
  ))
)

export default shiftsInGradeSelector
