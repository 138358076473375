/* global I18n */

import React from 'react'
import { func, arrayOf } from 'prop-types'

import { FormGroup, ControlLabel, FormControl, InputGroup } from 'react-bootstrap'
import { Checkbox } from 'react-icheck'
import FontAwesome from 'react-fontawesome'

import { sortingMethodTypes, sortingOrderTypes } from '../../../constants/sorting-method-types'

import ToggleButton from '../../../../../components/ToggleButton'

const i18noptions = {
  scope: 'components.schedule_planner.members.filter_form'
}

class Form extends React.PureComponent {
  handleChangeTextFiltering = (event) => {
    const { onChangeFilter } = this.props
    const text = event.target.value

    onChangeFilter({ text: text.length > 0 ? text : null })
  }

  handleChangeHideFulfilledFiltering = (event) => {
    const { onChangeFilter } = this.props
    const hideFulfilled = event.target.checked

    onChangeFilter({ hideFulfilled })
  }

  handleChangeShowAcceptedShiftsOnlyFiltering = (event) => {
    const { onChangeFilter } = this.props
    const showAcceptedShiftsOnly = event.target.checked

    onChangeFilter({ showAcceptedShiftsOnly })
  }

  handleChangeIncludeExternalShifts = (event) => {
    const { onChangeIncludeExternalShifts } = this.props
    const includeExternalShifts = event.target.checked

    onChangeIncludeExternalShifts(includeExternalShifts)
  }

  handleChangeSortingMethod = (event) => {
    const { onChangeSorting } = this.props
    const method = event.target.value

    onChangeSorting({ method })
  }

  handleChangeSortingOrder = (ascendingOrder) => {
    const { onChangeSorting } = this.props
    const order = ascendingOrder
      ? sortingOrderTypes.ASCENDING_ORDER
      : sortingOrderTypes.DESCENDING_ORDER

    onChangeSorting({ order })
  }

  render() {
    return (
      <div>
        <FormGroup>
          <FormControl
            name="members[search]"
            placeholder={I18n.t('search_for_members', i18noptions)}
            onChange={this.handleChangeTextFiltering}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>{I18n.t('sorting_by', i18noptions)}</ControlLabel>
          <InputGroup>
            <FormControl
              name="members[sort]"
              componentClass="select"
              onChange={this.handleChangeSortingMethod}
            >
              {Object.entries(sortingMethodTypes).map(([sortingMethod, value]) => (
                <option key={value} value={value}>
                  {I18n.t(sortingMethod.toLowerCase(), i18noptions)}
                </option>
              ))}
            </FormControl>
            <InputGroup.Button>
              <ToggleButton
                checkedContent={<FontAwesome name="sort-amount-asc" />}
                uncheckedContent={<FontAwesome name="sort-amount-desc" />}
                onToggle={this.handleChangeSortingOrder}
                defaultChecked
              />
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <div className="checkbox">
            <Checkbox
              name="members[hideFulfilled]"
              label={I18n.t('hide_members_with_all_requirements', i18noptions)}
              onChange={this.handleChangeHideFulfilledFiltering}
              checkboxClass="icheckbox_square-green icheck-item"
            />
          </div>
        </FormGroup>

        <FormGroup>
          <div className="checkbox">
            <Checkbox
              name="members[showAcceptedShiftsOnly]"
              label={I18n.t('show_only_members_with_accepted_shifts', i18noptions)}
              onChange={this.handleChangeShowAcceptedShiftsOnlyFiltering}
              checkboxClass="icheckbox_square-green icheck-item"
            />
          </div>
        </FormGroup>

        <FormGroup>
          <div className="checkbox">
            <Checkbox
              name="members[includeAll]"
              label={I18n.t('show_info_across_schedules', i18noptions)}
              onChange={this.handleChangeIncludeExternalShifts}
              checkboxClass="icheckbox_square-green icheck-item"
            />
          </div>
        </FormGroup>
      </div>
    )
  }
}

Form.propTypes = {
  onChangeFilter: func.isRequired,
  onChangeSorting: func.isRequired,
  onChangeIncludeExternalShifts: func.isRequired
}

export default Form
