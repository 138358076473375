import React from 'react'

import '../styles/shift.scss'

const Shift = ({ children, actionButton, style }) => (
  <div className="shift" style={style}>
    {children}
    <div className="shift-actions">
      {actionButton}
    </div>
  </div>
)

export default Shift
