import React, { useState, useRef, useEffect } from 'react'
import { useDrag } from 'react-dnd15'
import PropTypes from 'prop-types'

const PlacedField = ({ field, onRemove, onSizeChange }) => {
  const { id, x, y, size = 100, elementType, type } = field
  const [isHovered, setIsHovered] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [startResizePos, setStartResizePos] = useState({ x: 0, y: 0 })
  const [startSize, setStartSize] = useState(size)
  const fieldRef = useRef(null)

  const [{ isDragging }, drag] = useDrag({
    type: 'FIELD',
    item: { ...field },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const isQrCode = elementType === 'qr' || id.includes('qr')

  // Define handlers outside of event handlers to ensure proper cleanup
  const handleResize = (e) => {
    if (!isResizing) return

    const deltaX = e.clientX - startResizePos.x
    const deltaY = e.clientY - startResizePos.y

    // Use the larger of the two deltas to maintain a square aspect ratio
    const delta = Math.max(deltaX, deltaY)
    const newSize = Math.max(50, Math.min(200, startSize + delta))

    onSizeChange(id, newSize)
  }

  const handleResizeEnd = () => {
    setIsResizing(false)
  }

  // Use useEffect to manage event listeners
  useEffect(() => {
    if (isResizing) {
      // Add global event listeners when resizing starts
      document.addEventListener('mousemove', handleResize)
      document.addEventListener('mouseup', handleResizeEnd)
    }

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener('mousemove', handleResize)
      document.removeEventListener('mouseup', handleResizeEnd)
    }
  }, [isResizing, startResizePos, startSize])

  const handleResizeStart = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsResizing(true)
    setStartResizePos({ x: e.clientX, y: e.clientY })
    setStartSize(size)
  }

  return (
    <div
      ref={(node) => {
        drag(node)
        fieldRef.current = node
      }}
      style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        opacity: isDragging ? 0.5 : 1,
        cursor: isResizing ? 'nwse-resize' : 'move',
        padding: '8px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        onClick={(e) => {
          e.stopPropagation()
          onRemove(id)
        }}
        style={{
          position: 'absolute',
          top: '-8px',
          right: '-8px',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '50%',
          cursor: 'pointer',
          zIndex: 30
        }}
      >
        ×
      </button>

      {isQrCode ? (
        <div
          className="qr-placeholder"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            position: 'relative'
          }}
        >
          QR Code
          {(isHovered || isResizing) && (
            <div
              className="resize-handle"
              style={{
                position: 'absolute',
                bottom: '-4px',
                right: '-4px',
                width: '12px',
                height: '12px',
                backgroundColor: '#2563eb',
                borderRadius: '50%',
                cursor: 'nwse-resize',
                zIndex: 20
              }}
              onMouseDown={handleResizeStart}
              role="button"
              tabIndex={0}
            />
          )}
        </div>
      ) : (
        field.name
      )}
    </div>
  )
}

PlacedField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    size: PropTypes.number,
    elementType: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func.isRequired
}

export default PlacedField 
