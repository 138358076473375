/* global I18n */

import React from 'react'
import { string } from 'prop-types'
import FontAwesome from 'react-fontawesome'

const Sex = ({ value }) => {
  let iconName

  switch (value) {
    case 'f':
      iconName = 'female'
      break
    case 'm':
      iconName = 'male'
      break
    case 'o':
    default:
      iconName = 'transgender-alt'
  }

  return (
    <span>
      <FontAwesome name={iconName} />
      {' '}{I18n.t(value, { scope: 'sex' })}
    </span>
  )
}

Sex.propTypes = {
  value: string.isRequired
}

export default Sex
