import { string, shape, oneOfType, instanceOf } from 'prop-types'

const schedulePropTypes = shape({
  id: string,
  name: string.isRequired,
  location: string,
  selectionType: string.isRequired,
  startingAt: oneOfType([string, instanceOf(Date)]).isRequired
})

export default schedulePropTypes
