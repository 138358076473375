import { ForeignKey } from 'redux-orm'
import {
  reverseFieldName,
  reverseFieldErrorMessage
} from 'redux-orm/lib/utils'

import {
  attrDescriptor,
  backwardManyToOneDescriptor
} from 'redux-orm/lib/descriptors'

import forwardPolymorphicManyToOneDescriptor from './forwardPolymorphicManyToOneDescriptor'

export class PolymorphicForeignKey extends ForeignKey {
  static displayName = 'PolymorphicForeignKey'

  install(model, fieldName, orm) {
    const toModelNames = this.toModelName

    // Forwards.
    Object.defineProperty(
      model.prototype,
      `${fieldName}Type`,
      attrDescriptor(`${fieldName}Type`)
    )
    Object.defineProperty(
      model.prototype,
      fieldName,
      forwardPolymorphicManyToOneDescriptor(fieldName, toModelNames)
    )

    // Backwards.
    const backwardsFieldName = this.relatedName
      ? this.relatedName
      : reverseFieldName(model.modelName)

    const ThisField = this.getClass()

    toModelNames.forEach((toModelName) => {
      const toModel = toModelName === 'this' ? model : orm.get(toModelName)

      const backwardsDescriptor = Object.getOwnPropertyDescriptor(
        toModel.prototype,
        backwardsFieldName
      )

      if (backwardsDescriptor) {
        const errorMsg = reverseFieldErrorMessage(
          model.modelName,
          fieldName,
          toModel.modelName,
          backwardsFieldName
        )
        throw new Error(errorMsg)
      }

      Object.defineProperty(
        toModel.prototype,
        backwardsFieldName,
        backwardManyToOneDescriptor(fieldName, model.modelName)
      )

      toModel.virtualFields[backwardsFieldName] = new ThisField(model.modelName, fieldName)
    })
  }
}

export function polyFk(...args) {
  return new PolymorphicForeignKey(...args)
}
