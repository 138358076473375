/* global React, Reflux */
import CustomFormStore from './custom_form_store';
import CustomFormActions from './custom_form_actions';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [Reflux.connect(CustomFormStore, 'custom_form')],

  saveCustomForm: function () {
    CustomFormActions.saveCustomForm(this.props.url);
  },

  render: function () {
    return (
      <span>
        {function () {
          if (this.state.custom_form.save_status == "saving") {
            return <a href="##" className="btn btn-primary"><i className="fa fa-spinner fa-spin"></i></a>
          } else if (this.state.custom_form.save_status == "success") {
            return <a href="##" className="btn btn-primary"><i className="fa fa-check"></i></a>
          } else if (this.state.custom_form.save_status == "error") {
            return <a href="##" className="btn btn-danger"><i className="fa fa-times"></i></a>
          } else {
            return <a href="##" onClick={this.saveCustomForm} className="btn btn-primary">Save</a>
          }
        }.call(this)}
      </span>
    );
  }
});
