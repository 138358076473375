import { number, string, instanceOf, shape, arrayOf } from 'prop-types'
import attendancePropTypes from './attendancePropTypes'
import preferencePropTypes from './preferencePropTypes'
import requirementPropTypes from './requirementPropTypes'

export const shiftPropTypes = shape({
  id: string.isRequired,
  name: string,
  startTime: instanceOf(Date),
  endTime: instanceOf(Date),
  availableSpots: number,
  duration: number,
  // selected: bool.isRequired,
  // selectionType: string,
  attendanceLimit: number,
  attendanceCount: number,
  // schedule: shape({
  //   id: number.isRequired,
  //   name: string.isRequired,
  //   location: string,
  //   shiftSelectionSetting: string.isRequired
  // }).isRequired,
  attendances: arrayOf(attendancePropTypes),
  preferences: arrayOf(preferencePropTypes),
  requirements: arrayOf(requirementPropTypes),
  memberIds: arrayOf(number)
})

export const newShiftPropTypes = shape({
  name: string,
  times: arrayOf(shape({
    startTime: instanceOf(Date),
    endTime: instanceOf(Date)
  })),
  attendanceLimit: number,
  attendanceCount: number,
  attendances: arrayOf(attendancePropTypes),
  preferences: arrayOf(preferencePropTypes),
  requirements: arrayOf(requirementPropTypes),
  memberIds: arrayOf(number)
})
