/* global Routes, I18n */

import React from 'react'
import { connect } from 'react-redux'
import { bool, number, func, arrayOf, object } from 'prop-types'

import { Button, DropdownButton, MenuItem } from 'react-bootstrap'

import { fetchGuestLists } from '../actions/guest-list-actions'
import getGuestListsWithGroupAndOwner from '../selectors/guest-lists/getGuestListsWithGroupAndOwner'
import { humanizeAttribute } from '../utilities/i18n'

import { PersistableServerSideDataTable } from '../components/DataTable'
import BooleanIcon from '../components/BooleanIcon'
import { ModelLink } from '../components/modelComponents'
import DirectGuestListLinkWidget from './DirectGuestListLinkWidget'
import DeleteLinkPromptWidget from './DeleteLinkPromptWidget'

const i18noptions = {
  scope: 'components.guest_list_table_widget'
}

const GuestListTableWidget = ({
  guestLists,
  pages,
  loading,
  onFetchGuestLists
}) => (
  <PersistableServerSideDataTable
    stateKey="GuestListTable"
    onFetchData={onFetchGuestLists}
    data={guestLists}
    loading={loading}
    pages={pages}
    defaultSorted={[{
      id: 'name',
      desc: false
    }]}
    columns={[
      {
        Header: humanizeAttribute('guest_list', 'name'),
        accessor: 'name',
        Cell: row => (
          <a href={Routes.guest_list_guests_path(row.original.id)}>
            {row.value}
          </a>
        )
      },
      {
        Header: humanizeAttribute('guest_list', 'owner'),
        id: 'owner',
        accessor: ({ owner }) => (owner ? owner.name : '-'),
        sortable: false,
        filterable: false,
        Cell: row => (row.original.ownerType === 'Member' ? (
          <a href={Routes.member_path(row.original.owner.id)}>
            {row.value}
          </a>
        ) : row.value)
      },
      {
        Header: humanizeAttribute('guest_list', 'group'),
        accessor: 'group.name'
      },
      {
        Header: I18n.t('vouchers_distributed', i18noptions),
        id: 'ticketItemsCount',
        accessor: ({ usedTicketItemsCount, totalTicketItemsCount }) => (
          `${usedTicketItemsCount} / ${totalTicketItemsCount}`
        ),
        filterable: false,
        sortable: false,
        maxWidth: 120
      },
      {
        Header: I18n.t('all_vouchers_sent', i18noptions),
        accessor: 'allTicketsSent',
        filterable: false,
        sortable: false,
        maxWidth: 150,
        Cell: row => (<BooleanIcon value={row.value} />)
      },
      {
        Header: I18n.t('actions', i18noptions),
        accessor: 'id',
        filterable: false,
        sortable: false,
        maxWidth: 200,
        Cell: row => (
          <div className="action-wrapper">
            <DropdownButton
              bsStyle="white"
              bsSize="xs"
              id={`guest-list-actions-${row.value}`}
              title={I18n.t('actions', i18noptions)}
            >
              <MenuItem
                href={Routes.guest_list_guests_url(row.value)}
                eventKey="1"
              >
                {I18n.t('assign_guests', i18noptions)}
              </MenuItem>
              <ModelLink
                action="edit"
                model="GuestList"
                id={row.value}
                component={MenuItem}
                eventKey="2"
                modal
              />
              <ModelLink
                action="delete"
                model="GuestList"
                id={row.value}
                eventKey="3"
                component={({ href, children, ['data-method']: _method, ...menuItemProps }) => (
                  <MenuItem {...menuItemProps}>
                    <DeleteLinkPromptWidget
                      linkText={children}
                      message={I18n.t('confirm_delete', i18noptions)}
                      path={href}
                      menuItem
                    />
                  </MenuItem>
                )}
              />
              <MenuItem
                href={Routes.activities_path({
                  model_name: 'GuestList',
                  model_id: row.value
                })}
                eventKey="4"
                data-modal="true"
              >
                {I18n.t('activities', i18noptions)}
              </MenuItem>
            </DropdownButton>

            {row.original.ownerType === 'GuestListOwner' ? (
              <DirectGuestListLinkWidget
                guestListId={row.value}
                clipboardText={
                  Routes.guest_list_guests_url(row.value, {
                    user_email: row.original.owner.email,
                    user_token: row.original.owner.authenticationToken
                  })
                }
                bsStyle="info"
                bsSize="xs"
                className="m-r-xs"
              />
            ) : (
              <Button
                href={Routes.guest_list_guests_url(row.value)}
                bsStyle="success"
                bsSize="xs"
                className="m-r-xs"
              >
                {I18n.t('assign_guests', i18noptions)}
              </Button>
            )}
          </div>
        )
      }
    ]}
    filterable
  />
)

GuestListTableWidget.propTypes = {
  guestLists: arrayOf(object).isRequired,
  pages: number,
  loading: bool.isRequired,
  onFetchGuestLists: func.isRequired
}

GuestListTableWidget.defaultProps = {
  pages: null
}

const mapStateToProps = (state) => {
  const { guestListsList: loading } = state.loadingStatus
  const { total: pages } = state.orm.GuestList.meta.page || {}

  return {
    guestLists: getGuestListsWithGroupAndOwner(state),
    pages,
    loading
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchGuestLists: params => dispatch(fetchGuestLists(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestListTableWidget)
