/* global I18n */

import React from 'react'
import { string } from 'prop-types'

import FontAwesome from 'react-fontawesome'

const i18noptions = {
  scope: 'components.member_info_button.status'
}

const Status = ({ status }) => {
  let colorClass
  let labelText
  let iconName

  switch (status) {
    case 'deleted':
      colorClass = 'text-danger'
      labelText = I18n.t('paid', i18noptions)
      iconName = 'times'
      break
    case 'checked_in':
      colorClass = 'text-navy'
      labelText = I18n.t('partial_paid', i18noptions)
      iconName = 'ticket'
      break
    case 'shifts_accepted': {
      colorClass = 'text-navy'
      labelText = I18n.t('partial_paid', i18noptions)
      iconName = 'check'
      break
    }
    case 'on_waiting_list':
      colorClass = 'text-warning'
      labelText = I18n.t('partial_paid', i18noptions)
      iconName = 'circle'
      break
    case 'completed_signup':
      colorClass = 'text-navy'
      labelText = I18n.t('partial_paid', i18noptions)
      iconName = 'circle'
      break
    case 'confirmed':
      colorClass = 'text-danger'
      labelText = I18n.t('not_paid', i18noptions)
      iconName = 'circle'
      break
    case 'unconfirmed':
      colorClass = 'text-muted'
      labelText = I18n.t('not_paid', i18noptions)
      iconName = 'circle'
      break
    default:
      return null
  }

  return (
    <p>
      <FontAwesome name={iconName} className={colorClass} />
      {' '}{labelText}
    </p>
  )
}

Status.propTypes = {
  status: string.isRequired
}

export default Status
