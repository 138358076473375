import { combineReducers } from 'redux'
import isLoading from './isLoadingReducer'
import isActive from './isActiveReducer'
import validationErrors from './validationErrorsReducer'
import currentShiftBundle from './currentShiftBundleReducer'

const shiftBundleModalFormReducer = combineReducers({
  isLoading,
  isActive,
  validationErrors,
  currentShiftBundle
})

export default shiftBundleModalFormReducer
