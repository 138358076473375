import React from 'react'
import ReactDOM from 'react-dom'

import HeapStoreContainer from './HeapStoreContainer'
import HeapApp from './HeapApp'

import PriceInput from './components/PriceInput'
import MapInput from './components/MapInput'
import Select from './components/Select'
import ClipboardWidget from './components/ClipboardWidget'
import ModalButton from './components/ModalButton'
import Notifications from './components/Notifications'
import RadioButtonsCollection from './components/RadioButtonsCollection'
import RadioButtonsWithSettings from './components/RadioButtonsWithSettings'
import ShiftGradeLabel from './components/ShiftGradeLabel'
import DirectLinkModal from './components/DirectLinkModal'

import DirectGuestListLinkWidget from './widgets/DirectGuestListLinkWidget'
import TeamInviteLinksWidget from './widgets/TeamInviteLinksWidget'
import DeleteLinkPromptWidget from './widgets/DeleteLinkPromptWidget'
import ForceTeamSelect from './widgets/ForceTeamSelect'
import SmsPreviewWidget from './widgets/SmsPreviewWidget'
import RoskildeSyncButton from './widgets/RoskildeSyncButton'
import ScheduleShiftSettingsWidget from './widgets/ScheduleShiftSettingsWidget'
import TeamMemberTypesSelect from './widgets/TeamMemberTypesSelect'
import ShiftsExportButton from './widgets/ShiftsExportButton'
import GuestListTableWidget from './widgets/GuestListTableWidget'
import CheckInReportTableWidget from './widgets/CheckInReportTableWidget'
import EmailReportTableWidget from './widgets/EmailReportTableWidget'
import ShiftOverviewTable from './widgets/ShiftOverviewTable'
import GroupedShiftsForRegistration from './widgets/GroupedShiftsForRegistration'
import HelpGuideWidget from './widgets/HelpGuideWidget'
import SubdomainFieldWidget from './widgets/SubdomainFieldWidget'
import TeamTableWidget from './widgets/TeamTableWidget'
import OrganizationForceTeamSelect from './widgets/OrganizationForceTeamSelect'

import ShiftReportForm from './ShiftReportForm'
import ShiftSelector from './schedule-app/ShiftSelector'
import SchedulePlanner from './schedule-app/SchedulePlanner'
import TeamSelect from './TeamSelect'

import CustomForm from './CustomForm/custom_form'
import CustomFormSidebar from './CustomForm/custom_form_sidebar'
import CustomFormSaveButton from './CustomForm/custom_form_save_button'

import TicketScanner from './TicketScanner/TicketScanner';

import TicketDesigner from './TicketDesigner/TicketDesignerApp'

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update') // eslint-disable-line global-require, import/no-extraneous-dependencies
//   whyDidYouUpdate(React)
// }

const reactApps = {
  HeapApp,
  Select,
  MapInput,
  PriceInput,
  RadioButtonsCollection,
  RadioButtonsWithSettings,
  ShiftGradeLabel,
  DirectLinkModal,
  ClipboardWidget,
  ModalButton,
  Notifications,
  TeamMemberTypesSelect,
  GuestListTableWidget,
  CheckInReportTableWidget,
  EmailReportTableWidget,
  DirectGuestListLinkWidget,
  TeamInviteLinksWidget,
  ShiftReportForm,
  ShiftSelector,
  SchedulePlanner,
  DeleteLinkPromptWidget,
  TeamSelect,
  ForceTeamSelect,
  SmsPreviewWidget,
  RoskildeSyncButton,
  ScheduleShiftSettingsWidget,
  ShiftsExportButton,
  ShiftOverviewTable,
  GroupedShiftsForRegistration,
  HelpGuideWidget,
  SubdomainFieldWidget,
  TeamTableWidget,
  OrganizationForceTeamSelect,
  CustomForm,
  CustomFormSidebar,
  CustomFormSaveButton,
  TicketScanner,
  TicketDesigner
}

// Render component with data
const getReactApps = () => (
  Array.from(document.querySelectorAll('[data-react-app]'))
)

const mountReactElement = (element, container, name) => {
  ReactDOM.render(
    React.createElement(
      HeapStoreContainer,
      { appName: name },
      element
    ),
    container
  )
}

const createReactElement = (name, props) => {
  const reactComponent = reactApps[name]

  if (reactComponent !== undefined) {
    const children = props.children
    let childElements = undefined

    if (children !== undefined) {
      delete props.children

      childElements = children.map((child) => {
        const [childName, childProps] = Object.entries(child)[0]
        return createReactElement(childName, childProps)
      })
    }

    return React.createElement(reactComponent, props, childElements)
  }
}

const createAndMountReactFromNode = (node) => {
  const data = node.dataset
  const props = JSON.parse(data.props)
  const name = data.reactApp

  const reactElement = createReactElement(name, props)

  if (reactElement !== undefined) {
    mountReactElement(reactElement, node, name)
  }
}

const mountReactApps = () => {
  getReactApps().forEach(node => createAndMountReactFromNode(node))
}

const unMountReactApps = () => {
  getReactApps().forEach((element) => {
    ReactDOM.unmountComponentAtNode(element)
  })
}

document.addEventListener('DOMContentLoaded', mountReactApps)
document.addEventListener('DOMContentChanged', mountReactApps)
document.addEventListener('page:load', mountReactApps)
document.addEventListener('page:restore', mountReactApps)
document.addEventListener('page:before-unload', unMountReactApps)
