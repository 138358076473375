import React from 'react'
import { hot } from 'react-hot-loader'
import { string } from 'prop-types'
import { Provider } from 'react-redux'
import store from './store'

import { childrenPropTypes } from './propTypes'
import { clearCurrentAppAndReducers, replaceCurrentAppAndReducers } from './actions/heap-actions'

class HeapStoreContainer extends React.Component {
  constructor(props) {
    const { appName } = props

    if (store.appReducers[appName]) {
      store.dispatch(replaceCurrentAppAndReducers(appName))
    }
    super(props)
  }

  componentWillUnmount() {
    const { appName } = this.props

    if (store.appReducers[appName]) {
      store.dispatch(clearCurrentAppAndReducers())
    }
  }

  render() {
    const { children } = this.props

    return (
      <Provider store={store}>
        {children}
      </Provider>
    )
  }
}

HeapStoreContainer.propTypes = {
  children: childrenPropTypes.isRequired,
  appName: string.isRequired
}

export default hot(module)(HeapStoreContainer)
