import React from 'react'
import PropTypes from 'prop-types'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'

const ButtonIcon = ({ icon, tooltip, ...props }) => {
  const button = (
    <Button {...props}>
      <FontAwesome name={icon} />
    </Button>
  )

  if (tooltip !== null) {
    const tooltipElement = (
      <Tooltip id="button-tooltip">{tooltip}</Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={tooltipElement}>
        {button}
      </OverlayTrigger>
    )
  }

  return button
}

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.node
}

ButtonIcon.defaultProps = {
  tooltip: null
}

export default ButtonIcon
