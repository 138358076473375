import { combineReducers } from 'redux'
import isLoading from './isLoadingReducer'
import isActive from './isActiveReducer'
import isCopying from './isCopyingReducer'
import validationErrors from './validationErrorsReducer'
import currentShift from './currentShiftReducer'
import action from './actionReducer'

const shiftModalFormReducer = combineReducers({
  isLoading,
  isActive,
  isCopying,
  validationErrors,
  currentShift,
  action
})

export default shiftModalFormReducer
