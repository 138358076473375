import Api, { RestPath } from '../../../Api'
import { getRequest, postRequest, deleteRequest } from '../../../Api/request'

class ShiftApi extends Api {
  constructor() {
    const path = new RestPath('shifts')
    super(path)
  }

  fetchAll() {
    return getRequest({
      path: `${this.path.collectionPath()}/selectable`,
      params: {
        include: 'schedule,requirements,grade,bundle.requirements'
      }
    })
  }

  select(id, { body }) {
    return postRequest({
      path: `${this.path.memberPath(id)}/select`,
      body
    })
  }

  deselect(id) {
    return deleteRequest({
      path: `${this.path.memberPath(id)}/select`
    })
  }

  prefer(id) {
    return postRequest({
      path: `${this.path.memberPath(id)}/prefer`
    })
  }

  unprefer(id) {
    return deleteRequest({
      path: `${this.path.memberPath(id)}/prefer`
    })
  }
}

export default ShiftApi
