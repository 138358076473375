import React from 'react'
import { bool, func, shape } from 'prop-types'
import { connect } from 'react-redux'

import { childrenPropTypes, memberPropTypes, eventOrOrganizationPropTypes } from './propTypes'
import { receiveCurrentMember, receiveCurrentEventOrOrganization } from './actions/heap-actions'

class HeapApp extends React.Component {
  constructor(props) {
    super(props)

    const {
      currentMember,
      onReceiveCurrentMember,
      currentEventOrOrganization,
      onReceiveCurrentEventOrOrganization,
      loadingStatus
    } = props

    if (!loadingStatus.currentMember) {
      onReceiveCurrentMember(currentMember)
    }

    if (!loadingStatus.currentEventOrOrganization) {
      onReceiveCurrentEventOrOrganization(currentEventOrOrganization)
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

HeapApp.propTypes = {
  children: childrenPropTypes,
  currentMember: memberPropTypes,
  currentEventOrOrganization: eventOrOrganizationPropTypes,
  loadingStatus: shape({
    currentMember: bool.isRequired,
    currentEventOrOrganization: bool.isRequired
  }).isRequired,
  onReceiveCurrentMember: func.isRequired,
  onReceiveCurrentEventOrOrganization: func.isRequired
}

HeapApp.defaultProps = {
  children: null,
  currentMember: null,
  currentEventOrOrganization: null
}

const mapStateToProps = state => ({
  loadingStatus: state.loadingStatus
})

const mapDispatchToProps = dispatch => ({
  onReceiveCurrentMember: currentMember => (
    dispatch(receiveCurrentMember(currentMember))
  ),
  onReceiveCurrentEventOrOrganization: currentEventOrOrganization => (
    dispatch(receiveCurrentEventOrOrganization(currentEventOrOrganization))
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(HeapApp)
