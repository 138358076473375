/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

import ModalButton from '../../components/ModalButton'
import ClipboardWidget from '../../components/ClipboardWidget'
import SendLinkForm from './SendLinkForm'

const i18noptions = {
  scope: 'components.team_invite_links_widget'
}

class TeamInviteLinksWidget extends Component {
  state = {
    showingFormForMemberType: null
  }

  handleSendLink = (memberType) => {
    this.setState({ showingFormForMemberType: memberType })
  }

  handleSendLinkComplete = () => {
    this.setState({ showingFormForMemberType: null })
  }

  renderSendLinkForm() {
    const { showingFormForMemberType } = this.state
    const { team, sendEmailPath } = this.props

    return (
      <SendLinkForm
        memberType={showingFormForMemberType}
        sendEmailPath={sendEmailPath}
        team={team}
        onComplete={this.handleSendLinkComplete}
      />
    )
  }

  renderMemberTypeClipboards() {
    const { memberTypes, inviteUrl } = this.props

    return memberTypes.map((memberType) => {
      const { name, id } = memberType
      const urlWithId = inviteUrl.replace(encodeURIComponent('{id}'), id)

      return (
        <div key={id}>
          <h3>{name}</h3>

          <ClipboardWidget text={urlWithId}>
            <Button
              bsStyle="white"
              onClick={() => { this.handleSendLink(memberType) }}
            >
              Send link
            </Button>
          </ClipboardWidget>
        </div>
      )
    })
  }

  render() {
    const { showingFormForMemberType } = this.state
    const title = I18n.t('invite_links', i18noptions)

    let content
    if (showingFormForMemberType === null) {
      content = (
        <Modal.Body>
          {this.renderMemberTypeClipboards()}
        </Modal.Body>
      )
    } else {
      content = this.renderSendLinkForm()
    }

    return (
      <ModalButton
        buttonText={title}
        bsStyle="white"
        style={{
          borderRadius: 0
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        {content}
      </ModalButton>
    )
  }
}

TeamInviteLinksWidget.propTypes = {
  memberTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  inviteUrl: PropTypes.string.isRequired,
  sendEmailPath: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired
}

export default TeamInviteLinksWidget
