/* global React, Reflux */

import QueryStore from './QueryStore';
import Actions from './Actions';
import SearchAutocompleteItem from './SearchAutocompleteItem';


import createReactClass from 'create-react-class';

export default createReactClass({
  RESULTS_LIMIT: 5,

  mixins: [
    Reflux.connect(QueryStore, 'query'),
  ],

  componentWillMount: function () {
    this.setState({
      width: '0',
      scrollPosition: -1
    });
  },

  componentDidMount: function () {
    this.updateWidth();
  },

  updateWidth: function () {
    var width = $(ReactDOM.findDOMNode(this)).siblings('input').outerWidth();
    this.setState({ width: width });
  },

  handleShowAllClick: function () {
    Actions.forceSearch(this.state.query);
  },

  hasMore: function () {
    return this.props.owners.totalCount > this.RESULTS_LIMIT;
  },

  listLength: function () {
    var listLength = this.props.owners.list.length;
    if (this.hasMore()) { ++listLength; }

    return listLength - 1;
  },

  scrollToPrev: function () {
    var position = this.state.scrollPosition - 1;
    if (position < 0) { position = this.listLength(); }
    this.setState({ scrollPosition: position });
  },

  scrollToNext: function () {
    var position = this.state.scrollPosition + 1;
    if (position > this.listLength()) { position = 0; }
    this.setState({ scrollPosition: position });
  },

  touchUnderScroll: function () {
    if (this.hasMore() && this.state.scrollPosition === this.listLength()) {
      Actions.forceSearch(this.state.query);
    } else {
      var owner = this.props.owners.list[this.state.scrollPosition];
      Actions.updateQuery(owner.barcode);
      Actions.resetOwnersList();
    }
  },

  render: function () {
    var list = [];
    var self = this;

    if (this.props.owners.list.length > 0) {
      list = this.props.owners.list.map(function (owner, i) {
        return (
          <SearchAutocompleteItem
            className={true ? "active" : null}
            key={owner.barcode}
            barcode={owner.barcode}
            name={owner.name}
            email={owner.email}
            highlight={i === self.state.scrollPosition} />
        );
      });
    } else {
      list =
        <div className="autocomplete-item disabled">
          <div className='row'>
            <div className='col-xs-12'>
              <h2 className="text-center">
                {I18n.t('tickets_scanner.owners_list.not_found')}
              </h2>
            </div>
          </div>
        </div>
    };

    var showMoreButton = null;

    if (this.hasMore()) {
      var className = "autocomplete-item";
      if (this.state.scrollPosition === this.listLength()) { className += " active"; }

      showMoreButton =
        <div className={className} onClick={this.handleShowAllClick}>
          <div className='row'>
            <div className='col-xs-12'>
              <h4 className='text-center'>
                {I18n.t('tickets_scanner.show_all_results', { count: this.props.owners.totalCount })}
              </h4>
            </div>
          </div>
        </div>
    }

    return (
      <div className="members-autocomplete" style={{ width: this.state.width + 'px' }}>
        {list}
        {showMoreButton}
      </div>
    );
  }
})
