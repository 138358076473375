/* global React, I18n, Reflux */

import Actions from './Actions';
import MemberInfo from './MemberInfo';
import GuestInfo from './GuestInfo';
import EventPosModal from './EventPosModal';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [
    Reflux.ListenerMixin
  ],

  getInitialState: function () {
    return {
      showEventPosModal: false
    };
  },

  componentDidMount: function () {
    this.listenTo(Actions.showEventPosModal, function () {
      this.setState({ showEventPosModal: true });
    });

    this.listenTo(Actions.dismissEventPosModal, function () {
      this.setState({ showEventPosModal: false });
    });

    this.focusOnCheckIn();
  },


  componentDidUpdate: function () {
    this.focusOnCheckIn();
  },

  focusOnCheckIn: function () {
    $(ReactDOM.findDOMNode(this)).find('#check-in').focus();
  },

  checkIn: function () {
    const hasEventPosProducts = this.props.ticket.has_event_pos_products
    const eventPosChipActivated = this.props.ticket.event_pos_chip_activated
    Actions.checkIn({ hasEventPosProducts: hasEventPosProducts, eventPosChipActivated: eventPosChipActivated });
  },

  removeCheckIn: function () {
    Actions.removeCheckIn();
  },

  cancelCheckIn: function () {
    Actions.reset();
  },

  hasError: function () {
    if (!this.props.ticket.valid) {
      if (this.props.ticket.blacklisted) {
        return I18n.t('tickets_scanner.errors.ticket_blacklisted');
      } else if (!this.props.ticket.paid) {
        return I18n.t('tickets_scanner.errors.ticket_not_paid');
      } else {
        return I18n.t('tickets_scanner.errors.ticket_deleted');
      }
    }

    if (this.props.ticket.checked_in_at) {
      return I18n.t('tickets_scanner.errors.ticket_already_checked_in');
    }
  },

  render: function () {
    var notice;
    var errorMsg = this.hasError();

    if (errorMsg) {
      notice =
        <div className="alert alert-danger">
          <i className="fa fa-warning fa-2x">
          </i>
          <span style={{ marginLeft: '10px' }}>{errorMsg}</span>
        </div>
    };

    var info;
    if (!!this.props.ticket.owner) {
      if (this.props.ticket.owner_type == "Member") {
        info = <MemberInfo member={this.props.ticket.owner} groupedTicketItems={this.props.ticket.ticket_items} />;
      } else {
        info = <GuestInfo guest={this.props.ticket.owner} groupedTicketItems={this.props.ticket.ticket_items} />;
      }
    }

    if (this.props.ticket.valid) {
      var checkInBtnClass = "btn btn-block ";
      checkInBtnClass += this.hasError() ? "btn-warning" : "btn-primary"
      var checkInTitle = this.hasError() ? I18n.t('tickets_scanner.check_in_anyway') : I18n.t('tickets_scanner.check_in')

      var removeCheckInBtnClass = "btn btn-block btn-danger";
      var removeCheckInTitle = I18n.t('tickets_scanner.remove_check_in')

      return (
        <div className="ibox float-e-margins">
          <div className="ibox-content">
            {notice}
            {info}
          </div>
          <div className="ibox-footer">
            <div className="row">
              <div className="col-md-8">
                <button
                  id="check-in"
                  onClick={this.checkIn}
                  className={checkInBtnClass}>
                  {checkInTitle}
                </button>
              </div>
              {this.props.ticket.checked_in_at ?
                <div className="col-md-2">
                  <button
                    id="check-out"
                    onClick={this.removeCheckIn}
                    className={removeCheckInBtnClass}>
                    {removeCheckInTitle}
                  </button>
                </div>
                : null
              }
              <div className="col-md-2">
                <button
                  onClick={this.cancelCheckIn}
                  className="btn btn-block btn-default">
                  {I18n.t('tickets_scanner.cancel_check_in')}
                </button>
              </div>
            </div>
          </div>
          <EventPosModal />
        </div>
      );
    } else {
      return (
        <div className="ibox float-e-margins">
          <div className="ibox-content">
            {notice}
            {info}
          </div>
        </div>
      );
    }
  }
})
