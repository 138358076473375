import { pick } from 'lodash'
import { zonedTimeToUtc } from 'date-fns-tz'

import * as types from '../constants/action-types'
import ShiftApi from '../apis/ShiftApi'
import scheduleSelector from '../selectors/schedules/scheduleSelector'
import draggedMembersSelector from '../selectors/members/draggedMembersSelector'
import shiftActions from '../../../actions/shiftActions'

const {
  requestShifts,
  receiveShifts,
  requestShiftCreation,
  receiveShiftCreation,
  requestShiftUpdate,
  receiveShiftUpdate,
  requestShiftDeletion,
  receiveShiftDeletion
} = shiftActions

export const receiveShiftError = errors => ({ type: types.RECEIVE_SHIFT_ERROR, payload: errors })
export const startShiftCopy = shift => ({ type: types.COPY_SHIFT, payload: shift })
export const openShiftModal = (action, shift) => ({
  type: types.OPEN_SHIFT_MODAL,
  payload: {
    action,
    attributes: shift
  }
})
export const closeShiftModal = () => ({ type: types.CLOSE_SHIFT_MODAL })
export const changeCurrentShift = changes => ({
  type: types.CHANGE_CURRENT_SHIFT,
  payload: changes
})

const requestBodyAttributes = (object, defaultAttributes, excludedAttributes) => (
  defaultAttributes.filter(element => (
    !excludedAttributes.includes(element)
  ))
)

const requestBody = (object, attributes) => {
  const body = pick(object, attributes)
  if (body.startTime) body.startTime = zonedTimeToUtc(body.startTime, 'UTC')
  if (body.endTime) body.endTime = zonedTimeToUtc(body.endTime, 'UTC')

  return body
}

const getRequirementRequestBody = (requirement, excludedAttributes = []) => {
  const defaultAttributes = [
    'id',
    'requiredValue',
    'propertyId',
    'requiredNumber',
    '_destroy'
  ]

  const attributes = requestBodyAttributes(requirement, defaultAttributes, excludedAttributes)
  const body = requestBody(requirement, attributes)

  return body
}

const getAttendanceRequestBody = (attendance, excludedAttributes = []) => {
  const defaultAttributes = [
    'id',
    'leader',
    'member',
    '_destroy'
  ]

  const attributes = requestBodyAttributes(attendance, defaultAttributes, excludedAttributes)
  const body = requestBody(attendance, attributes)

  if (attributes.includes('member') && body.member) {
    body.memberId = body.member.id
    delete body.member
  }

  return body
}

const getShiftRequestBody = ({
  shift,
  excludedShiftAttributes = [],
  excludedAttendanceAttributes = [],
  excludedRequirementsAttributes = []
}) => {
  const defaultAttributes = [
    'id',
    'attendanceLimit',
    'startTime',
    'endTime',
    'meetingPoint',
    'gradeId',
    'bundleId',
    'hidden',
    'attendances',
    'requirements',
    'translationsAttributes'
  ]

  const attributes = requestBodyAttributes(shift, defaultAttributes, excludedShiftAttributes)
  const body = requestBody(shift, attributes)

  if (attributes.includes('attendances') && body.attendances) {
    body.attendances = body.attendances.map(attendance => (
      getAttendanceRequestBody(attendance, excludedAttendanceAttributes)
    ))
  }

  if (attributes.includes('requirements') && body.requirements) {
    body.requirements = body.requirements.map(requirement => (
      getRequirementRequestBody(requirement, excludedRequirementsAttributes)
    ))
  }

  return body
}

export const copyShift = shift => (dispatch) => {
  const body = getShiftRequestBody({
    shift,
    excludedShiftAttributes: ['id', 'attendances', 'requirements']
  })

  const { startTime, endTime, ...newBody } = body
  newBody.times = [{
    startTime: shift.startTime,
    endTime: shift.endTime
  }]

  body.requirements = shift.requirements.filter(requirement => (
    !requirement._destroy
  )).map(({ id, ...attributes }) => attributes)

  dispatch(startShiftCopy())
  dispatch(openShiftModal('new', newBody))
}

export const buildShiftWithMembers = attributes => (dispatch, getState) => {
  const state = getState()
  const draggedMembers = draggedMembersSelector(state)
  const attendances = draggedMembers.map(member => ({
    member,
    memberId: member.id,
    leader: false
  }))

  dispatch(openShiftModal('new', {
    ...attributes,
    attendances
  }))
}

export const fetchShifts = () => (dispatch, getState) => {
  dispatch(requestShifts())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new ShiftApi(scheduleId)

  return api.fetchAll()
    .then(response => dispatch(receiveShifts(response.data)))
}

export const createShifts = shifts => (dispatch, getState) => {
  dispatch(requestShiftCreation())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new ShiftApi(scheduleId)
  const bodies = shifts.map(shift => (
    getShiftRequestBody({ shift })
  ))

  return api.create({ body: { shift: bodies } })
    .then(response => dispatch(receiveShiftCreation(response.data)))
    .catch(error => dispatch(receiveShiftError({ ...error.response.data })))
}

export const updateShift = (id, shift) => (dispatch) => {
  dispatch(requestShiftUpdate(id))

  const api = new ShiftApi()
  const body = getShiftRequestBody({ shift })

  return api.update(id, { body: { shift: body } })
    .then(response => dispatch(receiveShiftUpdate(response.data)))
    .catch(error => dispatch(receiveShiftError({ id, ...error.response.data })))
}

export const deleteShift = id => (dispatch) => {
  dispatch(requestShiftDeletion(id))

  const api = new ShiftApi()

  return api.destroy(id)
    .then(() => dispatch(receiveShiftDeletion(id)))
}
