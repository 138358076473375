import { FormControl } from 'react-bootstrap'

import '../../../stylesheets/form-control-extensions.scss'

import FormControlContainer from './FormControlContainer'
import FormControlNested from './FormControlNested'

FormControl.Container = FormControlContainer
FormControl.Nested = FormControlNested

export default FormControl
