/* global I18n */

import React from 'react'
import SelectedShiftsList from './components/SelectedShiftsList'
import PreferredShiftsList from './components/PreferredShiftsList'

const i18noptions = {
  scope: 'shift_selector.sidebar'
}

const Sidebar = () => (
  <div className="schedule-app-sidebar">
    <div className="ibox">
      <div className="ibox-title">
        <h5>{I18n.t('selected_shifts_header', i18noptions)}</h5>
      </div>
      <SelectedShiftsList />
    </div>
    <div className="ibox">
      <div className="ibox-title">
        <h5>{I18n.t('preferred_shifts_header', i18noptions)}</h5>
        <p>{I18n.t('preferred_shifts_description', i18noptions)}</p>
      </div>
      <PreferredShiftsList />
    </div>
  </div>
)

export default Sidebar
