import { createSelector } from 'redux-orm'
import { orm } from '../../../../store'

const ormSelector = state => state.orm

const getSelectedSchedules = createSelector(
  orm,
  ormSelector,
  session => session.Schedule.all()
    .toModelArray()
    .filter(schedule => schedule.shifts.filter(shift => shift.selected).count() > 0)
    .map(schedule => ({
      ...schedule.ref,
      shifts: schedule.shifts.toRefArray().map(shift => shift.id),
      bundles: schedule.bundles.toRefArray(),
      grades: schedule.grades.toRefArray()
    }))
)

export default getSelectedSchedules
