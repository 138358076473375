import MemberTypeApi from '../apis/MemberTypeApi'
import scheduleSelector from '../selectors/schedules/scheduleSelector'
import memberTypeActions from '../../../actions/memberTypeActions'

const {
  requestMemberTypes,
  receiveMemberTypes
} = memberTypeActions

export const fetchMemberTypes = () => (dispatch, getState) => {
  dispatch(requestMemberTypes())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new MemberTypeApi(scheduleId)

  return api.fetchAll()
    .then(response => dispatch(receiveMemberTypes(response.data)))
}
