/* global Routes */

import React from 'react'
import { connect } from 'react-redux'
import { bool, func, arrayOf, object } from 'prop-types'
// import foldableTable from 'react-table/lib/hoc/foldableTable'

import { fetchCheckInReportMembers } from '../actions/member-actions'
import getMembers from '../selectors/members/getMembers'
import { humanizeAttribute, humanizeModel } from '../utilities/i18n'

import DataTable from '../components/DataTable'

// const FoldableTable = foldableTable(DataTable)

class CheckInReportTableWidget extends React.Component {
  componentDidMount() {
    const { onFetchMembers } = this.props

    onFetchMembers()
  }

  render() {
    const {
      ticketItems,
      members,
      loading
    } = this.props

    const ticketItemColumns = ticketItems.map(ticketItem => ({
      Header: ticketItem.attributes.name,
      id: ticketItem.id,
      accessor: member => member.checkIns[ticketItem.id] || 0,
      filterable: false,
      foldable: true
    }))

    return (
      <DataTable
        data={members}
        loading={loading}
        showPagination={false}
        pageSize={members.length}
        defaultSorted={[{
          id: 'name',
          desc: false
        }]}
        columns={[
          {
            Header: humanizeAttribute('member', 'name'),
            accessor: 'name',
            filterable: true,
            foldable: true,
            Cell: row => (
              <a href={Routes.member_path(row.original.id)}>
                {row.value}
              </a>
            )
          },
          {
            Header: humanizeAttribute('user', 'email'),
            accessor: 'email',
            filterable: true,
            foldable: true
          },
          {
            Header: humanizeModel('ticket', true),
            accessor: 'totalCheckIns',
            filterable: false,
            foldable: true
          },
          ...ticketItemColumns
        ]}
      />
    )
  }
}

CheckInReportTableWidget.propTypes = {
  ticketItems: arrayOf(object).isRequired,
  members: arrayOf(object).isRequired,
  loading: bool.isRequired,
  onFetchMembers: func.isRequired
}

const mapStateToProps = (state) => {
  const { membersList: loading } = state.loadingStatus

  return {
    members: getMembers(state),
    loading
  }
}

const mapDispatchToProps = dispatch => ({
  onFetchMembers: params => dispatch(fetchCheckInReportMembers(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckInReportTableWidget)
