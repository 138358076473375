import React from 'react'
import classNames from 'classnames'

import FontAwesome from 'react-fontawesome'
import BackgroundWrapper from 'react-big-calendar/lib/BackgroundWrapper'

import { childrenPropTypes } from '../../../../../propTypes'

const ClickableBackgroundWrapper = ({ children }) => {
  const hoverChild = (
    <FontAwesome name="plus" className="hover-content" />
  )

  const resultingChildren = React.cloneElement(children, {
    children: hoverChild,
    className: classNames(
      children.props.className,
      'background-wrapper'
    )
  })

  return (
    <BackgroundWrapper>
      {resultingChildren}
    </BackgroundWrapper>
  )
}

ClickableBackgroundWrapper.propTypes = {
  children: childrenPropTypes
}

ClickableBackgroundWrapper.defaultProps = {
  children: null
}

export default ClickableBackgroundWrapper
