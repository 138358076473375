import {
  getSpotsWithoutRequirement,
  getMatchingRequirements
} from './requirementsChecks'
import findDoubleBookedShiftIds from '../../utilities/findDoubleBookedShiftIds'


export const shiftHasAvailableSpots = (shift, member) => (
  shift.availableSpots > 0 &&
    (getSpotsWithoutRequirement(shift) > 0 || getMatchingRequirements(shift, member).length > 0)
)

export const allShiftsHaveAvailableSpots = (shifts, member) => (
  shifts.every(shift => shiftHasAvailableSpots(shift, member))
)

export const memberHasEnoughAvailableHours = (shiftDuration, currentMember, totalSelectedHours) => {
  if (currentMember.maxShiftHours === null) { return true }

  return totalSelectedHours + shiftDuration <= currentMember.maxShiftHours
}

const countShiftsWithGrade = (gradeId, assignedShifts) => assignedShifts.filter(
  selectedShift => selectedShift.gradeId === gradeId
).length

export const memberCanSelectShiftWithGrade = (shift, assignedShifts) => {
  if (shift.grade.maximumCount === null) { return true }

  const shiftCount = countShiftsWithGrade(shift.grade.id, assignedShifts)

  return shiftCount < shift.grade.maximumCount
}

export const memberCanSelectShiftsWithGrade = (shifts, assignedShifts) => {
  const grades = shifts.filter(shift => shift.grade).map(shift => shift.grade)

  const gradesCount = {}
  grades.forEach(grade => (
    gradesCount[grade.id] = (gradesCount[grade.id] || 0) + 1
  ))

  return grades.every(
    (grade) => {
      const currentShiftsCount = countShiftsWithGrade(grade.id, assignedShifts)
      if (grade.maximumCount === null) { return true }
      return currentShiftsCount + gradesCount[grade.id] <= grade.maximumCount
    }
  )
}

export const memberHasNoDoubledBookedShifts = (assignedShifts, newShifts) => {
  const newShiftIds = newShifts.map(shift => shift.id)
  const doubleBookedShiftIds = findDoubleBookedShiftIds(assignedShifts.concat(newShifts))

  return doubleBookedShiftIds.filter(shiftId => newShiftIds.includes(shiftId)).length === 0
}
