import { createSelector } from 'redux-orm'
import { orm } from '../../../../store'

const ormSelector = state => state.orm

const getSchedules = createSelector(
  orm,
  ormSelector,
  session => session.Schedule.all().toRefArray()
)

export default getSchedules
