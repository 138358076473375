import {
  OPEN_SHIFT_MODAL,
  CLOSE_SHIFT_MODAL
} from '../../../constants/action-types'

const actionReducer = (state = null, action) => {
  switch (action.type) {
    case OPEN_SHIFT_MODAL:
      return action.payload.action
    case CLOSE_SHIFT_MODAL:
      return null
    default:
      return state
  }
}

export default actionReducer
