/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { Nav, NavItem, Modal, Badge, Button } from 'react-bootstrap'

import { fetchShifts, selectShift } from '../actions/shift-actions'
import { selectBundle } from '../actions/bundle-actions'
import { setCurrentTab, selectedRequirement } from '../actions/ui-actions'
import Sidebar from '../components/Sidebar'
import MainView from '../components/MainView'
import RequirementSelector from '../components/RequirementSelector'
import getSelectedShifts from '../selectors/shifts/getSelectedShifts'

class ScheduleApp extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userShiftsCount: PropTypes.number.isRequired
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchShifts())
  }

  render() {
    const i18noptions = {
      scope: 'shift_selector'
    }

    const {
      dispatch,
      currentTab,
      setCurrentTab,
      currentView,
      isSelectingRequirement,
      requirementSelectionType,
      selectingRequirementsForShiftOrBundle,
      requirementsForSelection,
      noRequirementOption,
      onRequirementSelect,
      onCancelRequirementSelection,
      isLoading,
      minShiftHours,
      maxShiftHours
    } = this.props

    const handleTabClick = (tab) => {
      dispatch(setCurrentTab(tab))
    }

    if (isLoading) {
      return (
        <div className="loading_container"><i className="fa fa-spinner fa-spin" /></div>
      )
    }

    return (
      <div className="row schedule-app">
        {/* Mobile layout: */}
        <MediaQuery query="(max-width: 1280px)">
          <div className="mobile-view">
            <div className="col-xs-12">
              <div className="tabs-container">
                <Nav bsStyle="tabs" activeKey={currentTab} onSelect={handleTabClick}>
                  <NavItem eventKey="available-shifts">{I18n.t('available_shifts_header', i18noptions)}</NavItem>
                  <NavItem eventKey="your-shifts">
                    {I18n.t('sidebar_header', i18noptions)}
                    &nbsp;
                    <Badge>
                      {this.props.userShiftsCount}
                    </Badge>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <div className="col-xs-12">
              {currentTab === 'available-shifts' ? <MainView currentView={currentView} /> : <Sidebar minShiftHours={minShiftHours} maxShiftHours={maxShiftHours} />}
            </div>
          </div>
        </MediaQuery>

        {/* Desktop layout: */}
        <MediaQuery query="(min-width: 1280px)">
          <div className="desktop-view">
            <div className="col-md-8">
              <MainView currentView={currentView} />
            </div>
            <div className="col-md-4">
              <Sidebar minShiftHours={minShiftHours} maxShiftHours={maxShiftHours} />
            </div>
          </div>
        </MediaQuery>

        <Modal backdrop="static" show={isSelectingRequirement} onHide={onCancelRequirementSelection}>
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('requirement_selector.header', i18noptions)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {selectingRequirementsForShiftOrBundle && (
              <RequirementSelector
                selectionType={requirementSelectionType}
                onCancel={onCancelRequirementSelection}
                shiftOrBundleId={selectingRequirementsForShiftOrBundle}
                requirements={requirementsForSelection}
                noRequirementOption={noRequirementOption}
                onRequirementSelect={onRequirementSelect}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="white" onClick={onCancelRequirementSelection}>
              {I18n.t('requirement_selector.cancel', i18noptions)}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { currentApp, currentMember } = state

  return {
    ...currentApp.ui,
    minShiftHours: currentMember.minShiftHours,
    maxShiftHours: currentMember.maxShiftHours,
    userShiftsCount: getSelectedShifts(state).length
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentTab,
  onRequirementSelect: (type, shiftOrBundleId, requirementId) => {
    if (type === 'bundle') {
      dispatch(selectBundle(shiftOrBundleId, requirementId))
    } else {
      dispatch(selectShift(shiftOrBundleId, requirementId))
    }

  },
  onCancelRequirementSelection: () => {
    dispatch(selectedRequirement())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleApp)
