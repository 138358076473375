import React from 'react'
import { func, oneOf } from 'prop-types'

import { SketchPicker as ColorPicker } from 'react-color'

export const lightColors = ['#f8c1c7', '#fce0c2', '#ffffba', '#e7f653', '#abe3d8', '#afeaeb', '#acd2ea', '#ffa6ff', '#f9f9f9']
export const normalColors = ['#ed5565', '#f8ac59', '#ffeb3b', '#b4c320', '#1ab394', '#23c6c8', '#1c84c6', '#9c27b0', '#d1dade']
export const darkColors = ['#6c272e', '#714f29', '#b39f00', '#4e5d00', '#0c5244', '#105a5b', '#0d3c5a', '#36004a', '#000000']

class ColorSelect extends React.Component {
  state = {
    displayColorPicker: false
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    
    const { value, onChange, ...otherProps } = this.props
    
    return (
      <div>
        <div style={{ width: '40px', height: '40px', borderRadius: '50%', border: '1px solid #e5e6e7', backgroundColor: value }} onClick={ this.handleClick } />
        { this.state.displayColorPicker ? <div style={{ position: 'absolute', zIndex: '2' }}>
          <div style={{ position: 'fixed', top: '0px', left: '0px', bottom: '0px', right: '0px' }} onClick={ this.handleClose }/>
          <ColorPicker
            color={value || undefined}
            presetColors={[...lightColors, ...normalColors, ...darkColors]}
            width="380px"
            onChangeComplete={onChange}
            disableAlpha={true}
            {...otherProps}
           />
        </div> : null }
      </div>
    )
  }
}

ColorSelect.propTypes = {
  value: oneOf([...lightColors, ...normalColors, ...darkColors]),
  onChange: func
}

ColorSelect.defaultProps = {
  value: null,
  onChange: null
}

export default ColorSelect
