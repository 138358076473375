/* global Reflux, _ */
import CustomFormStore from './custom_form_store';

function getItemById(list, itemId) {
  return _.find(list, function (item) {
    return item.id === itemId;
  });
}

export default Reflux.createStore({
  member_type_properties: [],
  visible_member_type_properties: [],

  init: function () {
    this.listenTo(CustomFormStore, this.updateMemberTypeProperties);
  },

  setMemberTypeProperties: function (member_type_properties) {
    this.member_type_properties = member_type_properties;
    this.updateMemberTypeProperties();
  },

  updateMemberTypeProperties: function () {
    var current_field_ids = CustomFormStore.getCurrentFieldIds();

    this.visible_member_type_properties = _.reject(this.member_type_properties, function (member_type_property) {
      return _.contains(current_field_ids, member_type_property.id);
    });

    this.trigger(this.visible_member_type_properties);
  },

  getHtmlForMemberTypeProperty: function (field_id) {
    var field = getItemById(this.member_type_properties, field_id);
    return field.html;
  },

  getRemainingRequiredMemberTypeProperties: function () {
    return _.filter(this.visible_member_type_properties, function (member_type_property) {
      return member_type_property.required;
    });
  },

  getInitialState: function () {
    return this.visible_member_type_properties;
  }
});
