import { createSelector } from 'reselect'
import { startOfDay, formatISO } from 'date-fns'

import getUnselectedShiftsWithoutBundle from './getUnselectedShiftsWithoutBundle'

const getGroupedShifts = createSelector(
  getUnselectedShiftsWithoutBundle,
  (shifts) => {
    const shiftGroups = shifts.reduce((groups, shift) => {
      const groupKey = formatISO(startOfDay(shift.startTime))

      groups[groupKey] = groups[groupKey] || []
      groups[groupKey].push(shift)

      return groups
    }, {})

    return Object.entries(shiftGroups).sort(([groupKeyA, _A], [groupKeyB, _B]) => (
      groupKeyA.localeCompare(groupKeyB)
    )).reduce((groups, [key, group]) => {
      groups[key] = group.sort((shiftA, shiftB) => (
        shiftA.startTime - shiftB.startTime
      ))

      return groups
    }, {})
  }
)

export default getGroupedShifts
