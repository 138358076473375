import React from 'react'
import { number, func, string, arrayOf, object, bool } from 'prop-types'
import { cloneDeep } from 'lodash'

import { Button, CloseButton } from 'react-bootstrap'

import '../stylesheets/field-collection.scss'

class FieldCollection extends React.PureComponent {
  addField = () => {
    const { fieldData, onChange, onAddField } = this.props

    const fieldCount = fieldData.length
    const newFieldData = cloneDeep(fieldData)
    const newField = {}
    newFieldData[fieldCount] = newField

    if (onChange !== null) {
      onChange(newFieldData, fieldCount)
    }

    if (onAddField !== null) {
      onAddField(newFieldData, newField, fieldCount)
    }
  }

  removeField = (index) => {
    const { fieldData, onChange, onRemoveField, flagForDestruction } = this.props

    const newFieldData = cloneDeep(fieldData)
    const oldField = newFieldData[index]

    if (flagForDestruction && oldField.id !== undefined) {
      oldField._destroy = true // eslint-disable-line no-underscore-dangle
    } else {
      newFieldData.splice(index, 1)
    }

    if (onChange !== null) {
      onChange(newFieldData, index)
    }

    if (onRemoveField !== null) {
      onRemoveField(newFieldData, oldField, index)
    }
  }

  renderFields = () => {
    const { children, fieldData, minimumCount, formInputNamePrefix } = this.props
    const overMinimumCount = fieldData.length > minimumCount

    return fieldData.map((data, index) => (
      /* eslint-disable react/no-array-index-key */
      <fieldset
        className="field"
        key={index}
        // eslint-disable-next-line no-underscore-dangle
        style={{ display: data._destroy ? 'none' : null }}
      >
        <input
          type="hidden"
          name={`${formInputNamePrefix}[${index}][_destroy]`}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={data._destroy}
        />
        <input
          type="hidden"
          name={`${formInputNamePrefix}[${index}][id]`}
          defaultValue={data.id}
        />
        {overMinimumCount && (
          <CloseButton
            onClick={() => this.removeField(index)}
          />
        )}
        {children(data, index)}
      </fieldset>
      /* eslint-enable react/no-array-index-key */
    ))
  }

  renderAddButton = () => {
    const { maximumCount, fieldData, addButtonText, addButtonClasses } = this.props

    // eslint-disable-next-line no-underscore-dangle
    if (maximumCount !== null && fieldData.filter(data => !data._destroy).length >= maximumCount) {
      return null
    }

    return (
      <Button
        className={addButtonClasses}
        bsStyle="link"
        onClick={this.addField}
      >
        {addButtonText}
      </Button>
    )
  }

  render() {
    return (
      <div className="field-collection">
        {this.renderFields()}
        {this.renderAddButton()}
      </div>
    )
  }
}

FieldCollection.propTypes = {
  fieldData: arrayOf(object),
  formInputNamePrefix: string,
  children: func,
  addButtonText: string,
  addButtonClasses: string,
  onChange: func,
  onAddField: func,
  onRemoveField: func,
  minimumCount: number,
  maximumCount: number,
  flagForDestruction: bool
}

FieldCollection.defaultProps = {
  fieldData: [],
  formInputNamePrefix: '',
  children: () => {},
  addButtonText: '+',
  addButtonClasses: 'pull-right',
  onChange: null,
  onAddField: null,
  onRemoveField: null,
  minimumCount: 0,
  maximumCount: null,
  flagForDestruction: false
}

export default FieldCollection
