import { shape, string, number, bool } from 'prop-types'

import memberPropTypes from './memberPropTypes'

const attendancePropTypes = shape({
  id: string.isRequired,
  leader: bool.isRequired,
  member: memberPropTypes
})

export default attendancePropTypes
