/* global React */

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    var items = this.props.ticketItems.map(function (item) {
      return (
        <li key={item.name}>
          {item.name} x {item.amount}
        </li>
      )
    });

    return (
      <div>
        <ul>{items}</ul>
      </div>
    );
  }
})
