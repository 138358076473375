import { createSelector } from 'redux-orm'
import { format } from 'date-fns'


import { orm } from '../../store'

const dbStateSelector = state => state.orm

const getShifts = createSelector(
  orm,
  dbStateSelector,
  session => session.Shift.all().toModelArray().map((shift) => {
    const { startTime, endTime } = shift

    const formattedStartTime = format(startTime, 'dd.MM.yyyy - HH:mm')
    const formattedEndTime = format(endTime, 'dd.MM.yyyy - HH:mm')

    return {
      ...shift.ref,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      team: shift.team.ref,
      schedule: shift.schedule.ref
    }
  })
)

export default getShifts
