/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormControl, HelpBlock } from 'react-bootstrap'
import { Checkbox } from 'react-icheck'

class PriceInput extends React.Component {
  static roundToTwoDecimals(value) {
    return Math.round(value * 100) / 100
  }

  static checkForMinimumAndMaximum(amount, minimum, maximum) {
    if (amount < minimum) {
      amount = minimum
    } else if (maximum !== undefined && amount > maximum) {
      amount = maximum
    }

    return amount
  }

  static calculateFee(amount, feeOptions) {
    const { percent, flat, minimum, maximum } = feeOptions
    let fee = 0.0

    if (flat !== undefined) {
      fee += flat
    }

    if (percent !== undefined) {
      fee += amount * percent
    }

    fee = PriceInput.checkForMinimumAndMaximum(fee, minimum, maximum)

    return fee
  }

  static calculateCharge(amount, feeOptions) {
    let { percent, flat, minimum } = feeOptions
    const { maximum } = feeOptions

    percent = percent || 0.0
    flat = flat || 0.0
    minimum = minimum || 0.0

    let totalFee = ((amount * percent) + flat) / (1 - percent)
    totalFee = PriceInput.checkForMinimumAndMaximum(totalFee, minimum, maximum)
    const charge = amount + totalFee

    return charge
  }

  static feeDescription(feeOptions) {
    const result = []

    if (feeOptions.percent) {
      result.push(I18n.numberToPercentage(feeOptions.percent * 100, { precision: 2 }))
    }

    if (feeOptions.flat) {
      result.push(`${I18n.formatNumber(feeOptions.flat, { precision: 2 })} kr.`)
    }

    return result.join(' + ')
  }

  constructor(props) {
    super(props)

    const currentValue = parseFloat(props.value) || 0.0

    this.state = {
      value: currentValue.toFixed(2),
      included: true,
      activated: currentValue > 0.0
    }
  }

  feeCalculations(amount) {
    const { minimumCharge } = this.props
    const { vatRate, paymentFee, applicationFee } = this.props
    const { included } = this.state
    let vat = 0.0
    let charge

    if (included) {
      charge = amount
    } else {
      charge = PriceInput.calculateCharge(amount, applicationFee)
    }
    charge = Math.max(charge, minimumCharge)

    let applicationFeeAmount = PriceInput.calculateFee(charge, applicationFee)
    const paymentFeeAmount = PriceInput.calculateFee(charge, paymentFee)

    if (vatRate !== undefined) {
      vat = applicationFeeAmount * vatRate
      applicationFeeAmount += vat
    }

    const earnings = charge - applicationFeeAmount
    return ({
      charge: PriceInput.roundToTwoDecimals(charge),
      vatAmount: vat,
      earnings: PriceInput.roundToTwoDecimals(earnings),
      paymentFeeAmount,
      applicationFeeAmount
    })
  }

  feeDescriptions() {
    const { vatRate, paymentFee, applicationFee } = this.props

    return ({
      vatDescription: PriceInput.feeDescription({ percent: vatRate }),
      paymentFeeDescription: PriceInput.feeDescription(paymentFee),
      applicationFeeDescription: PriceInput.feeDescription(applicationFee)
    })
  }

  handleChangeAmount(event) {
    this.setState({ value: event.target.value })
  }

  handleBlurAmount() {
    this.sanitizeValue()
  }

  sanitizeValue() {
    const { included, value } = this.state
    let amount = parseFloat(value) || 0.0
    const { charge, earnings } = this.feeCalculations(amount)

    if (included && amount < charge) {
      amount = charge
    } else if (!included && amount < earnings) {
      amount = earnings
    }

    this.setState({ value: amount.toFixed(2) })
  }

  handleChangeIncluded(event) {
    this.setState({ included: event.target.checked })
  }

  handleChangeActivated(event) {
    this.setState({ activated: event.target.checked })
  }

  render() {
    const { value, included, activated } = this.state
    const { inputProps, paymentProviderLink, minimumCharge } = this.props
    const amount = parseFloat(value)
    const {
      charge,
      applicationFeeAmount,
      earnings,
      paymentFeeAmount
    } = this.feeCalculations(amount)
    const { applicationFeeDescription, paymentFeeDescription } = this.feeDescriptions()
    const helpMessage = {
      __html: I18n.t('components.price_input.total_fee_html', {
        price: I18n.formatNumber(charge, { precision: 2 }),
        application_fee: I18n.formatNumber(applicationFeeAmount, { precision: 2 }),
        application_fee_description: applicationFeeDescription,
        earnings: I18n.formatNumber(earnings, { precision: 2 }),
        payment_fee: I18n.formatNumber(paymentFeeAmount, { precision: 2 }),
        payment_fee_description: paymentFeeDescription,
        payment_provider_link: paymentProviderLink
      })
    }

    let formValue
    if (!activated || !value) {
      formValue = '0.00'
    } else if (included) {
      formValue = value
    } else {
      formValue = charge.toFixed(2)
    }

    return (
      <FormGroup>
        <div className="checkbox">
          <label htmlFor="payment_fee_activated_checkbox" className="boolean icheck-label">
            <Checkbox
              id="payment_fee_activated_checkbox"
              key="activated"
              defaultChecked={activated}
              checkboxClass="icheckbox_square-green icheck-item"
              onChange={e => this.handleChangeActivated(e)}
            />
            {I18n.t('components.price_input.has_price')}
          </label>
        </div>

        <FormControl
          name={inputProps.name}
          value={formValue}
          key="formValue"
          style={{ display: 'none' }}
        />

        {activated &&
          <div>
            <FormControl
              {...inputProps}
              key="formInput"
              name={null}
              type="number"
              step="0.01"
              min={included ? minimumCharge : 0}
              value={value}
              onChange={e => this.handleChangeAmount(e)}
              onBlur={e => this.handleBlurAmount(e)}
            />

            <div className="checkbox">
              <label htmlFor="payment_fee_included_checkbox" className="boolean icheck-label">
                <Checkbox
                  id="payment_fee_included_checkbox"
                  key="included"
                  defaultChecked={included}
                  checkboxClass="icheckbox_square-green icheck-item"
                  onChange={e => this.handleChangeIncluded(e)}
                />
                {I18n.t('components.price_input.include_fees')}
              </label>
            </div>

            <HelpBlock dangerouslySetInnerHTML={helpMessage} />
          </div>
        }
      </FormGroup>
    )
  }
}

PriceInput.defaultProps = {
  value: 0.0,
  inputProps: {},
  vatRate: 0.0,
  minimumCharge: 0.0
}

PriceInput.propTypes = {
  value: PropTypes.string,
  inputProps: PropTypes.shape({
    name: PropTypes.string
  }),
  vatRate: PropTypes.number,
  paymentProviderLink: PropTypes.string.isRequired,
  minimumCharge: PropTypes.number,
  applicationFee: PropTypes.shape({
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    percent: PropTypes.number,
    flat: PropTypes.number
  }).isRequired,
  paymentFee: PropTypes.shape({
    percent: PropTypes.number,
    flat: PropTypes.number
  }).isRequired
}

export default PriceInput
