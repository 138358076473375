/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import {
  FormGroup, FormControl, ControlLabel, HelpBlock
} from 'react-bootstrap'

import { FieldGroup } from '../components/formExtensions'

import { parameterize } from '../utilities/inflectors'

class SubdomainFieldWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.defaultValue || ''
    }
  }

  handleChangeName = (event) => {
    const value = event.target.value

    this.setState({
      name: value
    })
  }

  render() {
    const { name } = this.state
    const { errors } = this.props
    const placeholderTranslation = key => I18n.t(`public_scope.users.registrations.new.placeholders.${key}`)
    const labelTranslation = key => I18n.t(`activemodel.attributes.public_scope/signup_form.${key}`)
    const subdomain = parameterize(name || placeholderTranslation('organizer_name'))

    return (
      <div>
        <FieldGroup
          label={labelTranslation('organizer_name')}
          placeholder={placeholderTranslation('organizer_name')}
          value={name}
          name="user[organizer_name]"
          onChange={this.handleChangeName}
          errors={errors}
          help={
            <div>
              {`${labelTranslation('organizer_subdomain')}: `}
              <strong>
                {subdomain}.heap360.com
              </strong>
            </div>
          }
          autoFocus
          required
        />
        <input
          name="user[organizer_subdomain]"
          type="hidden"
          value={subdomain}
        />
      </div>
    )
  }
}

SubdomainFieldWidget.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string
}

SubdomainFieldWidget.defaultProps = {
  errors: [],
  defaultValue: ''
}

export default SubdomainFieldWidget
