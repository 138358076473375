import { connect } from 'react-redux'

import memberTypesSelector from '../../../../selectors/memberTypes/memberTypesSelector'
import propertiesByIdSelector from '../../../../selectors/properties/propertiesByIdSelector'

import Tab from './Tab'

const mapStateToProps = (state) => {
  const propertiesById = propertiesByIdSelector(state)
  const memberTypes = memberTypesSelector(state)

  return {
    memberTypes,
    propertiesById
  }
}

export default connect(
  mapStateToProps
)(Tab)
