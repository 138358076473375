import { parse, getHours, getMinutes, set } from 'date-fns'

const parseDateFromValue = (oldTime, value) => {
  let newTime = oldTime
  newTime = parse(value, 'dd/MM/yyyy', newTime)
  newTime = set(
    newTime, {
      hours: getHours(oldTime),
      minutes: getMinutes(oldTime),
      seconds: 0,
      milliseconds: 0
    }
  )

  if (isNaN(newTime.getTime())) return oldTime

  return newTime
}

export default parseDateFromValue
