/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Clearfix } from 'react-bootstrap'

import { teamPropTypes } from './propTypes'

import TeamList from './components/TeamList'

import './styles.scss'

class TeamSelect extends Component {
  handleChangeTeam = (teamId) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(teamId)
    }
  }

  render() {
    const { teams, value } = this.props

    return (
      <div>
        <TeamList
          teams={teams}
          onChange={this.handleChangeTeam}
          value={value}
        />

        <Clearfix>
          <input type="submit" value={I18n.t('shared.continue')} className="btn btn-primary" />
        </Clearfix>
      </div>
    )
  }
}

TeamSelect.propTypes = {
  teams: PropTypes.arrayOf(teamPropTypes).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.number
}

TeamSelect.defaultProps = {
  onChange: null,
  value: null
}

export default TeamSelect
