/* global Reflux */

import Actions from './Actions';

export default Reflux.createStore({
  listenables: [Actions],

  owners: { loaded: false },

  searchTimeout: null,

  isBarcodeString: function (query) {
    return /\d{8}/.test(query);
  },

  setSearchURL: function (url) {
    this.url = url;
  },

  onUpdateQuery: function (query) {
    clearTimeout(this.searchTimeout);

    if (query.length < 3 || this.isBarcodeString(query)) {
      this.resetList();
    } else {
      var self = this;
      this.searchTimeout = setTimeout(function () { self.doSearch(query, 5); }, 500);
    }
  },

  onForceSearch: function (query) {
    clearTimeout(this.searchTimeout);

    if (query.length < 3 || this.isBarcodeString(query)) return;

    this.resetList();
    this.doSearch(query, 100);

    Actions.showExtendedModal();
  },

  onResetOwnersList: function () {
    this.resetList();
  },

  resetList: function () {
    this.owners = { loaded: false };
    this.trigger(this.owners);
  },

  currentXHR: null,

  doSearch: function (query, limit) {
    limit = limit || 10;

    if (this.currentXHR) {
      this.currentXHR.abort();
      this.currentXHR = null;
    }

    this.currentXHR = $.ajax({
      url: this.url,
      dataType: 'json',
      data: {
        query: query,
        limit: limit
      },
      cache: false,
      success: function (data) {
        this.currentXHR = null;
        this.owners = {
          loaded: true,
          totalCount: data.total_count,
          list: data.list
        };
        this.trigger(this.owners);
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  },

  getInitialState: function () {
    return this.owners;
  }
});
