import { string, number, arrayOf, shape } from 'prop-types'

import propertyPropTypes from './propertyPropTypes'

const memberTypePropTypes = shape({
  id: string.isRequired,
  name: string.isRequired,
  minimumShiftHours: number,
  maximumShiftHours: number,
  properties: arrayOf(propertyPropTypes)
})

export default memberTypePropTypes
