// Filters
export const SET_INELIGIBILITY_FILTER = 'SHIFT_SELECTOR_SET_INELIGIBILITY_FILTER'
export const SET_TEXT_FILTER = 'SHIFT_SELECTOR_SET_TEXT_FILTER'
export const SET_SCHEDULE_FILTER = 'SHIFT_SELCETOR_SET_SCHEDULE_FILTER'
export const SET_GRADE_FILTER = 'SHIFT_SELCETOR_SET_GRADE_FILTER'

// UI
export const SET_CURRENT_TAB = 'SHIFT_SELECTOR_SET_CURRENT_TAB'
export const SET_CURRENT_VIEW = 'SHIFT_SELECTOR_SET_CURRENT_VIEW'
export const SELECTING_REQUIREMENT = 'SHIFT_SELECTOR_SELECTING_REQUIREMENT'
export const SELECTED_REQUIREMENT = 'SHIFT_SELECTOR_SELECTED_REQUIREMENT'
