import {
  SET_INCLUDE_EXTERNAL_SHIFTS
} from '../../../constants/action-types'

const includeExternalShiftsReducer = (state = false, action) => {
  switch (action.type) {
    case SET_INCLUDE_EXTERNAL_SHIFTS:
      return action.payload
    default:
      return state
  }
}

export default includeExternalShiftsReducer
