/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { differenceInMinutes, isEqual, startOfDay, isBefore, isAfter, addMinutes } from 'date-fns'

import BaseShiftModalForm from './BaseShiftModalForm'
import DateTimeInput from '../../../../../components/DateTimeInput'
import FieldCollection from '../../../../../components/FieldCollection'

import { newShiftPropTypes } from '../../../../propTypes/shiftPropTypes'

import parseTimeFromValue from './parseTimeFromValue'
import parseDateFromValue from './parseDateFromValue'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form'
}

class NewShiftModalForm extends React.Component {
  state = {
    hasChangedTime: {
      startTime: false,
      endTime: false
    }
  }

  componentWillMount() {
    const { shift } = this.props
    const { times } = shift
    const { startTime, endTime } = times[0]

    const isStartOfDay = time => isEqual(time, startOfDay(time))

    if (!isStartOfDay(startTime) || !isStartOfDay(endTime)) {
      this.setState({
        hasChangedTime: {
          startTime: true,
          endTime: true
        }
      })
    }
  }

  handleTimesChange = (name, index, newValue) => {
    const { onChange, shift } = this.props
    const newArray = cloneDeep(shift.times)
    const newEntry = newArray[index]


    if ((name === 'startTime' && isBefore(newEntry.endTime, newValue))
      || (name === 'endTime' && isAfter(newEntry.startTime, newValue))
    ) {
      newArray[index] = {
        startTime: newValue,
        endTime: newValue
      }
    } else {
      newArray[index] = {
        ...newEntry,
        [name]: newValue
      }
    }

    onChange({
      times: newArray
    })
  }

  handleDateChange = (event, index) => {
    const { shift } = this.props
    const { times } = shift
    const { value, name: fullName } = event.target
    const name = fullName.replace('[date]', '')
    const oldTime = times[index][name]
    const newTime = parseDateFromValue(oldTime, value)

    this.handleTimesChange(name, index, newTime)
  }

  handleTimeChange = (event, index) => {
    const { hasChangedTime } = this.state
    const { shift } = this.props
    const { times } = shift
    const { value, name: fullName } = event.target
    const name = fullName.replace('[time]', '')

    if (hasChangedTime[name]) {
      const oldTime = times[index][name]
      const newTime = parseTimeFromValue(oldTime, value)
      this.handleTimesChange(name, index, newTime)
    } else {
      const { onChange } = this.props

      const newTimes = times.map((time) => {
        return {
          ...time,
          [name]: parseTimeFromValue(time[name], value)
        }
      })

      onChange({
        times: newTimes
      })

      this.setState({
        hasChangedTime: {
          ...hasChangedTime,
          [name]: true
        }
      })
    }
  }

  handleSave = (shift) => {
    const { onCreate } = this.props
    const { times, ...attributes } = shift

    const shifts = times.map(({ startTime, endTime }) => (
      Object.assign({}, attributes, {
        startTime,
        endTime
      })
    ))

    onCreate(shifts)
  }

  handleAddTime = (newTimes, newTime) => {
    const { onChange } = this.props
    const timesCount = newTimes.length - 1

    const lastTimeEntry = newTimes[timesCount - 1]
    const lastStartTimeEntry = lastTimeEntry ? lastTimeEntry.startTime : null
    const lastEndTimeEntry = lastTimeEntry ? lastTimeEntry.endTime : null

    const getTimeDiff = () => {
      const secondLastTimeEntry = newTimes[timesCount - 2]
      const secondLastStartTimeEntry = secondLastTimeEntry ? secondLastTimeEntry.startTime : null

      if (lastEndTimeEntry === null || lastStartTimeEntry === null) {
        return null
      }

      if (timesCount >= 2 && secondLastStartTimeEntry !== null) {
        return differenceInMinutes(lastStartTimeEntry, secondLastStartTimeEntry)
      } else if (timesCount >= 1) {
        return 60 * 24
      }

      return null
    }

    const timeDiff = getTimeDiff()
    if (timeDiff !== null) {
      newTime.startTime = addMinutes(lastStartTimeEntry, timeDiff)
      newTime.endTime = addMinutes(lastEndTimeEntry, timeDiff)
    } else {
      newTime.startTime = null
      newTime.endTime = null
    }

    onChange({
      times: newTimes
    })
  }

  handleRemoveTime = (newTimes) => {
    const { onChange } = this.props

    onChange({
      times: newTimes
    })
  }

  renderTimeInputs = (name, value, index) => {
    const { hasChangedTime } = this.state
    const { shift, validationErrors } = this.props
    const { id } = shift

    return (
      <DateTimeInput
        id={`shift-${id}-${name}_${index}`}
        model="shift"
        name={name}
        value={value}
        timeValue={hasChangedTime[name] ? null : ''}
        onDateChange={event => this.handleDateChange(event, index)}
        onTimeChange={event => this.handleTimeChange(event, index)}
        errors={validationErrors !== null ? validationErrors[index][name] : null}
        withIcon
      />
    )
  }

  render() {
    const {
      shift,
      isCopying,
      validationErrors,
      onCreate,
      ...otherProps
    } = this.props
    const { times } = shift

    let modelName
    if (times.length > 1) {
      modelName = I18n.t('activerecord.models.shift.other')
    } else {
      modelName = I18n.t('activerecord.models.shift.one')
    }

    const renderTimeInputs = this.renderTimeInputs

    return (
      <BaseShiftModalForm
        {...otherProps}
        modelName={modelName}
        onSave={this.handleSave}
        validationErrors={validationErrors !== null ? validationErrors[0] : null}
        title={isCopying ? I18n.t('copy_shift', i18noptions) : null}
        shift={shift}
        newRecord
      >
        <FieldCollection
          fieldData={times}
          addButtonText={I18n.t('add_shift', i18noptions)}
          onAddField={this.handleAddTime}
          onRemoveField={this.handleRemoveTime}
        >
          {(data, index) => (
            <div>
              {renderTimeInputs('startTime', data.startTime, index)}
              {renderTimeInputs('endTime', data.endTime, index)}
            </div>
          )}
        </FieldCollection>
      </BaseShiftModalForm>
    )
  }
}


NewShiftModalForm.propTypes = {
  shift: newShiftPropTypes.isRequired,
  onCreate: PropTypes.func.isRequired,
  isCopying: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.string
      )
    )
  )
}

NewShiftModalForm.defaultProps = {
  validationErrors: null
}

export default NewShiftModalForm
