/* global Routes, I18n */

import React from 'react'
import { string, bool, number, func, arrayOf, object } from 'prop-types'
import { DropdownButton, MenuItem } from 'react-bootstrap'

import { humanizeAttribute } from '../../utilities/i18n'

import { PersistableServerSideDataTable } from '../../components/DataTable'
import PopoverIcon from '../../components/PopoverIcon'
import { ModelLink } from '../../components/modelComponents'
import DeleteLinkPromptWidget from '../DeleteLinkPromptWidget'


const i18noptions = {
  scope: 'components.team_table_widget'
}

const TeamTable = ({ stateKey, teams, handleFetchData, loading, pages }) => (
  <PersistableServerSideDataTable
    stateKey={stateKey}
    onFetchData={handleFetchData}
    data={teams}
    loading={loading}
    pages={pages}
    defaultSorted={[{
      id: 'name',
      desc: false
    }]}
    columns={[
      {
        Header: humanizeAttribute('team', 'name'),
        filterable: true,
        accessor: 'name',
        Cell: row => (
          <a href={Routes.team_path(row.original.id)}>
            {row.value}
          </a>
        )
      },
      {
        Header: humanizeAttribute('team', 'group'),
        filterable: true,
        accessor: 'group.name'
      },
      {
        Header: humanizeAttribute('team', 'signup_status'),
        accessor: 'signupStatus'
      },
      {
        id: 'memberLimit',
        Header: humanizeAttribute('team', 'member_limit'),
        sortable: false,
        accessor: ({ nonLeadersCount, memberLimitText }) => (
          `${nonLeadersCount} / ${memberLimitText}`
        ),
        Cell: (row) => {
          const faClass = row.original.spotsUsedRatio >= 1 ?
            'navy' : 'danger'
          const memberTypeLimits = row.original.memberTypeLimits
          return (
            <div>
              <i className={`fa fa-circle text-${faClass}`} />
              {` ${row.value} `}

              {memberTypeLimits && (
                <PopoverIcon
                  icon="question-circle"
                  content={Object.entries(memberTypeLimits).map(([memberType, limitData]) => (
                    <div>
                      <i className={`fa fa-circle text-${limitData.count >= limitData.limit ? 'navy' : 'danger'}`} />
                      {` ${memberType}: ${limitData.count} / ${limitData.limit}`}
                    </div>
                  ))}
                />
              )}
            </div>
          )
        }
      },
      {
        Header: humanizeAttribute('team', 'created_at'),
        accessor: 'createdAt'
      },
      {
        Header: I18n.t('actions', i18noptions),
        accessor: 'id',
        sortable: false,
        maxWidth: 200,
        Cell: row => (
          <div className="action-wrapper">
            <DropdownButton
              bsStyle="white"
              bsSize="xs"
              title={I18n.t('actions', i18noptions)}
            >
              <ModelLink
                action="edit"
                model="Team"
                id={row.value}
                component={MenuItem}
                eventKey="2"
                modal
              />
              <ModelLink
                action="delete"
                model="Team"
                id={row.value}
                eventKey="3"
                component={({ href, children, ['data-method']: _method, ...menuItemProps }) => (
                  <MenuItem {...menuItemProps}>
                    <DeleteLinkPromptWidget
                      linkText={children}
                      path={href}
                      menuItem
                    />
                  </MenuItem>
                )}
              />
            </DropdownButton>
          </div>
        )
      }
    ]}
  />
)

TeamTable.propTypes = {
  stateKey: string.isRequired,
  teams: arrayOf(object).isRequired,
  pages: number,
  loading: bool.isRequired,
  handleFetchData: func.isRequired
}

TeamTable.defaultProps = {
  pages: null
}

export default TeamTable
