import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import { FormControl } from 'react-bootstrap'

const FormControlNested = ({ className, ...formControlProps }) => (
  <FormControl
    className={classNames(
      'form-control',
      'form-control-nested',
      className
    )}
    {...formControlProps}
  />
)

FormControlNested.propTypes = {
  className: string
}

FormControlNested.defaultProps = {
  className: null
}

export default FormControlNested
