/* global React */

import Actions from './Actions';

import createReactClass from 'create-react-class';

export default createReactClass({
  handleClick: function () {
    Actions.updateQuery(this.props.barcode);
    Actions.resetOwnersList();
    Actions.dismissExtendedModal();
  },

  render: function () {
    return (
      <tr style={{ cursor: 'pointer' }}
        key={this.props.barcode}
        onClick={this.handleClick}>
        <td dangerouslySetInnerHTML={{ __html: this.props.name }}></td>
        <td>{this.props.email}</td>
        <td>{this.props.barcode}</td>
      </tr>
    );
  }
})
