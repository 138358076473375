import { RECEIVE_CURRENT_EVENT_OR_ORGANIZATION } from '../constants/action-types'

const currentEventOrOrganizationReducer = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_EVENT_OR_ORGANIZATION:
      return action.payload
    default:
      return state
  }
}

export default currentEventOrOrganizationReducer
