/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, FormGroup, FormControl, ControlLabel, HelpBlock, Checkbox } from 'react-bootstrap'
import fetch from 'isomorphic-fetch'
import PubSub from 'pubsub-js'

import TagsInput from '../../components/TagsInput'

const i18noptions = {
  scope: 'components.team_invite_links_widget'
}

class SendLinkForm extends Component {
  constructor(props) {
    super(props)

    const { team } = props

    this.state = {
      sendingLink: false,
      emails: [],
      message: I18n.t('default_message', { ...i18noptions, team }),
      asTeamlead: false,
      errorMessages: {}
    }
  }

  handleChangeEmail = (email) => {
    const { errorMessages } = this.state
    this.setState({
      email,
      errorMessages: {
        ...errorMessages,
        email: null
      }
    })
  }

  handleChangeEmails = (emails) => {
    this.setState({ emails })
  }

  handleChangeMessage = (event) => {
    const { errorMessages } = this.state
    this.setState({
      message: event.target.value,
      errorMessages: {
        ...errorMessages,
        message: null
      }
    })
  }

  handleChangeAsTeamlead = (event) => {
    this.setState({ asTeamlead: event.target.checked })
  }

  handleCancel = (event) => {
    this.setState({ message: event.target.value })
  }

  handleSubmit = () => {
    const { sendingLink, emails, message, asTeamlead } = this.state
    if (sendingLink) return

    const { sendEmailPath, onComplete, memberType: { id: memberTypeId } } = this.props

    this.setState({ sendingLink: true })

    const body = {
      member_type_id: memberTypeId,
      emails,
      message,
      as_teamlead: asTeamlead
    }

    fetch(sendEmailPath, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
        Accept: 'text/javascript, application/json'
      },
      body: JSON.stringify({ invite_link_email: body })
    }).then((response) => {
      this.setState({ sendingLink: false })

      response.json().then((json) => {
        const { notifications, errors } = json

        if (notifications) {
          notifications.map(flashMessage => PubSub.publish('NOTIFICATION', flashMessage))
        }

        if (response.ok) {
          onComplete()
        } else if (errors) {
          this.setState({ errorMessages: errors })
        }
      })
    })
  }

  render() {
    const { sendingLink, emails, message, errorMessages, asTeamlead } = this.state
    const { team, onComplete, memberType: { name: memberTypeName } } = this.props

    const { emails: emailError, message: messageError } = errorMessages

    return (
      <div>
        <Modal.Body>
          <h3>{memberTypeName}</h3>
          <form>
            <FormGroup validationState={emailError ? 'error' : null}>
              <ControlLabel>E-mail</ControlLabel>
              <TagsInput
                value={emails}
                onChange={this.handleChangeEmails}
                placeholder={I18n.t('default_email', i18noptions)}
                validationRegex={/^[^@\s]+@[^@\s]+$/}
                onlyUnique
              />
              {emailError &&
                <HelpBlock>{emailError[0]}</HelpBlock>
              }
            </FormGroup>
            <FormGroup>
              <Checkbox
                checked={asTeamlead}
                onChange={this.handleChangeAsTeamlead}
              >
                {I18n.t('as_teamlead', i18noptions)}
              </Checkbox>
            </FormGroup>
            <FormGroup validationState={messageError ? 'error' : null}>
              <ControlLabel>{I18n.t('message', i18noptions)}</ControlLabel>
              <FormControl
                componentClass="textarea"
                rows="5"
                value={message}
                placeholder={I18n.t('default_message', { ...i18noptions, team })}
                onChange={this.handleChangeMessage}
                required
              />
              {messageError &&
                <HelpBlock>{messageError[0]}</HelpBlock>
              }
            </FormGroup>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            bsStyle="default"
            className="pull-left"
            disabled={sendingLink}
            onClick={onComplete}
          >
            {I18n.t('cancel', i18noptions)}
          </Button>

          <Button
            type="submit"
            bsStyle="primary"
            disabled={sendingLink}
            onClick={this.handleSubmit}
          >
            {sendingLink ? I18n.t('sending_link', i18noptions) : I18n.t('send_link', i18noptions)}
          </Button>
        </Modal.Footer>
      </div>
    )
  }
}

SendLinkForm.propTypes = {
  memberType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }).isRequired,
  sendEmailPath: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired
}

export default SendLinkForm
