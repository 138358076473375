/* global React, ReactDOM */


import createReactClass from 'create-react-class';

export default createReactClass({

  handleDescriptionChange: function (locale, event) {
    const description = event.target.value;
    this.props.handleDescriptionChange(locale, description)
  },

  render: function () {

    var self = this

    const available_locales = JSON.parse(document.body.dataset.availableLocales)
    const locale_country_mapping = {
      da: "dk",
      en: "gb"
    }


    var description_field_tabs = available_locales.map(function (locale, i) {
      if (i == 0) { var classname = 'active' } else { var classname = '' }
      return (
        <li key={locale} className={classname}>
          <a data-toggle="tab" href={"#tab-" + self.props.page_key + "-" + locale}><span className=
            {"fi fi-" + locale_country_mapping[locale]}></span></a>
        </li>
      )
    })

    var description_field_content = available_locales.map(function (locale, i) {
      if (i == 0) { var classname = 'active' } else { var classname = '' }
      return (
        <div key={'description' + locale} className={"tab-pane " + classname} id={"tab-" + self.props.page_key + "-" + locale}>
          <div className="panel-body">
            <textarea
              onChange={(e) => self.handleDescriptionChange(locale, e)}
              className="form-control"
              value={self.props.page['description_' + locale]}
            ></textarea>
            <p className="help-block">This description will be shown at the top of the page.</p>
          </div>
        </div>
      )
    })

    return (
      <div className="form-group">
        <label>Description</label>
        <div className="tabs-container">
          <ul className="nav nav-tabs">
            {description_field_tabs}
          </ul>
          <div className="tab-content">
            {description_field_content}
          </div>
        </div>
      </div>
    );
  }
})
