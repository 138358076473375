/* global I18n */
import React from 'react'
import { node } from 'prop-types'

import FontAwesome from 'react-fontawesome'

import ShiftCalendarEvent from '../../../../../components/ShiftCalendarEvent'

import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'

const ShiftMonthEvent = ({
  event: shift,
  attendanceMessage,
  ...eventProps
}) => {
  const { name, bundle, hidden, requirements, timeTitle } = shift

  let title = name || ''
  if (bundle) {
    title += ` (${bundle.name})`
  }

  return (
    <ShiftCalendarEvent
      event={shift}
      className="shift-month-event-content"
      infoMessage={timeTitle}
      {...eventProps}
      title={title}
    >
      <div className="shift-event-ekstra-info">
        {bundle && (
          <FontAwesome
            name="cube"
            className="shift-event-icon"
            style={{
              color: bundle.color
            }}
          />
        )}
        {hidden && (
          <FontAwesome
            name="eye-slash"
            className="shift-event-icon"
          />
        )}
        {requirements.length > 0 && (
          <FontAwesome
            name="lock"
            className="shift-event-icon"
          />
        )}
        <span>{attendanceMessage}</span>
      </div>
    </ShiftCalendarEvent>
  )
}

ShiftMonthEvent.defaultProps = {
}

ShiftMonthEvent.propTypes = {
  event: shiftPropTypes.isRequired,
  attendanceMessage: node.isRequired
}

export default ShiftMonthEvent
