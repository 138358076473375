import { createSelector } from 'reselect'
import getVisibleBundles from './getVisibleBundles'

const getTextFilter = state => state.currentApp.filters.textFilter
const getScheduleFilter = state => state.currentApp.filters.scheduleFilter
const getGradeFilter = state => state.currentApp.filters.gradeFilter

const getDatumForBundle = (bundle) => {
  let datum = ''
  if (bundle.name) { datum += bundle.name }
  if (bundle.schedule.location) { datum += bundle.schedule.location }
  if (bundle.schedule.name) { datum += bundle.schedule.name }
  return datum
}

const applyTextFilter = (bundles, textFilter) => {
  const regex = new RegExp(textFilter, 'i')

  return bundles.filter((bundle) => {
    const datum = getDatumForBundle(bundle)
    return datum.search(regex) > -1
  })
}

const hasShiftWithGrade = (bundle, gradeFilter) => (
  bundle.shifts.filter(shift => shift.grade && gradeFilter.includes(shift.grade.id)).length > 0
)

const applyGradeFilter = (bundles, gradeFilter) => (
  bundles.filter(bundle => hasShiftWithGrade(bundle, gradeFilter))
)

const applyScheduleFilter = (bundles, scheduleFilter) => (
  bundles.filter(bundle => scheduleFilter.includes(bundle.schedule.id))
)

const getFilteredBundles = createSelector(
  getVisibleBundles,
  getScheduleFilter,
  getGradeFilter,
  getTextFilter,
  (bundles, scheduleFilter, gradeFilter, textFilter) => {
    if (scheduleFilter.length > 0) {
      bundles = applyScheduleFilter(bundles, scheduleFilter)
    }

    if (gradeFilter.length > 0) {
      bundles = applyGradeFilter(bundles, gradeFilter)
    }

    if (textFilter !== '') {
      bundles = applyTextFilter(bundles, textFilter)
    }

    return bundles
  }
)

export default getFilteredBundles
