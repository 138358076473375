/* global React, I18n */

import createReactClass from 'create-react-class';

export default createReactClass({
  activityClass: function (activity) {
    switch (activity.key) {
      case "ticket.checked_in":
        return "strong text-navy"
      default:
        return null
    }
  },

  render: function () {
    const self = this;

    var historyRows = this.props.ticket.activities.map(function (activity) {
      return (
        <tr key={activity.id} className={self.activityClass(activity)}>
          <td>{activity.date}</td>
          <td>
            {I18n.t('tickets_scanner.history_row.' + activity.key, {
              name: activity.member.name,
              email: activity.member.email,
              reason: activity.reason
            })}
          </td>
        </tr>
      )
    });

    return (
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h5>{I18n.t('tickets_scanner.history_title')}</h5>
        </div>
        <div className="ibox-content">
          <table className="table">
            <tbody>
              {historyRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
})
