import React from 'react'

import FontAwesome from 'react-fontawesome'
import { Overlay, Popover } from 'react-bootstrap'

import PopoverContent from './PopoverContent'
import PopoverTitle from './PopoverTitle'

import memberPropTypes from '../../propTypes/memberPropTypes'

import './styles.scss'

class MemberInfoButton extends React.Component {
  static popoverDelay = 500

  state = {
    content: null,
    delayedShowFunction: null,
    delayedHideFunction: null,
    isLoaded: false,
    isOpen: false
  }

  setOpenStatus(newStatus) {
    this.setState({
      isOpen: newStatus,
      [newStatus ? 'delayedShowFunction' : 'delayedHideFunction']: null
    })
  }

  setTimeout = (functionName, delayedFunction) => {
    const { [functionName]: timeoutFunction } = this.state

    if (timeoutFunction === null) {
      this.setState({
        [functionName]: setTimeout(delayedFunction, MemberInfoButton.popoverDelay)
      })
    }
  }

  clearTimeout = (functionName) => {
    const { [functionName]: timeoutFunction } = this.state

    if (timeoutFunction !== null) {
      clearTimeout(timeoutFunction)

      this.setState({
        [functionName]: null
      })
    }
  }

  fetchMemberInformation = () => {
    this.setState({
      content: '',
      delayedShowFunction: null,
      isLoaded: true,
      isOpen: true
    })
  }

  handleMouseEnterPopover = () => {
    const { isOpen } = this.state

    if (isOpen) {
      this.clearTimeout('delayedHideFunction')
    } else {
      this.setTimeout('delayedShowFunction', () => this.setOpenStatus(true))
    }
  }

  handleMouseLeavePopover = () => {
    const { isOpen } = this.state

    if (isOpen) {
      this.setTimeout('delayedHideFunction', () => this.setOpenStatus(false))
    } else {
      this.clearTimeout('delayedShowFunction')
    }
  }

  handleMouseEnterButton = () => {
    const { isLoaded, isOpen } = this.state
    this.setTimeout()

    if (!isLoaded || !isOpen) {
      this.setTimeout('delayedShowFunction', this.fetchMemberInformation)
    }
  }

  handleMouseLeaveButton = () => {
    const { isLoaded, isOpen } = this.state

    if (!isLoaded || !isOpen) {
      this.clearTimeout('delayedShowFunction')
    }
  }

  handleClick = () => {
    const { isLoaded } = this.state

    if (!isLoaded) {
      this.clearTimeout('delayedShowFunction')
    }

    return true
  }

  renderPopover = () => {
    const { member } = this.props

    return (
      <Popover
        className="member-info-popup"
        title={<PopoverTitle member={member} />}
        onMouseEnter={this.handleMouseEnterPopover}
        onMouseLeave={this.handleMouseLeavePopover}
      >
        <PopoverContent
          member={member}
        />
      </Popover>
    )
  }

  render() {
    const { isOpen } = this.state
    const { member } = this.props
    const { id } = member

    return (
      <span>
        <div className="ibox-tools">
          <a
            onMouseEnter={this.handleMouseEnterButton}
            onMouseLeave={this.handleMouseLeaveButton}
            href={`/members/${id}`}
            onClick={this.handleClick}
            ref={(link) => { this.linkDOMNode = link }}
          >
            <FontAwesome name="info-circle" />
          </a>
        </div>
        <Overlay
          target={this.linkDOMNode}
          show={isOpen}
          placement="right"
          arrowOffsetTop="50%"
        >
          {this.renderPopover()}
        </Overlay>
      </span>
    )
  }
}

MemberInfoButton.propTypes = {
  member: memberPropTypes.isRequired
}

export default MemberInfoButton
