import React from 'react'

import FlashEffect from './FlashEffect'

const Highlight = props => (
  <FlashEffect
    flash={{ backgroundColor: '#ffff99' }}
    transitionAttribute="background-color"
    {...props}
  />
)

export default Highlight
