/* global Reflux */

import Actions from './Actions';

export default Reflux.createStore({
  listenables: [Actions],

  listenPusher: function (channel) {
    var self = this;

    if (window.pusher === undefined) {
      return
    }

    pusher.subscribe(channel).bind('checked_in_count', function (count) {
      self.counter.checked = count;
      self.trigger(self.counter);
    });
  },

  setupCounter: function (checked, total) {
    this.counter = {
      checked: checked,
      total: total
    };

    this.trigger(this.counter);
  },

  getInitialState: function () {
    return this.counter;
  }
});
