/* global moment, Routes, I18n */

import React from 'react'
import { string } from 'prop-types'
import axios from 'axios'

import DataTable from '../components/DataTable'
import BooleanIcon from '../components/BooleanIcon'

const fetchEmails = params => axios.get(Routes.api_email_report_path(), { params })

const i18noptions = {
  scope: 'components.email_report_table_widget'
}

class EmailReportTableWidget extends React.Component {
  state = {
    loading: true,
    emails: []
  }

  componentDidMount() {
    const { email, subject, beginDate } = this.props

    fetchEmails({ email, subject, begin_date: beginDate }).then((response) => {
      this.setState({
        loading: false,
        emails: response.data
      })
    })
  }

  render() {
    const {
      emails,
      loading
    } = this.state

    return (
      <DataTable
        data={emails}
        loading={loading}
        defaultSorted={[{
          id: 'timestamp',
          desc: false
        }]}
        columns={[
          {
            Header: I18n.t('email', i18noptions),
            accessor: 'email',
            filterable: true
          },
          {
            Header: I18n.t('subject', i18noptions),
            accessor: 'subject',
            filterable: true
          },
          {
            Header: I18n.t('timestamp', i18noptions),
            id: 'timestamp',
            filterable: true,
            accessor: email => moment(email.timestamp).format('D. MMMM YYYY H:mm:ss')
          },
          {
            Header: I18n.t('state', i18noptions),
            accessor: 'state',
            filterable: true
          },
          {
            Header: I18n.t('opened', i18noptions),
            accessor: 'opened',
            filterable: true,
            Cell: row => (
              <BooleanIcon value={row.value} />
            )
          }
        ]}
      />
    )
  }
}

EmailReportTableWidget.propTypes = {
  email: string,
  subject: string,
  beginDate: string
}

EmailReportTableWidget.defaultProps = {
  email: '',
  subject: '',
  beginDate: ''
}

export default EmailReportTableWidget
