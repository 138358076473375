import { createSelector } from 'redux-orm'
import { parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { orm } from '../../../../store'

const dbStateSelector = state => state.orm

const getBundles = createSelector(
  orm,
  dbStateSelector,
  session => session.Bundle.filter({ selected: false }).toModelArray().map(bundle => ({
    ...bundle.ref,
    duration: bundle.shifts.toRefArray().reduce((sum, shift) => sum + shift.duration, 0),
    schedule: bundle.schedule.ref,
    shifts: bundle.shifts.toModelArray().map(shift => ({
      ...shift.ref,
      grade: shift.grade && shift.grade.ref,
      startTime: utcToZonedTime(parseISO(shift.ref.startTime), 'UTC'),
      endTime: utcToZonedTime(parseISO(shift.ref.endTime), 'UTC'),
      requirements: shift.requirements.toRefArray(),
      schedule: shift.schedule.ref
    }))
  }))
)

export default getBundles
