import { connect } from 'react-redux'
import getShifts from '../../../../../../selectors/shifts/getShifts'
import ShiftHoursBadge from './components/ShiftHoursBadge'

const mapStateToProps = (state, { schedule }) => {
  const shiftHours = getShifts(state)
    .filter(shift => shift.selected &&
      (schedule === undefined || shift.schedule.id === schedule.id))
    .map(shift => shift.duration)
    .reduce((a, b) => a + b, 0)

  return {
    shiftHours,
    minimumShiftHours: schedule.minimumShiftHours
  }
}

const SelectedShiftsBadge = connect(
  mapStateToProps
)(ShiftHoursBadge)

export default SelectedShiftsBadge
