import {
  RECEIVE_SHIFT_GRADE_ERROR,
  CLOSE_SHIFT_GRADE_MODAL
} from '../../../constants/action-types'

import {
  REQUEST_OBJECT,
  REQUEST_OBJECT_CREATION,
  RECEIVE_OBJECT,
  RECEIVE_OBJECT_DELETION
} from '../../../../../constants/action-types'

const isLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case REQUEST_OBJECT:
    case REQUEST_OBJECT_CREATION:
      if (action.payload.type === 'grades') {
        return true
      }
      return state
    case RECEIVE_SHIFT_GRADE_ERROR:
    case CLOSE_SHIFT_GRADE_MODAL:
      return false
    case RECEIVE_OBJECT:
    case RECEIVE_OBJECT_DELETION:
      if (action.payload.type === 'grades') {
        return false
      }
      return state
    default:
      return state
  }
}

export default isLoadingReducer
