/* global I18n */

import React from 'react'
import { Label } from 'react-bootstrap'

const i18noptions = {
  scope: 'shift_selector.shift_hours_badge'
}

const ShiftHoursBadge = ({ shiftHours, minimumShiftHours, pullRight }) => {
  const bsStyle = (minimumShiftHours && shiftHours < minimumShiftHours) ? 'danger' : 'primary'

  return (
    <div className={pullRight ? 'pull-right' : ''}>
      <Label bsStyle={bsStyle}>
        {I18n.t('shift_duration', { ...i18noptions, count: shiftHours })}
      </Label>
    </div>
  )
}

export default ShiftHoursBadge
