import React from 'react'
import { string, func, arrayOf } from 'prop-types'
import { Radio } from 'react-icheck'

import { FieldGroup } from './formExtensions'

class RadioButtonsCollection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedValue: `${props.defaultCheckedValue}`
    }
  }

  handleChange = (event) => {
    const { onChange } = this.props
    const target = event.target
    const value = target.value

    this.setState({
      checkedValue: value
    })

    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { checkedValue } = this.state
    const {
      model,
      name,
      options,
      onChange: _onChange,
      defaultCheckedValue: _defaultCheckedValue,
      ...fieldGroupProps
    } = this.props

    return (
      <FieldGroup
        model={model}
        name={name}
        {...fieldGroupProps}
      >
        <div>
          {options.map(([optionLabel, optionValue]) => {
            const id = `${model}-${name}-${optionValue}`

            return (
              <span className="radio">
                <label className="icheck-label" htmlFor={id}>
                  <Radio
                    id={id}
                    name={name}
                    radioClass="iradio_square-green icheck-item"
                    value={optionValue}
                    checked={`${optionValue}` === checkedValue}
                    onChange={this.handleChange}
                  />
                  {optionLabel}
                </label>
              </span>
            )
          })}
        </div>
      </FieldGroup>
    )
  }
}

RadioButtonsCollection.propTypes = {
  model: string.isRequired,
  name: string.isRequired,
  options: arrayOf(arrayOf(string.isRequired).isRequired).isRequired,
  defaultCheckedValue: string,
  onChange: func
}

RadioButtonsCollection.defaultProps = {
  defaultCheckedValue: null,
  onChange: null
}

export default RadioButtonsCollection
