import React from 'react'

const IBox = ({ title, tools, children }) => (
  <div className="ibox">
    {title && (
      <div className="ibox-title">
        <h5>{title}</h5>
        {tools &&
          <div className="ibox-tools">
            {tools}
          </div>
        }
      </div>
    )}
    <div className="ibox-content">
      {children}
    </div>
  </div>
)

export default IBox
