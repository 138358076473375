import React from 'react'
import { connect } from 'react-redux'
import { number, element, bool, func, arrayOf } from 'prop-types'

import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'
import { addMembersToShift } from '../../../../actions/member-actions'

import HighlightableShiftWrapper from './HighlightableShiftWrapper'
import DraggableShiftWrapper from './DraggableShiftWrapper'
import DropableShiftWrapper from './DropableShiftWrapper'
import BaseShiftWrapper from './BaseShiftWrapper'

const ShiftEventWrapper = ({ children, event, loading, filled, onDrop, isDraggingMember, selectedMemberIds }) => (
  <DropableShiftWrapper
    shift={event}
    onDrop={onDrop}
    filled={filled}
    isDraggingMember={isDraggingMember}
    selectedMemberIds={selectedMemberIds}
  >
    <HighlightableShiftWrapper loading={loading}>
      <BaseShiftWrapper filled={filled} shift={event} >
        <DraggableShiftWrapper shift={event} >
          {children}
        </DraggableShiftWrapper>
      </BaseShiftWrapper>
    </HighlightableShiftWrapper>
  </DropableShiftWrapper>
)

ShiftEventWrapper.propTypes = {
  event: shiftPropTypes.isRequired,
  children: element.isRequired,
  loading: bool.isRequired,
  filled: bool.isRequired,
  isDraggingMember: bool.isRequired,
  selectedMemberIds: arrayOf(number).isRequired,
  onDrop: func.isRequired
}

const mapStateToProps = (state, props) => {
  const { id, attendanceCount, attendanceLimit } = props.event
  const { loadingStatus, currentApp } = state
  const { ui } = currentApp
  const { membersView } = ui
  const { isDragging: isDraggingMember, selection } = membersView
  const { ids: selectedMemberIds } = selection
  const loading = loadingStatus.shifts.includes(id)
  const filled = !(attendanceLimit > attendanceCount)

  return {
    loading,
    filled,
    isDraggingMember,
    selectedMemberIds
  }
}

const mapDispatchToProps = dispatch => ({
  onDrop: shiftId => dispatch(addMembersToShift(shiftId))
})

export const ShiftEventWrapperTemplate = BaseShiftWrapper
export default connect(mapStateToProps, mapDispatchToProps)(ShiftEventWrapper)
