/* global I18n */

import React from 'react'
import { string, object } from 'prop-types'

const i18noptions = {
  scope: 'components.member_type_property_value.profile_image'
}

const ProfileImage = ({ value, imageOptions, ...imageProps }) => (
  <img
    src={value}
    alt={I18n.t('alt_text', i18noptions)}
    {...imageProps}
  />
)

ProfileImage.propTypes = {
  value: string.isRequired,
  imageOptions: object // eslint-disable-line react/forbid-prop-types
}

ProfileImage.defaultProps = {
  imageOptions: null
}

export default ProfileImage
