/* global React, Reflux, I18n */
import CheckedInStore from './CheckedInStore';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [
    Reflux.connect(CheckedInStore, 'counter')
  ],

  render: function () {
    return (
      <span className="label checked-in">
        {I18n.t('tickets_scanner.checked_in')}: {this.state.counter.checked}/{this.state.counter.total}
      </span>
    );
  }
})
