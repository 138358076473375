/* global I18n */

import React from 'react'
import { func, number } from 'prop-types'

import FontAwesome from 'react-fontawesome'
import { Button } from 'react-bootstrap'

import BaseItem from './BaseItem'

import memberPropTypes from '../../../../../propTypes/memberPropTypes'
import attendancePropTypes from '../../../../../propTypes/attendancePropTypes'
import requirementPropTypes from '../../../../../propTypes/requirementPropTypes'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.modal_form.members'
}

class AssignedItem extends React.Component {
  handleToggleLeader = () => {
    const { attendance, onChange, index } = this.props
    const { leader } = attendance

    onChange(index, {
      leader: !leader
    })
  }

  handleRemoveMember = () => {
    const { onRemove, index } = this.props

    onRemove(index)
  }

  fulfilledRequirementDescription = () => {
    const { requirement } = this.props

    if (requirement === null) {
      return null
    }

    return `${requirement.name} ${requirement.requiredValue}`
  }

  render() {
    const { member, attendance } = this.props
    const { leader } = attendance

    const fulfilledRequirementDescription = this.fulfilledRequirementDescription()

    return (
      <BaseItem
        member={member}
        actionButtons={[
          <Button
            key="leader"
            bsStyle={leader ? 'primary' : 'white'}
            bsSize="xs"
            onClick={this.handleToggleLeader}
            block
          >
            <FontAwesome name="star" />
            {' '}{I18n.t('leader', i18noptions)}
          </Button>,
          <Button
            key="remove"
            bsStyle="danger"
            bsSize="xs"
            onClick={this.handleRemoveMember}
            block
          >
            <FontAwesome name="times" />
            {' '}{I18n.t('remove', { scope: 'components.schedule_planner.shifts.shared.modal_form' })}
          </Button>
        ]}
      >
        {fulfilledRequirementDescription && (
          <div>
            {I18n.t('fulfilled_requirement', i18noptions)}: {fulfilledRequirementDescription}
          </div>
        )}
      </BaseItem>
    )
  }
}

AssignedItem.propTypes = {
  attendance: attendancePropTypes.isRequired,
  member: memberPropTypes.isRequired,
  requirement: requirementPropTypes,
  onChange: func.isRequired,
  onRemove: func.isRequired,
  index: number.isRequired
}

AssignedItem.defaultProps = {
  requirement: null
}

export default AssignedItem
