import {
  SET_CURRENT_TAB,
  SET_CURRENT_VIEW,
  SELECTING_REQUIREMENT,
  SELECTED_REQUIREMENT
} from '../constants/action-types'

import {
  RECEIVE_OBJECTS
} from '../../../constants/action-types'

const defaultState = {
  currentTab: 'available-shifts',
  currentView: 'list',
  isSelectingRequirement: false,
  selectingRequirementsForShiftOrBundle: null,
  requirementsForSelection: null,
  requirementSelectionType: null,
  noRequirementOption: false,
  isLoading: true
}

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.tab
      }
    case SELECTING_REQUIREMENT:
      return {
        ...state,
        isSelectingRequirement: true,
        requirementsForSelection: action.requirements,
        selectingRequirementsForShiftOrBundle: action.shiftOrBundleId,
        requirementSelectionType: action.selectionType,
        noRequirementOption: action.noRequirementOption
      }
    case SELECTED_REQUIREMENT:
      return {
        ...state,
        isSelectingRequirement: false,
        selectingRequirementsForShiftOrBundle: null,
        requirementSelectionType: null,
        requirementsForSelection: null,
        noRequirementOption: false
      }
    case RECEIVE_OBJECTS:
      if (action.payload.type === 'shifts') {
        return {
          ...state,
          isLoading: false
        }
      }

      return state
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.view
      }
    default:
      return state
  }
}

export default uiReducer
