/* global I18n */

import { snakeCase } from 'lodash'

export const humanizeModel = (modelName, plural = false) => (
  I18n.t(`activerecord.models.${snakeCase(modelName)}.${plural ? 'other' : 'one'}`)
)

export const humanizeAttribute = (modelName, attributeName) => (
  I18n.t(`activerecord.attributes.${snakeCase(modelName)}.${snakeCase(attributeName)}`)
)
