import {
  OPEN_SHIFT_GRADE_MODAL,
  CLOSE_SHIFT_GRADE_MODAL,
  CHANGE_CURRENT_SHIFT_GRADE
} from '../../../constants/action-types'

const currentShiftGradeReducer = (state = null, action) => {
  switch (action.type) {
    case OPEN_SHIFT_GRADE_MODAL: {
      return action.payload
    }
    case CHANGE_CURRENT_SHIFT_GRADE:
      return {
        ...state,
        ...action.payload
      }
    case CLOSE_SHIFT_GRADE_MODAL:
      return null
    default:
      return state
  }
}

export default currentShiftGradeReducer
