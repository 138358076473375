import {
  SET_MEMBERS_SORTING
} from '../../../constants/action-types'

import { sortingMethodTypes, sortingOrderTypes } from '../../../constants/sorting-method-types'

const { SORTING_BY_NAME } = sortingMethodTypes
const { ASCENDING_ORDER } = sortingOrderTypes

const defaultState = {
  method: SORTING_BY_NAME,
  order: ASCENDING_ORDER
}

const sortingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MEMBERS_SORTING:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default sortingReducer
