import React from 'react'
import { arrayOf, oneOfType, string } from 'prop-types'

import ReactSelect from 'react-select'
import FontAwesome from 'react-fontawesome'

import 'react-select/dist/react-select.css'
import '../../../../../stylesheets/react-select.scss'

import shiftGradePropTypes from '../../../../propTypes/shiftGradePropTypes'
import shiftBundlePropTypes from '../../../../propTypes/shiftBundlePropTypes'

class ShiftCollectionSelect extends React.Component {
  renderOption = (option) => {
    const { optionsIcon } = this.props

    return (
      <div>
        <FontAwesome name={optionsIcon} style={{ color: option.color }} />
        {' '}<span>{option.label}</span>
      </div>
    )
  }

  render() {
    const { collections, icon, ...selectProps } = this.props

    return (
      <ReactSelect
        valueRenderer={this.renderOption}
        optionRenderer={this.renderOption}
        options={collections ? collections.map(({ id, name, color }) => ({
          value: id,
          label: name,
          color
        })) : []}
        {...selectProps}
      />
    )
  }
}

ShiftCollectionSelect.propTypes = {
  collections: arrayOf(oneOfType([shiftGradePropTypes, shiftBundlePropTypes])).isRequired,
  optionsIcon: string.isRequired
}

export default ShiftCollectionSelect
