import { combineReducers } from 'redux'
import isLoading from './isLoadingReducer'
import isActive from './isActiveReducer'
import validationErrors from './validationErrorsReducer'
import currentShiftGrade from './currentShiftGradeReducer'

const shiftGradeModalFormReducer = combineReducers({
  isLoading,
  isActive,
  validationErrors,
  currentShiftGrade
})

export default shiftGradeModalFormReducer
