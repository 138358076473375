import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import SelectableShift from '../../../../../SelectableShift'
import ShiftDetailsMedium from '../../../ShiftDetailsMedium'

import './styles.scss'

const SelectableShiftCalendarEventWrapper = ({ event: shift, children }) => {
  const { name, id, schedule, grade } = shift

  const child = React.Children.only(children)
  const childProps = { ...child.props }

  if (grade) {
    childProps.style = {
      ...childProps.style,
      borderLeft: `${grade.color} solid 5px`
    }
  }

  const shiftDetails = (
    <Popover title={name || schedule.name} id="event-details-popover">
      <SelectableShift
        key={id}
        shift={shift}
      >
        <ShiftDetailsMedium shift={shift} />
      </SelectableShift>
    </Popover>
  )

  const nestedChild = (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={shiftDetails}
      rootClose
    >
      <div className="overlay-trigger">
        {childProps.children}
      </div>
    </OverlayTrigger>
  )

  const childWithOverlay = React.cloneElement(
    child,
    childProps,
    nestedChild
  )

  return (
    <div className="shift-event-wrapper">
      {childWithOverlay}
    </div>
  )
}

export default SelectableShiftCalendarEventWrapper
