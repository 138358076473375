import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

class ModalButton extends React.PureComponent {
  state = {
    showModal: this.props.showModal
  }

  handleShowModal = (e) => {
    const { onShowModal } = this.props

    e.preventDefault()
    this.setState({ showModal: true })
    onShowModal()
  }

  handleCloseModal = () => {
    const { onCloseModal } = this.props

    this.setState({ showModal: false })
    onCloseModal()
  }

  render() {
    const { showModal } = this.state
    const {
      children,
      buttonText,
      onShowModal,
      onCloseModal,
      modalProps,
      ...buttonProps
    } = this.props

    return (
      <div>
        <Button
          {...buttonProps}
          onClick={this.handleShowModal}
        >
          {buttonText}
        </Button>

        <Modal
          onHide={this.handleCloseModal}
          show={showModal}
          {...modalProps}
        >
          {children}
        </Modal>
      </div>
    )
  }
}

ModalButton.propTypes = {
  showModal: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  buttonText: PropTypes.node.isRequired,
  onShowModal: PropTypes.func,
  onCloseModal: PropTypes.func,
  modalProps: PropTypes.object
}

ModalButton.defaultProps = {
  showModal: false,
  children: [],
  modalProps: {},
  onShowModal: () => {},
  onCloseModal: () => {}
}

export default ModalButton
