import Api, { RestPath } from '../Api'
import { getRequest } from '../Api/request'

class GuestListApi extends Api {
  constructor() {
    const path = new RestPath('guest_lists')
    super(path)
  }

  fetchAll(params) {
    return getRequest({
      path: this.path.collectionPath(),
      params: Object.assign({}, {
        include: 'group,owner',
        fields: {
          members: 'id,name',
          guestListOwners: 'id,name,email,authenticationToken'
        }
      }, params),
      abortable: true
    })
  }
}

export default GuestListApi
