import * as types from '../constants/action-types'
import ShiftBundleApi from '../apis/ShiftBundleApi'
import scheduleSelector from '../selectors/schedules/scheduleSelector'
import bundleActions from '../../../actions/bundleActions'
import { updateShift } from './shift-actions'

const {
  requestBundles,
  receiveBundles,
  requestBundleCreation,
  receiveBundleCreation,
  requestBundleUpdate,
  receiveBundleUpdate,
  requestBundleDeletion,
  receiveBundleDeletion
} = bundleActions

export const receiveShiftBundleError = errors => ({
  type: types.RECEIVE_SHIFT_BUNDLE_ERROR,
  payload: errors
})

export const openShiftBundleModal = shiftBundle => ({
  type: types.OPEN_SHIFT_BUNDLE_MODAL,
  payload: shiftBundle
})
export const closeShiftBundleModal = () => ({
  type: types.CLOSE_SHIFT_BUNDLE_MODAL
})
export const changeCurrentShiftBundle = changes => ({
  type: types.CHANGE_CURRENT_SHIFT_BUNDLE,
  payload: changes
})

export const fetchShiftBundles = () => (dispatch, getState) => {
  dispatch(requestBundles())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new ShiftBundleApi(scheduleId)

  return api.fetchAll()
    .then(response => dispatch(receiveBundles(response.data)))
}

export const createShiftBundle = shiftBundle => (dispatch, getState) => {
  dispatch(requestBundleCreation())

  const { id: scheduleId } = scheduleSelector(getState())
  const api = new ShiftBundleApi(scheduleId)

  return api.create({ body: { shiftBundle } })
    .then(response => dispatch(receiveBundleCreation(response.data)))
    .catch(error => dispatch(receiveShiftBundleError({ ...error.response.data })))
}

export const updateShiftBundle = (id, shiftBundle) => (dispatch) => {
  dispatch(requestBundleUpdate(id))

  const api = new ShiftBundleApi()

  return api.update(id, { body: { shiftBundle } })
    .then(response => dispatch(receiveBundleUpdate(response.data)))
    .catch(error => dispatch(receiveShiftBundleError({ id, ...error.response.data })))
}

export const createOrUpdateShiftBundle = shiftBundle => (dispatch) => {
  const { id } = shiftBundle
  if (id) {
    dispatch(updateShiftBundle(id, shiftBundle))
  } else {
    dispatch(createShiftBundle(shiftBundle))
  }
}

export const deleteShiftBundle = id => (dispatch) => {
  dispatch(requestBundleDeletion())

  const api = new ShiftBundleApi()

  return api.destroy(id)
    .then(() => dispatch(receiveBundleDeletion(id)))
}

export const removeShift = shiftId => dispatch => (
  dispatch(updateShift(shiftId, { bundleId: null }))
)
