import Api, { RestPath } from '../../../Api'
import { postRequest, deleteRequest } from '../../../Api/request'

class BundleApi extends Api {
  constructor() {
    const path = new RestPath('shift_bundles')
    super(path)
  }

  select(id, { body }) {
    return postRequest({
      path: `${this.path.memberPath(id)}/select?include=shifts`,
      body
    })
  }

  deselect(id) {
    return deleteRequest({
      path: `${this.path.memberPath(id)}/select?include=shifts`
    })
  }

  prefer(id) {
    return postRequest({
      path: `${this.path.memberPath(id)}/prefer?include=shifts`
    })
  }

  unprefer(id) {
    return deleteRequest({
      path: `${this.path.memberPath(id)}/prefer?include=shifts`
    })
  }
}

export default BundleApi
