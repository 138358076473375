import { createSelector } from 'reselect'

import groupById from '../../utilities/groupById'
import propertiesSelector from './propertiesSelector'

const propertiesByIdSelector = createSelector(
  propertiesSelector,
  properties => groupById(properties)
)

export default propertiesByIdSelector
