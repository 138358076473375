import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

const Message = ({ type, onDismiss, message, timeout, ...otherProps }) => {
  let style
  if (type === 'alert') {
    style = 'danger'
  } else if (type === 'notice') {
    style = 'success'
  } else {
    style = type
  }

  if (timeout && timeout > 0) {
    setTimeout(onDismiss, timeout)
  }

  const innerHTML = { __html: message }

  return (
    <Alert
      className="notification-message"
      bsStyle={style}
      onClick={onDismiss}
      onDismiss={onDismiss}
      {...otherProps}
    >
      <span dangerouslySetInnerHTML={innerHTML} />
    </Alert>
  )
}


Message.propTypes = {
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['notice', 'alert', 'warning']),
  timeout: PropTypes.number
}

Message.defaultProps = {
  type: 'notice',
  timeout: 3000
}

export default Message
