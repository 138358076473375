/* global React */

import Actions from './Actions';

import createReactClass from 'create-react-class';

export default createReactClass({
  handleClick: function () {
    Actions.updateQuery(this.props.barcode);
    Actions.resetOwnersList();
  },

  render: function () {
    var className = "autocomplete-item";
    if (this.props.highlight) { className += " active"; }

    return (
      <div className={className} onClick={this.handleClick}>
        <div className='row'>
          <div className='col-xs-2 text-center'>
            <h3 className='barcode'>{this.props.barcode}</h3>
          </div>
          <div className='col-xs-10'>
            <h3 className='name' dangerouslySetInnerHTML={{ __html: this.props.name }}></h3>
            <p>{this.props.email}</p>
          </div>
        </div>
      </div>
    );
  }
})
