import {
  REQUEST_OBJECT,
  RECEIVE_OBJECT,
  RECEIVE_OBJECT_DELETION,
  RECEIVE_OBJECT_ERROR,
  REQUEST_OBJECTS,
  RECEIVE_OBJECTS,
  RECEIVE_CURRENT_MEMBER,
  RECEIVE_CURRENT_EVENT_OR_ORGANIZATION
} from '../constants/action-types'

const initialState = {
  shiftsList: true,
  membersList: true,
  gradesList: true,
  bundlesList: true,
  currentMember: false,
  currentEventOrOrganization: false,
  shifts: [],
  members: [],
  grades: [],
  bundles: [],
  schedules: []
}

const addObjectToLoadingList = (state, { id, type }) => ({
  ...state,
  [type]: [
    id,
    ...state[type]
  ]
})

const removeObjectFromLoadingList = (state, { id, type }) => ({
  ...state,
  [type]: state[type].filter(currentId => currentId !== id)
})

const removeObjectsFromLoadingList = (state, { ids, type }) => ({
  ...state,
  [type]: state[type].filter(currentId => ids.includes(currentId))
})

const setModelLoadingStatus = (state, type, status) => ({
  ...state,
  [`${type}List`]: status
})

const loadingStatusReducer = (state = initialState, action) => {
  let newState = state

  switch (action.type) {
    case REQUEST_OBJECT: {
      const { id, type } = action.payload

      if (id) {
        newState = addObjectToLoadingList(newState, { id, type })
      }

      return newState
    }
    case RECEIVE_OBJECT: {
      const { response } = action.payload
      const { data } = response

      if (data && data instanceof Array) {
        const type = data[0].type
        const ids = data.map(d => d.id)
        newState = removeObjectsFromLoadingList(newState, { type, ids })
      } else {
        const { id, type } = data
        newState = removeObjectFromLoadingList(newState, { type, id })
      }

      return newState
    }
    case RECEIVE_OBJECT_ERROR:
    case RECEIVE_OBJECT_DELETION: {
      const { type, id } = action.payload

      return removeObjectFromLoadingList(newState, { id, type })
    }
    case REQUEST_OBJECTS: {
      const { type } = action.payload

      return setModelLoadingStatus(state, type, true)
    }
    case RECEIVE_OBJECTS: {
      const { type } = action.payload

      return setModelLoadingStatus(state, type, false)
    }
    case RECEIVE_CURRENT_MEMBER:
      return {
        ...state,
        currentMember: true
      }
    case RECEIVE_CURRENT_EVENT_OR_ORGANIZATION:
      return {
        ...state,
        currentEventOrOrganization: true
      }
    default:
      return state
  }
}

export default loadingStatusReducer
