/* global React, I18n */

import OwnersListItem from './OwnersListItem';

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    var list = this.props.owners.map(function (owner) {
      return (
        <OwnersListItem
          key={owner.barcode}
          barcode={owner.barcode}
          name={owner.name}
          email={owner.email} />
      );
    });

    return (
      <div>
        <table className="table table-hover table-condensed">
          <thead>
            <tr>
              <th>{I18n.t('tickets_scanner.owners_list.name')}</th>
              <th>{I18n.t('tickets_scanner.owners_list.email')}</th>
              <th>{I18n.t('tickets_scanner.owners_list.barcode')}</th>
            </tr>
          </thead>
          <tbody>
            {list}
          </tbody>
        </table>
      </div>
    );
  }
})
