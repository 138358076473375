import React from 'react'
import { string, node } from 'prop-types'

import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import './styles.scss'

const ShiftCalendarEvent = ({ title, infoMessage, children, className }) => {
  const content = (
    <div className={className}>
      {infoMessage && (
        <div className="shift-event-info">
          {infoMessage}
        </div>
      )}
      {children}
    </div>
  )

  if (title) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip id="event-title-tooltip">
            {title}
          </Tooltip>
        )}
      >
        {content}
      </OverlayTrigger>
    )
  }

  return content
}

ShiftCalendarEvent.defaultProps = {
  className: null,
  title: null,
  children: null,
  infoMessage: null
}

ShiftCalendarEvent.propTypes = {
  className: string,
  title: string,
  children: node,
  infoMessage: string
}

export default ShiftCalendarEvent
