import axios from 'axios'
import qs from 'qs'
import csrfToken from '../utilities/csrfToken'

const request = ({ path, params, body, method, abortable = false, headers = {} }) => {
  if (body !== undefined) {
    body = JSON.stringify(body)
  }

  let url = `/api/${path}`

  if (params) {
    const paramsString = qs.stringify(params, { arrayFormat: 'brackets' })
    url += `?${paramsString}`
  }

  headers = Object.assign({}, {
    'Accept': 'application/json', // eslint-disable-line quote-props
    'Content-Type': 'application/json'
  }, headers)

  if (method !== 'GET') {
    headers['X-CSRF-Token'] = csrfToken()
  }

  const options = {
    url,
    method,
    headers,
    data: body,
    withCredentials: true
  }

  let source
  if (abortable) {
    const CancelToken = axios.CancelToken
    source = CancelToken.source()

    options.cancelToken = source.token
  }

  const requestObject = axios(options)

  if (abortable) {
    return {
      abort: message => source.cancel(message),
      request: requestObject
    }
  }

  return requestObject
}

export const getRequest = (options = {}) => (
  request({
    method: 'GET',
    ...options
  })
)

export const postRequest = (options = {}) => (
  request({
    method: 'POST',
    ...options
  })
)

export const patchRequest = (options = {}) => (
  request({
    method: 'PATCH',
    ...options
  })
)

export const putRequest = (options = {}) => (
  request({
    method: 'PUT',
    ...options
  })
)

export const deleteRequest = (options = {}) => (
  request({
    method: 'DELETE',
    ...options
  })
)
