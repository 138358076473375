import { createSelector } from 'redux-orm'
import { orm } from '../../../../store'

const dbStateSelector = state => state.orm

const getGrades = createSelector(
  orm,
  dbStateSelector,
  session => session.Grade.all().toRefArray()
)

export default getGrades
