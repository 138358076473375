import Sex from './Sex'
import Birthdate from './Birthdate'
import ProfileImage from './ProfileImage'

const MemberTypePropertyValue = {}

MemberTypePropertyValue.Sex = Sex
MemberTypePropertyValue.Birthdate = Birthdate
MemberTypePropertyValue.ProfileImage = ProfileImage

export default MemberTypePropertyValue
