/* global React, Reflux */

import TicketStore from './TicketStore';
import TicketNotFound from './TicketNotFound';
import TicketOwnerInfo from './TicketOwnerInfo';
import TicketHistory from './TicketHistory';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [Reflux.connect(TicketStore, 'ticket')],

  render: function () {
    var ticketView;

    if (this.state.ticket) {
      if (this.state.ticket.notFound) {
        ticketView =
          <TicketNotFound />
      } else {
        ticketView =
          <div>
            <TicketOwnerInfo ticket={this.state.ticket} />
            <TicketHistory ticket={this.state.ticket} />
          </div>
      }
    }

    return (
      <div>{ticketView}</div>
    );
  }
})
