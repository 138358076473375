/* global React */

import createReactClass from 'create-react-class';

export default createReactClass({
  render: function () {
    let required_string = "";
    if (this.props.member_type_property.required) {
      required_string = <small>(required)</small>;
    }

    return (
      <li className="member_type_property" data-member-type-property-id={this.props.member_type_property.id}>
        <h3>{this.props.member_type_property.name} {required_string}</h3>
        {this.props.member_type_property.type}
      </li>
    );
  }
})
