import DataTable from './DataTable'
import serverSide from './serverSide'
import persistable from './persistable'

export const ServerSideDataTable = serverSide(DataTable)
export const serverSideDataTable = serverSide

export const PersistableDataTable = persistable(DataTable)
export const persistableDataTable = persistable

export const PersistableServerSideDataTable = persistable(ServerSideDataTable)

export default DataTable
