import {
  SET_MEMBERS_FILTERING
} from '../../../constants/action-types'

const defaultState = {
  text: null,
  hideFulfilled: false,
  showAcceptedShiftsOnly: false
}

const filtersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MEMBERS_FILTERING:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default filtersReducer
