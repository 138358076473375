import Api, { RestPath } from '../Api'
import { getRequest } from '../Api/request'

class CheckInReportApi extends Api {
  constructor() {
    const path = new RestPath('check_in_report')
    super(path)
  }

  fetchAll() {
    return getRequest({
      path: this.path.collectionPath(),
      abortable: true
    })
  }
}

export default CheckInReportApi
