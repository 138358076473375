import React from 'react'
import { bool, func, arrayOf } from 'prop-types'

import FontAwesome from 'react-fontawesome'

import LoadingIndicator from '../../../../components/LoadingIndicator'

import shiftBundlePropTypes from '../../../propTypes/shiftBundlePropTypes'

const BundleList = ({ bundles, onClick, isLoading }) => (
  <div className="pull-right">
    {isLoading ? (
      <LoadingIndicator />
    ) : (bundles.map((bundleAttributes) => {
      const { name, color } = bundleAttributes

      return (
        <div
          key={name}
          className="pull-right"
          role="button"
          tabIndex="0"
          onClick={() => onClick(bundleAttributes)}
          style={{
            color
          }}
        >
          <FontAwesome name="cube" />
          {' '}{name}
        </div>
      )
    }))}
  </div>
)

BundleList.propTypes = {
  bundles: arrayOf(shiftBundlePropTypes).isRequired,
  onClick: func.isRequired,
  isLoading: bool.isRequired
}

export default BundleList
