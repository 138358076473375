import { combineReducers } from 'redux'
import shiftModalForm from './shiftModalForm'
import shiftGradeModalForm from './shiftGradeModalForm'
import shiftBundleModalForm from './shiftBundleModalForm'
import membersView from './membersView'

const uiReducer = combineReducers({
  shiftModalForm,
  shiftGradeModalForm,
  shiftBundleModalForm,
  membersView
})

export default uiReducer
