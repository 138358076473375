import { orm } from '../../../store'
import ShiftApi from '../apis/ShiftApi'
import {
  getSpotsWithoutRequirement,
  getMatchingRequirements
} from '../utilities/requirementsChecks'
import shiftActions from '../../../actions/shiftActions'
import {
  selectingRequirement,
  selectedRequirement
} from '../actions/ui-actions'
import { publishResponseErrorNotification } from '../../../utilities/notifications'

const {
  requestShifts,
  receiveShifts,
  receiveShift,
  requestShiftUpdate,
  receiveShiftUpdate,
  receiveShiftError
} = shiftActions

export const selectingShift = requestShiftUpdate
export const deselectingShift = requestShiftUpdate
export const selectedShift = receiveShiftUpdate
export const deselectedShift = receiveShiftUpdate
export const preferringShift = requestShiftUpdate
export const unpreferringShift = requestShiftUpdate
export const preferredShift = receiveShiftUpdate
export const unpreferredShift = receiveShiftUpdate

const api = new ShiftApi()

export const fetchShifts = () => (dispatch) => {
  dispatch(requestShifts())

  api.fetchAll()
    .then(response => dispatch(receiveShifts(response.data)))
}

export const fetchShift = shiftId => (dispatch) => {
  api.fetch(shiftId)
    .then(response => dispatch(receiveShift(response.data)))
}

export const selectShift = (shiftId, requirementId = null) => (dispatch) => {
  dispatch(selectedRequirement())
  dispatch(selectingShift(shiftId))
  return api.select(shiftId, {
    body: { requirementId }
  })
    .then(response => dispatch(selectedShift(response.data)))
    .catch((error) => {
      publishResponseErrorNotification(error.response.data)
      dispatch(receiveShiftError(shiftId))
    })
}

export const checkRequirementsAndSelectShift = shiftId => (dispatch, getState) => {
  const state = getState()
  const shift = getShift(shiftId, state)

  if (shift.requirements.length === 0) {
    dispatch(selectShift(shiftId))
  } else {
    const currentMember = state.currentMember

    const matchingRequirements = getMatchingRequirements(shift, currentMember)

    const spotsWithoutRequirement = getSpotsWithoutRequirement(shift)

    if (spotsWithoutRequirement > 0 && matchingRequirements.length === 0) {
      dispatch(selectShift(shiftId))
    } else if (spotsWithoutRequirement <= 0 && matchingRequirements.length === 1) {
      dispatch(selectShift(shiftId, matchingRequirements[0].id))
    } else {
      const noRequirementOption = spotsWithoutRequirement > 0
      dispatch(selectingRequirement('shift', shiftId, matchingRequirements, noRequirementOption))
    }
  }
}

export const deselectShift = shiftId => (dispatch) => {
  dispatch(deselectingShift(shiftId))
  return api.deselect(shiftId)
    .then(response => dispatch(deselectedShift(response.data)))
    .catch((error) => {
      publishResponseErrorNotification(error.response.data)
      dispatch(receiveShiftError(shiftId))
    })
}

export const preferShift = shiftId => (dispatch) => {
  dispatch(preferringShift(shiftId))
  return api.prefer(shiftId)
    .then(response => dispatch(preferredShift(response.data)))
    .catch((error) => {
      publishResponseErrorNotification(error.response.data)
      dispatch(receiveShiftError(shiftId))
    })
}

export const unpreferShift = shiftId => (dispatch) => {
  dispatch(unpreferringShift(shiftId))
  return api.unprefer(shiftId)
    .then(response => dispatch(unpreferredShift(response.data)))
    .catch((error) => {
      publishResponseErrorNotification(error.response.data)
      dispatch(receiveShiftError(shiftId))
    })
}

const getShift = (shiftId, state) => {
  const session = orm.session(state.orm)
  const { Shift } = session
  const shift = Shift.withId(shiftId)
  return {
    ...shift.ref,
    requirements: shift.requirements.toRefArray()
  }
}
