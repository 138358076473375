import React from 'react'
import { node } from 'prop-types'

import FontAwesome from 'react-fontawesome'

import ShiftCalendarEvent from '../../../../../components/ShiftCalendarEvent'

import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'

const ShiftStandardEvent = ({
  event: shift,
  attendanceMessage,
  ...eventProps
}) => {
  const { name, bundle, hidden, requirements } = shift

  return (
    <ShiftCalendarEvent
      event={shift}
      className="shift-event-content"
      {...eventProps}
      title={null}
    >
      {name}
      <div className="shift-event-ekstra-info">
        {bundle && (
          <span
            className="shift-event-icon"
            style={{
              color: bundle.color
            }}
          >
            <FontAwesome name="cube" />
            {' '}{bundle.name}
          </span>
        )}
        {hidden && (
          <FontAwesome
            name="eye-slash"
            className="shift-event-icon"
          />
        )}
        {requirements.length > 0 && (
          <FontAwesome
            name="lock"
            className="shift-event-icon"
          />
        )}
        <span>{attendanceMessage}</span>
      </div>
    </ShiftCalendarEvent>
  )
}

ShiftStandardEvent.defaultProps = {
}

ShiftStandardEvent.propTypes = {
  event: shiftPropTypes.isRequired,
  attendanceMessage: node.isRequired
}

export default ShiftStandardEvent
