import React from 'react'

import FontAwesome from 'react-fontawesome'

import ShiftCalendarEvent from '../../../../../../../components/ShiftCalendarEvent'

import { shiftPropTypes } from '../../../../../../../propTypes/shiftPropTypes'

const StandardEvent = ({
  event: shift,
  ...eventProps
}) => {
  const { name, bundle, attendanceCount, attendanceLimit, requirements } = shift

  return (
    <ShiftCalendarEvent
      event={shift}
      className="shift-event-content"
      {...eventProps}
      title={null}
    >
      {name}
      <div className="shift-event-ekstra-info">
        {bundle && (
          <span
            className="shift-event-icon"
            style={{
              color: bundle.color
            }}
          >
            <FontAwesome name="cube" />
            {' '}{bundle.name}
          </span>
        )}
        {requirements.length > 0 && (
          <FontAwesome
            name="lock"
            className="shift-event-icon"
          />
        )}
        <span>{`${attendanceCount}/${attendanceLimit}`}</span>
      </div>
    </ShiftCalendarEvent>
  )
}

StandardEvent.defaultProps = {
}

StandardEvent.propTypes = {
  event: shiftPropTypes.isRequired
}

export default StandardEvent
