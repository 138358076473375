import React from 'react'
import { string, func } from 'prop-types'

import RadioButtonsCollection from './RadioButtonsCollection'

class RadioButtonsWithSettings extends React.Component {
  constructor(props) {
    super(props)

    const { defaultCheckedValue } = props

    this.state = {
      checkedValue: defaultCheckedValue
    }
  }

  handleChange = (newValue) => {
    this.setState({
      checkedValue: newValue
    })
  }

  render() {
    const { checkedValue } = this.state
    const { renderContent, ...radioButtonsCollectionProps } = this.props

    return (
      <div>
        <RadioButtonsCollection
          {...radioButtonsCollectionProps}
          onChange={this.handleChange}
        />
        {renderContent(checkedValue)}
      </div>
    )
  }
}

RadioButtonsWithSettings.propTypes = {
  defaultCheckedValue: string,
  renderContent: func.isRequired
}

RadioButtonsWithSettings.defaultProps = {
  defaultCheckedValue: null
}

export default RadioButtonsWithSettings
