import React from 'react'

const Bundle = ({ children, actionButton, style }) => (
  <div className="bundle" style={style}>
    {children}
    <div className="bundle-actions">
      {actionButton}
    </div>
  </div>
)

export default Bundle
