import PubSub from 'pubsub-js'

export const publishNotification = ({ message, ...options }) => PubSub.publish(
  'NOTIFICATION',
  { message, ...options }
)

const objectToString = (element) => {
  if (typeof element === 'string') {
    return `${element.charAt(0).toUpperCase()}${element.slice(1)}`
  } else if (element instanceof Array) {
    return element.map(childElement => objectToString(childElement)).join('. ')
  }

  return Object.entries(element).map(([_, errorMessage]) => (
    objectToString(errorMessage)
  )).join('. ')
}

export const publishResponseErrorNotification = (json) => {
  const { errors, message } = json
  let notificationText = message

  if (errors) {
    notificationText += `: ${objectToString(errors)}`
  }

  publishNotification({ message: notificationText, type: 'alert' })
}
