/* global I18n */

import { connect } from 'react-redux'
import { Checkbox } from 'react-icheck'

import { setIneligibilityFilter } from '../../../actions/filter-actions'

const i18noptions = {
  scope: 'shift_selector.ineligibility_filter'
}

const mapStateToProps = state => ({
  checked: state.currentApp.filters.ineligibilityFilter === 'HIDE_INELIGIBLE',
  label: I18n.t('label', i18noptions),
  checkboxClass: 'icheckbox_square-green icheck-item'
})

const mapDispatchToProps = dispatch => ({
  onChange: ((event) => {
    const filter = event.target.checked ? 'HIDE_INELIGIBLE' : 'SHOW_ALL'
    dispatch(setIneligibilityFilter(filter))
  })
})

const IneligibilityFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkbox)

export default IneligibilityFilter
