import React from 'react'
import { element, bool, func, string } from 'prop-types'
import { DragSource } from 'react-dnd'
import classNames from 'classnames'

import { SHIFT_EVENT } from '../../../../constants/item-types'
import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'

class DraggableShiftWrapper extends React.Component {
  render() {
    const {
      connectDragSource,
      isDragging,
      children,
      className,
      shift: _shift,
      ...otherProps
    } = this.props

    const child = React.Children.only(children)
    const childWithProps = React.cloneElement(child, {
      ...otherProps,
      ...child.props,
      style: {
        ...child.props.style,
        ...otherProps.style
      },
      className: classNames(className, child.props.className, {
        dragged: isDragging
      })
    })

    return connectDragSource(childWithProps)
  }
}

DraggableShiftWrapper.propTypes = {
  children: element.isRequired,
  connectDragSource: func.isRequired,
  isDragging: bool.isRequired,
  className: string,
  shift: shiftPropTypes.isRequired
}

DraggableShiftWrapper.defaultProps = {
  className: null
}

const shiftCalenderEventSource = {
  beginDrag: ({ shift }) => ({ event: shift })
}


const dragCollect = (dragSourceConnect, monitor) => ({
  connectDragSource: dragSourceConnect.dragSource(),
  isDragging: monitor.isDragging()
})

export default DragSource(
  SHIFT_EVENT,
  shiftCalenderEventSource,
  dragCollect
)(DraggableShiftWrapper)
