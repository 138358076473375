import { uniq } from 'lodash'

import {
  SELECT_MEMBER,
  DESELECT_MEMBER,
  SELECT_MEMBERS_RANGE,
  DESELECT_MEMBERS_RANGE
} from '../../../constants/action-types'

const defaultState = {
  ids: [],
  lastSelectedId: null
}

const selectionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_MEMBER: {
      const { id, multiSelect } = action.payload
      if (multiSelect) {
        return {
          ids: [
            ...state.ids,
            id
          ],
          lastSelectedId: id
        }
      }
      return {
        ids: [id],
        lastSelectedId: id
      }
    }
    case SELECT_MEMBERS_RANGE: {
      const { id, ids } = action.payload

      return {
        ids: uniq([
          ...state,
          ...ids
        ]),
        lastSelectedId: id
      }
    }
    case DESELECT_MEMBER: {
      const { id, multiSelect } = action.payload
      if (multiSelect) {
        return {
          ids: state.ids.filter(currentId => currentId !== id),
          lastSelectedId: id
        }
      }

      return {
        ids: [id],
        lastSelectedId: id
      }
    }
    case DESELECT_MEMBERS_RANGE: {
      const { id, ids } = action.payload

      return {
        ids: state.ids.filter(currentId => !ids.includes(currentId)),
        lastSelectedId: id
      }
    }
    default:
      return state
  }
}

export default selectionReducer
