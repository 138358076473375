/* global I18n, moment */

import React from 'react'
import FontAwesome from 'react-fontawesome'
import { string } from 'prop-types'

const i18noptions = {
  scope: 'components.member_type_property_value.birtdate'
}

const Birthdate = ({ value }) => {
  const momentDate = moment(value)
  const momentCurrent = moment()
  const age = momentCurrent.diff(momentDate, 'years')
  const birthday = (momentCurrent.month() === momentDate.month()) &&
    (momentCurrent.date() === momentDate.date())

  return (
    <span>
      {age}
      {birthday && (
        <span>
          ({I18n.t('today', i18noptions)} {' '}<FontAwesome name="birthday-cake" />)
        </span>
      )}
    </span>
  )
}

Birthdate.propTypes = {
  value: string.isRequired
}

export default Birthdate
