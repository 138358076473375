import React from 'react'
import { connect } from 'react-redux'
import { number, bool, arrayOf, oneOf, shape, object } from 'prop-types'
import { DragLayer } from 'react-dnd'

import * as types from '../constants/item-types'
import memberPropTypes from '../../propTypes/memberPropTypes'
import draggedMembersSelector from '../selectors/members/draggedMembersSelector'

import { MemberCardTemplate } from '../components/MembersList'

const CustomDragLayer = ({ isDragging, itemType, draggedMembers, currentOffset }) => {
  if (!isDragging) {
    return null
  }

  const getItemStyles = (index = 0) => {
    if (!currentOffset) {
      return {
        display: 'none'
      }
    }

    const { x, y } = currentOffset
    const xOffset = index * 10
    const yOffset = index * 40
    const transform = `translate(${x - xOffset}px, ${y - yOffset}px)`

    return {
      transform,
      WebkitTransform: transform
    }
  }

  let items
  switch (itemType) {
    case types.MEMBER_CARD:
      if (draggedMembers === null) {
        return null
      }
      items = (
        <div className="member-card-list">
          {draggedMembers.map((member, index) => (
            <MemberCardTemplate
              key={member.id}
              member={member}
              style={getItemStyles(index)}
              selected
              dragging
            />
          ))}
        </div>
      )
      break
    case types.SHIFT_EVENT:
    default:
      return null
  }

  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  }

  return (
    <div style={layerStyles}>
      {items}
    </div>
  )
}

CustomDragLayer.propTypes = {
  isDragging: bool.isRequired,
  draggedMembers: arrayOf(memberPropTypes),
  itemType: oneOf([types.MEMBER_CARD, types.SHIFT_EVENT]),
  currentOffset: shape({
    x: number.isRequired,
    y: number.isRequired
  })
}

CustomDragLayer.defaultProps = {
  currentOffset: null,
  itemType: null,
  draggedMembers: null
}

const collect = monitor => ({
  itemType: monitor.getItemType(),
  isDragging: monitor.isDragging(),
  currentOffset: monitor.getSourceClientOffset()
})

const mapStateToProps = (state) => {
  const draggedMembers = draggedMembersSelector(state)

  return {
    draggedMembers
  }
}

export default connect(mapStateToProps)(
  DragLayer(collect)(CustomDragLayer)
)
