import ShiftOverviewApi from '../apis/ShiftOverviewApi'
import shiftActions from './shiftActions'

const {
  requestShifts,
  receiveShifts
} = shiftActions

const api = new ShiftOverviewApi()

export const fetchShifts = params => (dispatch) => {
  dispatch(requestShifts())

  const { request, abort } = api.fetchAll(params)

  request.then(response => dispatch(receiveShifts(response.data)))

  return { request, abort }
}
