import { createSelector } from 'redux-orm'

import { orm } from '../../../../store'

const ormStateSelector = state => state.orm
const shiftIdSelector = state => state.currentApp.ui.shiftModalForm.currentShift

const requirementsForCurrentShiftSelector = createSelector(
  orm,
  ormStateSelector, shiftIdSelector,
  (session, currentShift) => {
    if (!currentShift || !currentShift.id) {
      return undefined
    }

    const { id } = currentShift
    const shift = session.Shift.withId(id)

    return shift.requirements.toModelArray().map((requirement) => {
      const requirementRef = requirement.ref

      return {
        ...requirementRef,
        attendanceCount: requirement.attendanceCount()
      }
    })
  }
)

export default requirementsForCurrentShiftSelector
