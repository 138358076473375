import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import CustomDragLayer from './CustomDragLayer'
import MembersList from '../components/MembersList'
import ShiftsView from '../components/ShiftsView'
import {
  receiveSchedule,
  fetchData
} from '../actions/app-actions'
import schedulePropTypes from '../../propTypes/schedulePropTypes'

class App extends Component {
  constructor(props) {
    super(props)

    if (!props.loaded) {
      const { dispatch, schedule } = props

      dispatch(receiveSchedule(schedule))
      dispatch(fetchData())
    }
  }

  render() {
    return (
      <div className="row schedule-app">
        <CustomDragLayer />
        <div className="col-xs-3">
          <MembersList />
        </div>
        <div className="col-xs-9">
          <ShiftsView />
        </div>
      </div>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    data: PropTypes.shape({
      attributes: schedulePropTypes.isRequired
    }).isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  const { shiftsList, membersList } = state.loadingStatus

  return {
    loaded: !shiftsList && !membersList
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(
  DragDropContext(HTML5Backend)(App)
)
