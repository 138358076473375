import Api, { RestPath } from '../../../Api'
import { getRequest, patchRequest, postRequest } from '../../../Api/request'

class ShiftApi extends Api {
  constructor(scheduleId) {
    const parentPath = new RestPath('schedules')
    const path = new RestPath('shifts', parentPath)
    super(path)

    this.scheduleId = scheduleId
  }

  fetchAll() {
    return getRequest({
      path: this.path.collectionPath(this.scheduleId),
      params: {
        include: 'preferences,requirements'
      }
    })
  }

  create({ body }) {
    return postRequest({
      path: this.path.collectionPath(this.scheduleId),
      params: {
        include: 'attendances,preferences,requirements,bundle.requirements'
      },
      body
    })
  }

  update(id, { body }) {
    return patchRequest({
      path: this.path.memberPath(id),
      params: {
        include: 'attendances,preferences,requirements,bundle.requirements'
      },
      body
    })
  }
}

export default ShiftApi
