/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import fetch from 'isomorphic-fetch'

import DirectLinkModal from '../components/DirectLinkModal'

const i18noptions = {
  scope: 'components.direct_guest_list_link_widget'
}

class DirectGuestListLinkWidget extends Component {
  state = {
    sendingLink: false
  }

  handleSendLink = () => {
    const { sendingLink } = this.state
    if (sendingLink) return

    this.setState({ sendingLink: true })

    fetch(`/guest_lists/${this.props.guestListId}/authentication_links`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(() => {
      this.setState({ sendingLink: false })
    })
  }

  render() {
    const { clipboardText, guestListId: _, ...buttonProps } = this.props
    const { sendingLink } = this.state

    return (
      <DirectLinkModal
        copiedMessage={I18n.t('copied', i18noptions)}
        clipboardText={clipboardText}
        {...buttonProps}
      >
        <Button
          bsStyle="primary"
          disabled={sendingLink}
          onClick={this.handleSendLink}
        >
          {sendingLink ?
            I18n.t('sending_link', i18noptions) :
            I18n.t('send_link_to_owner', i18noptions)}
        </Button>
      </DirectLinkModal>
    )
  }
}

DirectGuestListLinkWidget.propTypes = {
  clipboardText: PropTypes.string.isRequired,
  guestListId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

export default DirectGuestListLinkWidget
