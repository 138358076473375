import React from 'react'
import ReactSelect from 'react-select'
import fetch from 'isomorphic-fetch'

import 'react-select/dist/react-select.css'

class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: props.value || []
    }
  }

  getOptions(input, callback) {
    if (input === '') {
      callback(null, { options: this.props.options })
    } else {
      const url = `${this.props.url}.json?q=${input}`

      fetch(url, {
        credentials: 'include'
      }).then(response => response.json())
        .then(json => callback(null, { options: json || [] }))
        .catch(error => callback(error, { options: [] }))
    }
  }

  handleChange(values) {
    this.setState({
      values
    })
  }

  renderInputs() {
    const name = this.props.name + '[]'

    return (
      <div>
        {
          ['', ...this.state.values].map((value, _) => (
            <input
              type="hidden"
              key={value.value}
              name={name}
              value={value.value}
              readOnly
            />
          ))
        }
      </div>
    )
  }

  render() {
    const reactSelectProps = Object.assign({}, this.props)
    const { ajax, multi } = reactSelectProps
    let ReactSelectComponent = ReactSelect

    reactSelectProps.labelKey = 'text'

    if (ajax !== undefined) {
      delete reactSelectProps.ajax
      delete reactSelectProps.url

      reactSelectProps.autoload = !!reactSelectProps.options
      reactSelectProps.loadOptions = this.getOptions.bind(this)
      ReactSelectComponent = ReactSelect.Async
    }

    if (multi === true) {
      delete reactSelectProps.name

      reactSelectProps.value = this.state.values
      reactSelectProps.onChange = this.handleChange.bind(this)
    }

    const reactSelect = React.createElement(
      ReactSelectComponent,
      reactSelectProps
    )

    return (
      <div>
        {this.renderInputs()}
        {reactSelect}
      </div>
    )
  }
}

export default Select
