import React from 'react'
import { string } from 'prop-types'
import classnames from 'classnames'

import { Label } from 'react-bootstrap'

import { lightColors } from './ColorSelect'

const ShiftGradeLabel = ({ color, name, className, ...labelProps }) => (
  <Label
    className={
      classnames(
        className, {
          'label-dark': !lightColors.includes(color)
        }
      )
    }
    style={{
      backgroundColor: color
    }}
    {...labelProps}
  >
    {name}
  </Label>
)


ShiftGradeLabel.propTypes = {
  color: string.isRequired,
  className: string
}

ShiftGradeLabel.defaultProps = {
  className: null
}

export default ShiftGradeLabel
