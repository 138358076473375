import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, HelpBlock } from 'react-bootstrap'

import FieldLabel from './FieldLabel'
import FieldInput from './FieldInput'

const FieldGroup = ({
  id,
  model,
  name,
  label,
  help,
  errors,
  children,
  ...inputProps
}) => {
  const hasErrors = errors !== null && errors.length > 0

  const controlIdParts = [model, id, name]
  const controlId = controlIdParts.filter(part => part !== null).join('-')

  if (typeof children === 'function') {
    return (
      <FormGroup
        controlId={controlId}
        validationState={hasErrors ? 'error' : null}
      >
        {children(specificInputProps => (
          <FieldInput {...inputProps} name={name} {...specificInputProps} />
        ), specificLabelProps => (
          label !== false && (<FieldLabel label={label} model={model} name={name} {...specificLabelProps} />)
        ), () => (
          <div>
            {help && <HelpBlock>{help}</HelpBlock>}
            {hasErrors && <HelpBlock>{errors.join(', ')}</HelpBlock>}
          </div>
        ))}
      </FormGroup>
    )
  }

  return (
    <FormGroup
      controlId={controlId}
      validationState={hasErrors ? 'error' : null}
    >
      {label !== false && (<FieldLabel label={label} model={model} name={name} />)}
      <FieldInput {...inputProps} name={name}>{children}</FieldInput>
      {help && <HelpBlock>{help}</HelpBlock>}
      {hasErrors && <HelpBlock>{errors.join(', ')}</HelpBlock>}
    </FormGroup>
  )
}

FieldGroup.defaultProps = {
  id: null,
  model: null,
  name: null,
  label: null,
  help: null,
  errors: [],
  children: null
}

FieldGroup.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  model: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
}

export default FieldGroup
