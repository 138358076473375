import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import classNames from 'classnames'

class MaskedDateInput extends React.PureComponent {
  static autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy')

  render() {
    const { className, dateFormat, mask, value, ...datePickerProps } = this.props
    const datePickerClass = classNames(
      'form-control',
      className
    )

    const formattedValue = value ? format(value, 'dd/MM/yyyy') : ''

    return (
      <MaskedInput
        value={formattedValue}
        className={datePickerClass}
        placeholder={dateFormat}
        mask={mask}
        pipe={MaskedDateInput.autoCorrectedDatePipe}
        keepCharPositions
        {...datePickerProps}
      />
    )
  }
}

MaskedDateInput.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  mask: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])),
  value: PropTypes.instanceOf(Date)
}

MaskedDateInput.defaultProps = {
  className: null,
  dateFormat: 'dd/MM/yyyy',
  mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  value: null
}

export default MaskedDateInput
