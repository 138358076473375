import { createSelector } from 'redux-orm'
import bundlesSelector from './bundlesSelector'

import { orm } from '../../../../store'

const ormStateSelector = state => state.orm

const bundlesWithRequirementsSelector = createSelector(
  orm,
  ormStateSelector,
  bundlesSelector,
  (session, bundles) => bundles.map(bundle => ({
    ...bundle,
    requirements: session
      .Requirement
      .filter({ requirementable: bundle.id, requirementableType: 'Bundle' })
      .toModelArray()
      .map(requirement => ({
        ...requirement.ref,
        propertyId: requirement.ref.property,
        attendanceCount: requirement.attendanceCount()
      }))
  }))
)

export default bundlesWithRequirementsSelector
