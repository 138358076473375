import { floor, ceil } from 'lodash'

import findDoubleBookedShiftIds from '../../../utilities/findDoubleBookedShiftIds'

const calculateShiftHours = shifts => (
  shifts.reduce((result, shift) => result + shift.duration, 0)
)

const calculateShiftDuration = (shiftHours) => {
  const shiftMinutes = (shiftHours % 1) * 0.6 * 100
  return `${floor(shiftHours)}t ${ceil(shiftMinutes)}m`
}

const memberRecordToObject = (member, { schedule, includeExternalShifts }) => {
  const memberRef = member.ref

  const shiftsAccepted = (member.attendances.exists() &&
    member.attendances.exclude('acceptedAt').count() === 0)
  const allShifts = member.attendances
    .orderBy(['id'], ['desc'])
    .toModelArray()
    .map(attendance => attendance.shift)
    .filter(shift => !!shift)
    .map(shift => ({
      ...shift.ref,
      startTime: shift.startTime,
      endTime: shift.endTime,
      schedule: shift.schedule.ref
    }))

  const currentShifts = allShifts.filter(shift => shift.schedule.id === schedule.id)
  const shiftCount = currentShifts.length
  const shiftHours = calculateShiftHours(currentShifts)

  let displayedShiftHours
  let displayedShiftCount
  let displayedShiftDuration
  if (includeExternalShifts) {
    displayedShiftHours = calculateShiftHours(allShifts)
    displayedShiftCount = allShifts.length
    displayedShiftDuration = calculateShiftDuration(displayedShiftHours)
  } else {
    displayedShiftHours = shiftHours
    displayedShiftCount = currentShifts.length
    displayedShiftDuration = calculateShiftDuration(shiftHours)
  }

  const allShiftsOnSchedule = allShifts.filter(shift => shift.schedule.id === schedule.id)
  const doubleBookedShiftIds = findDoubleBookedShiftIds(allShiftsOnSchedule)

  return {
    ...memberRef,
    shiftCount,
    shiftHours,
    displayedShiftHours,
    displayedShiftCount,
    displayedShiftDuration,
    doubleBookedShiftIds,
    shiftsAccepted
  }
}

export default memberRecordToObject
