/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'

import { getRequest } from '../../Api/request'

import LoadingIndicator from '../../components/LoadingIndicator'
import Group from './Group'

const i18noptions = {
  scope: 'components.grouped_shifts_for_registration'
}

class GroupsCollection extends React.PureComponent {
  state = {
    loaded: false,
    groups: []
  }

  componentDidMount() {
    const { fetchGroupsPath } = this.props

    getRequest({
      path: fetchGroupsPath
    }).then((response) => {
      this.setState({
        groups: response.data,
        loaded: true
      })
    })
  }

  renderGroups = () => {
    const { loaded, groups } = this.state
    const { groupProps } = this.props
    if (!loaded) {
      return (
        <LoadingIndicator size="5x" />
      )
    }

    if (groups.length === 0) {
      return I18n.t('no_shifts', i18noptions)
    }

    return groups.map(group => (
      <Group
        {...groupProps(group)}
        count={groups.length}
      />
    ))
  }

  render() {
    return (
      <div>
        {this.renderGroups()}
      </div>
    )
  }
}

GroupsCollection.defaultProps = {
}

GroupsCollection.propTypes = {
  fetchGroupsPath: PropTypes.string.isRequired,
  groupProps: PropTypes.func.isRequired
}

export default GroupsCollection
