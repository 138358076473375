import { addHours, subHours, isBefore, parseISO } from 'date-fns'

const shiftsAreOverlapping = (firstShift, secondShift) => {
  let firstShiftStartTime = parseISO(firstShift.startTime)
  let firstShiftEndTime = parseISO(firstShift.endTime)

  if (firstShift.schedule.id === secondShift.schedule.id) {
    const { shiftTimePaddingHours } = firstShift.schedule

    firstShiftStartTime = subHours(firstShiftStartTime, shiftTimePaddingHours)
    firstShiftEndTime = addHours(firstShiftEndTime, shiftTimePaddingHours)
  }

  const secondShiftStartTime = parseISO(secondShift.startTime)
  const secondShiftEndTime = parseISO(secondShift.endTime)
  return isBefore(firstShiftStartTime, secondShiftEndTime) &&
    isBefore(secondShiftStartTime, firstShiftEndTime)
}

const cachedKeys = new Map()
const cachedDoubleBooked = new Map()
const cachedNotDoubleBooked = new Map()

const cacheKeyForShift = shift => `${shift.startTime}-${shift.endTime}`

const validCacheForShift = (currentShift) => {
  const cachedKey = cachedKeys.get(currentShift.id)
  const currentKey = cacheKeyForShift(currentShift)

  return cachedKey === currentKey
}

const cachedValueForShifts = (currentShift, otherShift) => {
  if (!validCacheForShift(currentShift) || !validCacheForShift(otherShift)) return undefined
  if ((cachedDoubleBooked.get(currentShift.id) || []).includes(otherShift.id)) return true
  if ((cachedNotDoubleBooked.get(currentShift.id) || []).includes(otherShift.id)) return false

  return undefined
}

const setCachedValue = (currentShift, otherShift, cache) => {
  cachedKeys.set(currentShift.id, cacheKeyForShift(currentShift))
  const newCachedValue = cache.get(currentShift.id) || []
  newCachedValue.push(otherShift.id)
  cache.set(currentShift.id, newCachedValue)
}

const registerDoubleBooked = (currentShift, otherShift) => {
  setCachedValue(currentShift, otherShift, cachedDoubleBooked)
  setCachedValue(otherShift, currentShift, cachedDoubleBooked)
}

const registerNotDoubleBooked = (currentShift, otherShift) => {
  setCachedValue(currentShift, otherShift, cachedNotDoubleBooked)
  setCachedValue(otherShift, currentShift, cachedNotDoubleBooked)
}

// using doubleBookedShifts as a Map to keep the order as entires are inputed
const findDoubleBookedShiftIds = (shifts, doubleBookedShifts = []) => {

  const [currentShift, ...shiftsToIterate] = shifts

  if (shiftsToIterate.length === 0) {
    return doubleBookedShifts
  }

  doubleBookedShifts = shiftsToIterate.reduce(
    (doubleBooked, currentIteratedShift) => {
      const cachedValue = cachedValueForShifts(currentShift, currentIteratedShift)
      const isCached = cachedValue !== undefined
      const isDoubleBooked = isCached ? cachedValue : shiftsAreOverlapping(currentShift, currentIteratedShift)

      if (isDoubleBooked) {
        doubleBooked.push(currentShift.id)
        doubleBooked.push(currentIteratedShift.id)
      }

      if (isCached) return doubleBooked

      if (isDoubleBooked) {
        registerDoubleBooked(currentShift, currentIteratedShift)
      } else {
        registerNotDoubleBooked(currentShift, currentIteratedShift)
      }

      return doubleBooked
    },
    [...doubleBookedShifts]
  )

  return findDoubleBookedShiftIds(shiftsToIterate, doubleBookedShifts)
}

export default findDoubleBookedShiftIds
