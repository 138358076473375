/* global I18n */

import React from 'react'
import { string, bool, func, object, arrayOf, oneOfType } from 'prop-types'
import BigCalendar from 'react-big-calendar'
import classNames from 'classnames'
import { format } from 'date-fns'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.less'

import createWrapper from './DropableBackgroundWrapper'
import dateFnsLocalizer from './dateFnsLocalizer'

import './styles.scss'

const i18noptions = {
  scope: 'components.calendar'
}

// Use date-fns to localize react-big-calendar
BigCalendar.setLocalizer(dateFnsLocalizer())

class Calendar extends React.Component {
  constructor(props, context) {
    super(props)

    const { dragAndDropType } = props
    const dragDropEnabled = !!dragAndDropType

    this.dragDropEnabled = dragDropEnabled
    this.dragDropManager = context.dragDropManager

    this.state = {
      isDragging: false
    }
  }

  componentWillMount() {
    if (this.dragDropEnabled) {
      const monitor = this.dragDropManager.getMonitor()

      this.dragDropMonitor = monitor
      this.unsubscribeToDragDropMonitorStateChange = monitor.subscribeToStateChange(
        this.handleDragDropMonitorStateChange
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dragAndDropType } = nextProps
    const dragDropEnabled = !!dragAndDropType

    this.dragDropEnabled = dragDropEnabled
  }

  componentWillUnmount() {
    this.dragDropMonitor = null
    if (this.unsubscribeToDragDropMonitorStateChange) {
      this.unsubscribeToDragDropMonitorStateChange()
    }
  }

  handleDragDropMonitorStateChange = () => {
    const { dragAndDropType } = this.props
    const isDragging = this.dragDropMonitor.getItemType() === dragAndDropType

    if (isDragging !== this.state.isDragging) {
      setTimeout(() => this.setState({ isDragging }))
    }
  }

  render() {
    const { dragAndDropType, onEventDrop, components, ...calendarProps } = this.props
    const { isDragging } = this.state

    let extendedComponents = components || {}
    if (this.dragDropEnabled) {
      const { dateCellWrapper, dayWrapper } = components

      extendedComponents = {
        ...components,
        dateCellWrapper: createWrapper(dragAndDropType, 'dateCellWrapper', onEventDrop, dateCellWrapper),
        dayWrapper: createWrapper(dragAndDropType, 'dayWrapper', onEventDrop, dayWrapper)
      }
    }

    const translate = (key, options) => I18n.t(key, { ...i18noptions, ...options })

    return (
      <BigCalendar
        selectable={this.dragDropEnabled ? 'ignoreEvents' : false}
        popup
        showMultiDayTimes
        messages={{
          allDay: translate('all_day'),
          previous: translate('previous'),
          next: translate('next'),
          today: translate('today'),
          month: translate('month'),
          week: translate('week'),
          day: translate('day'),
          agenda: translate('agenda'),
          date: translate('date'),
          time: translate('time'),
          event: translate('event'),
          showMore: total => translate('show_more', { total })
        }}
        formats={{
          eventTimeRangeStartFormat: ({ start }, culture, local) => (
            `${format(start, 'HH:mm', culture)} — `
          ),
          eventTimeRangeEndFormat: ({ end }, culture, local) => (
            ` — ${format(end, 'HH:mm', culture)}`
          )
        }}
        {...calendarProps}
        components={extendedComponents}
        className={classNames(calendarProps.className, {
          'rbc-addons-dnd': this.dragDropEnabled,
          'rbc-addons-dnd-is-dragging': this.dragDropEnabled && isDragging
        })}
      />
    )
  }
}


Calendar.defaultProps = {
  dragAndDropType: null,
  isDragging: false,
  onEventDrop: null,
  components: null,
  views: ['month', 'week', 'day']
}

Calendar.propTypes = {
  dragAndDropType: oneOfType([string, arrayOf(string)]),
  isDragging: bool,
  onEventDrop: func,
  components: object // eslint-disable-line react/forbid-prop-types
}

Calendar.contextTypes = {
  dragDropManager: object
}

export default Calendar
