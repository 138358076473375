import PubSub from 'pubsub-js'

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TransitionGroup } from 'react-transition-group'

import { Slide } from '../effects'
import Message from './Message'
import './styles.scss'

class Notifications extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string
      })
    ).isRequired
  }

  static defaultProps = {
    messages: []
  }

  constructor(props) {
    super(props)

    this.idCounter = 0
    this.channel = null

    const { messages } = props
    const initialMessages = messages.map(message => this.buildNewMessage(message))

    this.state = {
      messages: initialMessages
    }
  }

  componentDidMount() {
    this.channel = PubSub.subscribe('NOTIFICATION', (_, data) => { this.handlePublishedNotification(data) })
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.channel)
  }

  buildNewMessage(messageData) {
    messageData.id = this.idCounter
    this.idCounter += 1

    return messageData
  }

  handlePublishedNotification(messageData) {
    const { messages } = this.state
    const newMessage = this.buildNewMessage(messageData)

    this.setState({
      messages: [...messages, newMessage]
    })
  }

  handleMessageDismiss(id) {
    const { messages } = this.state
    const newMessages = messages.filter(message => message.id !== id)

    this.setState({
      messages: newMessages
    })
  }

  render() {
    const { messages } = this.state

    return (
      <div className="notifications">
        <TransitionGroup>
          {messages.sort(message => -message.id).map((message) => {
            const { id, ...messageProps } = message

            return (
              <Slide duration={200} height={60} appear>
                <Message
                  key={id}
                  onDismiss={() => { this.handleMessageDismiss(message.id) }}
                  {...messageProps}
                />
              </Slide>
            )
          })}
        </TransitionGroup>
      </div>
    )
  }
}

export default Notifications
