import modelNameFromType from '../../utilities/modelNameFromType'

import {
  RECEIVE_OBJECTS
} from '../../constants/action-types'

const updateMetaData = (modelName, dbState, newMetaData) => ({
  ...dbState,
  [modelName]: {
    ...dbState[modelName],
    meta: {
      ...dbState[modelName].meta,
      ...newMetaData
    }
  }
})

const metaReducer = (dbState, action) => {
  switch (action.type) {
    case RECEIVE_OBJECTS: {
      const { data, meta } = action.payload.response

      if (data.length <= 0) {
        return dbState
      }

      const modelName = modelNameFromType(data[0].type)
      const newDbState = updateMetaData(modelName, dbState, meta)
      return newDbState
    }
    default:
      return dbState
  }
}

export default metaReducer
