import { shape, string, number, arrayOf } from 'prop-types'

const memberPropType = shape({
  id: string.isRequired,
  name: string.isRequired,
  shiftCount: number,
  shiftHours: number,
  propertyIds: arrayOf(number)
})

export default memberPropType
