import { createSelector } from 'redux-orm'
import { orm } from '../../store'

const dbStateSelector = state => state.orm

const getGuestListsWithGroupAndOwner = createSelector(
  orm,
  dbStateSelector,
  session => session.GuestList.all().toModelArray().map(guestList => ({
    ...guestList.ref,
    group: guestList.group && guestList.group.ref,
    owner: guestList.owner && guestList.owner.ref
  }))
)

export default getGuestListsWithGroupAndOwner
