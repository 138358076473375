/* global I18n */

import React from 'react'
import PropTypes from 'prop-types'
import TextFilter from './components/TextFilter'
import IneligibilityFilter from './components/IneligibilityFilter'
import ScheduleFilter from './components/ScheduleFilter'
import GradeFilter from './components/GradeFilter'
import ListView from './components/ListView'
import CalendarView from './components/CalendarView'
import ViewSelector from './components/ViewSelector'

const i18noptions = {
  scope: 'shift_selector'
}

const MainView = ({ currentView }) => {
  const ShiftsView = (currentView === 'calendar') ? CalendarView : ListView

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>{I18n.t('available_shifts_header', i18noptions)}</h5>
        <div className="ibox-tools">
          <ViewSelector />
        </div>
      </div>
      <div className="ibox-content">
        <div className="form-group">
          <TextFilter />
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-4">
              <IneligibilityFilter />
            </div>
            <div className="col-md-4">
              <ScheduleFilter />
            </div>
            <div className="col-md-4">
              <GradeFilter />
            </div>
          </div>

        </div>
      </div>
      <div className="ibox-content">
        <ShiftsView />
      </div>
    </div>
  )
}

MainView.propTypes = {
  currentView: PropTypes.string.isRequired
}

export default MainView
