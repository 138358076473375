import { createSelector } from 'redux-orm'

import scheduleSelector from '../schedules/scheduleSelector'
import shiftRecordToObject from './shiftRecordToObject'
import { orm } from '../../../../store'

const ormStateSelector = state => state.orm
const loadingStatusSelector = state => state.loadingStatus

const shiftsSelector = createSelector(
  orm,
  ormStateSelector,
  loadingStatusSelector,
  scheduleSelector,
  (session, { gradesList, bundlesList, membersList }, schedule) => (
    session.Shift.filter({ schedule: schedule.id }).toModelArray().map(shift => (
      shiftRecordToObject(shift, { gradesList, bundlesList, membersList })
    ))
  )
)

export default shiftsSelector
