import React from 'react'
import { bool, element, func, string, number, arrayOf } from 'prop-types'
import { DropTarget } from 'react-dnd'
import { intersection } from 'lodash'
import classNames from 'classnames'

import { MEMBER_CARD } from '../../../../constants/item-types'
import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'

const DropableShiftWrapper = ({
  connectDropTarget,
  children,
  isOver,
  isDraggingMember,
  canDrop,
  className
}) => {
  const notDropable = isDraggingMember && !canDrop

  const child = React.Children.only(children)
  const childWithProps = React.cloneElement(child, {
    className: classNames(className, child.props.className, {
      over: isOver,
      'can-not-drop': notDropable
    })
  })

  return connectDropTarget(
    <div>
      {childWithProps}
    </div>
  )
}

DropableShiftWrapper.propTypes = {
  children: element.isRequired,
  connectDropTarget: func.isRequired,
  isDraggingMember: bool.isRequired,
  selectedMemberIds: arrayOf(number),
  isOver: bool.isRequired,
  canDrop: bool.isRequired,
  onDrop: func.isRequired,
  filled: bool.isRequired,
  className: string,
  shift: shiftPropTypes.isRequired
}

DropableShiftWrapper.defaultProps = {
  className: null
}

const shiftCalenderEventTarget = {
  drop: (props) => {
    const { onDrop, shift } = props
    const { id } = shift

    onDrop(id)
  },
  canDrop: ({ filled, selectedMemberIds, shift }) => {
    if (filled) {
      return false
    }

    const { attendances } = shift
    const attendingMemberIds = attendances.map(attendance => attendance.member.id)
    const draggedMemberAlreadyAttending = intersection(
      selectedMemberIds,
      attendingMemberIds
    ).length > 0

    return !draggedMemberAlreadyAttending
  }
}

const dropCollect = (dropTargetConnect, monitor) => ({
  connectDropTarget: dropTargetConnect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

export default DropTarget(
  MEMBER_CARD,
  shiftCalenderEventTarget,
  dropCollect
)(DropableShiftWrapper)
