import React from 'react'
import { connect } from 'react-redux'
import getGroupedShifts from '../../../../selectors/shifts/getGroupedShifts'
import getFilteredBundles from '../../../../selectors/bundles/getFilteredBundles'
import getBundles from '../../../../selectors/bundles/getBundles'
import GroupedShiftsList from './components/GroupedShiftsList'
import BundlesList from './components/BundlesList'

const ListView = ({ hasBundles, bundles, groupedShifts }) => (
  <div>
    { hasBundles &&
      <span>
        <h3>Vagtpakker</h3>
        <BundlesList bundles={bundles} />
        <br />
        <h3>Vagter</h3>
      </span>
    }
    <GroupedShiftsList groupedShifts={groupedShifts} />
  </div>
  )

const mapStateToProps = state => ({
  hasBundles: getBundles(state).length > 0,
  bundles: getFilteredBundles(state),
  groupedShifts: getGroupedShifts(state)
})

export default connect(
  mapStateToProps
)(ListView)
