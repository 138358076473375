import React, { useState } from 'react';

const ImageUploader = ({ onUpload, hasExistingImage }) => {
  const [showFileInput, setShowFileInput] = useState(!hasExistingImage);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const uploadUrl = window.location.pathname.replace(/\/edit$/, '/upload_template');
    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.error || 'Upload failed';
        onUpload({ error: errorMessage });
        return;
      }

      onUpload({ signedId: data.signed_id });
      setShowFileInput(false);
    } catch (error) {
      console.error('Upload failed:', error);
      onUpload({ error: 'Network error occurred while uploading' });
    }
  };

  if (!showFileInput) {
    return (
      <button
        type="button"
        onClick={() => setShowFileInput(true)}
        className="change-image-button"
      >
        Change Image
      </button>
    );
  }

  return (
    <input
      type="file"
      accept="image/*"
      onChange={handleFileChange}
      className="file-upload"
    />
  );
};

export default ImageUploader
