import {
  OPEN_SHIFT_BUNDLE_MODAL,
  CLOSE_SHIFT_BUNDLE_MODAL,
  CHANGE_CURRENT_SHIFT_BUNDLE
} from '../../../constants/action-types'

const currentShiftBundleReducer = (state = null, action) => {
  switch (action.type) {
    case OPEN_SHIFT_BUNDLE_MODAL: {
      return action.payload
    }
    case CHANGE_CURRENT_SHIFT_BUNDLE:
      return {
        ...state,
        ...action.payload
      }
    case CLOSE_SHIFT_BUNDLE_MODAL:
      return null
    default:
      return state
  }
}

export default currentShiftBundleReducer
