import CheckInReportApi from '../apis/CheckInReportApi'
import memberActions from './memberActions'

const {
  requestMembers,
  receiveMembers
} = memberActions

const api = new CheckInReportApi()

export const fetchCheckInReportMembers = () => (dispatch) => {
  dispatch(requestMembers())

  const { request, abort } = api.fetchAll()

  request.then(response => dispatch(receiveMembers(response.data)))

  return { request, abort }
}
