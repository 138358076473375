import { set, parse, getDate, getMonth, getYear } from 'date-fns'

const parseTimeFromValue = (oldTime, value) => {
  let newTime = oldTime
  const formattedValue = value.replace(/[_hm]/g, '0')
  newTime = parse(formattedValue, 'HH:mm', newTime)
  newTime = set(
    newTime, {
      date: getDate(oldTime),
      month: getMonth(oldTime),
      year: getYear(oldTime),
      seconds: 0,
      milliseconds: 0
    }
  )

  if (isNaN(newTime.getTime())) return oldTime

  return newTime
}

export default parseTimeFromValue
