/* global I18n */
/* eslint-disable jsx-a11y/label-has-for */

import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Row, Col } from 'react-bootstrap'

import { teamPropTypes } from '../propTypes'

const i18noptions = {
  scope: 'components.team_select'
}

const Team = ({ team, onChange, selected }) => (
  <label className="team" key={team.id}>
    <hr />
    <Row>
      <Col xs={8}>
        <Radio
          name="member[team_id]"
          value={team.id}
          onChange={onChange}
          checked={selected}
        />
        <h3 className="title">{team.name}</h3>
        <div className="description">
          <p>{team.description}</p>
        </div>
      </Col>
      <Col xs={4}>
        <h4>{I18n.t('spots_left', i18noptions)}</h4>
        {team.freeSpots}
      </Col>
    </Row>
  </label>
)

Team.propTypes = {
  team: teamPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default Team
