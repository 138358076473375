/* global I18n */

import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const i18noptions = {
  scope: 'shift_selector.bundle'
}

const BundleDetails = ({ bundle }) => {
  const {
    name,
    schedule,
    availableSpots,
    duration,
    shifts
  } = bundle

  return (
    <div className="bundle-details">
      <div className="bundle-name">
        <p><strong>{name}</strong></p>
      </div>
      <div className="bundle-schedule-name">
        <p>{schedule && schedule.name}</p>
      </div>
      <div className="bundle-total-shifts">
        <p>{I18n.t('shift_count', { ...i18noptions, count: shifts.length })}</p>
      </div>
      <div className="bundle-total-hours">
        <p>{I18n.t('hours', { ...i18noptions, count: duration, hours: duration })}</p>
      </div>
      <div className="bundle-available-spots">
        <p>{I18n.t('available_spots', { ...i18noptions, count: availableSpots })}</p>
      </div>
    </div>
  )
}

export default BundleDetails
