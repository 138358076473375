/* global Reflux */

import Actions from './Actions';

export default Reflux.createStore({
  listenables: [Actions],

  query: "",

  onReset: function () {
    this.query = "";
  },

  onUpdateQuery: function (query) {
    this.query = query;
    this.trigger(query);
  },

  getInitialState: function () {
    return this.query;
  }
});
