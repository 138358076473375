/* global I18n */

import React from 'react'

import { Label } from 'react-bootstrap'

import SelectableShift from '../../../../SelectableShift'
import ShiftDetailsSmall from '../../../../ShiftDetailsSmall'

const i18noptions = {
  scope: 'shift_selector.sidebar'
}

const ShiftGradeOverview = ({ grades, shifts, actions }) => {
  const getShiftsForGrade = gradeId => shifts.filter(shift => shift.grade && shift.grade.id === gradeId)

  const getShiftElements = gradeShifts => (gradeShifts.map(shift => (
    <SelectableShift
      key={shift.id}
      shift={shift}
      actions={actions}
    >
      <ShiftDetailsSmall shift={shift} />
    </SelectableShift>
  )))

  const getGradeContent = (gradeId, minimumCount) => {
    const gradeShifts = getShiftsForGrade(gradeId)
    if (gradeShifts.length > 0) {
      return getShiftElements(gradeShifts)
    }
    return (
      <div className="class-shift-placeholder">
        <i>{I18n.t('grade_requirement', { ...i18noptions, count: minimumCount || 0 })}</i>
      </div>
    )
  }

  const getGradeRequirements = () => grades.map((grade) => {
    const { id, name, color, minimumCount, maximumCount } = grade

    const gradeShifts = getShiftsForGrade(id)

    const getGradeLabel = () => {
      if (maximumCount) {
        return I18n.t('grade_label_min_max', {
          ...i18noptions,
          selectedCount: gradeShifts.length,
          minimumCount: minimumCount || 0,
          maximumCount
        })
      }

      return I18n.t('grade_label_fixed', { ...i18noptions, selectedCount: gradeShifts.length, minimumCount })
    }

    return (
      <div className="shift-class-group" key={id}>
        <i className="fa fa-circle" style={{ color }} />&nbsp;
        <strong>{name}</strong>
        <div className="pull-right">
          {!!minimumCount && (
            <Label bsStyle={gradeShifts.length < minimumCount ? 'danger' : 'primary'}>
              {getGradeLabel()}
            </Label>
          )}
        </div>
        {getGradeContent(id, minimumCount)}
      </div>
    )
  })

  return (
    <div>
      {getGradeRequirements()}
      <hr />
    </div>
  )
}

export default ShiftGradeOverview
