/* global I18n */

import React from 'react'
import { bool, func, arrayOf } from 'prop-types'
import { connect } from 'react-redux'
import { camelCase } from 'lodash'

import {
  Tab,
  Nav,
  NavItem,
  Modal,
  Badge
} from 'react-bootstrap'

import {
  closeShiftGradeModal,
  createOrUpdateShiftGrade,
  changeCurrentShiftGrade,
  deleteShiftGrade,
  removeShift
} from '../../actions/shift-grade-actions'
import errorsPropTypes from '../../../../propTypes/errorsPropTypes'
import shiftGradePropTypes from '../../../propTypes/shiftGradePropTypes'
import { shiftPropTypes } from '../../../propTypes/shiftPropTypes'
import shiftsInGradeSelector from '../../selectors/shifts/shiftsInGradeSelector'

import ColorSelect from '../../../../components/ColorSelect'
import ModalFormShiftsList from './ModalFormShiftsList'
import ButtonIcon from '../../../../components/ButtonIcon'
import { FieldGroup } from '../../../../components/formExtensions'
import ModalForm from '../../../../components/ModalForm'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.grades.modal_form'
}

class GradeModalForm extends React.Component {
  handleChangeName = (newTranslationsAttributes) => {
    const { onChange } = this.props

    onChange({
      translationsAttributes: newTranslationsAttributes
    })
  }

  handleChangeColor = (color) => {
    const { onChange } = this.props
    const { hex } = color

    onChange({
      color: hex
    })
  }

  handleChangeCountPerMemberType = (id, event) => {
    const target = event.target
    const { value, name } = target
    const type = camelCase(name)
    const { currentGrade, onChange } = this.props
    const { [type]: currentValue } = currentGrade

    onChange({
      [type]: {
        ...currentValue,
        [id]: value
      }
    })
  }

  handleChangeCountPerMemberTypeForId = id => event => (
    this.handleChangeCountPerMemberType(id, event)
  )

  handleExited = () => {
    const { onClose } = this.props

    onClose()
  }

  handleDelete = () => {
    const { onDelete, currentGrade } = this.props
    const { id } = currentGrade

    if (confirm(I18n.t('confirm_delete', i18noptions))) {
      onDelete(id)
    }
  }

  handleSubmit = () => {
    const { onSubmit, currentGrade } = this.props

    onSubmit(currentGrade)
  }

  render() {
    const { validationErrors, currentGrade, memberTypes, isLoading, shifts, onRemoveShift } = this.props

    const { translationsAttributes, color, minimumCountPerMemberType = {}, maximumCountPerMemberType = {} } = currentGrade
    const newRecord = !currentGrade.id
    const moreThenOneMemberType = memberTypes.length > 1

    return (
      <ModalForm
        model="ShiftGrade"
        onSubmit={this.handleSubmit}
        onExited={this.handleExited}
        newRecord={newRecord}
        isLoading={isLoading}
        headerButtons={newRecord ? null : [
          <ButtonIcon
            key="delete"
            bsStyle="danger"
            onClick={this.handleDelete}
            icon="trash"
            tooltip={I18n.t('helpers.actions.destroy', { model: I18n.t('activerecord.models.shift_grade.one') })}
          />
        ]}
        body={
          <Tab.Container defaultActiveKey="information">
            <div>
              <Nav bsStyle="tabs" justified>
                <NavItem eventKey="information">
                  <span>{I18n.t('information', i18noptions)}</span>
                </NavItem>
                <NavItem eventKey="shifts">
                  <span>{I18n.t('shifts', i18noptions)} {shifts && (<Badge>{shifts.length}</Badge>)}</span>
                </NavItem>
              </Nav>
              <Modal.Body className={isLoading ? 'is-loading' : null}>
                <Tab.Content animation>
                  <Tab.Pane eventKey="information">
                    <p>{I18n.t('description', i18noptions)}</p>
                    <FieldGroup.Translateable
                      model="ShiftGrade"
                      name="name"
                      onChange={this.handleChangeName}
                      translationsAttributes={translationsAttributes}
                      errors={validationErrors ? validationErrors.translationsAttributes : undefined}
                    />
                    <div>
                      <div>
                        <h4>{I18n.t('minimum_count_per_member_type_header', i18noptions)}</h4>
                        <p>{I18n.t('minimum_count_per_member_type_description', i18noptions)}</p>
                        {memberTypes.map(memberType => (
                          <FieldGroup
                            model="ShiftGrade"
                            name="minimum_count_per_member_type"
                            onChange={this.handleChangeCountPerMemberTypeForId(memberType.id)}
                            label={moreThenOneMemberType ? memberType.name : false}
                            defaultValue={minimumCountPerMemberType[memberType.id]}
                          />
                        ))}
                      </div>
                      <div>
                        <h4>{I18n.t('maximum_count_per_member_type_header', i18noptions)}</h4>
                        <p>{I18n.t('maximum_count_per_member_type_description', i18noptions)}</p>
                        {memberTypes.map(memberType => (
                          <FieldGroup
                            model="ShiftGrade"
                            name="maximum_count_per_member_type"
                            onChange={this.handleChangeCountPerMemberTypeForId(memberType.id)}
                            label={moreThenOneMemberType ? memberType.name : false}
                            defaultValue={maximumCountPerMemberType[memberType.id]}
                          />
                        ))}
                      </div>
                    </div>
                    <FieldGroup
                      model="ShiftGrade"
                      name="color"
                      errors={validationErrors ? validationErrors.color : undefined}
                      value={color}
                      inputComponent={ColorSelect}
                      onChange={this.handleChangeColor}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="shifts">
                    <ModalFormShiftsList shifts={shifts} onRemoveShift={onRemoveShift} />
                  </Tab.Pane>
                </Tab.Content>
              </Modal.Body>
            </div>
          </Tab.Container>
        }
      />
    )
  }
}

GradeModalForm.propTypes = {
  currentGrade: shiftGradePropTypes.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired,
  onRemoveShift: func.isRequired,
  shifts: arrayOf(shiftPropTypes).isRequired,
  validationErrors: errorsPropTypes,
  isLoading: bool.isRequired
}

GradeModalForm.defaultProps = {
  validationErrors: null,
  shifts: []
}

const mapStateToProps = (state) => {
  const {
    validationErrors,
    currentShiftGrade,
    isLoading
  } = state.currentApp.ui.shiftGradeModalForm

  const id = currentShiftGrade.id
  let shifts
  if (id) {
    shifts = shiftsInGradeSelector(state, id)
  }

  return {
    currentGrade: currentShiftGrade,
    validationErrors,
    isLoading,
    shifts
  }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeShiftGradeModal()),
  onSubmit: attributes => dispatch(createOrUpdateShiftGrade(attributes)),
  onChange: attributes => dispatch(changeCurrentShiftGrade(attributes)),
  onDelete: id => dispatch(deleteShiftGrade(id)),
  onRemoveShift: id => dispatch(removeShift(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(GradeModalForm)
