import { orm } from '../../store'

import updateSession from './updateSession'
import metaReducer from './metaReducer'

const ormReducer = (dbState, action) => {
  const session = orm.session(dbState)

  updateSession(session, action)

  return metaReducer(session.state, action)
}

export default ormReducer
