// Members
export const sortingMethodTypes = {
  SORTING_BY_NAME: 'SCHEDULE_PLANNER_SORTING_BY_NAME',
  SORTING_BY_SHIFT_COUNT: 'SCHEDULE_PLANNER_SORTING_BY_SHIFT_COUNT',
  SORTING_BY_SHIFT_HOURS: 'SCHEDULE_PLANNER_SORTING_BY_SHIFT_HOURS',
  SORTING_BY_MISSING_HOURS: 'SCHEDULE_PLANNER_SORTING_BY_MISSING_HOURS'
}

export const sortingOrderTypes = {
  ASCENDING_ORDER: 'SCHEDULE_PLANNER_ASCENDING_ORDER',
  DESCENDING_ORDER: 'SCHEDULE_PLANNER_DESCENDING_ORDER'
}
