/* global I18n */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel, Table } from 'react-bootstrap'

import { getRequest } from '../../Api/request'

import LoadingIndicator from '../../components/LoadingIndicator'
import ShiftRow from './ShiftRow'

const i18noptions = {
  scope: 'components.grouped_shifts_for_registration'
}

class Group extends Component {
  constructor(props) {
    super(props);
    this.myRef = null;  // Initialize the ref
    this.setMyRef = element => {  // Define a callback ref
      this.myRef = element;
    };
    this.state = {
      shiftsLoaded: false,
      shifts: [],
      firstRender: true
    }
  }



  loadShifts = () => {
    const { shiftsLoaded } = this.state
    const { fetchShiftsPath } = this.props

    if (!shiftsLoaded) {
      getRequest({
        path: fetchShiftsPath
      }).then((response) => {
        this.setState({
          shiftsLoaded: true,
          shifts: response.data
        })
      })
    }
  }

  renderShifts = () => {
    const { shifts, shiftsLoaded } = this.state
    const { showSchedule } = this.props

    if (!shiftsLoaded) {
      return <LoadingIndicator size="2x" />
    }

    return (
      <div className="shifts-container">
        <div className="shifts-header">
          <div className="shift-cell">{I18n.t('date_time', i18noptions)}</div>
          {showSchedule && (
            <div className="shift-cell">{I18n.t('schedule', i18noptions)}</div>
          )}
          <div className="shift-cell">{I18n.t('shift', i18noptions)}</div>
          <div className="shift-cell"></div>
        </div>

        <div className="shifts-body">
          {shifts.map(shift => (
            <ShiftRow {...shift} key={shift.id} showSchedule={showSchedule} />
          ))}
        </div>
      </div>
    )
  }

  handleToggle = (expanded) => {
    const { name } = this.props
    this.props.handleGroupSelect(name, expanded)
  }

  scrollToGroup = () => {
    const node = this.myRef;
    if (node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  }

  componentDidMount(props) {
    const { activeGroup, name } = this.props
    if (activeGroup === name) {
      this.scrollToGroup()
    }
    this.setState({ firstRender: false });
  }

  render() {
    const { name, count, expandOnLessThen, activeGroup } = this.props

    const defaultExpanded = count <= expandOnLessThen
    const expanded = defaultExpanded || activeGroup === name

    if (expanded) {
      this.loadShifts()
    }

    return (
      <section ref={this.setMyRef}>
        <Panel onToggle={this.handleToggle} expanded={expanded} >
          <Panel.Heading>
            <Panel.Title toggle>
              {name}
            </Panel.Title>
          </Panel.Heading>

          <Panel.Collapse>
            <Panel.Body className="group-panel">
              {this.renderShifts()}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </section>
    )
  }
}

Group.defaultProps = {
  showSchedule: true,
  expandOnLessThen: 3
}

Group.propTypes = {
  fetchShiftsPath: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showSchedule: PropTypes.bool,
  count: PropTypes.number.isRequired,
  expandOnLessThen: PropTypes.number
}

export default Group
