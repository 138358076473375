/* global I18n, moment */

import React from 'react'
import PropTypes from 'prop-types'

import {
  Tabs, Tab,
  Form, FormGroup, FormControl, ControlLabel,
  Col, Row
} from 'react-bootstrap'

import MaskedDateInput from '../components/MaskedDateInput'

const i18noptions = {
  scope: 'components.sms_preview_widget'
}

class SmsPreviewWidget extends React.Component {
  state = {
    teamName: I18n.t('example_team', i18noptions),
    startTime: moment()
  }

  handleChangeTeamName = (event) => {
    this.setState({
      teamName: event.target.value
    })
  }

  handleChangeStartTime = (startTime) => {
    this.setState({ startTime })
  }

  renderTabs = () => {
    const { languages } = this.props
    const { teamName, startTime } = this.state
    const dateStartTime = startTime.toDate()

    let translationKey
    if (startTime.isSame(moment(), 'day')) {
      translationKey = 'shift_reminder'
    } else {
      translationKey = 'shift_reminder_with_date'
    }

    const previousLocale = I18n.locale

    const tabs = languages.map((locale) => {
      I18n.locale = locale

      const smsText = I18n.t(`sms.${translationKey}`, {
        start_time: I18n.l('time.formats.time', dateStartTime),
        start_date: I18n.l('date.formats.short', dateStartTime),
        team_name: teamName
      })

      return (
        <Tab eventKey={locale} title={locale}>
          <br />
          <div>{smsText}</div>
          <br />
          <div><small>{I18n.t('number_of_characters', { ...i18noptions, length: smsText.length, locale: previousLocale })}</small></div>
        </Tab>
      )
    })

    I18n.locale = previousLocale

    return tabs
  }

  render() {
    const { teamName, startTime } = this.state

    return (
      <div>
        <h4>{I18n.t('sms_preview_title', i18noptions)}</h4>
        <Row>
          <Col md={4}>
            <Form>
              <FormGroup>
                <ControlLabel>
                  Team name
                </ControlLabel>
                <FormControl
                  value={teamName}
                  onChange={this.handleChangeTeamName}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  Start time
                </ControlLabel>
                <MaskedDateInput
                  customInput={<FormControl />}
                  selected={startTime}
                  onChange={this.handleChangeStartTime}
                  popperPlacement="auto"
                  showTimeSelect
                  mask={false}
                  timeFormat={I18n.t('time_format', i18noptions)}
                  dateFormat={`${I18n.t('date_format', i18noptions)} ${I18n.t('time_format', i18noptions)}`}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col md={8}>
            <Tabs defaultActiveKey={I18n.locale} animation={false}>
              {this.renderTabs()}
            </Tabs>
          </Col>
        </Row>
      </div>
    )
  }
}

SmsPreviewWidget.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
}

export default SmsPreviewWidget
