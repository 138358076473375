import schedulePlannerReducers from '../schedule-app/SchedulePlanner/reducers'
import shiftSelectorReducers from '../schedule-app/ShiftSelector/reducers'

const assignStoreAppReducers = store => (
  store.appReducers = {
    ShiftSelector: shiftSelectorReducers,
    SchedulePlanner: schedulePlannerReducers
  }
)

export default assignStoreAppReducers
