/* global I18n */

import React from 'react'
import { bool, func, arrayOf } from 'prop-types'
import { connect } from 'react-redux'

import {
  Tab,
  Nav,
  NavItem,
  Modal,
  Badge
} from 'react-bootstrap'

import {
  closeShiftBundleModal,
  createOrUpdateShiftBundle,
  changeCurrentShiftBundle,
  deleteShiftBundle,
  removeShift
} from '../../actions/shift-bundle-actions'
import errorsPropTypes from '../../../../propTypes/errorsPropTypes'
import shiftBundlePropTypes from '../../../propTypes/shiftBundlePropTypes'
import { shiftPropTypes } from '../../../propTypes/shiftPropTypes'
import shiftsInBundleSelector from '../../selectors/shifts/shiftsInBundleSelector'

import ColorSelect from '../../../../components/ColorSelect'
import ModalFormShiftsList from './ModalFormShiftsList'
import ButtonIcon from '../../../../components/ButtonIcon'
import { FieldGroup } from '../../../../components/formExtensions'
import ModalForm from '../../../../components/ModalForm'
import RegistrationRequirementsTab from './ShiftModalForm/RegistrationRequirementsTab'

const i18noptions = {
  scope: 'components.schedule_planner.shifts.bundles.modal_form'
}

class BundleModalForm extends React.Component {
  handleChangeName = (newTranslationsAttributes) => {
    const { onChange } = this.props

    onChange({
      translationsAttributes: newTranslationsAttributes
    })
  }

  handleChangeColor = (color) => {
    const { onChange } = this.props
    const { hex } = color

    onChange({
      color: hex
    })
  }

  handleExited = () => {
    const { onClose } = this.props

    onClose()
  }

  handleDelete = () => {
    const { onDelete, currentBundle } = this.props
    const { id } = currentBundle

    if (confirm(I18n.t('confirm_delete', i18noptions))) {
      onDelete(id)
    }
  }

  handleSubmit = () => {
    const { onSubmit, currentBundle } = this.props

    onSubmit(currentBundle)
  }

  render() {
    const { validationErrors, currentBundle, isLoading, shifts, onRemoveShift, onChange } = this.props

    const { translationsAttributes, color, requirements } = currentBundle
    const currentRequirements = requirements || []
    const newRecord = !currentBundle.id

    return (
      <ModalForm
        model="ShiftBundle"
        onSubmit={this.handleSubmit}
        onExited={this.handleExited}
        newRecord={newRecord}
        isLoading={isLoading}
        headerButtons={newRecord ? null : [
          <ButtonIcon
            key="delete"
            bsStyle="danger"
            onClick={this.handleDelete}
            icon="trash"
            tooltip={I18n.t('helpers.actions.destroy', { model: I18n.t('activerecord.models.shift_bundle.one') })}
          />
        ]}
        body={
          <Tab.Container defaultActiveKey="information">
            <div>
              <Nav bsStyle="tabs" justified>
                <NavItem eventKey="information">
                  <span>{I18n.t('information', i18noptions)}</span>
                </NavItem>
                <NavItem eventKey="requirements">
                  <span>{I18n.t('requirements', i18noptions)} {shifts && (<Badge>{currentRequirements.length}</Badge>)}</span>
                </NavItem>
                <NavItem eventKey="shifts">
                  <span>{I18n.t('shifts', i18noptions)} {shifts && (<Badge>{shifts.length}</Badge>)}</span>
                </NavItem>
              </Nav>
              <Modal.Body className={isLoading ? 'is-loading' : null}>
                <Tab.Content animation>
                  <Tab.Pane eventKey="information">
                    <p>{I18n.t('description', i18noptions)}</p>
                    <FieldGroup.Translateable
                      model="ShiftBundle"
                      name="name"
                      onChange={this.handleChangeName}
                      translationsAttributes={translationsAttributes}
                      errors={validationErrors ? validationErrors.translationsAttributes : undefined}
                    />
                    <FieldGroup
                      model="ShiftBundle"
                      name="color"
                      errors={validationErrors ? validationErrors.color : undefined}
                      value={color}
                      inputComponent={ColorSelect}
                      onChange={this.handleChangeColor}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="requirements">
                    <RegistrationRequirementsTab
                      requirements={currentRequirements}
                      onChange={onChange}
                      onBundle
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="shifts">
                    <ModalFormShiftsList shifts={shifts} onRemoveShift={onRemoveShift} />
                  </Tab.Pane>
                </Tab.Content>
              </Modal.Body>
            </div>
          </Tab.Container>
        }
      />
    )
  }
}

BundleModalForm.propTypes = {
  currentBundle: shiftBundlePropTypes.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired,
  onRemoveShift: func.isRequired,
  shifts: arrayOf(shiftPropTypes),
  validationErrors: errorsPropTypes,
  isLoading: bool.isRequired
}

BundleModalForm.defaultProps = {
  validationErrors: null,
  shifts: []
}

const mapStateToProps = (state) => {
  const {
    validationErrors,
    currentShiftBundle,
    isLoading
  } = state.currentApp.ui.shiftBundleModalForm

  const id = currentShiftBundle.id
  let shifts
  if (id) {
    shifts = shiftsInBundleSelector(state, id)
  }

  return {
    currentBundle: currentShiftBundle,
    validationErrors,
    isLoading,
    shifts
  }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeShiftBundleModal()),
  onSubmit: attributes => dispatch(createOrUpdateShiftBundle(attributes)),
  onChange: attributes => dispatch(changeCurrentShiftBundle(attributes)),
  onDelete: id => dispatch(deleteShiftBundle(id)),
  onRemoveShift: id => dispatch(removeShift(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(BundleModalForm)
