/* global I18n */

import React from 'react'
import { bool, string, number, func, oneOf, oneOfType } from 'prop-types'

import polymorphicPath from '../../utilities/polymorphicPath'
import { humanizeModel } from '../../utilities/i18n'

const ModelLink = ({ model, id, action, component, modal, confirm, ...extraProps }) => {
  let labelKey = action
  const modelName = humanizeModel(model)
  const linkProps = {
    href: polymorphicPath({ model, id, action }),
    ...extraProps
  }

  if (action === 'delete') {
    labelKey = 'destroy'
    linkProps['data-method'] = 'delete'
  }

  if (modal) {
    linkProps['data-modal'] = true
  }

  if (confirm) {
    linkProps['data-confirm'] = confirm === true ? I18n.t('helpers.confirm_delete') : confirm
  }

  const label = I18n.t(`helpers.actions.${labelKey}`, { model: modelName })

  return React.createElement(
    component,
    linkProps,
    label
  )
}

ModelLink.propTypes = {
  model: string.isRequired,
  id: number.isRequired,
  component: oneOfType([func, string]),
  action: oneOf(['show', 'new', 'edit', 'delete']),
  modal: bool,
  confirm: oneOfType([bool, string])
}

ModelLink.defaultProps = {
  component: 'a',
  action: 'show',
  modal: false,
  confirm: false
}

export default ModelLink
