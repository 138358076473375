/* global I18n */

import React from 'react'
import { connect } from 'react-redux'
import ReactSelect from 'react-select'

import getSchedules from '../../../selectors/schedules/getSchedules'
import { setScheduleFilter } from '../../../actions/filter-actions'

const i18noptions = {
  scope: 'shift_selector.schedule_filter'
}

const getOptions = schedules => (
  schedules.map(schedule => ({
    value: schedule.id,
    label: schedule.name
  }))
)

const ScheduleFilter = ({ value, options, label, onChange }) => (
  <ReactSelect
    value={value}
    onChange={onChange}
    options={options}
    placeholder="Alle vagtplaner"
    multi
  />
)

const mapStateToProps = state => ({
  value: state.currentApp.filters.scheduleFilter,
  options: getOptions(getSchedules(state)),
  label: I18n.t('label', i18noptions)
})

const mapDispatchToProps = dispatch => ({
  onChange: values => dispatch(setScheduleFilter(values.map(value => value.value)))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleFilter)
