/* global I18n */

import React from 'react'
import { func, arrayOf, objectOf } from 'prop-types'
import ShiftList from '../../../../../components/ShiftList'
import SelectableShift from '../../../SelectableShift'
import ShiftDetailsSmall from '../../../ShiftDetailsSmall'
import SelectedShiftsBadge from './components/SelectedShiftsBadge'
import ShiftGradeOverview from './components/ShiftGradeOverview'
import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'
import schedulePropTypes from '../../../../../propTypes/schedulePropTypes'

const i18noptions = {
  scope: 'shift_selector.sidebar'
}

const ScheduleAndGradeGroupedShiftsList = ({ shifts, schedules, actions }) => {
  const getHourLimitsDescription = (schedule) => {
    const { minimumShiftHours, maximumShiftHours } = schedule

    let hourDescription
    if (minimumShiftHours && maximumShiftHours) {
      hourDescription = I18n.t('schedule.min_max_hours_description_html', {
        ...i18noptions,
        min_hours: minimumShiftHours,
        max_hours: maximumShiftHours
      })
    } else if (minimumShiftHours) {
      hourDescription = I18n.t('schedule.min_hours_description_html', {
        ...i18noptions,
        min_hours: minimumShiftHours
      })
    }

    return hourDescription
  }

  const getShiftList = shiftIds => shifts
    .filter(shift => shiftIds.includes(shift.id) && !shift.grade)
    .map(shift => (
      <SelectableShift
        key={shift.id}
        shift={shift}
        actions={actions}
      >
        <ShiftDetailsSmall shift={shift} />
      </SelectableShift>
    ))

  const getScheduleList = () => schedules.map(schedule => (
    <div className="ibox-content" key={schedule.id}>
      <div>
        <h3 style={{ display: 'inline-block' }}>{schedule.name}</h3>
        <SelectedShiftsBadge pullRight schedule={schedule} />
      </div>
      <p dangerouslySetInnerHTML={{ __html: getHourLimitsDescription(schedule) }} />
      <ShiftGradeOverview grades={schedule.grades} shifts={shifts} actions={actions} />
      {getShiftList(schedule.shifts)}
    </div>
  ))

  return (
    <ShiftList>
      {shifts.length === 0 ?
        <div className="ibox-content">
          <i>{I18n.t('no_shifts', i18noptions)}</i>
        </div>
        : getScheduleList()}
    </ShiftList>
  )
}

ScheduleAndGradeGroupedShiftsList.propTypes = {
  shifts: arrayOf(shiftPropTypes).isRequired,
  actions: objectOf(func).isRequired,
  schedules: arrayOf(schedulePropTypes).isRequired
}

export default ScheduleAndGradeGroupedShiftsList
