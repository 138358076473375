import Api, { RestPath } from '../../../Api'
import { getRequest, patchRequest, postRequest } from '../../../Api/request'

class ShiftGradeApi extends Api {
  constructor(scheduleId) {
    const parentPath = new RestPath('schedules')
    const path = new RestPath('shift_grades', parentPath)
    super(path)

    this.scheduleId = scheduleId
  }

  fetchAll() {
    return getRequest({
      path: this.path.collectionPath(this.scheduleId)
    })
  }

  create({ body }) {
    return postRequest({
      path: this.path.collectionPath(this.scheduleId),
      body
    })
  }

  update(id, { body }) {
    return patchRequest({
      path: this.path.memberPath(id),
      body
    })
  }
}

export default ShiftGradeApi
