/* global I18n */
import React from 'react'
import { string, shape, object, func, arrayOf } from 'prop-types'

import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap'

import FieldGroup from '../FieldGroup'

import './styles.scss'

const i18noptions = {
  scope: 'components.translatable_field_group'
}

const localeToCountryCode = locale => ({
  da: 'dk',
  en: 'gb'
}[locale] || locale)

class TranslateableFieldGroup extends React.Component {
  locales = []

  componentWillMount() {
    this.locales = JSON.parse(document.body.dataset.availableLocales)
  }

  handleChangeInput = (locale, event) => {
    const { onChange, translationsAttributes, name } = this.props
    const value = event.target.value

    onChange({
      ...translationsAttributes,
      [locale]: {
        ...translationsAttributes[locale],
        [name]: value
      }
    })
  }

  render() {
    const { translationsAttributes, name, errors, ...fieldProps } = this.props

    const errorsForLocale = {}
    this.locales.forEach(locale => (
      errorsForLocale[locale] = errors
        .map(error => error[locale] && error[locale][name])
        .filter(error => error !== undefined)
    ))

    const defaultLocale = this.locales[0]

    if (this.locales.length <= 1) {
      return (
        <FieldGroup
          {...fieldProps}
          value={translationsAttributes[defaultLocale] ? translationsAttributes[defaultLocale][name] : ''}
          name={name}
          onChange={event => this.handleChangeInput(defaultLocale, event)}
          errors={errorsForLocale[defaultLocale]}
        >
          {(renderInput, renderLabel, renderHelp) => (
            <div>
              {renderLabel()}
              {renderInput({ name: `translationsAttributes[${defaultLocale}][${name}]` })}
              {renderHelp()}
            </div>
          )}
        </FieldGroup>
      )
    }

    const combinedErrorsForLocales = Object.entries(
      errorsForLocale
    ).reduce((generalErrors, forLocale) => (
      generalErrors.concat(
        forLocale[1].map(
          error => `${I18n.t(forLocale[0], i18noptions)}: ${error}`
        )
      )
    ), [])

    return (
      <FieldGroup
        {...fieldProps}
        name={name}
        errors={combinedErrorsForLocales}
      >
        {(renderInput, renderLabel, renderHelp) => (
          <div>
            {renderLabel()}
            <Tab.Container defaultActiveKey={defaultLocale}>
              <Row className="clearfix">
                <Col sm={12}>
                  <Nav bsStyle="tabs">
                    {this.locales.map(locale => (
                      <NavItem
                        eventKey={locale}
                        className={errorsForLocale[locale].length > 0 ? 'has-error' : undefined}
                      >
                        <span className={`fi fi-${localeToCountryCode(locale)}`} />
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content animation>
                    {this.locales.map(locale => (
                      <Tab.Pane eventKey={locale}>
                        {renderInput({
                          value: translationsAttributes[locale] ? translationsAttributes[locale][name] : '',
                          name: `translationsAttributes[${locale}][${name}]`,
                          onChange: event => this.handleChangeInput(locale, event),
                          errors: errorsForLocale[locale]
                        })}
                        {renderHelp()}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        )}
      </FieldGroup>
    )
  }
}

TranslateableFieldGroup.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  translationsAttributes: shape({
    da: object,
    en: object,
    de: object,
    fo: object
  }),
  errors: arrayOf(string)
}

TranslateableFieldGroup.defaultProps = {
  translationsAttributes: {},
  errors: []
}

export default TranslateableFieldGroup
