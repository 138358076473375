import { string, number, shape } from 'prop-types'

const memberTypePropTypes = shape({
  id: number.isRequired,
  name: string.isRequired,
  minimumShiftHours: number,
  maximumShiftHours: number
})

export default memberTypePropTypes
