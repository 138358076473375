/* global I18n */
import { createSelector } from 'redux-orm'
import { orm } from '../../store'

const dbStateSelector = state => state.orm

const getTeams = createSelector(
  orm,
  dbStateSelector,
  session => session.Team.all().toModelArray().map(team => ({
    ...team.ref,
    createdAt: I18n.l('time.formats.short', team.momentCreatedAt.toDate()),
    group: team.group && team.group.ref
  }))
)

export default getTeams
