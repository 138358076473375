import React from 'react'
import { func, arrayOf, shape } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deselectShift } from '../../../../actions/shift-actions'
import getDirectSelectedShifts from '../../../../selectors/shifts/getDirectSelectedShifts'
import getSelectedSchedules from '../../../../selectors/schedules/getSelectedSchedules'
import { shiftPropTypes } from '../../../../../propTypes/shiftPropTypes'
import schedulePropTypes from '../../../../../propTypes/schedulePropTypes'
import ScheduleAndGradeGroupedShiftsList from '../ScheduleAndGradeGroupedShiftsList'

const SelectedShiftsList = ({ shifts, schedules, actions }) => (
  <ScheduleAndGradeGroupedShiftsList
    shifts={shifts}
    schedules={schedules}
    actions={actions}
  />
)

SelectedShiftsList.propTypes = {
  shifts: arrayOf(shiftPropTypes).isRequired,
  schedules: arrayOf(schedulePropTypes).isRequired,
  actions: shape({
    deselectShift: func.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  shifts: getDirectSelectedShifts(state),
  schedules: getSelectedSchedules(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    deselectShift
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedShiftsList)
