import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNil } from 'lodash'

import scheduleSelector from '../../../selectors/schedules/scheduleSelector'
import requirementsForCurrentShiftSelector from '../../../selectors/shiftMemberTypePropertyRequirements/requirementsForCurrentShiftSelector'

import NewShiftModalForm from './NewShiftModalForm'
import EditShiftModalForm from './EditShiftModalForm'

import {
  closeShiftModal,
  changeCurrentShift,
  updateShift,
  deleteShift,
  copyShift,
  createShifts
} from '../../../actions/shift-actions'
import { shiftPropTypes, newShiftPropTypes } from '../../../../propTypes/shiftPropTypes'

import './styles.scss'

class ShiftModalForm extends React.Component {
  handleChange = (newValues) => {
    const { onChange } = this.props

    onChange(newValues)
  }

  render() {
    const { action, currentShift, isCopying, onCreate, onUpdate, ...props } = this.props

    if (action === 'new') {
      return (
        <NewShiftModalForm
          {...props}
          isCopying={isCopying}
          onCreate={onCreate}
          onChange={this.handleChange}
          shift={currentShift}
        />
      )
    } else if (action === 'edit') {
      return (
        <EditShiftModalForm
          {...props}
          onUpdate={onUpdate}
          onChange={this.handleChange}
          shift={currentShift}
        />
      )
    }

    return null
  }
}

ShiftModalForm.defaultProps = {
  currentShift: {},
  action: null,
  defaultMeetingPoint: null
}

ShiftModalForm.propTypes = {
  currentShift: PropTypes.oneOfType([newShiftPropTypes, shiftPropTypes]),
  defaultMeetingPoint: PropTypes.string,
  action: PropTypes.oneOf(['new', 'edit']),
  isCopying: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const { loadingStatus, currentApp } = state
  const { ui } = currentApp
  const { shiftModalForm } = ui
  const { membersList } = loadingStatus
  const { currentShift, action, isActive: _, ...otherUi } = shiftModalForm
  const defaultRequirements = requirementsForCurrentShiftSelector(state)
  const { location: defaultMeetingPoint } = scheduleSelector(state)
  let membersIsLoading = membersList

  if (currentShift !== null) {
    const { attendances, preferences } = currentShift

    // We need to update the current shift with member assocation first
    if (!attendances || !preferences) {
      membersIsLoading = true
    } else if (attendances.length > 0) {
      membersIsLoading = !attendances[0].member
    } else if (preferences.length > 0) {
      membersIsLoading = !preferences[0].member
    }

    if (!membersIsLoading) {
      const memberIds = attendances
        .filter(attendance => !attendance._destroy) // eslint-disable-line no-underscore-dangle
        .map(attendance => attendance.member.id)

      const filteredPreferences = preferences.filter(preference => (
        !memberIds.includes(preference.member.id)
      ))

      Object.assign(currentShift, {
        preferences: filteredPreferences
      })
    }

    // Assign default meeting point if action is new and the field has not been touched
    if (action === 'new' && isNil(currentShift.meetingPoint)) {
      Object.assign(currentShift, {
        meetingPoint: defaultMeetingPoint
      })
    }

  }

  return {
    ...otherUi,
    action,
    defaultRequirements,
    currentShift,
    membersIsLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeShiftModal()),
  onChange: changes => dispatch(changeCurrentShift(changes)),
  onUpdate: (id, shift) => dispatch(updateShift(id, shift)),
  onDelete: id => dispatch(deleteShift(id)),
  onCopy: shift => dispatch(copyShift(shift)),
  onCreate: shift => dispatch(createShifts(shift))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftModalForm)
