// ORM
export const REQUEST_OBJECT_CREATION = 'HEAP::REQUEST_OBJECT_CREATION'
export const RECEIVE_OBJECT_DELETION = 'HEAP::RECEIVE_OBJECT_DELETION'
export const RECEIVE_OBJECT_ERROR = 'HEAP::RECEIVE_OBJECT_ERROR'
export const REQUEST_OBJECT = 'HEAP::REQUEST_OBJECT'
export const RECEIVE_OBJECT = 'HEAP::RECEIVE_OBJECT'
export const REQUEST_OBJECTS = 'HEAP::REQUEST_OBJECTS'
export const RECEIVE_OBJECTS = 'HEAP::RECEIVE_OBJECTS'

// Current app
export const CLEAR_CURRENT_APP = 'HEAP::CLEAR_CURRENT_APP'

// Shared
export const RECEIVE_CURRENT_MEMBER = 'HEAP::RECEIVE_CURRENT_MEMBER'
export const RECEIVE_CURRENT_EVENT_OR_ORGANIZATION = 'HEAP::RECEIVE_CURRENT_EVENT_OR_ORGANIZATION'
