/* global React, ReactDOM, Reflux, I18n */

import CheckedInLabel from './CheckedInLabel';
import SearchAutocomplete from './SearchAutocomplete';
import ExtendedListModal from './ExtendedListModal';
import TicketData from './TicketData';
import OwnersStore from './OwnersStore';
import TicketStore from './TicketStore';
import QueryStore from './QueryStore';
import Actions from './Actions';
import CheckedInStore from './CheckedInStore';
import EventPosStore from './EventPosStore';

import createReactClass from 'create-react-class';

export default createReactClass({
  mixins: [
    Reflux.connect(OwnersStore, 'owners'),
    Reflux.connect(TicketStore, 'ticket'),
    Reflux.connect(QueryStore, 'query'),
    Reflux.ListenerMixin
  ],

  autocompleteRef: null,

  getInitialState: function () {
    return {
      showExtendedResults: false,
      autocompleteFocus: false
    };
  },

  componentWillMount: function () {
    OwnersStore.url = this.props.searchOwnerUrl;
    TicketStore.findUrl = this.props.findTicketUrl;
    TicketStore.checkInUrl = this.props.checkInUrl;
    TicketStore.removeCheckInUrl = this.props.removeCheckInUrl;
    EventPosStore.activateChipUrl = this.props.activateChipUrl;

    CheckedInStore.setupCounter(this.props.checkedTickets, this.props.totalTickets);
    CheckedInStore.listenPusher(this.props.counterChannel);
  },

  componentDidMount: function () {
    var self = this;

    $(ReactDOM.findDOMNode(this)).find('input').focus();

    this.listenTo(Actions.reset, function () {
      $(ReactDOM.findDOMNode(this)).find('input').focus();
    });

    this.listenTo(Actions.showExtendedModal, function () {
      this.setState({ showExtendedResults: true });
    });

    this.listenTo(Actions.dismissExtendedModal, function () {
      this.setState({ showExtendedResults: false });
    });

    this.listenTo(Actions.reset, function () {
      $('input#query').val('');
    });
  },

  handleFocus: function () {
    Actions.updateQuery('');
  },

  handleChange: function (e) {
    this.setState({ autocompleteFocus: false });
    Actions.updateQuery(e.target.value);
  },

  handleKeyUp: function (e) {
    // Arrow up
    if (e.keyCode == "38" && this.autocompleteRef) {
      this.setState({ autocompleteFocus: true });
      this.autocompleteRef.scrollToPrev();
    }

    // Arrow down
    if (e.keyCode == "40" && this.autocompleteRef) {
      this.setState({ autocompleteFocus: true });
      this.autocompleteRef.scrollToNext();
    }

    // Enter
    if (e.keyCode == "13") {
      if (this.state.autocompleteFocus && this.autocompleteRef) {
        this.autocompleteRef.touchUnderScroll();
      } else if (!this.state.owners.loaded || this.state.owners.list.length > 0) {
        Actions.forceSearch(e.target.value);
      }
    }
  },

  isShowAutocomplete: function () {
    return (!this.state.showExtendedResults && this.state.owners.loaded);
  },

  render: function () {
    return (
      <span>
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h5>{I18n.t('tickets_scanner.input_title')}</h5>
            <CheckedInLabel />
          </div>
          <div className="ibox-content">
            <input onChange={this.handleChange}
              onFocus={this.handleFocus}
              onKeyUp={this.handleKeyUp}
              type="text"
              id="query"
              placeholder={I18n.t('tickets_scanner.input_placeholder')}
              className="form-control"
              autoComplete="off" />

            {this.isShowAutocomplete() ?
              <SearchAutocomplete
                owners={this.state.owners}
                ref={(ref) => { this.autocompleteRef = ref; }}
              /> : null}
          </div>

          <ExtendedListModal />
        </div>
        <TicketData />
      </span>
    );
  }
})
