/* global React */
import MemberTypePropertyStore from './member_type_property_store';
import CustomFormActions from './custom_form_actions';
import createReactClass from 'create-react-class';

export default createReactClass({
  getInitialState: function () {
    return {
      field_html: <i className='fa fa-spinner fa-spin'></i>
    };
  },

  componentDidMount: function () {
    let form_field_html = MemberTypePropertyStore.getHtmlForMemberTypeProperty(this.props.field_id);
    this.setState({ field_html: <div dangerouslySetInnerHTML={{ __html: form_field_html }}></div> })
  },

  handleRemoveClick: function () {
    CustomFormActions.removeField(this.props.pageNumber, this.props.field_id);
  },

  render: function () {
    return (
      <li data-member-type-property-id={this.props.field_id} data-position={this.props.position}>
        {this.state.field_html}
        <a className="remove_link" onClick={this.handleRemoveClick} href="##">Remove</a>
      </li>
    );
  }
});
