/* global I18n */

import React from 'react'
import { format } from 'date-fns'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'


const ShiftDetailsSmall = ({ shift }) => {
  const { name, startTime, endTime, schedule, duration, bundle, selectionType } = shift
  const shiftTime = `${format(startTime, 'do MMM')}  ${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`
  const shiftLocation = schedule.location

  const showBundle = selectionType === 'preferred'
  let bundleIcon

  if (bundle) {
    bundleIcon = (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip id={bundle.id}>
            {bundle.name}
          </Tooltip>
        )}
      >
        <i className="fa fa-cube" style={{ color: bundle.color }} />
      </OverlayTrigger>
    )
  }

  return (
    <div className="shift-details shift-details-small">
      <div className="shiftTime">
        <p><strong>{shiftTime}</strong></p>
        <p>
          {I18n.t('shift_duration', { scope: 'shift_selector', count: duration })}
          &nbsp;&nbsp;
          {bundle && showBundle && bundleIcon}
        </p>
      </div>
      <div className="shift-info">
        <p>{name}</p>
        <p><i>{shiftLocation}</i></p>
      </div>
    </div>
  )
}

export default ShiftDetailsSmall
