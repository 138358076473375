import { combineReducers } from 'redux'

import loadingStatusReducer from './loadingStatusReducer'
import currentMember from './currentMemberReducer'
import currentEventOrOrganization from './currentEventOrOrganizationReducer'
import ormReducer from './ormReducer'

import { CLEAR_CURRENT_APP } from '../constants/action-types'

const sharedReducer = combineReducers({
  currentMember,
  currentEventOrOrganization
})

const createRootReducer = (appReducer) => {
  const rootReducer = (state = {}, action) => {
    const { currentApp, orm, loadingStatus, ...sharedState } = state
    let currentLoadingStatus = loadingStatus
    let currentAppState = currentApp
    let currentOrm = orm

    if (action.type === CLEAR_CURRENT_APP) {
      currentLoadingStatus = undefined
      currentAppState = undefined
      currentOrm = undefined
    }

    return {
      ...sharedReducer(sharedState, action),
      loadingStatus: loadingStatusReducer(currentLoadingStatus, action),
      orm: ormReducer(currentOrm, action),
      currentApp: (appReducer || (() => {}))(currentAppState, action)
    }
  }

  return rootReducer
}

export default createRootReducer
