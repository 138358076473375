/* global I18n */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, FormControl, Tooltip } from 'react-bootstrap'
import Clipboard from 'clipboard'

import generateId from '../utilities/generateId'

const i18noptions = {
  scope: 'components.clipboard_widget'
}

class ClipboardWidget extends Component {
  constructor(props) {
    super(props)

    this.clipboardId = generateId('clipboard-text')
    this.state = {
      showTooltip: false
    }
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.clipboardButtonRef)
    this.clipboard.on('success', () => {
      this.setState({ showTooltip: true })
    })
  }

  componentWillUnmount() {
    this.clipboard.destroy()
  }

  tooltip = () => {
    const { copiedMessage } = this.props

    if (this.state.showTooltip) {
      return (
        <Tooltip placement="bottom" className="in">
          {copiedMessage || I18n.t('copied', i18noptions)}
        </Tooltip>
      )
    }
  }

  handleHideTooltip = () => {
    this.setState({ showTooltip: false })
  }

  render() {
    const { text, children } = this.props

    return (
      <FormGroup>
        <InputGroup>
          <FormControl
            id={this.clipboardId}
            type="text"
            value={text}
            readOnly
          />
          <InputGroup.Button>
            <button
              className="btn btn-white"
              data-clipboard-target={`#${this.clipboardId}`}
              ref={(button) => { this.clipboardButtonRef = button }}
              onMouseLeave={this.handleHideTooltip}
            >
              <i className="fa fa-clipboard" aria-hidden="true" />
            </button>

            {this.tooltip()}

            {children}
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>
    )
  }
}

ClipboardWidget.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  text: PropTypes.string.isRequired,
  copiedMessage: PropTypes.string
}

ClipboardWidget.defaultProps = {
  children: [],
  copiedMessage: null
}

export default ClipboardWidget
