import React from 'react'

import GroupsCollection from './GroupsCollection'

const ScheduleGroups = (props) => {
  const scheduleProps = schedule => ({
    showSchedule: false,
    name: schedule.name,
    fetchShiftsPath: `registration/attendances/schedules/${schedule.id}/shifts`,
    key: schedule.id,
    ...props
  })

  return (
    <GroupsCollection
      groupProps={scheduleProps}
      fetchGroupsPath="registration/attendances/schedules"
    />
  )
}

export default ScheduleGroups
