import React from 'react'

import memberPropTypes from '../../propTypes/memberPropTypes'

import MemberTypePropertyValue from '../MemberTypePropertyValue'

const PopoverTitle = ({
  member: {
    name,
    memberTypeName,
    profileImage,
    id
  }
}) => (
  <div className="text-center">
    {profileImage && (
      <div className="image-wrapper">
        <MemberTypePropertyValue.ProfileImage
          value={profileImage}
          imageOptions={{
            width: 80,
            height: 80
          }}
          className="img-circle"
        />
      </div>
    )}
    <h2><a href={`/members/${id}`}>{name}</a></h2>
    <h3>{memberTypeName}</h3>
  </div>
)

PopoverTitle.propTypes = {
  member: memberPropTypes.isRequired
}

export default PopoverTitle
