import { getRequest, patchRequest, postRequest, deleteRequest } from './request'

class Client {
  constructor(path) {
    this.path = path
  }

  fetch(id) {
    return getRequest({
      path: this.path.memberPath(id)
    })
  }

  fetchAll() {
    return getRequest({
      path: this.path.collectionPath()
    })
  }

  update(id, { body }) {
    return patchRequest({
      path: this.path.memberPath(id),
      body
    })
  }

  create({ body }) {
    return postRequest({
      path: this.path.collectionPath(),
      body
    })
  }

  destroy(id) {
    return deleteRequest({
      path: this.path.memberPath(id)
    })
  }
}

export default Client
