import { isBefore, parseISO } from "date-fns"

export const getMatchingRequirements = (shiftOrBundle, member) => (
  shiftOrBundle.requirements.filter((requirement) => {
    if (requirement.attendanceCount < requirement.requiredNumber) {
      const matchingPropertyValue = Object.entries(member.properties).find(([id, value]) => {
        if (requirement.property !== id) {
          return false
        }

        if (['birthdate', 'custom_date'].includes(requirement.propertyType)) {
          return isBefore(parseISO(value), parseISO(requirement.requiredValue))
        }

        if (Array.isArray(value)) {
          return value.includes(parseInt(requirement.requiredValue))
        }
        return `${value}` === requirement.requiredValue
      })
      return matchingPropertyValue !== undefined
    }
    return false
  })
)

export const getMatchingRequirementSpots = (shiftOrBundle, member) => (
  getMatchingRequirements(shiftOrBundle, member).length
)

export const getSpotsWithRequirements = shiftOrBundle => (
  shiftOrBundle.requirements
    .map(requirement => requirement.requiredNumber - requirement.attendanceCount)
    .reduce((a, b) => a + b, 0)
)

export const getSpotsWithoutRequirement = shiftOrBundle => (
  shiftOrBundle.availableSpots - getSpotsWithRequirements(shiftOrBundle)
)

export const canSelectShift = (shiftOrBundle, member) => (
  shiftOrBundle.availableSpots > 0 &&
    (getSpotsWithoutRequirement(shiftOrBundle) > 0 || getMatchingRequirementSpots(shiftOrBundle, member) > 0)
)
